import styled from "styled-components";
import {useEffect, useRef, useState} from "react";
import * as React from "react";

import {MyActivite} from "../CompetencesInterfaces";
import {OneActiviteR} from "./OneActiviteR";


interface ListActivitesRCtrlProps{
  className?:string;
  Activites:MyActivite[];
}

const ListActivitesRCtrl = (props:ListActivitesRCtrlProps)=>{

  return (
    <div className={`list_actvites ${props.className}`}>
        <div className={"head"}>
            <div className="lib_item"></div>
            <div className="lib_nivo">Connaissance théorique, sans mise en pratique de l'activité</div>
            <div className="lib_nivo">Mise en oeuvre en situation normale mais supervision nécessaire</div>
            <div className="lib_nivo">Mise en oeuvre autonome et capacité à régler les dysfonctionnements</div>
            <div className="lib_nivo">Mise en oeuvre autonome y compris en situation complexe.</div>
            <div className="lib_nivo">Expert reconnu de l'activité</div>
            <div style={{width:"10px"}} />
        </div>
        <div className={`wrap_list`}>
            <div className={`in_list`}>
                  {props.Activites.sort((a,b)=>a.libelle > b.libelle ? 1 : -1).map(acti=>(
                    <OneActiviteR Activite={acti} key={`OneActi${acti.id}`}/>
                  ))}
            </div>
        </div>
    </div>
  )
}

export const ListActivitesR = styled(ListActivitesRCtrl)`
  padding: 1% 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .wrap_list{
    flex-grow: 1;
    overflow-y: scroll;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .lib_nivo{
    width: ${`${60/5}%`};
    text-align: center;
    font-size: 12px;
    padding: 0.5rem;
    border-right: dashed black 1px;
  }
  .lib_item{
    width: 40%;
  }
`
