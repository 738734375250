import styled, {keyframes} from "styled-components";
import {useEffect, useState} from "react";
import {useGetProxiCvThequeUseMetierSynchro} from "@mbj-front-monorepo/data-access";
import {ImSpinner3} from "react-icons/im";
import {MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface RefreshModalCtrlProps {
  className?:string;
  idPart:number;
  closeMe:()=>void;
}

const RefreshModalCtrl = ({className, idPart, closeMe}: RefreshModalCtrlProps) => {
  const [idParticulier, setIdParticulier] = useState(-1)
  const [time, setTime] = useState(new Date().getTime())
  const ProxiQuery = useGetProxiCvThequeUseMetierSynchro(idParticulier, time);
  const ValideRefresh = ()=>{
    setTime(new Date().getTime())
    setIdParticulier(idPart);
  }
  useEffect(() => {
    if(!ProxiQuery.isLoading && !ProxiQuery.isFetching && idParticulier!==-1){
      closeMe();
    }
  }, [ProxiQuery.isLoading, ProxiQuery.isFetching, idParticulier]);
  return (
    <div className={`refresh_cv ${className}`}>
      {idParticulier === -1 ?
        <div className={"ask_for"}>
          <p>Vous souhaitez rafraichir les proximités, cela peut prendre un peu de temps, souhaitez-vous lancer le rafraichissement ?</p>
          <div className="wrap_valide">
            <MbjButton size={"sm"} type="button" onClick={ValideRefresh} themeColor={"primary"}>
              Rafraichir
            </MbjButton>
          </div>
        </div>:
        <div className="refresh_curr">
          <p>Rafraichissement en cours</p>
          <div className="marker">
            <div className="wrap_spin">
              <ImSpinner3 />
            </div>
          </div>
        </div>
      }

    </div>
  )
}
const AnimateRotate = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`

export const RefreshModal = styled(RefreshModalCtrl)`
  width: 450px;
  padding: 1rem;
  .ask_for{
    p{
      text-align: center;
    }
    .wrap_valide{
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
  }
  .refresh_curr{
    p{
      font-size: 24px;
      text-align: center;
    }
  }
  .marker{
    font-size: 32px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    .wrap_spin{
        width: 32px;
        height: 32px;
        display: flex;
      animation: ${AnimateRotate} 0.7s infinite linear;
    }
  }
`
