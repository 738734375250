import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import AlliancesDashboard from "./Dashboard/AlliancesDashboard";
import {GestionMembreRooter} from "./GestionMembres/GestionMembreRooter";
import {MesAlliancesRooter} from "./MesAlliances/MesAlliancesRooter";

export function AlliancesRooter(){
    return (
        <Routes>
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path={"dashboard"} element={<AlliancesDashboard/>}/>
            <Route path={"mesalliances/*"} element={<MesAlliancesRooter/>}/>
        </Routes>
    )
}
