import styled from "styled-components";
import {useGetBesoinsFormationEntreprise, useGetFormationPlanningMember} from "@mbj-front-monorepo/data-access";
import {
  MbjCardBack,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjLoading, MbjNumber,
  MbjStatsNumber,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import React from "react";

interface StatsPlDashCardCtrlProps{
  className?:string;
}

const StatsPlDashCardCtrl = (props:StatsPlDashCardCtrlProps)=>{
  const PlannQuery = useGetFormationPlanningMember();
  return (
    <div className={`stats ${props.className}`}>
      {PlannQuery.isLoading ? (
        <MbjLoading />
      ) : PlannQuery.error ? (
        <p>Erreur ...</p>
      ) : (
          <>
            <MbjCardBack>
              <MbjNumber
                legend={PlannQuery.data.length > 1 ?  "Formations priorisées" : "Formation priorisée"}
                number={PlannQuery.data.length}
                themeColor={"third"}
              />
            </MbjCardBack>
            <MbjCardBack>
              <MbjNumber
                  legend={"Non planifiées"}
                  number={PlannQuery.data.filter(p=>!p.souhaitAt).length}
                  themeColor={"third"}
              />
            </MbjCardBack>
            <MbjCardBack>
              <MbjNumber
                  legend={"Planifiées"}
                  number={PlannQuery.data.filter(p=>p.souhaitAt).length}
                  themeColor={"third"}
              />
            </MbjCardBack>
          </>
        )
      }
    </div>
  )
}

export const StatsPlDashCard = styled(StatsPlDashCardCtrl)`
  display: flex;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
`
