import styled from "styled-components";
import {useForm} from "react-hook-form";
import {MbjButton, MbjSimpleInput, MbjSimpleInputContainer, MbjSimpleTextArea} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {usePostDemandeContact} from "@mbj-front-monorepo/data-access";
import {MyOF} from "./CardFormation";
import {AiOutlineIdcard} from "react-icons/ai";
import {IoLocationSharp} from "react-icons/io5";
import {MdOutlineAlternateEmail, MdOutlinePhonelinkRing} from "react-icons/md";
import {CgWebsite} from "react-icons/cg";
import {GiPathDistance} from "react-icons/gi";
import {useState} from "react";



interface FormAddAskContactOFCtrlProps{
    className?:string;
    MyAsker:MyOF;
    closeMe:()=>void;
}

const FormAddAskContactOFCtrl = ({className, MyAsker, ...props}:FormAddAskContactOFCtrlProps)=>{
    return (
        <div className={`form_contact_of ${className}`}>
                <p className={"intro"}>Vous avez demandé une mise en contact avec l'organisme de formation <strong>{MyAsker.libelleOF}</strong><br/> concernant la formation <strong>{MyAsker.libelleFormation}</strong>.<br/>Vous pouvez mettre un commentaire ci-dessous</p>
              <div className={`informations_orga`}>
                {MyAsker.adresse &&
                  <div className={`one_infos`}>
                    <div className="icon"><IoLocationSharp/></div>
                    <div className="info">{MyAsker.adresse}</div>
                  </div>
                }
                {MyAsker.email &&
                  <div className={`one_infos`}>
                    <div className="icon"><MdOutlineAlternateEmail /></div>
                    <div className="info">{MyAsker.email}</div>
                  </div>
                }
                {MyAsker.telephone &&
                  <div className={`one_infos`}>
                    <div className="icon"><MdOutlinePhonelinkRing /></div>
                    <div className="info">{MyAsker.telephone}</div>
                  </div>
                }
                {MyAsker.siteWeb &&
                  <div className={`one_infos`}>
                    <div className="icon"><CgWebsite /></div>
                    <div className="info">{MyAsker.siteWeb}</div>
                  </div>
                }
                {MyAsker.distance &&
                  <div className={`one_infos`}>
                    <div className="icon"><GiPathDistance/></div>
                    <div className="info">{MyAsker.distance}</div>
                  </div>
                }

              </div>
        </div>
    )
}

export const FormAddAskContactOF = styled(FormAddAskContactOFCtrl)`
  .intro{
    font-size: 16px;
    margin-bottom: 20px;
  }
  .wrapper_sender{
    padding: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .informations_orga{
    margin: 15px 0;
  }
  .one_infos{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    .icon{
      font-size: 18px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
`
