import {Navigate, Route, Routes} from "react-router-dom";
import * as React from "react";
import Recherches from "./Recherches";
import {OneRechercheRooter} from "./OneRecherche/OneRechercheRooter";


export const RecherchesRooter = ()=>{
    return (
        <Routes>
            <Route path="" element={<Recherches />} />
            <Route path={":id/*"} element={<OneRechercheRooter />} />
        </Routes>
    )
}
