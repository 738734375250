import {fetchApi, Operation} from "@mbj-front-monorepo/data-access";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";


export const useGetOFMBJMe = () => {
  const promise = () => fetchApi("/api/organisme_formation_mbjs/me", "get");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["OFMBJ", "me"], promise);
};


export const useGetAllianceMe = (idOF:number)=>{
  const promise = ()=>fetchApi("/api/o_f_alliances", {method:"get", query:{OF:idOF}})
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliances_OFMJ"], promise, {
    enabled:idOF!==0
  })
}

export const useMutateOFMBJ = (id:number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putOrganismeFormationMBJItem", "requestBody">) => {
      return fetchApi("/api/organisme_formation_m_b_js/{id}", { method: "put", params: { id: id + "" }, json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["OFMBJ", "me"]);
        queryClient.invalidateQueries(["OFMBJ"]);
        queryClient.invalidateQueries(["me"]);
        toast.success("Centre modifié !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
