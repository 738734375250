import styled from "styled-components";
import { useGetEmployeeByUserId } from "@mbj-front-monorepo/data-access";
import { MbjButton, MbjCardBack, MbjListItem, useModal } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { GoPencil, GoPlus } from "react-icons/go";
import { ModalEditLeadService } from "./ModalEditLeadService";

interface ListeMembreEquipeCtrlProps{
    className?:string;
    Service:any
}

const ListeMembreEquipeCtrl = (props:ListeMembreEquipeCtrlProps)=>{

    const getUserInfo = (str: string) => {
      const tab = str.split('/')

      return tab[tab.length - 1]
    }
    const userQuery = useGetEmployeeByUserId(props.Service.user ? getUserInfo(props.Service.user) : "")
    const {isShowing, toggle} = useModal();
    const title = {
      color: "#53838b",
      fontWeight: "600",
      fontSize: "14px",
      textDecoration: "underline",
      marginTop: "1em"
    }
    const srvtxt = {
      color: "#222",
      fontWeight: "600",
      fontSize: "15px",
      marginTop: ".5em"
    }

    return (
        <MbjCardBack title={"membres"}>
            <div className={`liste_membres ${props.className}`}>
                {props.Service.Employees?.map((employee: any) => (
                    <MbjListItem key={employee.id} to={"../../collaborateurs/" + employee.id}>
                  <span>
                    {employee.nom?.toUpperCase()} {employee.prenom}
                  </span>
                    </MbjListItem>
                ))}
            </div>
            <div>
              <p style={title}>Chef de service</p>
              <p style={srvtxt}>
                {userQuery.isSuccess && userQuery.data.length > 0 ? <>
                    <p>{userQuery.data[0].nom + " " + userQuery.data[0].prenom}</p><br />
                    <MbjButton onClick={toggle} leftIcon={<GoPencil />} themeColor={"third"}>
                      Modifier le chef de service
                    </MbjButton>
                  </>:<>
                    <p>Aucun chef de service</p><br />
                    <MbjButton onClick={toggle} leftIcon={<GoPlus />} themeColor={"third"}>
                      Ajouter le chef de service
                    </MbjButton>
                  </>
                }
              </p>
            </div>
          <ModalEditLeadService isShowing={isShowing} hide={toggle} service={props.Service} />
        </MbjCardBack>
    )
}

export const ListeMembreEquipe = styled(ListeMembreEquipeCtrl)`
  max-height: 320px;
  overflow-y: auto;


`
