import styled from "styled-components";
import {useParams} from "react-router-dom";
import {
    components,
    operations,
    useGetAllianceInvitationsSended,
    useGetAllianceMembers, useGetDemandeCurrent, useGetRight
} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {MbjButton, MbjErrorData, MbjLoading, MbjNoResult, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {ModalNewMembre} from "./components/ModalNewMembre";
import {OneInvitationCurrent} from "./components/OneInvitationCurrent";

interface AllianceInvitationsCurrentCtrlProps{
    className?:string;
}

const AllianceInvitationsCurrentCtrl = (props:AllianceInvitationsCurrentCtrlProps)=>{
    const {isLoading:loadRightW, isAuth:isAuthW} = useGetRight("Alliances", "write", "Mes Alliances", "", "")
    const {id} = useParams();
    const InvitationQuery = useGetDemandeCurrent(id ? parseInt(id) : 0);
    const {isShowing, toggle} = useModal();
    const listCurrent = useMemo(()=>{
        let list:(components["schemas"]["DemandeInvitationAlliance"])[] = [];
        if(InvitationQuery.data){
            list = InvitationQuery.data.filter(i=>!i.treatAt)
        }
        return list;
    }, [InvitationQuery.data])
    const ClickNewInvit = ()=>{
        toggle();
    }
    return (
        <div className={`gestion_invitations ${props.className}`}>
            <div className={`wrap_actions_top`}>
                {isAuthW &&
                    <MbjButton onClick={ClickNewInvit} themeColor={"third"} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                        Inviter un nouveau membre
                    </MbjButton>
                }
            </div>
            <MbjWidgetClassic title={"Invitation en cours de traitement"}>
                {InvitationQuery.isLoading ? <MbjLoading/> : InvitationQuery.isError ? <MbjErrorData/> :
                    <>
                        {listCurrent.map((item) => (
                            <OneInvitationCurrent key={`OnI${item.id}`} Invitation={item}/>
                        ))}
                        {listCurrent.length === 0 &&
                            <MbjNoResult text={"Aucune invitation en cours"}/>
                        }
                    </>
                }
            </MbjWidgetClassic>
            <ModalNewMembre isShowing={isShowing} hide={toggle}/>
        </div>
    )
}

export const AllianceInvitationsCurrent = styled(AllianceInvitationsCurrentCtrl)`
    padding: 2% 1%;
    .wrap_actions_top{
      display: flex;
      justify-content: flex-end;
      padding: 0.5rem 0;
    }
`
