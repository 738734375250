import styled from "styled-components";
import {Operation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import {MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useMemo} from "react";
import {ExpertCard} from "../../../../../../components/Experts/ExpertCard";

interface ExpertSuiviOneCompetenceWidgetCtrlProps{
  className?:string;
  activite:{id:number, libelle:string, niveau:number}
  employee: Operation<"getEmployeeItem">;
}

export interface ExpertOne{
  employee:{id:number, prenom:string, nom:string, service:string}
  niveau:number;
}

const ExpertSuiviOneCompetenceWidgetCtrl = (props:ExpertSuiviOneCompetenceWidgetCtrlProps)=>{
  if (!props.employee.id) throw new TypeError("employee.id is undefined");
  const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee.id, props.employee?.UserMetier?.id);
  const myList = useMemo(()=>{
    const list:ExpertOne[] = [];
    if(useGetMobBilanQuery.data){
      if(useGetMobBilanQuery.data.Experts){

        useGetMobBilanQuery.data.Experts.filter(e=>{
          const ActivitesCompare = e.activites?.find(a=>a.activite?.id === props.activite.id);
          if(e.id === 2){
            console.log(e.activites);
          }
          return ActivitesCompare &&  ActivitesCompare.niveau && ActivitesCompare.niveau > props.activite.niveau
        }).map(e=> {
          const ActivitesCompare = e.activites?.find(a=>a.id);
          const niveau = ActivitesCompare && ActivitesCompare.niveau ? ActivitesCompare.niveau : 0
          list.push({
            employee:{id:e.employee?.id||0, prenom:e.employee?.prenom||'nc', nom:e.employee?.nom||'nc', service:e.employee?.Service||'nc' },
            niveau:niveau
          })
        })
      }
    }
    return list;
  }, [useGetMobBilanQuery.data, props.activite])

  return (
    <MbjWidgetClassic title="Experts pouvant former à cette compétence" className={props.className}>
      <div className={`wrap_experts`}>
      {myList.map((item:ExpertOne, idx:number)=>(
        <ExpertCard key={`on_expert_one${idx}`} ExpertOne={item} niveauActuel={props.activite.niveau}/>
      ))}
      </div>
    </MbjWidgetClassic>
  )
}

export const ExpertSuiviOneCompetenceWidget = styled(ExpertSuiviOneCompetenceWidgetCtrl)`
  .wrap_experts{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
  }
`
