import styled from "styled-components";
import {MbjModal, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {AllMobilitesInternes} from "./AllMobilitesInternes";
import {MobiliteInterneEntretien} from "./MobiliteInterneEntretien";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
import {useState} from "react";


interface OneCollaborateurMobiliteInterneCtrlProps{
    className?:string;
}

interface ForProxi{
    idCompareA:number;
    idCompareB:number;
}

const OneCollaborateurMobiliteInterneCtrl = (props:OneCollaborateurMobiliteInterneCtrlProps)=>{
    const { isShowing, toggle } = useModal();
    const [forP, setForP] = useState<ForProxi|null>(null);
    const setForPTrigger = (idA:number, idB:number)=>{
        setForP({idCompareA:idA, idCompareB:idB})
        toggle();
    }
    return (
        <div className={`mob_ext ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
                <MbjWidgetClassic basis={"calc(50% - 20px)"}>
                    <MobiliteInterneEntretien setForPTrigger={setForPTrigger}/>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"calc(50% - 20px)"}>
                    <AllMobilitesInternes setForPTrigger={setForPTrigger}/>
                </MbjWidgetClassic>
            </MbjWidgetClassic>
            <MbjModal isShowing={isShowing} hide={toggle} maxWidth={"90%"}>
                <div>
                    <MbjCompareReferentiel
                        idToCompare1={forP?.idCompareA||0}
                        idToCompare2={forP?.idCompareB||0}
                        typeCompared={"EUM"}
                    />
                </div>
            </MbjModal>
        </div>
    )
}

export const OneCollaborateurMobiliteInterne = styled(OneCollaborateurMobiliteInterneCtrl)``
