import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjTable, MbjLabel,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  useGetEmployeesCountActivityLevel,
} from "@mbj-front-monorepo/data-access";
import { EmployeeActivityCount } from "./EmpoyeeActivityCount";

interface StatistiquesPriorisationCtrlProps {
  className?: string;
  info: any
}

const CountActivityTitles = [
  { libelle: "Niveau", Align: "isCenter" },
  { libelle: "Nombre de compétences", Align: "isCenter" },
];


function StatistiquesPriorisationCtrl(props: StatistiquesPriorisationCtrlProps) {

  const employeeCountActivityQuery = useGetEmployeesCountActivityLevel(props.info.empID)

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Statistiques sur les analyses de priorisation pour ce profil"}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="Nom">
              <MbjLabel>Nom et prénom du collaborateur </MbjLabel>
              <div className="data">{props.info.nom + ' ' + props.info.prenom }</div>
              <MbjLabel>Service </MbjLabel>
              <div className="data">{props.info.service }</div>
              <MbjLabel>Métier </MbjLabel>
              <div className="data">{props.info.namePerso }</div>
            </div>
          </MbjCardClassic>
          {<MbjCardClassic title="Nombre de compétences maîtrisées par niveau" basis={"100%"}>
            {employeeCountActivityQuery.isLoading ? <MbjLoading /> : employeeCountActivityQuery.data?.length > 0 ?
              <MbjTable titles={CountActivityTitles} themeColor={"primary"} noBorderRadius>
                {employeeCountActivityQuery.data?.map((item: any) => <EmployeeActivityCount item={item} />)}
              </MbjTable> : <p>Aucun résultat</p>}
          </MbjCardClassic>}
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const StatistiquesPriorisation = styled(StatistiquesPriorisationCtrl)((props) => ({
  padding: "1% 2%",

  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
