import styled from "styled-components";
import {Navigate, Route, Routes, To, useParams} from "react-router-dom";
import {
    InternSubDesktopLayout,
    MbjLoading
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {DashboardSuivi} from "./Dashboard/DashboardSuivi";
import {FormationsSuivi} from "./Formations/FormationsSuivi";
import {ExpertsSuivi} from "./Experts/ExpertsSuivi";
import {CompetencesSuivi} from "./Competences/CompetencesSuivi";
import {useGetEmployee, useGetRight} from "@mbj-front-monorepo/data-access";
import {NoUserMetierPage} from "./NoUserMetierPage";
import {MatrixComp} from "./MatrixComp/MatrixComp";

interface OneCollaborateurSuiviCtrlProps{
    className?:string
}

const TabSubMenus:{libelle:string, to:To}[] = [
    {libelle: 'Tableau de bord', to:"dashboard"},
    {libelle: 'Compétences à améliorer', to:"competence_to_rise"},
    {libelle: 'Matrice de compétences', to:"competence_matrix"},
    {libelle: 'Experts', to:"experts"},
    {libelle: 'Formations', to:"formations"},
]

const OneCollaborateurSuiviCtrl = (props:OneCollaborateurSuiviCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs", "OneCollab", "suivi")
    const {id} = useParams();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    if(employeeQuery.isLoading){
        return (
            <div><MbjLoading/></div>
        )
    } else if(!employeeQuery.data?.UserMetier){
        return <NoUserMetierPage employee={employeeQuery.data}/>
    }
    return (
        <Routes>
            <Route
                element={
                    <InternSubDesktopLayout
                        Links={TabSubMenus}
                    />
                }
            >
                <Route path="" element={<Navigate to="dashboard" />} />
                <Route path="/dashboard" element={<DashboardSuivi employee={employeeQuery.data} />} />
                <Route path="/competence_to_rise" element={<CompetencesSuivi employee={employeeQuery.data} />} />
                <Route path="/competence_matrix" element={<MatrixComp employee={employeeQuery.data} />} />
                <Route path="/experts" element={<ExpertsSuivi employee={employeeQuery.data} />} />
                <Route path="/formations" element={<FormationsSuivi employee={employeeQuery.data}/>} />
            </Route>
        </Routes>
    );
}

export const OneCollaborateurSuivi = styled(OneCollaborateurSuiviCtrl)`

`
