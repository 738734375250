import styled from "styled-components";
import { MbjButton, MbjFlex, MbjNavPath, useModal } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { ConversationsListe } from "./ConversationsListe/ConversationsListe";
import { FaPlus } from "react-icons/fa";
import { NewConversationModal } from "./NewConversationModal/NewConversationModal";
import { ConversationDetail } from "./ConversationDetail/ConversationDetail";

interface MessagerieCtrlProps {
  className?: string;
}

const MessagerieCtrl = (props: MessagerieCtrlProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { isShowing: showNewConversation, toggle: toggleNewConversation } = useModal();

  return (
    <div className={props.className}>
      <MbjFlex height={"100%"} wrap={"nowrap"} gap={"0"}>
        <ConversationsListe width={"calc(25% - 5px)"} newConversation={toggleNewConversation}/>
        {searchParams.get("id") && <ConversationDetail width={"calc(75% - 5px)"} />}
      </MbjFlex>
      <NewConversationModal isShowing={showNewConversation} hide={toggleNewConversation}></NewConversationModal>
    </div>
  );
};

export const Messagerie = styled(MessagerieCtrl)`
  padding: 1% 1%;
  height: 95%;
  .top-container{
    margin-bottom: 8px;
  }
`
