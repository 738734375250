import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {FormNewAlliance} from "./FormNewAlliance";

interface ModalNewAllianceCtrlProps extends MbjModalCtrlProps{}

const ModalNewAllianceCtrl = (props:ModalNewAllianceCtrlProps)=>{
    return (
        <MbjModal {...props} title="Nouvelle Alliance" fitContent>
            <FormNewAlliance close={props.hide}/>
        </MbjModal>
    )
}

export const ModalNewAlliance = styled(ModalNewAllianceCtrl)``
