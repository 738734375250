import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";
import {toast} from "react-toastify";
import {components} from "../../openApi-schema";
import {useGetMemberMe} from "../member";


export const usePostNewDemandeInvitation = ()=>{
    const queryClient = useQueryClient();
    return useMutation( (datas:components["schemas"]["DemandeInvitationAlliance"])=>{
            return fetchApi("/api/demande_invitation_alliances", {
                method: "post",
                json: datas,
            });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["alliance_members"]);
                queryClient.invalidateQueries(["alliances"]);
                queryClient.invalidateQueries(["member"]);
                toast.success("Invitation ajoutée !", {});
            },
            onError: (error: any) => {
                toast.error("Erreur : " + error.message, {});
            },
        }
    );
}

export const useGetDemandeCurrent = (id:number) => {
    const memberQuery = useGetMemberMe();
    const memberId = memberQuery.data?.id;
    const promise = () => fetchApi("/api/demande_invitation_alliances", { method: "get", query: { MemberFrom: memberId + "", Alliance:id+"" } });
    return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["alliance_invitation_current", { memberId }], promise, {
        enabled: !!memberId && id!==0,
    });
};
