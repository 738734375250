import * as React from "react";
import styled from "styled-components";
import {  useParams } from "react-router-dom";
import {
    MbjButton,
    MbjCardClassic,
    MbjFlex,
    MbjLoading, MbjMenuChoixOnPage,
    MbjModal,
    MbjNoResult,
    MbjWidgetClassic,
    ItemMenuChoixOnPage,
    useModal,
} from "@mbj-front-monorepo/ui";
import { useGetEmployee, useGetFI, useGetFICourte, useGetRight, useGetXp } from "@mbj-front-monorepo/data-access";
import {
    MbjEmployeeFiCard,
    MbjEmployeeFiNewForm,
    MbjEmployeeFiNewFormIA,
    MbjEmployeeFiCourteNewForm,
    MbjEmployeeXpCard,
    MbjEmployeeXpNewForm,
} from "@mbj-front-monorepo/data-ui";
import { GoPlus } from "react-icons/go";
import { useState } from "react";



interface OneCollaborateurCVCtrlProps {
    className?: string;
}

const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Expériences professionnelles'},
    {id:1, libelle:'Formations certifiantes'},
    {id:2, libelle:'Autres Formations'},
]

function OneCollaborateurCVCtrl(props: OneCollaborateurCVCtrlProps) {
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs", "OneCollab", "cv")
    const { id } = useParams();
    const { isShowing: showNewXp, toggle: toggleNewXp } = useModal();
    const { isShowing: showNewFi, toggle: toggleNewFi } = useModal();
    const { isShowing: showNewCourteFi, toggle: toggleNewCourteFi } = useModal();
    const { isShowing: showChoice, toggle: toggleChoice } = useModal();
    const { isShowing: showLibelle, toggle: toggleLibelle } = useModal();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>({id:0, libelle:'Expériences professionnelles'})

    const xpQuery = useGetXp(parseInt(id as string));
    const FisQuery = useGetFI(parseInt(id as string));
    const FisCourteQuery = useGetFICourte(parseInt(id as string));
    const DownloadCV = ()=>{
        window.open(`${process.env["REACT_APP_API_URL_ROOT"]}/documents/${employeeQuery.data?.CvDocument}`)
    }
    /*useEffect(() => {
      fetch('https://34.170.181.193:8081/formations_certifiantes', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: JSON.stringify({
          "phrase": "permis de conduire",
          "employee_id": 1048
        })
      }).then((res: any) => console.log(res))
    }, [])*/
    return (
        <div className={"CV " + props.className}>
            {employeeQuery.isLoading ? (
                <MbjLoading />
            ) : employeeQuery.isError ? (
                <p>Erreur ...</p>
            ) : (
                <>
                    {employeeQuery.data?.CvDocument &&
                        <MbjButton size={"sm"} themeColor={"primary"} onClick={DownloadCV}>Télécharger le cv</MbjButton>
                    }
                <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={Menus}/>
                    {MenuCurrent.id === 0 && <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
                      {isAuthWrite &&
                        <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
                          <MbjButton onClick={toggleNewXp} themeColor={"third"} leftIcon={<GoPlus />}>
                            Ajouter une expérience professionnelle
                          </MbjButton>
                        </div>
                      }
                      {xpQuery.data && xpQuery.data?.length > 0 ? (
                        <MbjFlex direction={"column"}>
                          {xpQuery.isLoading ? (
                            <MbjLoading />
                          ) : xpQuery.isError ? (
                            <p>Error...</p>
                          ) : (
                            xpQuery.data?.map((xp) => <MbjEmployeeXpCard EmployeeXp={xp} key={xp.id} isReadOnly={!isAuthWrite} />)
                          )}
                        </MbjFlex>
                      ) : (
                        <MbjCardClassic>
                          <div style={{padding:"20px"}}>
                            <MbjNoResult text={"Aucune expérience professionnelle"} />
                          </div>
                        </MbjCardClassic>
                      )}
                    </MbjWidgetClassic>}
                    {MenuCurrent.id === 1 && <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
                        {isAuthWrite &&
                        <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
                            <MbjButton onClick={toggleChoice} themeColor={"third"} leftIcon={<GoPlus />}>
                                Ajouter une formation certifiante
                            </MbjButton>
                        </div>
                        }
                        {FisQuery.data && FisQuery.data?.length > 0 ? (
                            <MbjFlex direction={"column"} gap={"20px"}>
                                {FisQuery.isLoading ? (
                                    <MbjLoading />
                                ) : FisQuery.isError ? (
                                    <p>Error...</p>
                                ) : (
                                    FisQuery.data?.map((fi, idx: number) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} isReadOnly={!isAuthWrite} />)
                                )}
                            </MbjFlex>
                        ) : (
                            <MbjCardClassic>
                                <div style={{padding:"20px"}}>
                                    <MbjNoResult text={"Aucune formation certifiante"} />
                                </div>
                            </MbjCardClassic>
                        )}

                    </MbjWidgetClassic>}
                    {MenuCurrent.id === 2 && <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
                        {isAuthWrite &&
                        <div style={{display:"flex", justifyContent:"flex-end", gap:"10px"}}>
                            <MbjButton onClick={toggleNewCourteFi} themeColor={"third"} leftIcon={<GoPlus />}>
                                Ajouter une autre formation
                            </MbjButton>
                        </div>
                        }
                        {FisCourteQuery.data && FisCourteQuery.data?.length > 0 ? (
                            <MbjFlex direction={"column"} gap={"20px"}>
                                {FisCourteQuery.isLoading ? (
                                    <MbjLoading />
                                ) : FisCourteQuery.isError ? (
                                    <p>Error...</p>
                                ) : (
                                    FisCourteQuery.data?.map((fi, idx: number) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} isReadOnly={!isAuthWrite} />)
                                )}
                            </MbjFlex>
                        ) : (
                            <MbjCardClassic>
                                <div style={{padding:"20px"}}>
                                    <MbjNoResult text={"Aucune autre formation"} />
                                </div>
                            </MbjCardClassic>
                        )}

                    </MbjWidgetClassic>}
                </>
            )}
            {id && (
                <>

                    <MbjModal isShowing={showNewXp} hide={toggleNewXp} fitContent title="Nouvelle expérience professionnelle">
                        <MbjEmployeeXpNewForm idEmployee={parseInt(id.toString())} hideModal={toggleNewXp} />
                    </MbjModal>
                    <MbjModal isShowing={showChoice} hide={toggleChoice} title={"Choix mode d'ajout"} >
                      <MbjFlex width={"100%"} direction={"column"} gap={"20px"}>
                        <MbjButton size={"lg"} type="button" onClick={toggleNewFi} themeColor={"primary"} isfully={true}>
                          Renseigner la formation
                        </MbjButton>
                        <MbjButton size={"lg"} type="button" onClick={toggleLibelle} themeColor={"primary"} isfully={true}>
                          Saisir le libellé
                        </MbjButton>
                      </MbjFlex>
                    </MbjModal>
                  <MbjModal isShowing={showNewFi} hide={toggleNewFi} fitContent title="Nouvelle formation certifiante">
                    <MbjEmployeeFiNewForm idEmployee={parseInt(id.toString())} hideModal={toggleNewFi} />
                  </MbjModal>
                  <MbjModal isShowing={showNewCourteFi} hide={toggleNewCourteFi} fitContent title="Nouvelle formation courte">
                    <MbjEmployeeFiCourteNewForm idEmployee={parseInt(id.toString())} hideModal={toggleNewCourteFi} />
                  </MbjModal>
                  <MbjModal isShowing={showLibelle} hide={toggleLibelle} fitContent title="Nouvelle formation certifiante depuis le libellé">
                    <MbjEmployeeFiNewFormIA idEmployee={parseInt(id.toString())} hideModal={toggleLibelle} hideChoiceModal={toggleChoice} />
                  </MbjModal>
                </>
            )}
        </div>
    );
}
export const OneCollaborateurCV = styled(OneCollaborateurCVCtrl)((props) => ({
    padding: "2% 2%",
}));
