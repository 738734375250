import styled from "styled-components";
import {MbjBlockMenu} from "@mbj-front-monorepo/ui";

interface MbjMenuDesktop2CtrlProps{
  className?:string;
  Blocks:MbjBlockMenu[];
}

const MbjMenuDesktop2Ctrl = (props:MbjMenuDesktop2CtrlProps)=>{
  return (
    <div className={`mbj_menu ${props.className}`}>

    </div>
  )
}

export const MbjMenuDesktop2 = styled(MbjMenuDesktop2Ctrl)``

