import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchApi, useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { toast } from "react-toastify";

export const useAddSettingsEntretien = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  return useMutation(
    {
      mutationFn: (data: any) => {
        return fetchApi("/api/setting_entretiens", {
          method: "post",
          json: {...data, "member": "/api/members/" + memberId}
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["setting_entretien_pro"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
}


export const useEditSettingsEntretien = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  return useMutation(
    {
      mutationFn: (data: any) => {
        return fetchApi("/api/setting_entretiens/{id}", {
          params: {id: memberId},
          method: "put",
          json: data
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["setting_entretien_pro"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
}



export const useGetSettingEntretien = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/setting_entretiens/{id}", {
    method: "get",
    params: { id: memberId + "" }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["setting_entretien_pro", { memberId }], promise, {
    enabled: !!memberId
  });
};
