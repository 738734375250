import styled from "styled-components";
import {useAddNiveauVerif, useGetConnected, useGetEmployees, useGetRefActvites} from "@mbj-front-monorepo/data-access";
import {useMemo, useRef, useState} from "react";
import {GrClose} from "react-icons/gr";
import {OneCardActiToChoice} from "./components/OneCardActiToChoice";
import {OneCardActiToRemove} from "./components/OneCardActiToRemove";
import {OneCardEmplToChoice} from "./components/OneCardEmplToChoice";
import {OneCardEmplToRemove} from "./components/OneCardEmplToRemove";
import * as React from "react";
import {MbjButton, MbjModal, useModal} from "@mbj-front-monorepo/ui";
import {FaCheck} from "react-icons/fa";
import {ModalSuccessIn} from "./components/ModalSuccessIn";

interface NouvelleFormationCtrlProps {
  className?:string;
}

export interface OnActiChoice{
  id:number;
  libelle:string;
}

export interface OnEmplChoice{
  id:number;
  libelle:string;
}

interface OneNivo{
  nivo:number;
  tooltip:string;
}

const listNivo:OneNivo[] = [
  {nivo:1, tooltip:"Connaissance théorique, sans mise en pratique de l'activité"},
  {nivo:2, tooltip:"Mise en oeuvre en situation normale mais supervision nécessaire"},
  {nivo:3, tooltip:"Mise en oeuvre autonome et capacité à régler les dysfonctionnements"},
  {nivo:4, tooltip:"Mise en oeuvre autonome y compris en situation complexe."},
  {nivo:5, tooltip:"Expert reconnu de l'activité"}
]

const NouvelleFormationCtrl = ({className}: NouvelleFormationCtrlProps) => {
  const ActivitesQuery = useGetRefActvites()
  const EmployeesQuery = useGetEmployees();
  const [ActivitesSelected, setActivitesSelected] = useState<OnActiChoice[]>([])
  const [EmployeesSelected, setEmployeesSelected] = useState<OnEmplChoice[]>([])
  const refSearch = useRef<HTMLInputElement>(null)
  const refEmpSearch = useRef<HTMLInputElement>(null)
  const [search, setSearch] = useState('')
  const [searchEmpl, setSearchEmpl] = useState('')
  const [nivoSelected, setNivoSelected] = useState<number>(0)
  const mutationVerif = useAddNiveauVerif();
  const UserConnectedQuery = useGetConnected();
  const {isShowing, toggle} = useModal();

  const listActi = useMemo(()=>{
    const list:OnActiChoice[] = [];
    if(ActivitesQuery.data && search.length >=3){
      const regex = new RegExp("" + search + "", "gi");
      ActivitesQuery.data.forEach(a=>{
        if(ActivitesSelected.map(ax=>ax.id).indexOf(a.id||0) === -1 && a.id && a.libelle && (a.libelle.match(regex))) list.push({id:a.id, libelle:a.libelle})
      })
    }
    return list;
  }, [ActivitesQuery.data, search, ActivitesSelected])
  const listEmployee = useMemo(()=>{
    const list:OnEmplChoice[] = [];
    if(EmployeesQuery.data){
      const regex = new RegExp("" + searchEmpl + "", "gi");
      EmployeesQuery.data.forEach(e=>{
        const nameEmployee = (e.prenom||'')+" "+(e.nom||'')
        if(EmployeesSelected.map(ex=>ex.id).indexOf(e.id||0) === -1 && e.id && e.prenom && e.nom && (searchEmpl === '' || nameEmployee.match(regex))) list.push({id:e.id, libelle:nameEmployee})
      })
    }
    return list;
  }, [EmployeesQuery.data, searchEmpl, EmployeesSelected])
  const HandleChangeSearchActi = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value)
    }
  }
  const HandleChangeSearchEmp = ()=>{
    if(refEmpSearch.current){
      setSearchEmpl(refEmpSearch.current.value)
    }
  }
  const AddActi = (a:OnActiChoice)=>{
    setActivitesSelected(as=>[...as, a])
  }
  const AddEmpl = (e:OnEmplChoice)=>{
    setEmployeesSelected(es=>[...es, e])
  }
  const RemoveActi = (a:OnActiChoice)=>{
    setActivitesSelected(as=>[...as.filter(i=>i.id !== a.id)])
  }
  const RemoveEmpl = (e:OnEmplChoice)=>{
    setEmployeesSelected(es=>[...es.filter(i=>i.id !== e.id)])
  }
  const SaveValidation = ()=>{
    if(ActivitesSelected.length === 0 || EmployeesSelected.length === 0){
      alert('Selectionnez au moins une compétence et un collaborateur')
    }
    const Tab:any[] = [];
    EmployeesSelected.forEach(e=>{
      ActivitesSelected.forEach(a=>{
        Tab.push({
          Employee:`/api/employees/${e.id}`,
          /** Format: iri-reference */
          UserVerif: `/api/users/${UserConnectedQuery.data?.id||0}`,
          Activite: `/api/activites/${a.id}`,
          niveau: nivoSelected,
        })
      })
    })
    Tab.forEach(item=>{
      mutationVerif.mutate(item, {
        onSuccess:()=>{
          toggle();
        }
      })
    })
  }
  return (
    <div className={"nouvelle_formation " + className}>
      <p>Afin de confirmer le niveau des collaborateurs, merci de sélectionner le niveau acquis au terme de la formation, les compétences
        concernées et les
        collaborateurs</p>
      <div className="title_block">Sélection du niveau</div>
      <div className="wrap_niveaux">
        {listNivo.map((item)=>(
          <div key={`oneNivo${item.nivo}`} aria-label={item.tooltip} className={`one_nivo ${item.nivo<=nivoSelected ? 'active' : ''}`} onClick={()=>setNivoSelected(item.nivo)}/>
        ))}
      </div>
      <div className="title_block">Sélection des compétences</div>
      <div className="wrap_selections">
        <div className="wrap_select_lefty">
          <div className="wrap_search">
            <input type="text" ref={refSearch} value={search} onChange={HandleChangeSearchActi}
                   placeholder={"Recherche de compétence"}/>
            {search.length > 0 &&
              <div className="closeSearch" onClick={() => setSearch('')}><GrClose/></div>
            }
          </div>
          <div className="wrap_result">
            {listActi.map((item) => (
              <OneCardActiToChoice Activite={item} AddActivite={AddActi} key={`ActiAdd${item.id}`}/>
            ))}
          </div>
        </div>
        <div className="wrap_select_rigthy">
          {ActivitesSelected.map((item) => (
            <OneCardActiToRemove RemoveActivite={RemoveActi} Activite={item} key={`ActiRemove${item.id}`}/>
          ))}
        </div>
      </div>
      <div className="title_block">Sélection des collaborateurs</div>
      <div className="wrap_selections">
        <div className="wrap_select_lefty">
          <div className="wrap_search">
            <input type="text" ref={refEmpSearch} value={searchEmpl} onChange={HandleChangeSearchEmp}
                   placeholder={"Recherche de collaborateurs"}/>
            {searchEmpl.length > 0 &&
              <div className="closeSearch"><GrClose/></div>
            }
          </div>
          <div className="wrap_result">
            {listEmployee.map((item) => (
              <OneCardEmplToChoice Employee={item} AddEmpl={AddEmpl} key={`EmplAdd${item.id}`}/>
            ))}
          </div>
        </div>
        <div className="wrap_select_rigthy">
          {EmployeesSelected.map((item) => (
            <OneCardEmplToRemove RemoveEmpl={RemoveEmpl} Employee={item} key={`EmplRemove${item.id}`}/>
          ))}
        </div>
      </div>
      <div className="wrap_sender">
        <MbjButton size={"lg"} type="button" onClick={SaveValidation} themeColor={"primary"}>
          Enregistrer les validations de niveaux
        </MbjButton>
      </div>
      <MbjModal isShowing={isShowing} hide={toggle} maxWidth={"70%"} title={"Confirmation"} closable={false}>
        <ModalSuccessIn/>
      </MbjModal>
    </div>
  )
}

export const NouvelleFormation = styled(NouvelleFormationCtrl)`
  padding: 1% 2%;
  overflow-y: auto;

  .wrap_sender {
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0;
  }

  .title_block {
    font-size: 18px;
    margin: 20px 0 15px 0;
    font-weight: bold;
  }
  .wrap_niveaux{
    margin: 15px 0;
    display: flex;
    justify-content: flex-start;
    gap: 2px;
  }
  .one_nivo{
    width: 60px;
    border-radius: 4px;
    height: 8px;
    background: ${props => props.theme.greyLighten};
    &.active{
      background: ${props => props.theme.thirdLighten};
    }
    position: relative;
    &:hover{
      cursor: pointer;
    }
    &:hover:before{
      content: attr(aria-label);
      background: black;
      padding: 0.25rem;
      border-radius: 4px;
      font-size: 12px;
      box-shadow: 1px 1px 3px rgba(0,0,0,.2);
      color: white;
      width: max-content;
      position: absolute;
      left: 50%;
      top: calc(100% + 5px);
      z-index: 4;
    }
  }
  .closeSearch{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    background: red;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    svg{
      fill:white;
      stroke:white;
      margin: auto;
    }
  }
  .wrap_search{
    width: 100%;
    position: relative;
    padding: 0.5rem 0.25rem 0.5rem 0.75rem;
    border-bottom: solid ${props => props.theme.primary} 1px;
    input{
      width: 100%;
      height: 32px;
      padding-left: 0.25rem;
      border:none;
      background: white;
      border-radius: 4px;
    }
  }
  .wrap_selections{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 25px;
    background: #cbd2d9;
    border-radius: 4px;
    overflow: hidden;
  }
  .wrap_select_lefty{
    width: 50%;
    padding-right: 0.5rem;
    border-right: solid ${props => props.theme.primary} 1px;
    height: 250px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .wrap_result{
      flex-grow: 1;
      overflow-y: auto;
      padding: 0.5rem 0.25rem;
    }
  }
  .wrap_select_rigthy{
    height: 250px;
    flex-grow: 1;
    overflow-y: auto;
    padding: 0.5rem;
  }
`
