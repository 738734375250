import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  niveau: string,
  number_activite: string,
}

interface EmployeeActivityCountCtrlProps {
  className?: string;
  item: ITemTypes
}


function EmployeeActivityCountCtrl(props: EmployeeActivityCountCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.niveau}</td>
      <td className="isCenter">{props.item.number_activite}</td>
    </tr>
  );
}

export const EmployeeActivityCount = styled(EmployeeActivityCountCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
