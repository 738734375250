import styled, {useTheme} from "styled-components";
import {useParams} from "react-router-dom";
import {useGetEmployeeSlug, useOptinAEmp} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjButton, MbjLoading, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {LienInactifOptin} from "./LienInactifOptin";
import {OptinBEmp} from "./OptinBEmp";

interface OptinAEmpCtrlProps {
  className?:string;
}

const OptinAEmpCtrl = (props: OptinAEmpCtrlProps) => {
  const {slug} = useParams();
  const EmployeeQuery = useGetEmployeeSlug(slug);
  const theme = useTheme();
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const {isShowing, toggle} = useModal();
  const [errorSub, setErrorSub] = useState<string>("")
  const mutation = useOptinAEmp();
  const MyEmployee = useMemo(()=>{
    if(!EmployeeQuery.data){
      return null;
    } else {
      return EmployeeQuery.data[0]
    }
  }, [EmployeeQuery.data])
  const SignatureA = ()=>{
    const id = MyEmployee ? MyEmployee.id : 0
    mutation.mutate(id||0, {
      onSuccess:()=>{
        toggle();
      }, onError:()=>{
        setErrorSub("Une erreur est survenue, merci de retenter plus tard");
      }
    })
  }
  return (
    <div className={`optin_employee ${props.className}`}>
      <div className="header">
        <div className="wrap_logo">
          <img src={theme.logo}/>
        </div>
      </div>
      {isShowing ?
        <div className="onAccepted">Acceptation en cours</div> :
        <div className="content">
          {EmployeeQuery.isLoading ? <MbjLoading/> :
            MyEmployee ?
              <div className={`wrap_optin`}>
                <h1>Acceptation de diffusion auprès des entreprises inscrites sur My-better-job</h1>
                <p style={{fontSize: "17px", marginBottom: "25px"}}>Afin que votre CV puisse être diffusé aux
                  entreprises merci de lire et d'accepter les conditions suvantes</p>
                <div className={"Block_article"}>
                  <h2>Art. 1 : Périmètre de diffusion</h2>
                  <p>Si vous acceptez cette diffusion, vous pourrez être contacté(e) par ces recruteurs si vos compétences sont proches de celles qu’ils recherchent.
                  </p>
                </div>
                <div className={"Block_article"}>
                  <h2>Art. 2 : Acceptation du candidat</h2>
                  <p>Vous restez libre d’accepter ou refuser ces demandes d’entretiens. Votre employeur actuel ne sera pas informé par My-Better-Job des contacts que vous aurez eus avec d’autres entreprises.<br/>
                  </p>
                </div>
                <div className="block_signature">
                  <div className={`accept accept-lecture`}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checked1}
                        onChange={() => setChecked1(o => !o)}
                      />
                      <span>Je reconnais avoir pris connaissance du document dans son ensemble</span>
                    </label>
                  </div>
                  <div className={`accept accept-content`}>
                    <label>
                      <input
                        type="checkbox"
                        checked={checked2}
                        onChange={() => setChecked2(o => !o)}
                      />
                      <span>J'accepte l'ensemble des articles ci-dessus et que mon cv soit diffusé</span>
                    </label>
                  </div>
                  {errorSub !== '' &&
                    <div className={"ErrorSub"}>{errorSub}</div>
                  }
                  <div className="wrap_sender">
                    <MbjButton onClick={SignatureA} size={"lg"} disabled={!checked1 || !checked2}
                               themeColor={"primary"}>Accepter la diffusion</MbjButton>
                  </div>
                </div>
              </div> :
              <LienInactifOptin/>
          }
        </div>
      }

      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Finalisation de l'acceptation"} Wd={"400px"}>
        <OptinBEmp idEmp={MyEmployee ? MyEmployee.id || 0 : 0}/>
      </MbjModal2>
    </div>
  )
}

export const OptinAEmp = styled(OptinAEmpCtrl)`
  background: ${props => props.theme.background1};
  height: 100vh;
  overflow-y: auto;
    .onAccepted{
      margin: 50px auto;
      text-align: center;
      font-size: 20px;
    }
  h1 {
    margin-bottom: 30px;
  }

  .Block_article {
    margin-bottom: 20px;
  }

  .ErrorSub {
    width: 100%;
    background: red;
    padding: 1rem;
    text-align: center;
    margin: 12px 0;
    color: white;
  }

  .intro {
  }

  .wrap_fram {
    margin: 20px auto;
  }

  .content {
    padding: 1% 2%;
  }

  .header {
    width: 100%;
    background: ${props => props.theme.dark};
    padding: 1rem;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .wrap_logo {
      height: 100%;
      position: relative;
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 70%;
      width: auto;
    }
  }

  .accept {
    margin-bottom: 8px;

    label {
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;

      span {
        font-weight: bold;
      }
    }
  }

  .wrap_sender {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }
`
