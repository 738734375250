import { Navigate, Route, Routes } from "react-router-dom";
import MetiersDashboard from "./Dashboard/MetiersDashboard";
import React from "react";
import {MetiersListing} from "./Listing/MetiersListing";
import {OneMetierRooter} from "./OneMetier/OneMetierRooter";
import {PrivateRouteSub} from "../../components/PrivateRouteSub";
import { SearchCompetence } from "./SearchCompetence/SearchCompetence";
import { MatrixRooter } from "./Matrix/MatrixRooter";


export function MetiersRooter(){
    return (
        <Routes>
            <Route element={<PrivateRouteSub Onglet={"Métiers"}/>}>
                <Route path="" element={<Navigate to="dashboard" />} />
                <Route path={"dashboard"} element={<MetiersDashboard/>}/>
                <Route path={"listing"} element={<MetiersListing/>}/>
                <Route path={"matrix_metiers/*"} element={<MatrixRooter />}/>
                <Route path={":id/*"} element={<OneMetierRooter/>}/>
                <Route path={"search_competence"} element={<SearchCompetence/>}/>
            </Route>
        </Routes>
    )
}
