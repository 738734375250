import styled from "styled-components";
import {useGetMemberAlliances, useGetMemberMe, useGetRight} from "@mbj-front-monorepo/data-access";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {
    createSelectColumn, MbjButton,
    MbjErrorData,
    MbjLink,
    MbjLoading,
    MbjScrollArea,
    MbjTableV2, MbjWidgetClassic, useModal
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {useMemo} from "react";
import {ModalNewAlliance} from "../NouvelleAlliance/ModalNewAlliance";

interface MesAlliancesCtrlProps{
    className?:string;
}

interface MyAllianceInt{
    id:number,
    nom:string;
    owner:string;
    nbMembre:number;
    dateCreation:string;
}

const MesAlliancesCtrl = (props:MesAlliancesCtrlProps)=>{
    const {isLoading:loadRightR, isAuth:isAuthR} = useGetRight("Alliances", "read", "Mes Alliances", "OneAlliance", "")
    const {isLoading:loadRightW, isAuth:isAuthW} = useGetRight("Alliances", "write", "Mes Alliances", "", "")
    const MemberQuery = useGetMemberMe();
    const alliancesQuery = useGetMemberAlliances();
    const {isShowing, toggle} = useModal();
    const myList = useMemo(()=>{
        const List:MyAllianceInt[] = [];
        if(MemberQuery.data && alliancesQuery.data){
            alliancesQuery.data.map(a=>{
                const owner =  a.Alliance?.Owner?.id === MemberQuery.data?.id ? 'Oui' : 'Non';
                List.push({
                    id:a.Alliance && a.Alliance.id ? a.Alliance.id : 0,
                    nom:a.Alliance && a.Alliance.name ? a.Alliance.name : '',
                    owner:owner,
                    nbMembre:a.Alliance?.nbMembre||0,
                    dateCreation:a.Alliance?.creationAt ?  getDateFR(new Date(a.Alliance.creationAt)) : '--'
                })
            })
        }
        return List;
    }, [MemberQuery.data, alliancesQuery.data])
    const columnHelper = createColumnHelper<MyAllianceInt>();
    const columns = [
        createSelectColumn(columnHelper),
        columnHelper.group({
            header: "Identité",
            columns: [
                columnHelper.accessor((row) => row.nom, {
                    header: "Nom",
                    cell: (info) => isAuthR ? <MbjLink to={info.row.original.id.toString() || "0"}>{info.getValue()}</MbjLink> : <span>{info.getValue()}</span>,
                }),
            ],
        }),
        columnHelper.group({
            header: "Informations",
            columns: [
                columnHelper.accessor((row) => row.owner, {
                    header: "Créateur",

                }),
                columnHelper.accessor((row) => row.nbMembre, {
                    header: "Nb membres",
                }),
                columnHelper.accessor((row) => row.dateCreation, {
                    header: "Date de Création",
                }),
            ],
        }),
    ];

    const table = useReactTable({
        data: myList,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        debugTable: false,
        initialState: {
            sorting: [{ id: "Nom", desc: false }],
        },
        sortingFns: {
            MySortPrenom: (rowA: any, rowB: any, columnId: any): number =>
                rowA.getValue(columnId).value.localeCompare(rowB.getValue(columnId).value)
            //rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1,
        },
    });
    return (
        <div className={`mes_alliances pageStd  ${props.className}`}>
            <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
            {MemberQuery.isLoading || alliancesQuery.isLoading ? <MbjLoading/> :
                MemberQuery.isError || alliancesQuery.isError ? <MbjErrorData/>:
                    (
                        <>
                        <div className={"space-between"}>
                            <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
                            <div>
                                {isAuthW &&
                                    <MbjButton onClick={toggle} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                                        Créer une alliance
                                    </MbjButton>
                                }
                            </div>
                        </div>
                        <MbjScrollArea overflowX={"auto"} maxHeight={"auto"}>
                            <MbjTableV2 table={table} Multiple={false}/>
                        </MbjScrollArea>
                        </>
                    )
            }
            </MbjWidgetClassic>
            <ModalNewAlliance isShowing={isShowing} hide={toggle}/>
        </div>
    )
}

export const MesAlliances = styled(MesAlliancesCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  .space-between{
    display:flex;
    justify-content:space-between;
    align-items: center;
  }
  .ResultNumber{
    font-size: 20px;
  }
`
