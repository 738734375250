import {
  CustomSelect,
  MbjButton, MbjLabel,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useAddManyMemberEquipeManager,
  useGetAllListEquipeManager,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as React from "react";
interface ModalSendToEquipeCtrlProps extends MbjModalCtrlProps {
  selectedItems: number[];
  onSetSelectedItems: any
  onSetAlert: any
}

const ModalSendToEquipeCtrl = (props: ModalSendToEquipeCtrlProps) => {
  const userEquipesQuery = useGetAllListEquipeManager();
  const mutation = useAddManyMemberEquipeManager();

  const HandleSend = (data: any) => {
    const results = props.selectedItems.map((id: number) => ({
      equipeManager: `/api/equipe_managers/${data.manager.id}`,
      employee: `/api/employees/${id}`
    }))
    mutation.mutate(
      results,
      {
        onSettled: () => {
          props.hide();
        },
        onSuccess: () => {
          props.onSetSelectedItems([])
          props.onSetAlert(`Les collaborateurs sélectionnés sont ajoutés à l'équipe "${data.manager.nom}"`)
        }
      }
    );
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <MbjModal {...props} title="Ajout de collaborateur(s) à une équipe" maxWidth={"600px"}>
      {userEquipesQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjLabel>{props.selectedItems.length} collaborateur(s) </MbjLabel>
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner une équipe"} htmlFor={"manager"}>
            <Controller
              name={"manager"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"userId"}
                  isLoading={userEquipesQuery.isLoading}
                  options={userEquipesQuery.data}
                  getOptionLabel={(team: any) => team.nom}
                  getOptionValue={(team: any) => team.id}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Ajouter
          </MbjButton>
        </form>
      )}
    </MbjModal>
  );
};

export const ModalSendToEquipe = styled(ModalSendToEquipeCtrl)((props) => ({
  ".serviceAddForm": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "end",
    },
  },
}));
