import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetFlopCompetences, useGetTopCompetences} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {CompetenceItem} from "./competenceItem";

interface CompToDevCtrlProps{
    className?:string;
}

const CompToDevCtrl = (props:CompToDevCtrlProps)=>{
    const flopCompetencesQuery = useGetFlopCompetences();
    return (
        <MbjCardBack title={"Les compétences les plus répandues"} height={"100%"}>
            <div className={`CompToDev ${props.className}`}>
                {flopCompetencesQuery.isLoading ? <MbjLoading/> : flopCompetencesQuery.isError ? <MbjErrorData/> :
                    (
                        flopCompetencesQuery.data?.map((competence: typeof flopCompetencesQuery.data[0], i: number) => (
                            <CompetenceItem competence={competence} key={competence.id} />
                        ))
                    )
                }
            </div>
        </MbjCardBack>
    )
}

export const CompToDev = styled(CompToDevCtrl)`
`
