import { fetchApi, Operation } from "../../services/fetchApi";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {toast} from "react-toastify";
import {useGetMemberMe} from "../member";


export const useGetFormationPlanningOne = (idEmp:number, idActivite:number)=>{
  const promise = () => fetchApi("/api/formation_plannings", { method: "get", query: { Employee: idEmp, Activite:idActivite } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning", idEmp, idActivite], promise);
}

export const useGetFormationPlanningMember = ()=>{
  const memberQuery = useGetMemberMe();
  const promise = () => fetchApi("/api/formation_plannings", { method: "get", query: { "Employee.MemberMaster": memberQuery.data?.id||0 } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_m"], promise, {
    enabled: !!memberQuery.data,
  });
}

export const useGetFormationPlanningMemberWithoutMoyenFormation = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/formation_plannings/without_moyen_formations", {
    method: "get", query: { "memberID": memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_without_mf", memberId], promise, {
    enabled: !!memberId,
  });
}

export const useGetFormationPlanningMemberWithMoyen = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/formation_plannings/with_moyen_formations", {
    method: "get", query: { "memberID": memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_with_moyen", memberId], promise, {
    enabled: !!memberId,
  });
}


export const useGetFormationPlanningAlliances = (ids:number[])=>{
  //const partms = {"Employee.MemberMaster[]":ids[0], "Employee.MemberMaster[]":ids[1]}
  const promise = () => fetchApi("/api/formation_plannings", { method: "get"});
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["formation_planning_a"], promise, {
    enabled: ids.length>0,
  });
}

export const useAddFormationPlanning = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postFormationPlanningCollection", "requestBody">) => {
      return fetchApi("/api/formation_plannings", {
        method: "post",
        json: data,
      });
    },
    {
      onSuccess: (newFormationPl) => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["employees_cnt_act_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
        queryClient.setQueryData(["formation_planning", newFormationPl.Employee ? newFormationPl.Employee.id||0 : 0, newFormationPl.Activite ? newFormationPl.Activite.id||0 : 0], newFormationPl);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useSetMoyenFormationPlanning = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return fetchApi("/api/formation_plannings/update_moyen_formation/{id}", {
        method: "PUT",
        json: data,
        params: {id: id}
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["employees_cnt_act_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
        queryClient.invalidateQueries(["formation_planning_with_moyen"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddMoyenForManyFormationPlanning = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data) => {
      return fetchApi("/api/formation_plannings/set_moyen_formation", {
        method: "POST",
        json: data
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["employees_cnt_act_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
        queryClient.invalidateQueries(["formation_planning_with_moyen"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddManyFormationPlanning = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: any[]) => {
      return Promise.all(
        data.map((item: any) => fetchApi("/api/formation_plannings", {
          method: "post",
          json: item,
        }))
      )
    },
    {
      onSuccess: (newFormationPl) => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["employees_cnt_act_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useModifyFormationPlanning = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putFormationPlanningItem", "requestBody">) => {
      return fetchApi("/api/formation_plannings/{id}", {
        method: "put",
        params:{id:data.id+""},
        json: data,
      });
    },
    {
      onSuccess: (newFormationPl) => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.setQueryData(["formation_planning_m", newFormationPl.Employee ? newFormationPl.Employee.id||0 : 0, newFormationPl.Activite ? newFormationPl.Activite.id||0 : 0], newFormationPl);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useDeleteFormationPlanning = ()=>{
  const queryClient = useQueryClient();
  return useMutation((id:number|number[]) => {
      if (Array.isArray(id)) {
        return Promise.all(
          ids.map((ID) => fetchApi("/api/formation_plannings/{id}", { method: "delete", params: { id: ID + "" } }))
        );
      }
      return fetchApi("/api/formation_plannings/{id}", {
        method: "delete",
        params:{id:id+""}
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["formation_planning_m"]);
        queryClient.invalidateQueries(["formation_planning"]);
        queryClient.invalidateQueries(["formation_planning_without_mf"]);
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        queryClient.invalidateQueries(["employees_cnt_act_needs_form_toanalyze"]);
        toast.success("Formation supprimée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useGetCheckFormationForEmployeeNeedsForm = (employeeID: number, activityID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/formation_plannings/{employeeID}/get_by_need_form/{activityID}", {
    method: "get",
    params: { employeeID: employeeID + "", activityID: activityID },
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["fp_get_by_need_form", { memberId }, employeeID, activityID], promise, {
    enabled: !!memberId && !!employeeID && !!activityID,
  });
};


export const useAddAvisPlanFormation = () => {
  return useMutation(
    (data: any) => {
      return fetchApi("/api/avis_plan_formations", {
        method: "post",
        json: data,
      });
    },
    {
      /*onSuccess: (avisPlan) => {
      },*/
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
