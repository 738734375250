import styled from "styled-components";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps, MbjCardFooter,
  MbjCardHeader, MbjConfirmModal,
  MbjFlex,
  MbjHeading, MbjIconButton, MbjModal, MbjStaticInfo,
  useModal
} from "@mbj-front-monorepo/ui";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import {Operation, useDeleteXpProStagiaire} from "@mbj-front-monorepo/data-access";
import {BiEdit} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import React from "react";
import {MbjStagiaireXpEditForm} from "./MbjStagiaireXpEditForm";

interface MbjStagiaireXpCardCtrlProps extends MbjCardCtrlProps {
  StagiaireXp:GetElementType<Operation<"getStagiaireXpCollection">>
}

const MbjStagiaireXpCardCtrl = (props: MbjStagiaireXpCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutateDelete = useDeleteXpProStagiaire();
  const HandleConfirmDelete = () => {
    mutateDelete.mutate(props.StagiaireXp.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Du {props.StagiaireXp.startAt ? getDateFR(new Date(props.StagiaireXp.startAt)) : "inconnu"} à{" "}
            {props.StagiaireXp.endAt ? getDateFR(new Date(props.StagiaireXp.endAt)) : "maintenant"} :{" "}
            {props.StagiaireXp.Metier?.libelle}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Type de contrat : "
                info={props.StagiaireXp.TypeContrat ? props.StagiaireXp.TypeContrat.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Salaire : "
                info={props.StagiaireXp.salary ? props.StagiaireXp.salary + "€" : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.StagiaireXp.description && (
              <MbjStaticInfo label="Description : " info={props.StagiaireXp.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        <MbjCardFooter justify={"flex-end"}>
          <MbjFlex direction={"row"} justify={"flex-end"} gap={"7px"}>
            <MbjIconButton
              onClick={toggle}
              size={"sm"}
              ariaLabel={"Editer l'expérience"}
              icon={<BiEdit />}
              themeColor={"secondary"}
              isRound={false}
            />
            <MbjIconButton
              onClick={toggleConfirm}
              size={"sm"}
              ariaLabel={"Supprimer l'expérience"}
              icon={<BsTrash />}
              themeColor={"warning"}
              isRound={false}
            />
          </MbjFlex>
        </MbjCardFooter>
        <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une expérience professionnelle">
          <MbjStagiaireXpEditForm StagiaireXp={props.StagiaireXp} hideModal={toggle} />
        </MbjModal>
      </MbjCard>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression expérience"
        confirmText={"Voulez-vous vraiment supprimer cette expérience ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutateDelete.isLoading}
      />
    </>
  );
}

export const MbjStagiaireXpCard = styled(MbjStagiaireXpCardCtrl)``
