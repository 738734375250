import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import {MbjButton, MbjFlex, MbjNavPath, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {AlliancesGroup} from "../MesAlliances/AlliancesTable/AlliancesGroup";
import {AlliancesTable} from "../MesAlliances/AlliancesTable/AlliancesTable";
import {ModalNewAlliance} from "../NouvelleAlliance/ModalNewAlliance";
import {ObjectifsSection} from "../../../components/ObjectifsSection";

interface AlliancesDashboardCtrlProps{
    className?:string;
}

const AlliancesDashboardCtrl = (props:AlliancesDashboardCtrlProps)=>{
    const location = useLocation();
    const {isShowing,toggle} = useModal();
    const clickNew = ()=>{
        toggle();
    }
    return (
        <div className={`gestion_alliances ${props.className}`}>
            <ObjectifsSection titre={"Objectif Alliance"}>
                <p>Coopérez avec d’autres entreprises pour développer votre marque employeur. Les « Alliances » sont des partenariats entre établissements qui peuvent, grâce à My-Better-Job, mutualiser des projets de formation de salariés, proposer des reclassements et organiser des coopérations approfondies avec certains organismes de formation.</p>
                <p>Un établissement peut appartenir à plusieurs alliances à la fois.</p>
            </ObjectifsSection>
        </div>
    )
}

const AlliancesDashboard = styled(AlliancesDashboardCtrl)`
  padding: 1% 2%;
  height: 100%;
  overflow-y: auto;
  .space-between{
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
  }
`


export default AlliancesDashboard;
