import styled from "styled-components";
import { FiUsers } from "react-icons/fi";
import {useDrag} from "react-dnd";
import {ItemType} from "./ItemType";
import { BlockPlannif } from "../Plannification/by_planification/Plannification";
import {DetailsPlannif} from "./DetailsPlannif";
import {useCallback, useEffect, useRef, useState} from "react";

interface OnePlannifCtrlProps{
  className?:string;
  BlockPlannif:BlockPlannif
}

const OnePlannifCtrl = (props:OnePlannifCtrlProps)=>{
  const [open, setOpen] = useState<boolean>(false)
  const refSelector = useRef<HTMLDivElement>(null);
  const [coord, setCoord] = useState({x:0, y:0})
  useEffect(() => {
    if(!open){
      console.log('closed')
    }
    console.log(' ww open '+open)
  }, [open]);
  const SetCoord = useCallback(()=>{
    if (refSelector.current) {
      console.log('ici2');
      const elt = refSelector.current.getBoundingClientRect();
      const top = elt.top -3 ;
      const left = elt.left + 20;
      const width = elt.width;
      console.log({x: left+width+5, y: top})
      setCoord({x: left+width+5, y: top})
    }
  }, [refSelector])
  useEffect(() => {
    SetCoord();
  }, [refSelector]);
  useEffect(() => {
    window.addEventListener('resize', SetCoord)
    return ()=>{
      window.removeEventListener('resize', SetCoord)
    }
  }, [SetCoord]);
  const handleClickOnSelector = ()=>{
    console.log('ici');
    SetCoord();
    setOpen(o=>!o);
  }
  const [{isDragging}, drag, preview] = useDrag(()=>({
    type:ItemType.FORM,
    item:{PlForm:props.BlockPlannif},
    canDrag:()=>true,
    collect:(monitor)=>({
      isDragging: !!monitor.isDragging()
    })
  }))

  return (
    <>
    <div className={`block_plannif ${props.className}`} ref={drag} style={{opacity:isDragging ? 0.5 : 1}} onClick={handleClickOnSelector}>
      <div className="content_block" ref={refSelector} >
        <div className={`activite_place`}>{props.BlockPlannif.Activite.libelle}</div>
        <div className={`nb_pers_place`}>{props.BlockPlannif.list.length}<FiUsers/></div>
      </div>

    </div>
      <DetailsPlannif BlockPlannif={props.BlockPlannif} closeMe={()=>setOpen(false)}  open={open} x={coord.x} y={coord.y} />
      </>
  )
}
export const OnePlannif = styled(OnePlannifCtrl)`
  padding: 0.2rem;

  &:hover{
    cursor: grab;
  }
  .content_block{
    border-radius: 6px;
    padding: 0.25rem;
    background: ${props => props.theme.third};
    color:white;
    .activite_place{
      font-size: 14px;
      font-weight: bold;
    }
    .nb_pers_place{
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      align-items: center;
    }
  }
`
