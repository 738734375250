import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";

export type ReferentielsComparaisonType =
  | "EM"
  | "ME"
  | "EUM"
  | "UME"
  | "UMUM"
  | "ER"
  | "RE"
  | "UMR"
  | "RUM"
  | "MUM"
  | "UMM"
  | "PM"
  | "MP"
  | "PUM"
  | "UMP"
  | "PR"
  | "RP"
  | "SM"
  | "SUM"
  | "UMS"
  | "MS"
  ;

export const useGetProximiteReferentiel = (type: ReferentielsComparaisonType, id1?: number, id2?: number) => {
  const promise = () =>
    fetchApi("/api/proximite_referentiels/{id}", {
      method: "get",
      params: { id: id1 + "" },
      query: { idTwo: id2 || -1, type: type },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["proximite_referentiel", { id1, id2, type }], promise, {
    enabled: id1!==-1 && id2!==-1 && !!type && id2!==-1 && !!id1 && !!id2,
  });
};
