import styled from "styled-components";
import React, { useRef, useState } from "react";
import {
  useAddManyFormationPlanning,
  useGetEmployeesNeedsForm,
  useGetRight,
  useUnToAnalyze,
} from "@mbj-front-monorepo/data-access";
import {
  createSelectColumn,
  MbjTableV2,
  MbjLoading,
  MbjCardClassic,
  MbjButton, MbjScrollArea, useModal, MbjConfirmModal, MbjModal2, MbjIconButton, MbjLabel,
} from "@mbj-front-monorepo/ui";

import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { BiCalendar, BiLineChartDown } from "react-icons/bi";
import { StatistiquesPriorisation } from "../Components/StatistiquesPriorisation";
import { PlanificationPriorisation } from "../Components/PlanificationPriorisation";
import { OneMonthForIn, TabMois } from "../../Plannification/by_planification/Plannification";

interface AnalysePriorisationCtrlProps{
  className?:string;
}

const AnalysePriorisationCtrl = (props:AnalysePriorisationCtrlProps) => {
  const { isShowing: isShowingDeleteEmployee, toggle: toggleDeleteEmployee } = useModal();
  const { isShowing: isShowingPlanningFormation, toggle: togglePlanningFormation } = useModal();
  const { isShowing: isShowingAllPlanification, toggle: toggleAllPlanification } = useModal();
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs")
  const empNeedsForm = useGetEmployeesNeedsForm()
  const deleteEmployee = useUnToAnalyze()
  const addManyFormationPlanning = useAddManyFormationPlanning()
  const {isShowing: isShowingDetail, toggle: toggleDetail} = useModal();
  const {isShowing: isShowingCalendar, toggle: toggleCalendar} = useModal();
  const TabMoisCpnt:OneMonthForIn[] = [];
  const refSelect = useRef<HTMLSelectElement>(null)
  const [moisChoix, setMoisChoix] = useState<OneMonthForIn|null>(null)
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const [info, setInfo] = useState({
    empID: 0,
    actID: 0,
    service: '',
    activite: '',
    prenom: '',
    nom: '',
    namePerso: ''
  })
  const formatedDate = (mydate: string|null): string => {
    if (!mydate) {
      return 'Aucune date enregistrée';
    }
    const date = new Date(mydate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${day}/${month}/${year}`;
  }
  const columnHelper = createColumnHelper<GetElementType<typeof empNeedsForm.data>>();

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: 'Collaborateurs',
      columns: [
        columnHelper.accessor((row: any) => row.nom, {
          header: "Nom",
        }),
        columnHelper.accessor((row: any) => row.prenom, {
          header: "Prénom",
        }),
      ]
    }),
    columnHelper.accessor((row: any) => row.activite, {
      header: "Compétence",
    }),
    columnHelper.accessor((row: any) => row.service ?? ' ', {
      header: "Service",
    }),
    columnHelper.accessor((row: any) => row.namePerso, {
      header: "Métier",
    }),
    columnHelper.accessor((row: any) => Math.floor(row.score * 100), {
      header: "Taux de proximité (en %)",
    }),
    columnHelper.accessor((row: any) => formatedDate(row.obtentionAt.recent_date) , {
      header: "Date dernière formation",
    }),
    columnHelper.accessor((row: any) => row.nbrComp ??  'Aucun', {
      header: "Nombre de compétences",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
      cell: (props: any) => <div className="btn-actions">
          <MbjIconButton
            size={"sm"}
            onClick={() => handleStatClick(props.row?.original)}
            ariaLabel={"Stats"}
            icon={<BiLineChartDown />}
            themeColor={"third"}
            isRound={false}
          />
          <MbjIconButton
            size={"sm"}
            onClick={() => handleCalendar(props.row?.original)}
            ariaLabel={"Stats"}
            icon={<BiCalendar />}
            themeColor={"primary"}
            isRound={false}
          />
        </div>,
    }),
  ];
  const table = useReactTable({
    data: empNeedsForm?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })

  const handleStatClick = (item: any) => {
    toggleDetail()
    setInfo({
      empID: item.empID,
      actID: item.actID,
      service: item.service,
      activite: item.activite,
      prenom: item.prenom,
      nom: item.nom,
      namePerso: item.namePerso
    })
  }
  const handleCalendar = (item: any) => {
    toggleCalendar()
    setInfo({
      empID: item.empID,
      actID: item.actID,
      service: item.service,
      activite: item.activite,
      prenom: item.prenom,
      nom: item.nom,
      namePerso: item.namePerso
    })
  }


  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  const handleChangeSelect = ()=> {
    if(refSelect.current){
      const myREfy = refSelect.current ? refSelect.current.value : '';
      const my = TabMoisCpnt.find(tm=>tm.date.toISOString() === myREfy)
      if(my) {
        setMoisChoix(my)
      }
    }
  }
  const handleSubmit = ()=>{
    const date = !moisChoix ? null : new Date(moisChoix.date.toISOString().slice(0, 10) + "T12:00:00").toISOString();
    const data = table.getSelectedRowModel().rows.map((row: any) => ({
      Employee: `/api/employees/${row.original.empID}`,
      Activite: `/api/activites/${row.original.actID}`,
      souhaitAt: date,
      is_diffusable: false,
    }))
    addManyFormationPlanning.mutate(data, {
      onSuccess: () => {
        toggleAllPlanification()
        table.toggleAllRowsSelected(false);
      }
    })
  }



  return (
      <div className={`Priorisation_listing pageStd ${props.className}`}>
        {empNeedsForm.isLoading ? (
          <MbjLoading />
        ) : empNeedsForm.isError ? (
          <p>Error: {empNeedsForm.error.message}</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ?  `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
              <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                  <>
                    <MbjButton themeColor={"warning"} onClick={toggleDeleteEmployee} isPending={false}>
                      Retirer {table.getSelectedRowModel().rows.length} collaborateur(s)
                    </MbjButton>
                    <MbjButton themeColor={"primary"} onClick={toggleAllPlanification} isPending={false}>
                      Planifier des formations pour ces {table.getSelectedRowModel().rows.length} collaborateur(s)
                    </MbjButton>
                  </>
                )}

                {/*{CanDelete() &&
                  <MbjButton themeColor={"warning"} onClick={TrashMetier} isPending={false}>
                    Supprimer le métier
                  </MbjButton>
                }*/}

              </div>
            </div>


            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}

        <MbjConfirmModal
          isShowing={isShowingDeleteEmployee}
          hide={toggleDeleteEmployee}
          title="Confirmation de suppression de collaborateur(s)"
          confirmText={`Etes-vous sûr de vouloir retirer ${table.getSelectedRowModel().rows.length} collaborateur(s) ?`}
          confirmFunction={() => {
            deleteEmployee.mutate(table.getSelectedRowModel().rows.map((row: any) => row.original.id|| 0),
              {
                onSuccess: () => {
                  toggleDeleteEmployee();
                  table.toggleAllRowsSelected(false);
                },
              }
            );
          }}
          isLoading={deleteEmployee.isLoading}
        />
        <MbjModal2 open={isShowingDetail} closeMe={toggleDetail} titre={"Informations détaillés"} Wd={"80%"}>
          <div style={{padding:"0 1rem", height:"max-content"}}>
            <StatistiquesPriorisation info={info} />
          </div>
        </MbjModal2>
        <MbjModal2 open={isShowingCalendar} closeMe={toggleCalendar} titre={"Planification priorisation"} Wd={"80%"}>
          <div style={{padding:"0 1rem", height:"max-content"}}>
            <PlanificationPriorisation info={info} onToggleCalendar={toggleCalendar}/>
          </div>
        </MbjModal2>
        <MbjModal2 open={isShowingAllPlanification} closeMe={toggleAllPlanification} titre={"Planification"} Wd={"80%"}>
          <div style={{ padding: "0 1rem", height: "max-content" }}>
            <MbjCardClassic title="Programmer une formation" basis={"100%"}>
              <div className="blk-plan">
                <select className="plprg_select" ref={refSelect} value={moisChoix ? moisChoix.date.toISOString() : ""}
                        onChange={handleChangeSelect}>
                  {TabMoisCpnt.map((item, idx) => (
                    <option value={item.date.toISOString()}
                            key={`choice_date_nopl${idx}`}>{item.month.libShort} {item.date.getFullYear()}</option>
                  ))}
                </select>
                <MbjButton size={"md"} onClick={() => handleSubmit()} isPending={addManyFormationPlanning.isLoading}>
                  Planifier
                </MbjButton>
              </div>
            </MbjCardClassic>
          </div>
        </MbjModal2>
      </div>

  )
}
export const AnalysePriorisation = styled(AnalysePriorisationCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
  }

  .plprg {
    margin-bottom: 0.5rem;
  }

  .plprg_select {
    display: block;
    margin: 20px 10px 20px 0;
  }
`
;
