import styled from "styled-components";
import {useState} from "react";

interface FrameCarteCtrlProps {
  className?:string;
  htmlFileString:string;
}

const FrameCarteCtrl = ({className, htmlFileString}: FrameCarteCtrlProps) => {
  return (
    <div className={`frame_carte ${className}`}>
      <iframe width={1300} height={600} srcDoc={htmlFileString}/>
    </div>
  )
}

export const FrameCarte = styled(FrameCarteCtrl)`
    display:flex;
  justify-content: center;
`
