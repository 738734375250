import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {FormAddAskContactOF} from "./FormAddAskContactOF";
import {MyOF} from "./CardFormation";

interface ModalNewDemandeContactCtrlProps extends MbjModalCtrlProps{
    MyAsker:MyOF|null;
}

const ModalNewDemandeContactCtrl = (props:ModalNewDemandeContactCtrlProps)=>{
    if(!props.MyAsker){
        return null;
    } else {
        return (
            <MbjModal {...props} title="Demande mise en contact" fitContent>
                <FormAddAskContactOF closeMe={props.hide} MyAsker={props.MyAsker}/>
            </MbjModal>
        )
    }
}

export const ModalNewDemandeContact = styled(ModalNewDemandeContactCtrl)``
