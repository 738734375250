import styled from "styled-components";
import {MbjCardBack} from "@mbj-front-monorepo/ui";

interface ObjectifSectionFormationCollabProps{
  className?:string
}

const ObjectifSectionFormationCollabCtrl = (props:ObjectifSectionFormationCollabProps)=>{
  return (
    <MbjCardBack title={"Objectifs de la section besoins de Formation"}>
      <div className={`obj ${props.className}`}>
        <p>Gérez ici tous les besoins de professionnalisation des collaborateurs. Ils sont présentés dans « Besoins en formations » par compétence, service, métier, niveau d’écart (c’est-à-dire l’ampleur du besoin).</p>
        <br/>
        <p>Pour une compétence donnée, vous identifiez tous les collaborateurs qui ont besoin de progresser. Le lien « Comment former ? » vous permet d’accéder à toutes les ressources disponibles : organismes extérieurs, experts internes, formations vidéos disponibles. Toutes ces solutions de formation sont sélectionnées selon la nature du besoin.</p>
        <br/>
        <p>Depuis cette rubrique, vous pourrez planifier les formations mensuellement.</p>
        <br/>
        <p>Enfin, si votre établissement est membre d’une « Alliance » (coopération inter-entreprises), vous pourrez diffuser l’information sur un projet de formation auprès de vos partenaires, pour accélérer la programmation des sessions et en réduire le coût.</p>
      </div>
    </MbjCardBack>
  )
}

export const ObjectifSectionFormationCollab = styled(ObjectifSectionFormationCollabCtrl)`

`
