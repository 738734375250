import styled from "styled-components";
import {useMemo, useState} from "react";
import {
    useGetBesoinsFormationEntreprise,
    useGetFormationPlanningMember
} from "@mbj-front-monorepo/data-access";
import {
    MasterBlock,
    OneActiviteMaster,
    OneBlockMaster,
    OneEmployeeMaster,
    OneItemMaster
} from "../../Components/OneBlockMaster";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {WrapResultBesoin} from "../../Components/WrapResultBesoin";
import {ModalFormationActivite} from "../../Components/ModalFormationActivite";
import * as React from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface BesoinsByNiveauCtrlProps{
    className?:string;
}

const BesoinsByNiveauCtrl = (props:BesoinsByNiveauCtrlProps)=>{
    const [search, setSearch] = useState<string>('')
    const [libelleComp, setLibelleComp] = useState<any>([]);
    const [detail, setDetail] = useState<any>([]);
    const BesoinsQuery = useGetBesoinsFormationEntreprise();
    const [openD, setOpenD] = useState(false);
    const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
    const [ExpertsCurr, setExpertsCurr] = useState<ExpertOne[]>([])
    const PlanificationQuery = useGetFormationPlanningMember();
    const listPlanification = useMemo(()=>{
        const list:{idEmployee:number, idActi:number}[] = [];
        if(PlanificationQuery.data && !PlanificationQuery.isLoading && !PlanificationQuery.isError){
            PlanificationQuery.data.forEach(p=>{
                list.push({idEmployee:(p.Employee ? p.Employee.id||0 : 0), idActi:(p.Activite ? p.Activite.id||0 : 0)})
            })
        }
        return list;
    }, [PlanificationQuery.data, PlanificationQuery.isLoading, PlanificationQuery.isError])
    const myList = useMemo(()=>{
        const list:MasterBlock[] = [];
        if(BesoinsQuery.data){
            const regex = new RegExp(""+search+"", 'gi');

            const EmployesNF:OneEmployeeMaster[] = [];
            BesoinsQuery.data.forEach(b=>{
                const Emps = b.EmployeeNeedFormation;
                if(Emps) {
                    Emps.forEach(e => {
                        if(e.Employees) {
                            EmployesNF.push({
                                Employee: {id: e.Employees?.id || 0, name: `${e.Employees.prenom} ${e.Employees.nom}`},
                                niveau:e.niveau||0, niveauTarget:e.cible||0,
                                idMaster:(e.cible||0) - (e.niveau||0), Activite: {
                                    id:e.Activite?.id || 0,
                                    libelle:e.Activite?.libelle||'',
                                    idCompetence:e.Activite?.Competence?.id||0
                                },
                                Competence:{id:e.Activite?.Competence?.id||0, libelle:e.Activite?.Competence?.libelle||''}
                            })
                        }
                    })
                }
            })
            const NiveauSSD:{id:number, libelle:string}[] = [
                {id:1, libelle:'Niveau -1'},
                {id:2, libelle:'Niveau -2'},
                {id:3, libelle:'Niveau -3'},
                {id:4, libelle:'Niveau -4'},
                {id:5, libelle:'Niveau 0'},
            ]


            NiveauSSD.forEach(s=>{
                let nb = 0;
                //if(search === '' || s.libelle.match(regex)) {
                    const EmloyeesConcernedNiveau: OneEmployeeMaster[] = EmployesNF.filter(e => e.idMaster === s.id)
                    const CompetencesConcerned = EmloyeesConcernedNiveau.map(e=>e.Competence);
                    const listCompetence = CompetencesConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
                    setLibelleComp((prev: any) => [...prev, listCompetence])
                    const resultSearch = listCompetence.filter(c => c.libelle.match(regex))
                    const TabComp:OneItemMaster[] = [];
                    resultSearch.forEach(c=>{
                        const EmloyeesConcernedCompetence: OneEmployeeMaster[] = EmloyeesConcernedNiveau.filter(e => e.Competence.id === c.id)
                        const ActiviteConcerned = EmloyeesConcernedCompetence.map(e=>e.Activite);
                        const listActivite = ActiviteConcerned.reduce((acc:{id:number, libelle:string}[], item:{id:number, libelle:string})=>acc.map(a=>a.id).indexOf(item.id) === -1 ? [...acc, item] : acc, [])
                        const TabActivites:OneActiviteMaster[] = [];
                        listActivite.forEach(a=>{
                            const EmployeesConcernedActivite: OneEmployeeMaster[] = EmloyeesConcernedCompetence.filter(e => e.Activite.id === a.id)
                            nb +=EmployeesConcernedActivite.length;
                            TabActivites.push(
                                {Activite:{id:a.id, libelle:a.libelle, idCompetence:c.id}, EmployeesInActi:EmployeesConcernedActivite},
                            )
                        })
                        TabComp.push({Competence:{id:c.id, libelle:c.libelle}, Activites:TabActivites})
                    })
                    list.push({Master:{id:s.id, libelle:s.libelle}, listCompetence:TabComp, nb:nb})
                //}
            })
        }
        return list;
    }, [BesoinsQuery.data, search])
    const SwitchOpen = ()=>{
        setOpenD(o=>!o);
    }
    const ClickActi = (item:{id:number, libelle:string, niveau:number})=>{
        const Besoins = BesoinsQuery.data?.find(b=>b.activite?.id === item.id);
        if(Besoins){
            const ExpertsThis = Besoins.Experts;
            if(ExpertsThis) {
                setExpertsCurr(ExpertsThis.map(e => {
                    return {
                        employee: {id: (e.employee?.id||0), prenom: (e.employee?.prenom||''), nom:  (e.employee?.nom||''), service: ''},
                        niveau: 0
                    }
                }))
            }
        }
        setActiCurr(item);
        SwitchOpen();
    }
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<any[]>([]);

  const checkboxHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = e.target.value;
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
      return
    }
    setSelectedItems((prevData) => prevData.filter((item) => item !== value ));
  };
    const [alert, setAlert] = useState('')
    const tabEmp: any[] = []
    const tabAct: string[] = []
    myList.forEach(value => value.listCompetence.map((item: any) => item.Activites.map((val: any) => {
      tabAct.push(`${val.Activite.idCompetence}_${val.Activite.id}`)
      val.EmployeesInActi.map((emp: any) => {
        if (emp.Activite && emp.Employee) {
          tabEmp.push({empID: emp.Employee.id, actID: emp.Activite.id})
        }
      })
    })))
  const checkAllHandler =  () => {
    if (selectedItems.length === tabEmp.length) {
      setSelectedItems([])
      setSelectedCompetenceItems([])
      setSelectedActivity([])
      return
    }
    const IDs = tabEmp.map((item)=> JSON.stringify(item));
    const compIDS: any[] = []
    const uniqueTabAct: string[] = [...new Set(tabAct)];
    myList.forEach((item) => item.listCompetence.map(itemE => compIDS.push(itemE.Competence.id)))
    setSelectedItems(IDs)
    setSelectedCompetenceItems(compIDS)
    setSelectedActivity(uniqueTabAct)
  }
  const checkboxCompetenceHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = parseInt(e.target.value, 10);
    const comps: any[] = []
    const tab: any[] = []
    if (isSelected) {
      setSelectedCompetenceItems([...selectedCompetenceItems, value]);
      myList.forEach(el => {
        const filteredCompetences = el.listCompetence.filter(c => c.Competence.id === value);
        filteredCompetences.length > 0 && comps.push(filteredCompetences)
      });
      const c = comps.reduce((acc: any, curr: any) => acc.concat(curr), [])
      for (let i =0; i < c.length; i++) {
        c[i].Activites.forEach((item: any) => {
          const elAct = `${item?.Activite?.idCompetence}_${item?.Activite?.id}`
          const idAct= document.getElementById(elAct) as HTMLInputElement
          if (idAct) {
            idAct.checked = true;
            setSelectedActivity((prevSelectedActivity) => [...prevSelectedActivity, idAct.value]);
          }
          item.EmployeesInActi.map((itemE: any) => {
            const el = `${itemE.Employee.id}_${itemE.Activite.id}`
            const id = document.getElementById(el) as HTMLInputElement
            id.checked =  true
            const dataVal = {empID: itemE.Employee.id, actID: itemE.Activite.id}
            tab.push(JSON.stringify(dataVal))
          })
        })
      }

      setDetail((prevDetail: any) => [...prevDetail, { id: value, list: tab }]);
      setSelectedItems([...selectedItems, ...tab])

      return
    }
    setSelectedCompetenceItems((prevData) => prevData.filter((item) => item !== value ));
    const wn = detail.filter((item: any) => item.id === value)
    wn.map((eli: any) => eli.list.map((item: any) => {
      const obj = JSON.parse(item)
      const el = `${obj.empID}_${obj.actID}`
      const id = document.getElementById(el) as HTMLInputElement
      const elAct = `${eli.id}_${obj.actID}`
      const idAct = document.getElementById(elAct) as HTMLInputElement
      setSelectedActivity((prevData) => prevData.filter((item) => item !== idAct.value ));
      id.checked = false
      idAct.checked = false
      setSelectedItems((prevData) => prevData.filter((mySelectedItem) => mySelectedItem !== item ));
    }))
    setDetail((prevDetail: any) => prevDetail.filter((item: any) => item.id !== value));
  }
  const checkboxActivityHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked;
    const value = e.target.value;
    const comps: any[] = []
    const tab: any[] = []
    if (isSelected) {
      setSelectedActivity([...selectedActivity, value]);
      const arrVals = value.split('_')
      const compID = arrVals[0]
      const actID = parseInt(arrVals[1], 10)
      const valueCompID = parseInt(compID, 10)
      myList.forEach(el => {
        const filteredCompetences = el.listCompetence.filter(c => c.Competence.id === valueCompID);
        filteredCompetences.length > 0 && comps.push(filteredCompetences)
      });
      const c = comps.reduce((acc: any, curr: any) => acc.concat(curr), [])
      for (let i =0; i < c.length; i++) {
        c[i].Activites.forEach((item: any) => item.EmployeesInActi.map((itemE: any) => {
          if (itemE.Activite.id === actID) {
            const el = `${itemE.Employee.id}_${itemE.Activite.id}`
            const id = document.getElementById(el) as HTMLInputElement
            id.checked =  true
            const dataVal = {empID: itemE.Employee.id, actID: itemE.Activite.id}
            tab.push(JSON.stringify(dataVal))
          }
        }))
      }
      setDetail((prevDetail: any) => [...prevDetail, { id: value, list: tab }]);
      setSelectedItems([...selectedItems, ...tab])

      return
    }
    setSelectedCompetenceItems((prevData) => prevData.filter((item) => item !== value ));
    const wn = detail.filter((item: any) => item.id === value)
    wn.map((el: any) => el.list.map((item: any) => {
      const obj = JSON.parse(item)
      const el = `${obj.empID}_${obj.actID}`
      const id = document.getElementById(el) as HTMLInputElement
      id.checked = false
      setSelectedItems((prevData) => prevData.filter((mySelectedItem) => mySelectedItem !== item ));
    }))
    setDetail((prevDetail: any) => prevDetail.filter((item: any) => item.id !== value));
    setSelectedActivity((prevData) => prevData.filter((item) => item !== value ));
  }
    const btnTitle = selectedItems.length === tabEmp.length ? 'Désélectionner' : 'Tout sélectionner'
  const queryClient = useQueryClient();
  const mutationItems = useMutation({
      mutationFn: (items) => {
        return fetch(`${process.env["REACT_APP_API_URL"]}/api/employee_needs_form_set_toanalyze`, {
          method: 'POST',
          body: JSON.stringify({data: items}),
          headers: {"Content-Type": "application/json", "Accept": "application/json"},
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["employees_needs_form_toanalyze"]);
        setSelectedItems([])
        setSelectedCompetenceItems([])
        setAlert('Les collaborateurs sélectionnés se retrouvent à présent sur le tableau "Analyse et priorisation"')
        setTimeout(() => setAlert(''), 5000)
      }
    })
    const [selectedCompetenceItems, setSelectedCompetenceItems] = useState<any[]>([]);

    const dropList = useMemo(() => {
    if (search && libelleComp.length > 0) {
      const regex = new RegExp(""+search+"", 'gi');
      const data = libelleComp.reduce((acc: any, curr: any) => {
        curr.forEach((item: {id: number, libelle: string}) => {
          if (!acc.some((existingItem: {id: number, libelle: string}) => existingItem.id === item.id && existingItem.libelle === item.libelle)) {
            acc.push(item);
          }
        });
        return acc;
      }, []);
      return data.filter((el: any) => el.libelle.match(regex))
    }
    return []
  }, [search, libelleComp])
    return (
        <div className={`besoins_Metier ${props.className}`}>
            {BesoinsQuery.isLoading ? (
                <MbjLoading />
            ) : BesoinsQuery.isError || !BesoinsQuery.data ? (
                <p>Erreur ...</p>
            ) : (
                <WrapResultBesoin
                  alert={alert}
                  onCheckAllHandler={checkAllHandler}
                  compItems={selectedItems}
                  onSendEmps={mutationItems.mutate}
                  btnTitle={btnTitle}
                  search={search}
                  setSearch={setSearch}
                  dropList={dropList}
                >
                    {myList.map(item=>(
                        <OneBlockMaster
                          selectedActivity={selectedActivity}
                          onCheckboxActivityHandler={checkboxActivityHandler}
                          selectedItems={selectedItems}
                          onCheckboxHandler={checkboxHandler}
                          listPlanification={listPlanification}
                          key={`one_bsoiMet${item.Master.id}`}
                          Blocks={item}
                          ClickActi={ClickActi}
                          onCheckboxCompetenceHandler={checkboxCompetenceHandler}
                          selectedCompetenceItems={selectedCompetenceItems}
                        />
                    ))}
                </WrapResultBesoin>

            )}
            <ModalFormationActivite
                open={openD}
                closeMe={SwitchOpen}
                activite={actiCurr ? actiCurr : {id:0, libelle:'nc', niveau:0}}
                Experts={ExpertsCurr}
            />
        </div>
    )
}
export const BesoinsByNiveau = styled(BesoinsByNiveauCtrl)``
