import styled from "styled-components";
import {MbjDirectLink} from "@mbj-front-monorepo/ui";
import {NavLink} from "react-router-dom";

interface MbjSideLinkCtrlProps{
    className?:string;
    Link:MbjDirectLink;
}

const MbjSideLinkCtrl = (props:MbjSideLinkCtrlProps)=>{
    return (
        <NavLink to={props.Link.to} className={`mbj_side_link ${props.className}`}>{props.Link.libelle}</NavLink>
    )
}

export const MbjSideLink = styled(MbjSideLinkCtrl)`
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  text-decoration: none;
  color: white;
  font-size: 16px;
  display: block;
  &:visited{
    color: white;
  }
  &:hover{
    background: ${props => props.theme.background2};
    cursor: pointer;
    color: ${props=>props.theme.dark};
    font-weight: bold;
  }
`
