import styled from "styled-components";
import {MbjMobileMenuSwitch} from "../../MbjMenu/MbjMenuLinks";
import {MbjMobileItemSwitch} from "../../../atoms/MbjItemMenu/mobile/MbjMobileItemSwitch";

interface HeaderMobileSwitchMenuCtrlProps {
  className?:string;
  Current:string,
  Menus:MbjMobileMenuSwitch[]
}

const HeaderMobileSwitchMenuCtrl = ({className, Menus, Current}: HeaderMobileSwitchMenuCtrlProps) => {
  return (
    <div className={`MenusSwitch ${className}`}>
      {Menus.map((item, idx)=>(
        <MbjMobileItemSwitch className={`oneSwitch`} isCurrent={Current === item.libelle} MbjSwitch={item} key={`oneMS${idx}`}/>
      ))}
    </div>
  )
}

export const HeaderMobileSwitchMenu = styled(HeaderMobileSwitchMenuCtrl)`
  display: flex;
  justify-content: flex-start;
`
