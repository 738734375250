import styled from "styled-components";
import {Navigate, Route, Routes, To} from "react-router-dom";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { MatrixAllMetiers } from "./MatrixAllMetiers";
import { MatrixCompetences } from "./MatrixCompetences";

interface MatrixRooterCtrlProps{
  className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Métiers-Métiers', to:"by_metiers_metiers"},
  {libelle: 'Métiers-Compétences', to:"by_metiers_competences"}
]

const MatrixRooterCtrl = (props:MatrixRooterCtrlProps)=>{
  return (
    <Routes>
      <Route
        element={
          <InternSubDesktopLayout
            Links={TabSubMenus}
          />
        }
      >
        <Route path="" element={<Navigate to="by_metiers_metiers" />} />
        <Route path="/by_metiers_metiers" element={<MatrixAllMetiers />} />
        <Route path="/by_metiers_competences" element={<MatrixCompetences />} />
      </Route>
    </Routes>
  )
}

export const MatrixRooter = styled(MatrixRooterCtrl)``
