import { fetchApi } from "../../services/fetchApi";
import {useQuery} from "@tanstack/react-query";


export const useGetAllEmployeeMatch = (id:number)=>{
  const promise = () => fetchApi("/api/match_employee_searchs", { method: "get", query: { idSearch: id} });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employee_match", id], promise, {
    enabled: id!==0,
  });
}
