import styled from "styled-components";
import {MbjCardBack, MbjListItem, MbjLoading, MbjNoResult, MbjScrollArea} from "@mbj-front-monorepo/ui";
import {useGetMobilites, useGetMobiliteSaved} from "@mbj-front-monorepo/data-access";
import React, {useMemo} from "react";
import {useParams} from "react-router-dom";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";

interface LastViewCtrlProps{
    className?:string;
    setIdMetier:(id:number)=>void;
}

const LastViewCtrl = (props:LastViewCtrlProps)=>{
    const { id } = useParams();
    const { isLoading, isError, data: mobiliteSaves } = useGetMobiliteSaved(id ? parseInt(id) : -1);
    const mobiliteQuery = useGetMobilites(id ? parseInt(id) : -1);

    const mobiliteFiltered = useMemo(() => {
        if (!mobiliteSaves || !mobiliteQuery.data) {
            return [];
        }
        const resultTab = [];
        for (const mobiliteSave of mobiliteSaves) {
            resultTab.push(mobiliteQuery.data.find((mobilite) => mobilite.Metier?.id === mobiliteSave.Metier?.id));
        }
        return resultTab;
    }, [mobiliteQuery.data, mobiliteSaves]);
    return (
        <MbjCardBack title={"Consultés récemment"}>
            <div className={`all_mob ${props.className}`}>
                {isLoading ? (
                    <MbjLoading />
                ) : isError ? (
                    "Errors"
                ) : mobiliteSaves?.length === 0 ? (
                    <MbjNoResult text={"Aucune mobilité consultée"}/>
                ) : (
                    <MbjScrollArea>
                        {mobiliteFiltered?.map(
                            (mobilite, index: number) =>
                                mobilite && (
                                    <MbjListItem key={mobilite.id} onClick={()=>props.setIdMetier(mobilite.Metier?.id||0)}>
                                        <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                                    </MbjListItem>
                                )
                        )}
                    </MbjScrollArea>
                )}
            </div>
        </MbjCardBack>
    )
}

export const LastView = styled(LastViewCtrl)`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  gap: 10px;
`
