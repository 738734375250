import styled from "styled-components";

interface LienInactifOptinCtrlProps {
  className?:string;
}

const LienInactifOptinCtrl = (props: LienInactifOptinCtrlProps) => {
  return (
    <div className={props.className}>
      <p>Nous sommes désolé, mais il semble que ce lien ne soit plus actif</p>
    </div>
  )
}

export const LienInactifOptin = styled(LienInactifOptinCtrl)`
  margin: 20px;
  border:solid #ccc 1px;
  border-radius: 12px;
  padding: 1rem;
  font-size: 17px;
`
