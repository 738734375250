import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";
import {LinkComp} from "../../../Metiers/OneMetier/GestionCompetences/LinkComp";
import * as React from "react";
import {useParams} from "react-router-dom";
import {useGetEmployeesCompetences} from "@mbj-front-monorepo/data-access";

interface OneCollaborateurResumeCompetenceCtrlProps{
    className?:string;
}

const OneCollaborateurResumeCompetenceCtrl = (props:OneCollaborateurResumeCompetenceCtrlProps)=>{
    const { id } = useParams();
    const queryCompetencesEmployee = useGetEmployeesCompetences(id ? parseInt(id) : undefined);
    return (
        <MbjCardBack title={"Compétences"}>
            <div className={`comps_resume ${props.className}`}>
                {queryCompetencesEmployee.isLoading ? <MbjLoading/> : queryCompetencesEmployee.isError ? <MbjErrorData/> :
                    queryCompetencesEmployee.data?.sort((a, b)=>(a.Niveau||0) > (b.Niveau||0) ? -1 : 1).map((item, idx)=>(
                        <LinkComp to={"../competences"} libelle={item.Competence ? item.Competence.libelle||'' : ''} key={`one_linkF${item.Competence ? item.Competence.id : 0}`} niveau={item.Niveau || 0}/>
                    ))
                }
            </div>
        </MbjCardBack>
    )
}

export const OneCollaborateurResumeCompetence = styled(OneCollaborateurResumeCompetenceCtrl)`
  max-height: 300px;
  overflow-y: auto;
  padding: 0 0.5rem;
`
