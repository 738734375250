import { MbjButton, MbjPasswordSimpleInput, MbjSimpleInput, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";
import { useNavigate } from "react-router-dom";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useLogin } from "@mbj-front-monorepo/data-access";
import jwt_decode from "jwt-decode";

interface LoginFormCtrlProps {
  className?: string;

  location: any;
}

function LoginFormCtrl(props: LoginFormCtrlProps) {
  const loginMutation = useLogin();
  const [isNoAuth, setIsNoAuth] = useState(false);

  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: any) => {
    loginMutation.mutate(data, {
      onSuccess: (result: {
        token: string | undefined;
        data: { NiveauAccess: number };
        refresh_token: string | undefined;
      }) => {
        if (!result.token) {
          setIsNoAuth(true);
        } else {
          const jwtDecode: any = jwt_decode(result.token);
          const Roles: string[] = jwtDecode["roles"];
          const isAuth =
            Roles.indexOf("ROLE_ENTREPRISE") !== -1 ||
            (Roles.indexOf("ROLE_EMPLOYEE") !== -1 && result.data.NiveauAccess !== -2);

          //Si l'utilisateur a été déconnecté (token expiré) on le redirige vers la page ou il était, sinon on le redirige vers l'accueil
          if (isAuth) {
            props.location.state ? navigate(props.location.state.from) : navigate("/app");
          } else {
            setIsNoAuth(true);
          }
        }
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={"ConnexionFormLogin" + props.className}>
      <MbjSimpleInputContainer errors={errors} label={"Nom d'utilisateur"}>
        <MbjSimpleInput
          name={"username"}
          id={"username"}
          register={register}
          registerOptions={{
            required: {
              value: true,
              message: "Nom d'utilisateur requis",
            },
          }}
        />
      </MbjSimpleInputContainer>
      <MbjSimpleInputContainer errors={errors} label={"Mot de passe"}>
        <MbjPasswordSimpleInput name={"password"} id={"password"} register={register} />
      </MbjSimpleInputContainer>
      {loginMutation.isError ? (
        <div className="incorrect">
          {loginMutation.error.message === "401"
            ? "Nom d'utilisateur ou mot de passe incorrect"
            : "Erreur réseau ... Veuillez nous excuser nous réglons cela au plus vite."}
        </div>
      ) : isNoAuth ? (
        <>Il semble que vous ne soyez pas authorisé à vous connecter à cette application</>
      ) : (
        <></>
      )}
      <MbjButton themeColor="primary" isPending={loginMutation.isLoading}>
        Connexion
      </MbjButton>
      {/*<MbjLink to="/forgot-password">Mot de passe oublié ?</MbjLink>*/}
    </form>
  );
}

export const LoginForm = styled(LoginFormCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  gap: "10px",

  "& .incorrect": {
    color: "#bf1650",
    fontSize: "16px",
  },
}));
