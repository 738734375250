import styled from "styled-components";
import {MbjCardBack, MbjListItem, MbjLoading, MbjScrollArea, MbjSearchBar} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetMobilites} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import * as React from "react";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";

interface AllMobilitesCtrlProps{
    className?:string;
    setIdMetier:(id:number)=>void;
}

const AllMobilitesCtrl = (props:AllMobilitesCtrlProps)=>{
    const { id } = useParams();
    const mobilitesQuery = useGetMobilites(id ? parseInt(id) : -1);
    const [search, setSearch] = useState<string>("");
    const ListMobiliteFiltered = useMemo(()=>{
        if (mobilitesQuery.isLoading || mobilitesQuery.isError || !mobilitesQuery.data) {
            return [];
        }
        return mobilitesQuery.data
            ?.filter((mob) => {
                if (search === "") {
                    return mob;
                } else if (mob.Metier?.libelle?.toLowerCase().includes(search.toLowerCase())) {
                    return mob;
                }
                return undefined;
            })
            .sort((a, b) => {
                return (a.Metier ? (a.Metier.libelle|| "0") : "0") > (b.Metier ? (b.Metier.libelle|| "0") : "0") ? 1 : -1;
            });
    }, [search, mobilitesQuery.data, mobilitesQuery.isLoading, mobilitesQuery.isError])
    return (
        <MbjCardBack title={"Toutes les mobilités"}>
            <div className={`all_mob ${props.className}`}>
                <MbjSearchBar
                    placeholder={"Rechercher un métier"}
                    className={"SearchBar"}
                    onChange={(event) => setSearch(event.target.value)}
                />
                <MbjScrollArea grow={1}>
                    {mobilitesQuery.isLoading ? (
                        <MbjLoading />
                    ) : mobilitesQuery.isError ? (
                        "Errors"
                    ) : (
                        ListMobiliteFiltered
                            .map((mobilite, idx: number) => (
                                <MbjListItem key={mobilite.id} onClick={()=>props.setIdMetier(mobilite.Metier?.id||0)}>
                                    <MbjMobiliteListItem mobilite={mobilite} className="in-line" />
                                </MbjListItem>
                            ))
                    )}
                </MbjScrollArea>
            </div>
        </MbjCardBack>
    )
}
export const AllMobilites = styled(AllMobilitesCtrl)`
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  gap: 10px;
`
