import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";


export const useAddNewMemberEquipeManager = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postMemberEquipeManagerCollection", "requestBody", any>) => {
      return fetchApi("/api/member_equipe_managers", {
        method: "post",
        json: {...data},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_one_detail"]);
        queryClient.invalidateQueries(["equipe_managers_byemployee_from_member"]);
        toast.success("Membre ajoutée !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
}

export const useAddManyMemberEquipeManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: any[]) => {
      return Promise.all(
        data.map((item: any) => fetchApi("/api/member_equipe_managers", {
          method: "post",
          json: item,
        }))
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_one_detail"]);
        toast.success("Membre ajoutée !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useTrashMemberEquipeManager = ()=>{
  const queryClient = useQueryClient();
  return useMutation(
    (idMember:number)=>{
      return fetchApi("/api/member_equipe_managers/{id}", {
        method:"delete",
        params:{id:idMember+""}
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_one_detail"]);
        queryClient.invalidateQueries(["equipe_managers_byemployee_from_member"]);
        toast.success("Membre supprimé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
}
