import {fetchApi} from "@mbj-front-monorepo/data-access";
import {useQuery} from "@tanstack/react-query";


export const useGetMetrixForEmployee = (id:number)=>{
  const promise = () => fetchApi("/api/metrix_comp_employees", { method: "get", query: { idEmployee: id} });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["employee_metrix_comp", id], promise, {
    enabled: id!==0,
  });
}
