import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useEffect, useMemo, useRef, useState} from "react";
import {components, useGetAllEmployeeMatch, useGetSearchOne} from "@mbj-front-monorepo/data-access";
import {ResultEmployeeInterneCard} from "../components/ResultEmployeeInterneCard";
import {MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {Compare} from "../../../../../../components/Comparateur/ComparaisonRef/Compare";
import {CompareInterne} from "../../../../../../components/Comparateur/ComparaisonRef/CompareInterne";

interface ResultsMobInterneCtrlProps {
  className?:string;
}

interface Scoring{
  min:number,
  max:number,
  libelle:string;
}
const TabScoring:Scoring[] = [
  {min:-1, max:1, libelle:"Tous"},
  {min:0.90, max:1, libelle:"Très Proche"},
  {min:0.80, max:0.90, libelle:"Proche"},
  {min:0.6, max:0.8, libelle:"Assez Proche"},
  {min:0.4, max:0.6, libelle:"Eloigné"},
  {min:-1, max:0.4, libelle:"Très éloigné"},
]

interface OneItemS{
  Employee:components["schemas"]["Employee-read.matchempSearch_read.Employee.simple"];
  score:number;
  type:number;
}

const ResultsMobInterneCtrl = (props: ResultsMobInterneCtrlProps) => {
  const {id} = useParams();
  const SearchQuery = useGetSearchOne(id ? parseInt(id) : 0)
  const ResultsQuery = useGetAllEmployeeMatch(id ? parseInt(id) : 0)
  const [filterOn, setFilterOn] = useState<Scoring>({min:0, max:1, libelle:"Tous"})
  const refFilter = useRef<HTMLSelectElement>(null);
  const {isShowing, toggle} = useModal();
  const [idMetierCurr, setIdMetierCurr] = useState<number|undefined>(undefined)
  const [idUserMetierCurr, setIdUserMetierCurr] = useState<number|undefined>(undefined)
  const [idEmpCurr, setIdEmpCurr] = useState<number|undefined>(undefined)
  const handleChangeFilter= ()=>{
    if(refFilter.current){
      const lib = refFilter.current.value;
      const myF = TabScoring.find(f=>f.libelle === lib) as Scoring;
      setFilterOn(myF)
    }
  }
  useEffect(() => {
    if(SearchQuery.data){
      if(SearchQuery.data.UserMetier){
        console.log('USERMETU '+SearchQuery.data.UserMetier.id)
        setIdUserMetierCurr(SearchQuery.data.UserMetier.id||undefined);
      } else {
        console.log('XXXXXX')
        setIdUserMetierCurr(undefined)

      }
      if(SearchQuery.data.Metier){
        setIdMetierCurr(SearchQuery.data.Metier.id||undefined);
      } else {
        setIdMetierCurr(undefined)

      }

    }
  }, [SearchQuery.data]);
  const clickOnEmp = (id:number|undefined)=>{
    setIdEmpCurr(id);
    if(id!==undefined){
      toggle();
    }
  }
  const ListFiltered = useMemo(()=>{
    const list:OneItemS[] = [];
    if(ResultsQuery.data && filterOn){
      ResultsQuery.data.forEach(r=>{
        if((r.score||0) > filterOn.min && (r.score||0) <= filterOn.max && r.Employee){
          list.push({
            Employee:r.Employee,
            score:(r.score||0),
            type:(r.type||0)
          })
        }
      })
    }
    return list;
  }, [ResultsQuery.data, filterOn])
  return (
    <div className={`result_interne ${props.className}`}>
        <div className="filterPlace">
          <select className={`select_filter`} ref={refFilter} value={filterOn.libelle} onChange={handleChangeFilter}>
            {TabScoring.map(item=>(
              <option key={`opF${item.min}${item.max}`} value={item.libelle}>{item.libelle}</option>
            ))}
          </select>
        </div>
        <div className="wrap_result">
          {ListFiltered.sort((a,b)=>a.score >b.score ? -1 : 1).map(item=>(
            <ResultEmployeeInterneCard Employee={item.Employee} score={item.score} type={item.type} key={`oneR${item.Employee.id||0}`} clickOn={clickOnEmp}/>
          ))}
        </div>
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Comparaison"}>
        {idUserMetierCurr &&
          <CompareInterne
            idEmp={idEmpCurr}
            UserMetierId={idUserMetierCurr}
          />
        }
      </MbjModal2>
    </div>
  )
}

export const ResultsMobInterne = styled(ResultsMobInterneCtrl)`
  padding: 2% 1%;
  .wrap_result{
    display: flex;
    flex-wrap: wrap;
  }
  .filterPlace{
    margin-bottom: 12px;
    display: flex;
    justify-content: flex-end;
    padding: 0 1rem;
    select{
      height: 36px;
      border-radius: 4px;
    }
  }
`
