import { Navigate, Route, Routes, useParams } from "react-router-dom";
import React from "react";
import { useGetRight, useGetUserMetier } from "@mbj-front-monorepo/data-access";
import { InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu } from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { OneMetierInformations } from "./OneMetierInformations";
import { OneMetierGestionCompetences } from "./OneMetierGestionCompetences";

interface OneMetierRooterCtrlProps {
  className?: string;
}

export function OneMetierRooterCtrl(props: OneMetierRooterCtrlProps) {
  const { id } = useParams();
  const UserMetierQuery = useGetUserMetier(parseInt(id || "-1"));
  const { isLoading: loadRightR_infos, isAuth: isAuthR_infos } = useGetRight(
    "Métiers",
    "read",
    "MetierEntreprise",
    "OneMetier",
    "infos"
  );
  const { isLoading: loadRightR_ref, isAuth: isAuthR_ref } = useGetRight(
    "Métiers",
    "read",
    "MetierEntreprise",
    "OneMetier",
    "referentiel"
  );
  const { isLoading: loadRightR_evo, isAuth: isAuthR_evo } = useGetRight(
    "Métiers",
    "read",
    "MetierEntreprise",
    "OneMetier",
    "evo"
  );
  const { isLoading: loadRightR_marche, isAuth: isAuthR_marche } = useGetRight(
    "Métiers",
    "read",
    "MetierEntreprise",
    "OneMetier",
    "Marche_travail"
  );
  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Entreprise"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {UserMetierQuery.data ? (
                      <>
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {UserMetierQuery.data?.namePerso?.toUpperCase()}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                {isAuthR_infos && (
                  <MbjLink to="informations" fontSize={"14px"}>
                    Informations
                  </MbjLink>
                )}
                {isAuthR_ref && (
                  <MbjLink to="competences" fontSize={"14px"}>
                    Référentiel Métier
                  </MbjLink>
                )}
                {/*{isAuthR_evo && process.env["REACT_APP_DEFAULT_THEME"] !== "sqyTheme" && (*/}
                {/*  <MbjLink to="evolution" fontSize={"14px"}>*/}
                {/*    Evolution Métier*/}
                {/*  </MbjLink>*/}
                {/*)}*/}
                {/*{isAuthR_marche && process.env["REACT_APP_DEFAULT_THEME"] !== "sqyTheme" && (*/}
                {/*  <MbjLink to="marche_du_travail" fontSize={"14px"}>*/}
                {/*    Marché du travail*/}
                {/*  </MbjLink>*/}
                {/*)}*/}
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="informations" />} />
        <Route path={"/informations"} element={<OneMetierInformations />} />
        {/*<Route path={"/evolution"} element={<OneMetierEvolution/>}/>*/}
        {/*<Route path={"/marche_du_travail"} element={<OneMetierMarche/>}/>*/}
        <Route path={"/competences/*"} element={<OneMetierGestionCompetences />} />
      </Route>
    </Routes>
  );
}

export const OneMetierRooter = styled(OneMetierRooterCtrl)`
  min-height: fit-content;
  justify-content: space-between;
  .custom-content-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .icon-profile-menu {
      fill: ${(props) => props.theme.primaryDarken};
      height: 25px;
      width: 25px;
    }
  }
`;
