import styled from "styled-components";
import {components, useGetMemberServices, useGetRight} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjErrorData, MbjLoading, MbjNoResult, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {useState} from "react";
import {ListeEquipe} from "../Equipes/ListeEquipes";
import {CardModifierEquipe} from "../Equipes/CardModifierEquipe";
import {ListeMembreEquipe} from "../Equipes/ListeMembreEquipe";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {ModalAddService} from "../Equipes/ModalAddService";

interface EntrepriseServicesCtrlProps{
  className?:string;
}


const EntrepriseServicesCtrl = (props:EntrepriseServicesCtrlProps)=>{
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Entreprise", "write", "Equipes", "listing")
  const userServicesQuery = useGetMemberServices();
  const [serviceCurrent, setServiceCurrent] = useState<components["schemas"]["Services-read.Services.Detailed_read.Employee.simple"]|null>(null)
  const {isShowing, toggle} = useModal();
  return (
    <div className={`entreprise_equipe pageStd ${props.className}`}>
      {isAuthWrite &&
        <div style={{display:"flex", justifyContent:"flex-end", marginBottom:"20px"}}>
          <MbjButton onClick={toggle} leftIcon={<GoPlus />}>
            Ajouter un service
          </MbjButton>
        </div>
      }
      <MbjWidgetClassic bodyDirection={"column"} bodyGap={"20px"} >
        <MbjWidgetClassic>
          {userServicesQuery.isLoading ? <MbjLoading/> : userServicesQuery.isError ? <MbjErrorData/> :
            !userServicesQuery.data ? <MbjErrorData/>:
              userServicesQuery.data.length === 0 ? <MbjNoResult text={"Aucun service pour le moment"}/>:
                <ListeEquipe list={userServicesQuery.data} setOne={setServiceCurrent}/>
          }
        </MbjWidgetClassic>
        {serviceCurrent &&
          <MbjWidgetClassic bodyDirection={"row"} bodyGap={"20px"}>
            <MbjWidgetClassic basis={"calc(50% - 10px)"}>
              <CardModifierEquipe Service={serviceCurrent} readonly={!isAuthWrite}/>
            </MbjWidgetClassic>
            <MbjWidgetClassic basis={"calc(50% - 10px)"}>
              <ListeMembreEquipe Service={serviceCurrent}/>
            </MbjWidgetClassic>
          </MbjWidgetClassic>
        }
      </MbjWidgetClassic>
      <ModalAddService isShowing={isShowing} hide={toggle} />
    </div>
  )
}

const EntrepriseServices = styled(EntrepriseServicesCtrl)``


export default EntrepriseServices;
