import styled from "styled-components";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {Route, To, Routes, Navigate, useParams} from "react-router-dom";
import {
    DashboardFormationCollaborateur
} from "../../../../Collaborateurs/Formation/Dashboard/DashboardFormationCollaborateur";
import ResultsSalaries from "./01_Salaries/ResultsSalaries";
import ResultsReclassements from "./02_Reclassements/ResultsReclassements";
import ResultsCvTheque from "./03_Cvtheque/ResultsCvTheque";
import {
    useGetResultsSearchFormationBySearch,
    useGetSearchOne,
    useGetUserMetierFormation
} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {VivierFormation} from "./04_VivierFormation/VivierFormation";
import {ResultsMobInterne} from "./00_MobInterne/ResultsMobInterne";

interface OneRechercheRooterCtrlProps {
    className?:string;
}
const TabSubMenus:{libelle:string, to:To, onlyUM:boolean}[] = [
    {libelle: 'Mobilités internes', to:"mob_interne", onlyUM:false},
    {libelle: 'Les salariés en mobilités', to:"salaries", onlyUM:false},
    {libelle: 'Les métiers et les formations de vos alliances', to:"reclassements", onlyUM:false},
    {libelle: 'la cvthèque', to:"cvtheque", onlyUM:false},
    {libelle: 'vivier formations', to:"vivier_formation", onlyUM:false},
]


const OneRechercheRooterCtrl = ({className}:OneRechercheRooterCtrlProps)=>{
    const {id} = useParams();
    const SearchQuery = useGetSearchOne(id ? parseInt(id) : -1)
    const FormationsQuery = useGetResultsSearchFormationBySearch(id ? parseInt(id) : -1)
    return (
        <div className={`one_recherche_rooter ${className}`}>
            <Routes>
                <Route
                    element={
                        <InternSubDesktopLayout
                            Links={SearchQuery.isLoading || FormationsQuery.isLoading ? TabSubMenus : TabSubMenus.map(t=>{
                                if(t.to === 'salaries'){
                                    return {...t, libelle:t.libelle + " ("+SearchQuery.data?.nbResultEmployee+")"}
                                } else if(t.to === 'reclassements'){
                                    return {...t, libelle:t.libelle + " ("+SearchQuery.data?.nbResultReclassement+")"}
                                } else if(t.to === 'cvtheque'){
                                    return {...t, libelle:t.libelle + " ("+SearchQuery.data?.nbResultParticulier+")"}
                                } else if(t.to === 'vivier_formation'){
                                    return {...t, libelle:t.libelle + " ("+FormationsQuery.data?.FormationContinusOFS?.length+")"}
                                } else {
                                    return {...t}
                                }
                            })}
                            contentContext={
                            <div style={{padding:"0.5rem 0", fontSize:"18px", marginBottom:"15px"}}>Résultats pour la recherche : <span style={{fontWeight:"bold"}}>{SearchQuery.isLoading ? '...' : SearchQuery.data && SearchQuery.data.UserMetier ? SearchQuery.data.UserMetier.namePerso||'' : SearchQuery.data && SearchQuery.data.Metier ? SearchQuery.data.Metier.libelle||'' : 'nc'}</span></div>}
                        />
                    }
                >
                    <Route path="" element={<Navigate to="mob_interne" />} />
                    <Route path="salaries" element={<ResultsSalaries />} />
                    <Route path="mob_interne" element={<ResultsMobInterne />} />
                    <Route path="reclassements" element={<ResultsReclassements />} />
                    <Route path="cvtheque" element={<ResultsCvTheque />} />
                    <Route path="vivier_formation" element={<VivierFormation />} />
                </Route>
            </Routes>
        </div>
    )
}

export const OneRechercheRooter = styled(OneRechercheRooterCtrl)``
