import * as React from "react";

import {
  MbjButton,
  MbjCardClassic,
  MbjErrorData,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjLoadingMessage,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  useAddMoyenForManyFormationPlanning,
  useGetFormationContinueCourtes,
  useGetFormationExpertsListe, useGetMemberMe,
} from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";

interface SelectionSolutionCtrlProps {
  className?: string;
  info: any;
  onToggle: any;
}

function SelectionSolutionCtrl(props: SelectionSolutionCtrlProps) {
  const actID = +props.info.actID
  const fccofQuery = useGetFormationContinueCourtes(actID)
  const expertsQuery = useGetFormationExpertsListe(actID)
  const mutation = useAddMoyenForManyFormationPlanning()
  const memberQuery = useGetMemberMe();
  const {
    handleSubmit,
    register,
  } = useForm({
    mode: "onTouched",
  });
  const handleSend = (data: any) => {
    const results = JSON.parse(data.formation)
    results['action'] = true
    results['fpIDs'] = props.info.fpIDs.split(',')
    results['memberID'] = memberQuery.data?.id
    mutation.mutate(results, {
      onSuccess: () => props.onToggle(),
    });
  };

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Sélection de la solution"}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="Nom">
              <MbjLabel>Activité </MbjLabel>
              <div className="data">{props.info.activite}</div>
            </div>
          </MbjCardClassic>
          <MbjCardClassic title="Sélection de la solution" basis={"100%"}>
            {(fccofQuery.isLoading || expertsQuery.isLoading) && (
              <MbjLoadingMessage>
                <p>L'affichage peut prendre une dizaine de secondes.</p>
              </MbjLoadingMessage>
            )}
            <form className="boxes" onSubmit={handleSubmit(handleSend)}>
              {fccofQuery.isLoading ? (
                <MbjLoading />
              ) : fccofQuery.isError ? (
                <MbjErrorData />
              ) : (
                fccofQuery.data?.length > 0 && (
                  <div className="inPutFx">
                    <h2 className="formationTitle">Organismes de formation pouvant former à cette compétence</h2>
                    <div className="detailBox">
                      {fccofQuery.data?.map((item: any) => (
                        <div className="infchk">
                          <input
                            {...register("formation")}
                            type="radio"
                            value={JSON.stringify({ id: item.id, type: "fccof" })}
                          />
                          <div>
                            {item.libelle +
                              " avec " +
                              item.rs +
                              " " +
                              item.adresse +
                              " à distance " +
                              Math.floor(item.distance) +
                              " km"}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
              {expertsQuery.isLoading ? (
                <MbjLoading />
              ) : expertsQuery.isError ? (
                <MbjErrorData />
              ) : (
                expertsQuery.data?.length > 0 && (
                  <div className="inPutFx">
                    <h2 className="formationTitle">Experts pouvant former à cette compétence</h2>
                    <div className="detailBox">
                      {expertsQuery.data?.map((item: any) => (
                        <div className="infchk">
                          <input
                            {...register("formation")}
                            type="radio"
                            value={JSON.stringify({ id: item.id, type: "expert" })}
                          />
                          <div>{item.nom + " " + item.prenom}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
              {((fccofQuery.data && fccofQuery.data?.length > 0) ||
                (expertsQuery.data && expertsQuery.data?.length > 0)) && (
                <div style={{ marginTop: "10px" }}>
                  <MbjButton themeColor="primary" size="md">
                    Sélectionner
                  </MbjButton>
                </div>
              )}
              {fccofQuery.data?.length === 0 && expertsQuery.data?.length === 0 && (
                <p>Pas de résultats pour le moment.</p>
              )}
            </form>
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const SelectionSolution = styled(SelectionSolutionCtrl)((props) => ({
  padding: "1% 2%",
  ".detailBox": {
    backgroundColor: "#f5f1f1",
    padding: "1rem",
  },
  ".formationTitle": {
    color: "#396c71",
    margin: "1rem 0",
  },
  ".infchk": {
    display: "flex",
    margin: "1rem 0",
  },
  ".infchk div": {
    marginLeft: "10px",
  },
  ".boxes": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
