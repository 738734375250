import styled, {keyframes} from "styled-components";
import {AvatarEmp, MbjCardBack} from "@mbj-front-monorepo/ui";
import {WidgetInscription} from "./WidgetInscription";
import * as React from "react";
import {useGetRight} from "@mbj-front-monorepo/data-access";


interface OneActiForBlock{
    activite:{id:number, libelle:string};
    Employees:{id:number, prenom?:string, nom?:string, name?:string, niveau:number, niveauCible:number}[];
}

interface OneBlockCompetenceStdCtrlProps{
    className?:string;
    titre:string;
    list:OneActiForBlock[]
    ClickActi:(item:{id:number, libelle:string, niveau:number})=>void;
    listPlanification:{idEmployee:number, idActi:number}[];
    selectedItems?: any[];
    onCheckboxHandler?: any;
    competenceID?: number
    onCheckboxCompetenceHandler ?: any
    onCheckboxActivityHandler ?: any
    selectedCompetenceItems: any[];
    selectedActivity: any[];
}

const OneBlockCompetenceStdCtrl = (props:OneBlockCompetenceStdCtrlProps)=>{
    const {isLoading:loadRightW_bs, isAuth:isAuthW} = useGetRight("Collaborateurs", "write", "Besoins formation", "Besoins", "")
    return (
        <MbjCardBack>
            <div className={`one_comp_block ${props.className}`}>
                <div className={`entry_comp`}>
                    <div className="divChkio">
                       <input
                         type="checkbox"
                         value={props.competenceID}
                         onChange={props.onCheckboxCompetenceHandler}
                         checked={props.selectedCompetenceItems.find(item => props.competenceID === item)}
                       />
                    </div>
                    <div className={`lib_in`}>{props.titre}</div>
                </div>
                <div className={`entry_acti`}>
                    {props.list.sort((a, b)=>(a.activite?.libelle||'') > (b.activite?.libelle||'') ? 1 : -1).map((item, idx)=>(
                        <div className={`wrap_acti`} key={`wraAct${idx}`}>
                          <div className={`entry_acti_name`}>
                            <div className="divChkio">
                              <input
                                id={`${props.competenceID}_${item.activite?.id}`}
                                type="checkbox"
                                value={`${props.competenceID}_${item.activite?.id}`}
                                onChange={props.onCheckboxActivityHandler}
                                checked={props.selectedActivity.includes(`${props.competenceID}_${item.activite?.id}`)}
                              />
                            </div>
                            <div className={`lib_in`}>
                              {item.activite?.libelle}
                              <div className={`link_former`} onClick={() => props.ClickActi({
                                id: item.activite.id,
                                libelle: item.activite.libelle,
                                niveau: 0,
                              })}>Comment former ?
                              </div>
                            </div>
                          </div>
                          <div className={`entry_employee`}>
                            {item.Employees?.map((itemE, idx2: number) => (
                              <div className={"Employee_need"} key={`Employee_need${idx2}`}>
                                <div className="divChk">
                                  <input id={itemE.id + "_" + item.activite?.id}
                                         type="checkbox"
                                         onChange={props.onCheckboxHandler}
                                             value={JSON.stringify({ empID: itemE.id, actID: item.activite?.id})}
                                             checked={props.selectedItems && props.selectedItems.some(i => i === JSON.stringify({ empID: itemE.id,actID: item.activite?.id}))}
                                      />
                                    </div>
                                    <div className={`line_one`}>
                                      <div className={`employee_name`}>
                                        <div className={`user_face`}><img src={AvatarEmp} alt={"avatar_part"} /></div>
                                        {itemE.prenom || ""} {itemE.nom || ""} {itemE.name || ""} {itemE.niveau}/{itemE.niveauCible}
                                      </div>
                                      <div className={"employee_action"}>
                                        {isAuthW &&
                                          <WidgetInscription listPlanification={props.listPlanification}
                                                             idActivite={(item.activite && item.activite.id) ? item.activite.id : 0}
                                                             idEmployee={itemE.id ? itemE.id : 0} />
                                        }
                                      </div>
                                    </div>
                                    <div className={`line_niveau`}>
                                      <div className={`wrapper_niveau`}>
                                        <div className={`wrappy_niveau_cible`}
                                             style={{ width: (itemE.niveauCible / 5 * 100) + "%" }} />
                                        <div className={`wrappy_niveau`}
                                             style={{ width: (itemE.niveau / 5 * 100) + "%" }} />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </MbjCardBack>
    )
}

const Progress = () => keyframes`
  from {
    width: 0;
  }

`;

export const OneBlockCompetenceStd = styled(OneBlockCompetenceStdCtrl)`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  .link_former{
    padding: 0.5rem 0;
    color:${props=>props.theme.secondary};
    &:hover{
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .divChkio {
    text-align: center;
  }
  .divChk {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  .wrap_acti {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0.25rem;

    &:not(:last-child) {
      border-bottom: solid 1px #ccc;
    }
  }

  .entry_comp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33.33%;
    flex-shrink: 0;
    border-right: solid 1px #ccc;

    .lib_in {
      color: ${props => props.theme.primary};
      font-weight: bold;
    }
  }

  .lib_in {
    padding: 0 0.35rem;
  }

  .entry_acti {
    display: flex;
    flex-direction: column;
    width: 66.66%;
    padding: 0.25rem;

    .lib_in {
      color: ${props => props.theme.greyDarken};
      font-weight: bold;
    }
  }

  .entry_acti_name {
    width: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-right: solid 1px #ccc;
  }

  .entry_employee {
    padding: 0.25rem 0.5rem;
    width: 50%;
  }

  .line_one {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
  }

  .Employee_need {
    padding: 0.25rem;

    &:not(:last-child) {
      border-bottom: solid 1px #ccc;
    }
  }

  .employee_name {
    display: flex;
    justify-content: space-between;
    gap: 4px;
    align-items: center;

    .user_face {
      width: 30px;

      img {
        width: 100%;
      }
    }
  }

  .line_niveau {
    padding: 0.25rem;

    .wrapper_niveau {
      width: 100%;
      height: 20px;
      border-radius: 4px;
      background: #ece9e9;
      overflow: hidden;
      position: relative;

      &:hover {
        cursor: pointer;
      }
    }

    .wrappy_niveau {
      position: absolute;
      left: 0;
      top: 0;
      height: 50%;
      background: ${props => props.theme.third};
      transition: width 0.3s;
      animation: ${Progress} 1.5s ease-out;
      border-radius: 4px;

      &.invisible {
        opacity: 0;
      }
    }

    .wrappy_niveau_cible {
      position: absolute;
      left: 0;
      top: 50%;
      height: 50%;
      background: ${props => props.theme.secondary};
      transition: width 0.3s;
      animation: ${Progress} 1.5s ease-out;
      border-radius: 4px;

      &.invisible {
        opacity: 0;
      }
    }
  }

`
