import styled from "styled-components";
import {Navigate, Route, Routes, To} from "react-router-dom";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { AnalysePriorisation } from "./by_collaborateur/AnalysePriorisation";
import { AnalyseByCompetence } from "./by_competence/AnalyseByCompetence";

interface AnalysePriorisationRooterCtrlProps{
  className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Par collaborateurs', to:"by_collaborateurs"},
  {libelle: 'Par compétences', to:"by_competences"},
]

const AnalysePriorisationRooterCtrl = (props:AnalysePriorisationRooterCtrlProps)=>{
  return (
    <Routes>
      <Route
        element={
          <InternSubDesktopLayout
            Links={TabSubMenus}
          />
        }
      >
        <Route path="" element={<Navigate to="by_collaborateurs" />} />
        <Route path="/by_collaborateurs" element={<AnalysePriorisation />} />
        <Route path="/by_competences" element={<AnalyseByCompetence />} />
      </Route>
    </Routes>
  )
}

export const AnalysePriorisationRooter = styled(AnalysePriorisationRooterCtrl)``
