import styled from "styled-components";
import React, { useState} from "react";
import { MbjListItem, MbjScrollArea, MbjSearchBar, MbjSimpleInputContainer } from "@mbj-front-monorepo/ui";

interface ZoneCompetenceSearchCtrlProps {
  className?: string;
  search?: string;
  setSearch: (s: string) => void;
  dropList?: any;
}

const ZoneCompetenceSearchCtrl = ({ className, search, setSearch, dropList }: ZoneCompetenceSearchCtrlProps) => {
  const [state, setState] = useState(false);
  const errors = {};
  const handleClick = (item: string) => {
    setSearch(item);
    setState(false);
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setState(true);
  }

  return (
    <div className={`zoneSearch ${className}`} style={{position: 'relative', margin: '0 10px'}}>
      <MbjSimpleInputContainer errors={errors} direction={"column"}>
        <MbjSearchBar
          id={"sarchComp"}
          placeholder={"Rechercher une compétence"}
          className={"SearchBar"}
          onChange={handleChange}
          defaultValue={search}
        />
      </MbjSimpleInputContainer>
      <div className={'rsx'}>
        <MbjScrollArea maxHeight={"390px"}>
          {state && dropList.map((item: any, index: number) => (
            <MbjListItem key={index} onClick={e => handleClick(item.libelle)} className="one-choice-activite">
              {item.libelle}
            </MbjListItem>
          ))}
        </MbjScrollArea>
      </div>
    </div>
  );
}

export const ZoneCompetenceSearch = styled(ZoneCompetenceSearchCtrl)`
  .rsx {
    margin: 0 12px 10px !important;
    position: absolute;
    left: 0;
    right: 0;
    background: #fff !important;
    z-index: 2;
    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }

  .mzsearch {
    position: relative;
  }

  .box-search {
    height: 90px;
    border-radius: 4px;
    border: 1px solid #07172d;
    background: #fff;
    padding: 8px;
    margin-bottom: 10px;
    overflow-y: scroll;
  }

  .one-choice-activite {
    border-radius: 4px;
    background: #fff;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .one-choice-activite:hover {
    background: #E6E6E6;
  }

`
