import styled from "styled-components";
import {MbjCardBack, MbjLoading, MbjRepartSlider, MbjWrapperRepart} from "@mbj-front-monorepo/ui";
import {useGetUserMetierStatistiques} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";

interface RepartitionEffectifMetierCtrlProps{
    className?:string;
}

const RepartitionEffectifMetierCtrl = (props:RepartitionEffectifMetierCtrlProps)=>{
    const userMetierSatistiquesQuery = useGetUserMetierStatistiques();
    const [effectifTotal, setEffectifTotal] = useState<number>(1);
    useEffect(()=>{
        if(userMetierSatistiquesQuery.data){
            setEffectifTotal(userMetierSatistiquesQuery.data.reduce((acc:number, item)=>acc+(item.effectif||0), 0))
        }
    }, [userMetierSatistiquesQuery.data])
    return (
        <MbjCardBack>

                {userMetierSatistiquesQuery.isLoading ?
                    <MbjLoading/>:
                    (
                        <MbjWrapperRepart>
                        {userMetierSatistiquesQuery.data?.sort((a,b)=>(a.effectif||0) > (b.effectif||0) ? -1 : 1).map((item, idx)=>(
                                <MbjRepartSlider tauxDec={(item.effectif||0)/effectifTotal} libelle={`${item.UserMetier?.namePerso||''} (${item.effectif||0})`} key={`Met${item.UserMetier?.id||0}`}/>
                            ))}
                        </MbjWrapperRepart>
                    )
                }
        </MbjCardBack>
    )
}

export const RepartitionEffectifMetier = styled(RepartitionEffectifMetierCtrl)`
`
