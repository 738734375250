import styled from "styled-components";
import {ReactNode} from "react";

interface ItemMenuBurgerCtrlProps {
  className?:string;
  icon:ReactNode;
  libelle:string;
  Action:()=>void;
}

const ItemMenuBurgerCtrl = ({className, icon, libelle, Action}: ItemMenuBurgerCtrlProps) => {
  return (
    <div className={`item_menu_burger ${className}`} onClick={()=>Action()}>
      <div className="wrap_icon">{icon}</div>
      <div className="libelle">{libelle}</div>
    </div>
  )
}

export const ItemMenuBurger = styled(ItemMenuBurgerCtrl)`
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
  &:hover{
    cursor: pointer;
    background: ${props => props.theme.primaryPastel};
  }
`
