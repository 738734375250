import * as React from "react";
import styled from "styled-components";
import {
  MbjButton,
  MbjHeading,
  MbjModal,
  MbjModalCtrlProps,
  MbjOption,
  MbjSearchBar,
  useSearchBar,
} from "@mbj-front-monorepo/ui";
import {components, Operation, useAddUserMetier, useGetMemberMe, useGetMetiers} from "@mbj-front-monorepo/data-access";
import {useMemo, useRef, useState} from "react";
import {BsSearch, BsTrash} from "react-icons/bs";
import {GrClose} from "react-icons/gr";
import {MdAdd} from "react-icons/md";

interface AjoutMetierModalCtrlProps extends MbjModalCtrlProps {}

function AjoutMetierModalCtrl(props: AjoutMetierModalCtrlProps) {
  const [list, setList] = useState<(components["schemas"]["Metier-read.Metier"])[]>([]);
  const metiersQuery = useGetMetiers();
  const [search, setSearch] = useState('');
  const refSearch = useRef<HTMLInputElement>(null)
  const memberQuery = useGetMemberMe();
  const mutation = useAddUserMetier();
  const listFiltered = useMemo(()=>{
    const regex = new RegExp(""+search+"", 'gi');
    const TabMet:(components["schemas"]["Metier-read.Metier"])[] = [];
    if(metiersQuery.data){
        metiersQuery.data.forEach(m=>{
          if(search === '' || m.libelle?.match(regex)){
            TabMet.push(m);
          }
        })
    }
    return TabMet;
  }, [metiersQuery.data, search])
  function handleClick() {
    list.forEach((Metier) => {
      mutation.mutate(
        { Member: "/api/members/" + memberQuery.data?.id, Metier: "api/metiers/" + Metier.id },
        {
          onSuccess:()=>{
            setList([]);
            setSearch('');
            props.hide();
          }
        }
      );
    });
  }
  const handleChangeSearch = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value);
    }
  }
  return (
    <MbjModal {...props} title="Ajout d'un ou plusieurs métiers" fitContent>
        <div className={`wrap_choices`}>
            <div className={`wrap_search`}>
                <div className={`search_zone`}>
                  <BsSearch className={"icon-search"} />
                  <input type={"text"} value={search} onChange={handleChangeSearch} ref={refSearch} placeholder={"Rechercher un métier"}/>
                  {search!=='' &&
                      <div className={`round_delete`} onClick={()=>setSearch('')}><GrClose/></div>
                  }
                </div>
                <div className={`list_zone`}>
                  {listFiltered.map((item)=>(
                      <div className={`metier_to_add`} key={`Om${item.id}`} onClick={()=>setList(l=>[...l, item])}>
                        <span>{item.libelle}</span>
                        <div className={`add_sig`}><MdAdd/></div>
                      </div>
                  ))}
                </div>
            </div>
          <div className={`addZone`}>
            <div className={"titre"}>Liste des métiers à ajouter</div>
            <div className={`list_metiers`}>
              {list.map((item)=>(
                  <div className={`metier_added`} key={`OmA${item.id}`} onClick={()=>setList(l=>[...l.filter(i=>i.id!==item.id)])}>
                    <div className={`remove_sig`}><BsTrash/></div>
                    <span>{item.libelle}</span>
                  </div>
              ))}
            </div>
            <div className={`valid_zone`}>
              {list.length>0 &&
                <MbjButton onClick={handleClick} isPending={mutation.isLoading}>
                  Confirmer
                </MbjButton>
              }
            </div>
          </div>
        </div>
    </MbjModal>
  );
}
export const AjoutMetierModal = styled(AjoutMetierModalCtrl)`
  .wrap_choices{
    display: flex;
    justify-content: flex-start;
  }
  .addZone{
    width: 300px;
    padding-left: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
  .list_metiers{
    flex-grow: 1;
    overflow-y: auto;
    padding: 0.25rem 0;
  }
  .valid_zone{
    padding: 0.5rem 0;
    display: flex;
    justify-content: flex-end;
  }
  .wrap_search{
    width: 350px;
    padding-right: 1rem;
    border-right: solid #ccc 1px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .list_zone{
    width: 100%;
    height: 325px;
    overflow-y: auto;
    padding: 0.25rem 0;
  }
  .search_zone{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    padding-bottom: 0.25rem;
    border-bottom: solid #ccc 1px;
    input{
      flex-grow: 1;
      border:none;
      height: 36px;
    }
  }
  .metier_added{
    position: relative;
    padding: 0.2rem 0.5rem;
    .remove_sig{
      opacity: 0;
      display: flex;
      left: 5px;
      width: 16px;
      height: 16px;
      flex-direction: column;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      svg{
        margin: auto;
      }
    }
    &:hover{
      color:white;
      .remove_sig{
        opacity: 1;
        transition: opacity 0.3s;
      }
      padding-left: 1.5rem;
      transition: padding-left 0.3s;
      cursor: pointer;
      background: ${props=>props.theme.warningPastel};
    }
  }
  .metier_to_add{
    width: 100%;
    padding: 0.2rem 0.5rem 0.2rem 0.25rem;
    position: relative;
    .add_sig{
      display: none;
      width: 16px;
      height: 16px;
      flex-direction: column;
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
      svg{
        margin: auto;
      }
    }
    &:hover{
      background: ${props => props.theme.primaryPastel};
      cursor: pointer;
      .add_sig{
        display: flex;
      }
    }
  }
  .titre{
    width: 100%;
    height: 40.5px;
    padding-bottom: 0.25rem;
    border-bottom: solid #ccc 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

`
