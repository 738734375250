import styled from "styled-components";
import React, { useState } from "react";
import {
  useGetCustomQuestions,
  useDeleteCustomQuestion, useUpdateStatusCustomQuestions,
} from "@mbj-front-monorepo/data-access";
import {
  MbjTableV2,
  MbjLoading,
  MbjButton,
  MbjScrollArea,
  useModal,
  MbjIconButton,
  createSelectColumn,
  MbjConfirmModal,
  MbjModal2,
} from "@mbj-front-monorepo/ui";

import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { MdOutlineLock, MdOutlineLockOpen, MdOutlineModeEdit } from "react-icons/md";
import { EditQuestion } from "./components/EditQuestion";
import { AddQuestion } from "./components/AddQuestion";
import { FrequencePro } from "./components/FrequencePro";
import { FrequenceEval } from "./components/FrequenceEval";


interface QuestionsCtrlProps{
  className?:string;
}

const QuestionsCtrl = (props:QuestionsCtrlProps) => {
  const {isShowing: isShowingEdit, toggle: toggleEdit} = useModal();
  const {isShowing: isShowingNew, toggle: toggleNew} = useModal();
  const {isShowing: isShowingFreq, toggle: toggleFreq} = useModal();
  const [questionID, setQuestionID] = useState(0)
  const questionsQuery = useGetCustomQuestions()
  const { isShowing: isShowingDeleteQuestion, toggle: toggleDeleteQuestion } = useModal();
  const columnHelper = createColumnHelper<GetElementType<typeof questionsQuery.data>>();
  const deleteQuestion = useDeleteCustomQuestion()
  const updateStatus = useUpdateStatusCustomQuestions()

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row: any) => row.libelle, {
      header: "Libelle",
    }),
    columnHelper.accessor((row: any) => row.isActive ? 'Activé' : 'Désactivé', {
      header: "Statut",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
      cell: (props: any) => <div className="btn-actions">
        <MbjIconButton
          size={"sm"}
          onClick={() => {
            setQuestionID(props.row?.original?.id)
            toggleEdit()
          }}
          ariaLabel={"Stats"}
          icon={<MdOutlineModeEdit />}
          themeColor={"primary"}
          isRound={false}
        />
        <MbjIconButton
          size={"sm"}
          onClick={() => handleStatusUpdate(props.row?.original)}
          ariaLabel={"Stats"}
          icon={props.row?.original?.isActive ? <MdOutlineLock />: <MdOutlineLockOpen />}
          themeColor={props.row?.original?.isActive? "complementary": "grey"}
          isRound={false}
        />
      </div>,
    }),
  ];
  const table = useReactTable({
    data: questionsQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })
  const handleStatusUpdate = (item: any) => {
    updateStatus.mutate({id:item.id, isActive: !item.isActive})
  }


  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {questionsQuery.isLoading ? (
        <MbjLoading />
      ) : questionsQuery.isError ? (
        <p>Error: {questionsQuery.error.message}</p>
      ) : (
        <>
          <div className={"space-between"}>
            <div className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ? `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && (
                <>
                  <MbjButton themeColor={"warning"} onClick={toggleDeleteQuestion} isPending={false}>
                    Retirer {table.getSelectedRowModel().rows.length} question(s)
                  </MbjButton>
                </>
              )}

              {/*{CanDelete() &&
                  <MbjButton themeColor={"warning"} onClick={TrashMetier} isPending={false}>
                    Supprimer le métier
                  </MbjButton>
                }*/}

            </div>
          </div>
          <div className="btnFlx">
            <MbjButton themeColor={"primary"} onClick={toggleNew} isPending={false}>Ajouter une question</MbjButton>
            <MbjButton themeColor={"secondary"} onClick={toggleFreq}>Définir la fréquence des entretiens</MbjButton>
          </div>
          <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true} />
          </MbjScrollArea>
          <MbjModal2 open={isShowingEdit} closeMe={toggleEdit} titre={"Formulaire d'édition"} Wd={"80%"}>
            <EditQuestion questionID={questionID} isShowing={isShowingEdit} hide={toggleEdit} onToggle={toggleEdit}/>
          </MbjModal2>
          <MbjModal2 open={isShowingNew} closeMe={toggleNew} titre={"Formulaire d'ajout"} Wd={"80%"}>
            <AddQuestion isShowing={isShowingNew} hide={toggleNew} onToggle={toggleNew}/>
          </MbjModal2>
          <MbjModal2 open={isShowingFreq} closeMe={toggleFreq} titre={"Formulaire de fréquence des entretiens"} Wd={"80%"}>
            <FrequencePro />
            <FrequenceEval />
          </MbjModal2>
        </>
        )}
      <MbjConfirmModal
        isShowing={isShowingDeleteQuestion}
        hide={toggleDeleteQuestion}
        title="Confirmation de suppression de(s) question(s)"
        confirmText={`Etes-vous sûr de vouloir retirer ${table.getSelectedRowModel().rows.length} question(s) ?`}
        confirmFunction={() => table.getSelectedRowModel().rows.map((row: any) => {
          return deleteQuestion.mutate(row.original.id || 0, {
              onSuccess: () => {
                toggleDeleteQuestion();
                table.toggleAllRowsSelected(false);
              },
            })
        })}
        isLoading={deleteQuestion.isLoading}
      />
    </div>


  )
}
export const Questions = styled(QuestionsCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;


  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sendButton {
    margin: 10px 0;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
    }

    .plprg {
      margin-bottom: 0.5rem;
    }

    .plprg_select {
      display: block;
      margin: 20px 10px 20px 0;
    }

  .btnFlx {
    display: flex;
    justify-content: space-between;
  }
  `
;
