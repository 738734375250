import styled from "styled-components";
import {Operation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import React, {useMemo, useState} from "react";
import {
    createColumnHelper,
    getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    useReactTable
} from "@tanstack/react-table";
import {
    createSelectColumn,
    MbjLoading, MbjModalSlidy,
    MbjScrollArea,
    MbjTableV2,
    MbjTabloCompareActi,
    MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import {ExpertSuiviOneCompetenceWidget} from "./ExpertSuivi/ExpertSuiviOneCompetenceWidget";
import {EmployeeAutoFormationOneCompetence} from "./AutoFormation/EmployeeAutoFormationOneCompetence";
import {IogaWidget} from "../../../../../components/Ioga/IogaWidget";

interface CompetencesSuiviCtrlProps{
    className?:string;
    employee?: Operation<"getEmployeeItem">;
}

interface OneLineLearn{
    id:number,
    libelle:string,
    niveau:number,
    niveauCible:number
}

const CompetencesSuiviCtrl = (props:CompetencesSuiviCtrlProps)=>{
    if (!props.employee?.id) throw new TypeError("employee.id is undefined");
    const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee?.id, props.employee?.UserMetier?.id);
    const [actiCurr, setActiCurr] = useState<{id:number, libelle:string, niveau:number}|null>(null)
    const [openD, setOpenD] = useState(false);
    const SwitchOpen = ()=>{
        setOpenD(o=>!o);
    }
    const OpenDwith = (item:{id:number, libelle:string, niveau:number})=>{
        setActiCurr(item);
        SwitchOpen();
    }
    const listToLearn = useMemo(()=>{
        if(!useGetMobBilanQuery.data){
            return []
        } else {
            const myList:OneLineLearn[] = [];
            if(useGetMobBilanQuery.data.Missed){
                useGetMobBilanQuery.data.Missed.map(l=>{
                    myList.push({
                        id:l.id || 0,
                        libelle:l.libelle || '',
                        niveau:l.niveauActuel || 0,
                        niveauCible:l.niveauAttendu || 0
                    })
                })
            }
            if(useGetMobBilanQuery.data.ToRised){
                useGetMobBilanQuery.data.ToRised.map(l=>{
                    myList.push({
                        id:l.id || 0,
                        libelle:l.libelle || '',
                        niveau:l.niveauActuel || 0,
                        niveauCible:l.niveauAttendu || 0
                    })
                })
            }
            console.log(myList);
            return myList;
        }
    }, [useGetMobBilanQuery.data])
    const ClickOn = (id:number)=>{
        const my = listToLearn.find(l=>l.id === id);
        if(my){
            OpenDwith({id:my.id, libelle:my.libelle, niveau:my.niveau})
        }
    }
    return (
        <div className={props.className}>
            <p style={{width:"100%", marginBottom:"15px"}} className={`introduction`}>Afin de mieux maîtriser les compétences attendues, selon My-Better-Job, {props.employee.prenom} devrait améliorer les compétences suivantes :</p>
            {useGetMobBilanQuery.isLoading ? (
                <MbjLoading />
            ) : useGetMobBilanQuery.isError || !useGetMobBilanQuery.data ? (
                <p>Erreur ...</p>
            ) : (
                <div className={"page_content"}>
                    <MbjTabloCompareActi listActi={listToLearn.map(l=> {
                        return {id:l.id, libelle:l.libelle, niveau:l.niveau, niveauCible:l.niveauCible}
                    })} colorNiveau={"primary"} colorNiveauCible={"secondary"} ActionClickOne={ClickOn}/>
                </div>
            )}
            <MbjModalSlidy open={openD} closeMe={SwitchOpen} title={(props.employee && actiCurr) ? "Former "+(props.employee.prenom||'')+" : "+actiCurr.libelle : ""}>
                {actiCurr &&
                    <>
                        <ExpertSuiviOneCompetenceWidget employee={props.employee} activite={actiCurr}/>
                        <EmployeeAutoFormationOneCompetence activite={actiCurr} employee={props.employee}/>
                        <MbjWidgetClassic title={"formation vidéos IOGA"}>
                            <IogaWidget/>
                        </MbjWidgetClassic>
                    </>
                }
            </MbjModalSlidy>
        </div>
    )
}

export const CompetencesSuivi = styled(CompetencesSuiviCtrl)`
  padding: 2%;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  .page_content{
    flex-grow: 1;
    overflow-y: auto;
  }

`
