import styled from "styled-components";
import {OnActiChoice} from "../NouvelleFormation";
import {FaPlus} from "react-icons/fa";

interface OneCardActiToChoiceCtrlProps {
  className?:string;
  Activite:OnActiChoice;
  AddActivite:(a:OnActiChoice)=>void;
}

const OneCardActiToChoiceCtrl = (props: OneCardActiToChoiceCtrlProps) => {
  return (
    <div className={"acti_to_add "+props.className } onClick={()=>props.AddActivite(props.Activite)}>
      <div className="libActi">{props.Activite.libelle}</div>
      <div className="wrap_add">
        <FaPlus />
      </div>
    </div>
  )
}

export const OneCardActiToChoice = styled(OneCardActiToChoiceCtrl)`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  border-radius: 4px;
  .libActi{
    flex-grow: 1;
    padding: 0 0.25rem;
  }
  .wrap_add{
    width: 0;
    position: relative;
    overflow: hidden;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &:hover{
    cursor: pointer;
    background: ${props => props.theme.thirdPastel};
    .wrap_add{
      transition: width 0.3s;
      width: 32px;
    }
  }
`
