import styled from "styled-components";
import { BlockPlannif } from "../Plannification/by_planification/Plannification";
import ReactDOM from "react-dom";
import {useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import {MbjSwitch, MbjSwitchThumb} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {Operation, useModifyFormationPlanning} from "@mbj-front-monorepo/data-access";
interface DetailsPlannifCtrlProps {
  className?:string;
  BlockPlannif:BlockPlannif
  x:number,
  y:number,
  open:boolean,
  closeMe:()=>void;
}

const DetailsPlannifCtrl = ({className, BlockPlannif, x, y, closeMe, open}: DetailsPlannifCtrlProps) => {
  const refObjt = useRef<HTMLDivElement>(null)
  const mutationChange = useModifyFormationPlanning();
  const [thisX, setThisX] = useState(x);
  const [isR, setIsR] = useState(false);
  const [isDiffusable, setIsDiffusable] = useState(false);
  useEffect(() => {
    const nbDiff = BlockPlannif.list.reduce((acc:number, item)=>acc+(item.is_diffusable ? 1 : 0), 0)
    setIsDiffusable(nbDiff===BlockPlannif.list.length)
  }, [BlockPlannif.list]);
  console.log('open >>> '+open)
  useEffect(()=>{
    if(refObjt.current) {
      const widWidth = window.innerWidth;
      const elt = refObjt.current.getBoundingClientRect();
      const widElt = elt.width;
      if(x+widElt > widWidth){
        setThisX((x-widElt*1.3));
        setIsR(true);
      } else {
        setThisX(x);
      }
    }
  }, [x, refObjt, open])
  const SwitchDiff = ()=>{
    const newDiff = !isDiffusable;
    const TabDatas:Operation<"putFormationPlanningItem", "requestBody">[] = [];
    BlockPlannif.list.forEach(i=>{
      TabDatas.push({
        id:i.id,
        isDiffusable: newDiff
      })
    })
    setIsDiffusable(newDiff)
    TabDatas.forEach(item=>{
      mutationChange.mutate(item)
    })
  }
  useEffect(() => {
    function handleClickOutside(event:any) {
      if (refObjt.current && !refObjt.current.contains(event.target)) {
        console.log('ici !!')
        closeMe();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refObjt]);

  if(!open){
    return null;
  }
  return ReactDOM.createPortal(
      <div className={`details_concerned ${className}`}  ref={refObjt}  style={{
        left: thisX+"px",
        top: y+"px",
        opacity:thisX === 0 ? 0 : 1
      }}>
        <div className={`header`}>
          <div className={`title`}>Projet de formation</div>
          <div className="closeList" onClick={()=>closeMe()}>
            <IoMdClose />
          </div>
        </div>
        <div className="switchFormation">
          <div className="labels">Le projet est-il diffusable ?</div>
          <div className="wrapswitch">
            <MbjSwitch
              defaultChecked={isDiffusable}
              onCheckedChange={SwitchDiff}
            >
              <MbjSwitchThumb />
            </MbjSwitch>
            <span>{isDiffusable ? "OUI" : "NON"}</span>
          </div>
        </div>
        <div className="listParticipant">
          <div className="labels">Liste des participants</div>
          {BlockPlannif.list.map((item, idx) => (
            <div className="on_item_concerned" key={`OT${idx}`}>{item.Employee?.prenom} {item.Employee?.nom}</div>
          ))}
        </div>
      </div>, document.body
  )
}

export const DetailsPlannif = styled(DetailsPlannifCtrl)`
  display: block;
  width: max-content;
  position:fixed;
  background: white;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  .wrapswitch{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }
  .labels{
    font-weight: bold;
    margin-bottom: 4px;
  }
  .switchFormation{
    margin: 10px 0;
  }
  .on_item_concerned{
    padding: 0.25rem 0.5rem;
  }
  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid #ccc 1px;
    padding-bottom: 0.25rem;
    .title{
      margin-right: 12px;
    }
    .closeList{
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:hover{
        cursor: pointer;
      }
    }
  }
`
