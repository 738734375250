import styled from "styled-components";
import {MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {OneMetierFormModif} from "./OneMetierFormModif";
import {OneMetierEmployee} from "./OneMetierEmployee";
import {OneMetierInfosBase} from "./OneMetierInfosBase";
import {useGetRight} from "@mbj-front-monorepo/data-access";

interface OneMetierInformationsCtrlProps{
    className?:string;
}

const OneMetierInformationsCtrl = (props:OneMetierInformationsCtrlProps)=>{
    const {isLoading:loadRightR_infos, isAuth:isAuthR_infos} = useGetRight("Métiers", "write", "MetierEntreprise", "OneMetier", "infos")
    return (
        <div className={`one_metier_content ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"row"} justify={"flex-start"}>
                <MbjWidgetClassic grow={1}>
                    <OneMetierFormModif readonly={!isAuthR_infos}/>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"30%"}>
                    <OneMetierEmployee/>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"30%"}>
                    <OneMetierInfosBase/>
                </MbjWidgetClassic>
            </MbjWidgetClassic>
        </div>
    )
}

export const OneMetierInformations = styled(OneMetierInformationsCtrl)`
  padding: 2% 2%;
`
