import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  id: number,
  name: string,
  name_perso: string,
  score: number,
}

interface MatchingEmployeeCtrlProps {
  className?: string;
  item: ITemTypes
}


function MatchingEmployeeCtrl(props: MatchingEmployeeCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.name}</td>
      <td className="isCenter">{props.item.name_perso}</td>
      <td className="isCenter">{Math.floor(props.item.score * 100)}</td>
    </tr>
  );
}

export const MatchingEmployee = styled(MatchingEmployeeCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
