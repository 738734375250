import styled from "styled-components";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {Outlet} from "react-router-dom";
import * as React from "react";
import {MbjTabsMobile} from "../../../organisms/layout/mobile/MbjTabsMobile";
import {MbjHeadBarMobile2} from "../../../molecules/MbjHeadBar/mobile/MbjHeadBarMobile2";

interface MobileLayoutCtrlProps {
  className?:string;
  Blocks:MbjBlockMenu[];
  DisconnectCallBack:()=>void;
  userLogo?: React.ReactElement;
}

const MobileLayoutCtrl = (props: MobileLayoutCtrlProps) => {
  return (
    <div className={"MobileLayout " + props.className}>
      <MbjHeadBarMobile2 className="MbjHeadBar" Disconnect={props.DisconnectCallBack}/>
      <div className="section">
        {/*props.type*/}
        <Outlet/>
      </div>
      <MbjTabsMobile className="MbjMenu" Blocks={props.Blocks}/>
    </div>
  );
}

export const MobileLayout = styled(MobileLayoutCtrl)`
  height: 100vh;
  display: flex;
  flex-direction: column;

  .section {
    flex-grow: 1;
    overflow: hidden;
    background: ${props => props.theme.background1};
    }
`
