import styled, {keyframes} from "styled-components";
import {useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";
import {ImSpinner3} from "react-icons/im";
import * as React from "react";
import {useQueryClient} from "@tanstack/react-query";

interface ModalRefreshSearchCtrlProps {
  className?:string;
  idSearch:number;
  closeMe:()=>void;
}

async function GetResultRefresh(id:number){
  const token = window.localStorage.getItem("__user_token__");
  const response = await fetch(`${process.env["REACT_APP_API_URL"]}/api/searchs/${id}/refresh_me`, {
    method: 'get',
    headers: {
      'Accept': 'application/json, application/xml, text/plain, text/html, *.*',
      'Authorization': `Bearer ${token}`
    },
  })
  return await response.json()
}

const ModalRefreshSearchCtrl = (props: ModalRefreshSearchCtrlProps) => {
  const [onRefresh, setOnRefresh] = useState(false);
  const queryClient = useQueryClient();
  const ValideRefresh = ()=>{
    setOnRefresh(true);
    GetResultRefresh(props.idSearch).then(()=>{
      queryClient.invalidateQueries(["search"])
      setOnRefresh(false);
      props.closeMe();
    })
  }
  return (
    <div className={`refresh_search ${props.className}`}>
      {!onRefresh ?
        <div className={"ask_for"}>
          <p>Vous souhaitez rafraichir la recherche, cela peut prendre un peu de temps, souhaitez-vous lancer le rafraichissement ?</p>
          <div className="wrap_valide">
            <MbjButton size={"sm"} type="button" onClick={ValideRefresh} themeColor={"primary"}>
              Rafraichir
            </MbjButton>
          </div>
        </div>:
        <div className="refresh_curr">
          <p>Rafraichissement en cours</p>
          <div className="marker">
            <div className="wrap_spin">
              <ImSpinner3 />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

const AnimateRotate = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`

export const ModalRefreshSearch = styled(ModalRefreshSearchCtrl)`
  width: 450px;
  padding: 1rem;
  .ask_for{
    p{
      text-align: center;
    }
    .wrap_valide{
      padding: 1rem;
      display: flex;
      justify-content: center;
    }
  }
  .refresh_curr{
    p{
      font-size: 24px;
      text-align: center;
    }
  }
  .marker{
    font-size: 32px;
    padding: 1rem;
    display: flex;
    justify-content: center;
    .wrap_spin{
      width: 32px;
      height: 32px;
      display: flex;
      animation: ${AnimateRotate} 0.7s infinite linear;
    }
  }
`
