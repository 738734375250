import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetEmployees, useGetUserMetier} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {MbjCardBack, MbjErrorData, MbjLabel, MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface OneMetierInfosBaseCtrlProps{
    className?:string;
}


const OneMetierInfosBaseCtrl = (props:OneMetierInfosBaseCtrlProps)=>{
    const {id} = useParams();
    const UseMetierQuery = useGetUserMetier(id ? parseInt(id) : -1)

    return (
        <MbjCardBack>
            <div className={`list_emp_metier ${props.className}`}>
                <div className="LibelleContainer">
                    <MbjLabel>Depuis la fiche MBJ :</MbjLabel>
                    <div className="data">{UseMetierQuery.data?.Metier?.libelle}</div>
                </div>
                <div className="DomaineContainer">
                    <MbjLabel>Domaine :</MbjLabel>
                    <div className="data">{UseMetierQuery.data?.Metier?.champPro}</div>
                </div>
            </div>
        </MbjCardBack>
    )
}

export const OneMetierInfosBase = styled(OneMetierInfosBaseCtrl)`
  display: flex;
  flex-direction: column;
  gap: 12px;
`
