import * as React from "react";
import styled from "styled-components";
import { MbjLink } from "../../atoms/MbjLink/MbjLink";

interface MbjSectionMenuCtrlProps {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du SectionMenu sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary";
  /**
   * Classe HTML du menu section ?
   */
  className?: string;
  /**
   * Liens de navigations du section menu
   */
  children?: React.ReactNode;
  /**
   * Contenu quelconque
   */
  otherContent?: React.ReactNode;
  /**
   * Justification du contenu :
   */
  justify?: "space-between" | "end" | "start";
  /**
   * Doit être un menu de navigation ?
   */
  isNavMenu?: boolean;
  /**
   * Padding ou pas ?
   */
  noPadding?: boolean;
}

function MbjSectionMenuCtrl(props: MbjSectionMenuCtrlProps) {
  return (
    <div className={props.className}>
      {props.otherContent}
      <nav>
        {Array.isArray(props.children) ? (
          props.children.map((child: any, i: number) => {
            if (child && child.type === MbjLink) {
              return React.cloneElement(child, {
                key: i,
                isNavLink: props.isNavMenu,
                themeColor: props.themeColor,
              });
            }
            return <React.Fragment key={`frag${i}`}/>;
          })
        ) : (
          <React.Fragment />
        )}
      </nav>
    </div>
  );
}

export const MbjSectionMenu = styled(MbjSectionMenuCtrl)((props) => ({
  display: "flex",
  justifyContent: props.justify,
  background: props.theme.background2,
  alignItems: "center",
  boxShadow: "0px 3px 6px #00000029",
  padding: !props.noPadding ? "10px 15px" : "0",

  "& nav": {
    display: "inline-flex",
    gap: "2vw",
    flexWrap: "wrap",

    a: {
      color: "#8E8E8E",
      fontSize: "16px",
    },
  },
}));
MbjSectionMenu.defaultProps = {
  justify: "end",
  themeColor: "primary",
  isNavMenu: true,
  noPadding: false,
};
