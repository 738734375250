import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetAllActiviteEmploye, useGetEmployeesCompetences} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";
import {OneBadgeStick} from "./components/OneBadgeStick";

interface BadgeExpertCtrlProps{
    className?:string;
}

const BadgeExpertCtrl = ({className}:BadgeExpertCtrlProps)=>{
    const {id} = useParams();
    const EmployeeCompQuery = useGetEmployeesCompetences(id ? parseInt(id) : 0);
    const listExpert:{id:number, libelle:string, niveau:number}[] = useMemo(()=>{
        if(EmployeeCompQuery.data){
            const CompetencesNiveau = EmployeeCompQuery.data.filter(e=>e.Niveau && e.Niveau >=4);
            console.log(CompetencesNiveau);


            return EmployeeCompQuery.data.filter(e=>(e.Niveau||0)>=4).map(e=>{
                return {id:e.Competence?.id||0, libelle:(e.Competence?.libelle||''), niveau:e.Niveau ? e.Niveau : 0}
            }).sort((a, b)=>a.niveau > b.niveau ? -1 : 1)
        } else {
            return [];
        }
    }, [EmployeeCompQuery.data])
    return (
        <div className={`badges_expert ${className}`}>
            <h2>Badges Expert</h2>
            <p className={"description"}>Les badges experts sont automatiquement attribués selon les compétences du collaborateur.</p>

            {EmployeeCompQuery.isLoading ? <MbjLoading/> : EmployeeCompQuery.isError ? <MbjErrorData/> :
                listExpert.length === 0 ?
                    <p>Aucun Badge Expert pour le moment</p>:
                    <div className={`wrap_list`}>
                        {listExpert.map(item => (
                            <OneBadgeStick libelle={"Expert " + item.libelle} niveau={item.niveau}
                                           key={`BadgeExpertActi${item.id}`}/>
                        ))}
                    </div>
            }
        </div>
    )
}

export const BadgeExpert = styled(BadgeExpertCtrl)`
  padding: 1rem;
  .wrap_list{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
`
