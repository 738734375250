import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {createContext, useContext, useEffect, useState} from "react";

import * as React from "react";
import {DomaineContext} from "./OneDomaineR";
import {OneCompetenceR} from "./OneCompetence";
import {CompetencesLayoutR} from "./CompetencesLayoutR";
import {ListCompetencesR} from "./ListCompetencesR";
import {MyCompetence, MyFamille} from "../CompetencesInterfaces";


interface OneFamilleRCtrlProps{
  className?:string;
}

interface ContextFamille{
  Competences:MyCompetence[];
  Famille:MyFamille|null
}

export const FamilleContext = createContext<ContextFamille>({Competences:[], Famille:null})

const OneFamilleRCtrl = (props:OneFamilleRCtrlProps)=>{
  const {idFamille} = useParams();
  const Context = useContext(DomaineContext)
  const [Famille, setFamille] = useState<MyFamille|null>(null)
  useEffect(() => {
    if(Context && idFamille){
      const Famille = Context.Familles.find(f=>f.id+""===idFamille);
      if(Famille){
        setFamille(Famille);
      }
    }
  }, [idFamille, Context]);
  return (
    <FamilleContext.Provider value={{Competences:Famille ? Famille.Competences : [], Famille:Famille}}>
      <Routes>
        <Route element={
          <CompetencesLayoutR link={{link:'../', libelle:Famille ? Famille.libelle : '', niveau : Famille ? Famille.Niveau : 0, backColor:'#96b2d8'}}/>}>
          <Route path="" element={<ListCompetencesR/>} />
          <Route path="/:idCompetence/*" element={<OneCompetenceR/>} />
          <Route path="*" element={<p>pas troue</p>} />
        </Route>
      </Routes>
    </FamilleContext.Provider>
  )
}

export const OneFamilleR = styled(OneFamilleRCtrl)`
  padding: 1% 2%;
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .title_link{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 15px 0;
  }
`
