import styled from "styled-components";
import {MbjBlockMenu, MbjDirectLink} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {useMemo, useState} from "react";
import {MbjSideLink} from "./MbjSideLink";
import {useGetMemberMe, useGetOFMBJMe} from "@mbj-front-monorepo/data-access";

interface MbjBlockMenuOFCtrlProps{
  className?:string;
  Block:MbjBlockMenu;
}

const MbjBlockMenuOFCtrl = (props:MbjBlockMenuOFCtrlProps)=>{
    const OFQuery = useGetOFMBJMe();
    const BlockFiltered = useMemo(()=>{
        const myBlocks:MbjDirectLink[] = [];
        if(OFQuery.data){
            const nivoAccess = OFQuery.data.niveauAcces||["-1"];
            const nivoAccessNb = nivoAccess.map((x:string)=>parseInt(x));
            props.Block.subMenus?.forEach(b=>{
                if(!b.nivo){
                    myBlocks.push(b);
                } else {
                    let nbCommun = 0;
                    b.nivo.forEach(i=>{
                        if(nivoAccessNb.indexOf(i)!==-1){
                            nbCommun++;
                        }
                    })
                    if(nbCommun>0){
                        myBlocks.push(b);
                    }
                }
            })
        }
        return myBlocks;
    }, [props.Block, OFQuery.data])
  const [open, setOpen] = useState(false);
  return (
    <div className={`block_menu_mbj ${props.className}  ${open ? 'opened' : 'closed'}`}>
      <div className={`title_section ${open ? 'opened' : 'closed'}`} onClick={()=>setOpen(o=>!o)}>
        <div className="icon_place">{props.Block.icon}</div>
        <div className="lib_place">{props.Block.libelle}</div>
      </div>
      <div className={`content_accordion`} aria-expanded={!open}>
        {BlockFiltered.map((item, idx)=>(
            <MbjSideLink Link={item} key={`oneMenu${idx}`}/>
        ))}
      </div>
    </div>
  )
}

export const MbjBlockMenuOFCpt = styled(MbjBlockMenuOFCtrl)`
  margin-bottom: 12px;
  &.opened{
    background: ${props => props.theme.primary};
  }
  .title_section{
    font-size: 17px;
    display: flex;
    justify-content: flex-start;
    color: white;
    gap: 8px;
    padding: 0.5rem;
    &.opened{
      background: ${props => props.theme.primaryDarken};
    }
    &:hover{
      cursor: pointer;
      background: ${props => props.theme.primaryLighten};
    }
  }
  .content_accordion{
    width: 100%;
    overflow: hidden;
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
  }
  .content_accordion[aria-expanded="true"] {
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
`
