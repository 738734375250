import styled from "styled-components";
import {MbjButton, MbjLoading, MbjModal2, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {useGetProxiCvThequeUseMetier} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {ProximitePartUserMetierCard} from "./ProximitePartUserMetierCard";
import {useEffect, useState} from "react";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {RefreshModal} from "./RefreshModal";


interface CompatibiliteOneCvThequeCtrlProps{
  className?:string;
}

const CompatibiliteOneCvThequeCtrl = (props:CompatibiliteOneCvThequeCtrlProps)=>{
  const navigate = useNavigate();
  const { id } = useParams();
  const ProxiQuery = useGetProxiCvThequeUseMetier(id ? parseInt(id) : -1)
  const [idUserMetierCurr, setIdUserMetierCurr] = useState(-1);
  const {isShowing, toggle} = useModal();
  const {isShowing:showRefresh, toggle:toggleRefresh} = useModal();
  const [lastMaj, setLastMaj] = useState<Date|null>(null)
  useEffect(() => {
    if(ProxiQuery.data){
      const Sorted = ProxiQuery.data.sort((a,b)=>new Date(a.updateAt || "1970-01-01") > new Date(b.updateAt || "1970-01-01") ? 1 : -1);
      setLastMaj(new Date(Sorted[0].updateAt||"1970-01-01"))
    }
  }, [ProxiQuery.data]);
  const GoMe = (id:number)=>{
    setIdUserMetierCurr(id);
    toggle();
  }
  const RefreshCompa = ()=>{
    toggleRefresh();
  }
  const CloseRefresh = ()=>{
    ProxiQuery.refetch();
    toggleRefresh();
  }
  return (
    <div className={`dash_cv_theque ${props.className}`}>
      {lastMaj &&
        <div className="lastmaj">
          <span>Dernière mise à jour : {lastMaj.toLocaleDateString()} {lastMaj.toLocaleTimeString()}</span>
          <MbjButton size={"sm"} type="button" onClick={RefreshCompa} themeColor={"primary"}>
            Rafraichir
          </MbjButton>
        </div>
      }
        <MbjWidgetClassic basis={"100%"} className={`bl ${props.className}`}>
          {ProxiQuery.isLoading ? (
            <MbjLoading />
          ) : ProxiQuery.isError ? (
            <p>Erreur ...</p>
          ) : (
            <div className={`wrap_results`}>
              {ProxiQuery.data.sort((a, b)=>(a.score||0)> (b.score||0) ? -1 : 1).map(p=>(
                <ProximitePartUserMetierCard key={`Proxy${p.id}`} Proximite={p} ClickOnIt={GoMe}/>
              ))}
            </div>
          )}
        </MbjWidgetClassic>
        <MbjModal2 open={isShowing} closeMe={toggle} titre={"Comparaison"}>
          <div style={{padding:"1rem"}}>
            <MbjCompareReferentiel
                idToCompare1={id ? parseInt(id) : -1}
                idToCompare2={idUserMetierCurr}
                typeCompared={"PUM"}
            />
          </div>
        </MbjModal2>
      <MbjModal2 open={showRefresh} closeMe={toggleRefresh} titre={"rafraichissement"} Wd={"500px"}>
        <RefreshModal idPart={id ? parseInt(id) : -1} closeMe={CloseRefresh}/>
      </MbjModal2>
    </div>
  )
}

export const CompatibiliteOneCvTheque = styled(CompatibiliteOneCvThequeCtrl)`
  padding: 1%;
  .lastmaj{
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    align-items: center;
    padding: 0 1.25rem;
    margin-bottom: 12px;
  }
  .wrap_results{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
`
