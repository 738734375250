import styled from "styled-components";
import {useForm} from "react-hook-form";
import {useParams} from "react-router-dom";
import {
    MbjAutocomplete,
    MbjButton,
    MbjEmailSimpleInput,
    MbjSimpleInput,
    MbjSimpleInputContainer
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {components, usePostNewDemandeInvitation} from "@mbj-front-monorepo/data-access";


interface FormNewMembreCtrlProps{
    className?:string;
    closeMe:()=>void;
}

const FormNewMembreCtrl = (props:FormNewMembreCtrlProps)=>{
    const mutation = usePostNewDemandeInvitation();
    const {id} = useParams();
    const methods = useForm({
        mode:'onChange',
        defaultValues:{
            prenom:'',
            nom:'',
            email:'',
            siren:'',
            entreprise:'',
            telephone:'',
            Alliance:`/api/alliances/${id}`
        }
    })
    const onSubmit = (datas:any)=>{
        mutation.mutate(datas, {
            onSuccess:()=>{
                props.closeMe();
            }
        })
    }
    return (
        <div className={"form_new_membre_alliance "+props.className}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="Infos">
                <div className={"rowContainer"}>
                    <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom Contact*"}>
                        <MbjSimpleInput
                            name={"prenom"}
                            id={"prenom"}
                            type={"text"}
                            noPadding
                            register={methods.register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Prénom requis",
                                },
                                minLength: {
                                    value: 2,
                                    message: "Prénom trop court",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                    <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom Contact*"}>
                        <MbjSimpleInput
                            name={"nom"}
                            id={"nom"}
                            type={"text"}
                            noPadding
                            register={methods.register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Nom requis",
                                },
                                minLength: {
                                    value: 2,
                                    message: "Nom trop court",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                </div>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Email contact*"}>
                    <MbjSimpleInput
                        name={"email"}
                        id={"email"}
                        type={"text"}
                        noPadding
                        register={methods.register}
                        registerOptions={{
                            required: {
                                value: true,
                                message: "Nom requis",
                            },
                            minLength: {
                                value: 2,
                                message: "Nom trop court",
                            },
                        }}
                    />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Téléphone*"}>
                    <MbjSimpleInput name={"telephone"} id={"telephone"} noPadding register={methods.register}
                                    registerOptions={{
                                        required: {
                                            value: true,
                                            message: "Téléphone requis",
                                        },
                                        minLength: {
                                            value: 10,
                                            message: "Téléphone trop court",
                                        },
                                    }}
                    />
                </MbjSimpleInputContainer>
                <div className={"rowContainer"}>
                    <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom Entreprise*"}>
                        <MbjSimpleInput
                            name={"entreprise"}
                            id={"entreprise"}
                            type={"text"}
                            noPadding
                            register={methods.register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Nom Entreprise requis",
                                },
                                minLength: {
                                    value: 2,
                                    message: "Nom Entreprise trop court",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                    <MbjSimpleInputContainer errors={methods.formState.errors} label={"Siren*"}>
                        <MbjSimpleInput
                            name={"siren"}
                            id={"siren"}
                            type={"text"}
                            noPadding
                            register={methods.register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Siren requis",
                                },
                                minLength: {
                                    value: 9,
                                    message: "Nom Entreprise trop court",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                </div>
                <span>Les champs notés * sont obligatoires</span>
                <div className={"wrap_sender"}>
                    <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
                        Inviter le nouveau membre
                    </MbjButton>
                </div>
            </form>
        </div>
    )
}

export const FormNewMembre = styled(FormNewMembreCtrl)`
  span{
    display: block;
  }
  .wrap_sender{
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
`
