import * as React from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
  MbjAvatar,
  MbjButton,
  MbjDragDropFile,
  MbjFlex,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { useQueryClient } from "@tanstack/react-query";

interface ChangeImageProfilModalCtrlProps extends MbjModalCtrlProps {
  className?: string;
  onToggle: () => void
}

function ChangeImageProfilModalCtrl(props: ChangeImageProfilModalCtrlProps) {
  const {
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const [image, setImage] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  function handleClick() {
    if(!image) return;
    setLoading(true)
    const data = new FormData();
    data.append('logo', image);
    fetch(`${process.env.REACT_APP_API_URL_ROOT}/api/members/${memberId}/logo`, {
      method: 'POST',
      body: data
    })
      .then((res: any) => {
        queryClient.invalidateQueries(["member", "me"]);
        props.onToggle()
        setLoading(false)
      })
      .catch((error: any) => console.log(error))
  }


return (
  <MbjModal {...props} maxWidth={"70%"} title={"Modifier l'image"}>
      <MbjDragDropFile
        key={''}
        accept={"image/png, image/jpeg, image/jpg"}
        full
        onUpload={(files) => {
          setImage(files[0]);
        }}
        count={1}
        formats={["png", "jpeg", "jpg"]}
      />
    <MbjSimpleInputContainer errors={errors} label={"Prévisualisation :"}>
        <MbjFlex>
          {!image ? (
            <p>Aucun fichier sélectionné pour le moment</p>
          ) : (
            <MbjAvatar src={URL.createObjectURL(image)} objectFit={"contain"} bg={"#ffffff"} size={"2xl"} name={"logo"} />
          )}
        </MbjFlex>
      </MbjSimpleInputContainer>
      <MbjButton alignSelf={"flex-end"} onClick={handleClick} isPending={loading}>Enregistrer</MbjButton>
  </MbjModal>
);
}
export const ChangeImageProfilModal = styled(ChangeImageProfilModalCtrl)((props) => ({
  ".ImageChooseForm": {
    display: "flex",
    flexDirection: "column",
    gap: "15px",
  },
}));
