import styled from "styled-components";
import {useLocation, useParams} from "react-router-dom";
import {useGetParticulier} from "@mbj-front-monorepo/data-access";
import {MbjLoading, MbjNavPath} from "@mbj-front-monorepo/ui";
import {MbjParticulierInformationsCard} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface SettingInfosCvThequeCtrlProps{
    className?:string;
}

const SettingInfosCvThequeCtrl = (props:SettingInfosCvThequeCtrlProps)=>{
    const { id } = useParams();
    const particulierQuery = useGetParticulier(parseInt(id || "-1"));
    return (
        <div className={"informations_cv_theque " + props.className}>
            {particulierQuery.isLoading || particulierQuery.isError ? (
                <MbjLoading />
            ) : (
                <MbjParticulierInformationsCard particulierId={particulierQuery.data?.id || -1} />
            )}
        </div>
    )
}

export const SettingInfosCvTheque = styled(SettingInfosCvThequeCtrl)`
    padding: 2% 1%;
`
