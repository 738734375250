import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {useGetMetiers, useGetUserMetiers, usePostSearch} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {
    MbjAutocomplete, MbjButton, MbjCardBack,
    MbjCardClassic,
    MbjFlex, MbjLabel,
    MbjNavPath,
    MbjRadioGroup, MbjRadioGroupIndicator,
    MbjRadioGroupItem, MbjSimpleInputContainer,
    useAutocomplete
} from "@mbj-front-monorepo/ui";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {useForm} from "react-hook-form";
import * as React from "react";

interface NewRechercheCtrlProps{
    className?:string;
}

const NewRechercheCtrl = ({className}:NewRechercheCtrlProps)=>{
    const location = useLocation();
    const metiersQuery = useGetMetiers();
    const userMetiersQuery = useGetUserMetiers();
    const mutation = usePostSearch();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [refChoice, setRefChoice] = useState("standard");

    const useAutoCompleteHook = useAutocomplete<
        GetElementType<typeof userMetiersQuery.data> | GetElementType<typeof metiersQuery.data>
    >();

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
        trigger,
    } = useForm({
        mode: "onChange",
    });

    const onSubmit = (data: { metier: string } | any) => {
        if (useAutoCompleteHook.selectedValue) {
            const data =
                refChoice === "perso"
                    ? { UserMetier: "/api/user_metiers/" + useAutoCompleteHook.selectedValue.id }
                    : { Metier: "/api/metiers/" + useAutoCompleteHook.selectedValue.id };
            mutation.mutate(data);
            setIsSubmitting(true);
            return;
        }
    };

    return (
        <div className={"ProfilsRetenus" + className}>
            <MbjCardBack>
                <div className={"searchContent"} style={{display:"flex", flexDirection:"column", gap:"10px"}}>
                    <MbjFlex align={"center"}>
                        <span>Référentiels utilisés :</span>
                        <div style={{flexGrow:1}}>
                            <MbjRadioGroup
                                defaultValue="standard"
                                aria-label="Choix des référentiels à chercher"
                                onValueChange={(value) => {
                                    setRefChoice(value);
                                    useAutoCompleteHook.clearValue();
                                }}
                                className={"radioContainer"}
                            >
                                <MbjFlex direction={"row"} wrap={"nowrap"} grow={1} justify={"flex-start"}>
                                    <MbjFlex wrap={"nowrap"} align={"center"}>
                                        <MbjRadioGroupItem value="perso" id="r1">
                                            <MbjRadioGroupIndicator />
                                        </MbjRadioGroupItem>
                                        <MbjLabel htmlFor="r1" themeColor={"dark"} size={"very-small"}>
                                           Vos métiers
                                        </MbjLabel>
                                    </MbjFlex>
                                    <MbjFlex wrap={"nowrap"} align={"center"}>
                                        <MbjRadioGroupItem value="standard" id="r2">
                                            <MbjRadioGroupIndicator />
                                        </MbjRadioGroupItem>
                                        <MbjLabel htmlFor="r2" themeColor={"dark"} size={"very-small"}>
                                            Métiers standards
                                        </MbjLabel>
                                    </MbjFlex>
                                </MbjFlex>
                            </MbjRadioGroup>
                        </div>
                    </MbjFlex>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <MbjFlex direction={"column"}>

                            <MbjSimpleInputContainer errors={errors}>
                                <MbjAutocomplete
                                    placeholder={
                                        refChoice === "perso"
                                            ? "Choisissez un métier parmi vos fiches personnalisées"
                                            : "Choisissez un métier parmi les fiches de my-better-job"
                                    }
                                    className={"SearchBar"}
                                    suggestionsQuery={refChoice === "perso" ? userMetiersQuery : metiersQuery}
                                    autocompleteHooks={useAutoCompleteHook}
                                    foldAfterChoose={true}
                                    name={"metier"}
                                    register={register}
                                    setValue={setValue}
                                    trigger={trigger}
                                    labelKey={refChoice === "perso" ? ("namePerso" as keyof unknown) : ("libelle" as keyof unknown)}
                                    required
                                />
                            </MbjSimpleInputContainer>
                            <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
                                Enregistrer la recherche
                            </MbjButton>
                        </MbjFlex>
                    </form>
                </div>
            </MbjCardBack>
        </div>
    );
}

export const NewRecherche= styled(NewRechercheCtrl)`
  padding: 1% 2%;
  .searchCard{
    .searchContent{
      .radioContainer{
        display: flex;
        align-item: center;
        gap: 5%;
        margin: 10px 0px;
        flex_grow: 1,
      }
    }
  }
`
