import styled from "styled-components";
import {useAddNewEquipeManager, useGetConnected} from "@mbj-front-monorepo/data-access";
import {useRef, useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";

interface FormNewTeamManagerCtrlProps {
  className?:string;
}

const FormNewTeamManagerCtrl = ({className}: FormNewTeamManagerCtrlProps) => {
  const mutationAdd = useAddNewEquipeManager();
  const [errorSave, setErrorSave] = useState(false);
  const UserConnectedQuery = useGetConnected();
  const [nom, setNom] = useState('')
  const refInput = useRef<HTMLInputElement>(null);
  const handleChangeInput = ()=>{
    if(refInput.current){
      setNom(refInput.current.value)
    }
  }
  const Submit = ()=>{
    const data:any = {
      nom:nom,
      User:`/api/users/${UserConnectedQuery.data?.id||0}`
    }
    mutationAdd.mutate(data, {
      onSuccess:()=>{
        setNom('');
      }, onError:()=>{
          setErrorSave(true)
      }
    })

  }
  return (
    <div className={`new_team_manager ${className}`}>
      <div className="wrap_content">
        <div className={`wrap_input`}>
          <input type={"text"} ref={refInput} value={nom} onChange={handleChangeInput} placeholder={"Nom de votre Equipe"}/>
        </div>
        <div className="wrap_sender">
          <MbjButton disabled={mutationAdd.isLoading} size={"sm"} themeColor={"primary"} onClick={Submit}>Nouvelle Equipe</MbjButton>
        </div>
      </div>
      {errorSave &&
        <div className="error_save">Une Erreur est survenue merci de retenter plus tard</div>
      }
    </div>
  )
}

export const FormNewTeamManager = styled(FormNewTeamManagerCtrl)`
  padding: 1rem;
  background: white;
  .wrap_content{
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    input{
      height: 32px;
      padding-left: 5px;
      width: 250px;
      border-radius: 4px;
      border:solid ${props => props.theme.greyLighten} 1px;
    }
  }
`
