import { MbjAvatar, MbjCardBack, MbjFlex, MbjLoading, useModal } from "@mbj-front-monorepo/ui";
import { useGetMemberMe } from "@mbj-front-monorepo/data-access";
import * as React from "react";
import { useState } from "react";
import { SwitchInfo } from "./components/SwitchInfo";
import { ChangeImageProfilModal } from "./ChangeImageProfilModal/ChangeImageProfilModal";

export const InformationsCard = () => {
  const memberQuery = useGetMemberMe();
  const [opened, setOpened] = useState("");
  const { isShowing, toggle } = useModal();
  function shortDesc(desc: string, size = 50) {
    if (desc.length > size) {
      return desc.substring(0, size) + " ...";
    }

    return desc.substring(0, size);
  }
  return (
    <MbjCardBack height={"100%"}>
      {memberQuery.isLoading ? (
        <MbjLoading />
      ) : memberQuery.isError || !memberQuery.data ? (
        <p>Erreur ...</p>
      ) : (
        <MbjFlex direction={"column"}>
          <div className={"wrap_avatar"} style={{ marginTop: "10px", textAlign: "center" }} onClick={toggle}>
            <MbjAvatar
              className={"profilImage"}
              src={`${process.env.REACT_APP_API_URL_ROOT}/logos/members/${memberQuery.data.logo}`}
              objectFit={"contain"}
              bg={"#ffffff"}
              size={"xl"}
              withShadow
              name={memberQuery.data.name}
            />
          </div>
          <MbjFlex direction={"column"} gap={"12px"}>
            <SwitchInfo
              type={"area"}
              opened={opened}
              setOpened={setOpened}
              info={memberQuery.data.description || ""}
              id={"description"}
              label={"Résumé"}
            />
            <SwitchInfo
              type={"text"}
              opened={opened}
              setOpened={setOpened}
              info={memberQuery.data.name || ""}
              id={"name"}
              label={"Raison Sociale"}
            />
            <SwitchInfo
              type={"text"}
              opened={opened}
              setOpened={setOpened}
              info={memberQuery.data.email || ""}
              id={"email"}
              label={"E-mail"}
            />
            <SwitchInfo
              type={"text"}
              opened={opened}
              setOpened={setOpened}
              info={memberQuery.data.siret || ""}
              id={"siret"}
              label={"Siret"}
            />
            <div style={{ width: "100%", display: "flex", gap: "6px" }}>
              <div style={{ width: "50%" }}>
                <SwitchInfo
                  type={"text"}
                  opened={opened}
                  setOpened={setOpened}
                  info={memberQuery.data.codePostal || ""}
                  id={"code_postal"}
                  label={"Code Postal"}
                />
              </div>
              <div style={{ width: "50%" }}>
                <SwitchInfo
                  type={"text"}
                  opened={opened}
                  setOpened={setOpened}
                  info={memberQuery.data.ville || ""}
                  id={"ville"}
                  label={"Ville"}
                />
              </div>
            </div>
          </MbjFlex>
        </MbjFlex>
      )}
      <ChangeImageProfilModal isShowing={isShowing} hide={toggle} onToggle={toggle} />
    </MbjCardBack>
  );
};
