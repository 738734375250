import {useGetMemberMe} from "../features/member/member.hooks";
import {useEffect, useState} from "react";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;

interface MyAuth{
    Onglet:string;
    SubMenu:string;
    SubPage:string;
    SubPage2:string;
    read:number[];
    write:number[];
}

const TabAuth:MyAuth[] = [
    {Onglet:"Entreprise", SubMenu:"", SubPage:"", SubPage2:"", read:[-1,1,3,2,4,5], write:[]},
    {Onglet:"Entreprise", SubMenu:"Tableau de bord", SubPage:"", SubPage2:"", read:[-1,1,3,4,5], write:[]},
    {Onglet:"Entreprise", SubMenu:"Equipes", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},
    {Onglet:"Entreprise", SubMenu:"Services", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},
    {Onglet:"Entreprise", SubMenu:"Paramétrage", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},





    {Onglet:"Messagerie", SubMenu:"", SubPage:"", SubPage2:"", read:[-1], write:[]},

    {Onglet:"Entreprise", SubMenu:"Equipes", SubPage:"listing", SubPage2:"", read:[], write:[-1,1,4,5]},


    {Onglet:"Métiers", SubMenu:"", SubPage:"", SubPage2:"", read:[-1,1,2,4,5,6,7], write:[]},
    {Onglet:"Métiers", SubMenu:"Tableau de bord", SubPage:"", SubPage2:"", read:[-1,1,2,4,5,6,7], write:[]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"", SubPage2:"", read:[-1,2,1,3,4,5,6,7], write:[-1,1,5]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"OneMetier", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"OneMetier", SubPage2:"infos", read:[-1,1,2,3,4,5,6,7], write:[-1,1,5]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"OneMetier", SubPage2:"referentiel", read:[-1,1,2,3,4,5,6,7], write:[-1,1,5]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"OneMetier", SubPage2:"evo", read:[-1,1,2,3,4,5], write:[-1,1,3,5]},
    {Onglet:"Métiers", SubMenu:"MetierEntreprise", SubPage:"OneMetier", SubPage2:"Marche_travail", read:[-1,1,2,4,5], write:[]},
    {Onglet:"Métiers", SubMenu:"Recherche Compétence", SubPage:"", SubPage2:"", read:[-1,1,3,4,5], write:[]},

    {Onglet:"Collaborateurs", SubMenu:"", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},
    {Onglet:"Collaborateurs", SubMenu:"Tableau de bord", SubPage:"", SubPage2:"", read:[-1,1,3,4,5], write:[]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,7], write:[-1,1,2]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"", read:[-1,1,3,4,5,7], write:[]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"infos", read:[-1,1,3,4,5,7], write:[-1,1,3]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"cv", read:[-1,1,3,4,5,7], write:[-1,1,7]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"competences", read:[-1,1,3,4,5,2,7], write:[-1,3,2,7]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"BadgesExpert", read:[-1,1,3,4,5,6,7], write:[]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"mobilité", read:[-1,1,3,4,5], write:[-1,1,3,5]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"suivi", read:[-1,1,3,4,5], write:[-1,1]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"entretien", read:[-1,1,3,4,5], write:[-1,1,3]},
    {Onglet:"Collaborateurs", SubMenu:"Mes collaborateurs", SubPage:"OneCollab", SubPage2:"droits", read:[-1], write:[-1]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"", SubPage2:"", read:[-1,1,4], write:[-1,1,4]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"Tableau de bord", SubPage2:"", read:[-1,1,4], write:[-1,1,4]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"Besoins", SubPage2:"", read:[-1,1,4], write:[-1,1,4]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"Analyse et priorisation", SubPage2:"", read:[-1,1,4], write:[-1,1,4]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"planification", SubPage2:"", read:[-1,1,3,4], write:[-1,1,2]},
    {Onglet:"Collaborateurs", SubMenu:"Besoins formation", SubPage:"planification alliances", SubPage2:"", read:[-1,1,4], write:[-1,1,4]},
    {Onglet:"Collaborateurs", SubMenu:"Pilotage RH", SubPage:"", SubPage2:"", read:[-1,1,2,3,4,5,6,7], write:[]},
    {Onglet:"Collaborateurs", SubMenu:"Nouvelle formation", SubPage:"", SubPage2:"", read:[2,7], write:[2,7]},
    {Onglet:"Collaborateurs", SubMenu:"Equipe Manager", SubPage:"", SubPage2:"", read:[3], write:[3]},
    {Onglet:"Collaborateurs", SubMenu:"Recherche Compétence", SubPage:"", SubPage2:"", read:[-1,1,3,4,5], write:[]},


    {Onglet:"Dynamique de l'effectif", SubMenu:"", SubPage:"", SubPage2:"", read:[-1,1,2,4,5], write:[]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Tableau de bord", SubPage:"", SubPage2:"", read:[-1,1,2,4,5], write:[]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"", SubPage2:"", read:[-1,1,2,4], write:[]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"Recherches", SubPage2:"", read:[-1,1,2], write:[-1,1,2]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"Recherches", SubPage2:"oneRecherche", read:[-1,1,2], write:[-1,1,2]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"cvTeque", SubPage2:"", read:[-1,1,2], write:[-1,1,2]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"cvTeque", SubPage2:"oneCvTheque", read:[-1,1,2], write:[-1,1,2]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"profil", SubPage2:"", read:[-1,1,2,4], write:[-1,1,2,4]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"profil", SubPage2:"oneProfil", read:[-1,1,2], write:[-1,1,2]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Recrutements", SubPage:"Analyse", SubPage2:"", read:[-1,1,2,4], write:[-1,1,2,4]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Reclassements", SubPage:"", SubPage2:"", read:[-1,1,5], write:[-1,1,5]},
    {Onglet:"Dynamique de l'effectif", SubMenu:"Chiffres clés", SubPage:"", SubPage2:"", read:[-1,1,2], write:[]},

    {Onglet:"Alliances", SubMenu:"", SubPage:"", SubPage2:"", read:[-1,1,4,5], write:[]},
    {Onglet:"Alliances", SubMenu:"Tableau de bord", SubPage:"", SubPage2:"", read:[-1,1,2,4,5], write:[]},
    {Onglet:"Alliances", SubMenu:"Mes Alliances", SubPage:"", SubPage2:"", read:[-1,1,2,4,5], write:[]},
    {Onglet:"Alliances", SubMenu:"Mes Alliances", SubPage:"OneAlliance", SubPage2:"", read:[-1,1,2,4,5], write:[1]},

]

export const GetListAuthOnglet = (Onglet:string, SubMenu?:string)=>{
    const mySubMenu = SubMenu ? SubMenu : '';
    const myT = TabAuth.find(i=>i.Onglet === Onglet && i.SubMenu===mySubMenu && i.SubPage2==='' && i.SubPage2==='')
  if(SubMenu === "Recherche compétence"){
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx')
    console.log(myT);
    console.log(Onglet);
    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx')
  }
    if(myT){
        return myT.read
    } else {
        return []
    }
}

export const useGetRight = (Onglet:string, task:"read"|"write", SubMenu?:string, SubPage?:string, SubPage2?:string)=>{
    const memberQuery = useGetMemberMe();
    const [isAuth, setIsAuth] = useState(false);
    useEffect(() => {
        let AuthBase = false;
        if(memberQuery.data){
            console.log('member ok')
            const nivoAccess = memberQuery.data.niveauAcces||["-2"];
            const mySubMenu = SubMenu ? SubMenu : '';
            const mySubPage = SubPage ? SubPage : '';
            const mySubPage2 = SubPage2 ? SubPage2 : '';
            const myAuth = TabAuth.find(a=>a.Onglet === Onglet && a.SubMenu === mySubMenu && a.SubPage === mySubPage && a.SubPage2 === mySubPage2)
            if(myAuth){
                const Auth =myAuth[task];
                let nbCommun = 0;
                Auth.forEach(i=>{
                    const myNivoNb = nivoAccess.map(x=>parseInt(x));
                    if(myNivoNb.indexOf(i)!==-1){
                        nbCommun++;
                    }
                })

                AuthBase = (nbCommun>0);
            }
        }
        setIsAuth(AuthBase);
    }, [memberQuery.data, Onglet, SubMenu, SubPage, SubPage2, task]);

    return {isLoading:memberQuery.isLoading, isAuth:isAuth}
}
