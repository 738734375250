import styled from "styled-components";
import {ReactNode} from "react";

interface MbjWrapperRepartCtrlProps{
    className?:string;
    children:ReactNode;
}

const MbjWrapperRepartCtrl = (props:MbjWrapperRepartCtrlProps)=>{
    return (
        <div className={`wrapper_repart ${props.className}`}>
            <div className={`content_repart`}>
                {props.children}
            </div>
        </div>
    )
}

export const MbjWrapperRepart = styled(MbjWrapperRepartCtrl)`
  overflow-y: auto;
  max-height: 410px;
  .content_repart{
    border-left: solid ${props => props.theme.primary} 4px;
    padding-left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
  }
`
