import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {
    useAddActiviteUserMetiers, useDeleteActiviteUserMetiers,
    useGetCompetencesUserMetiers, useGetRefActvites,
    useGetRefCompetences,
    useGetRefDomaines,
    useGetRefFamilles, useGetRight, useUpdateNiveauActiviteUserMetiers
} from "@mbj-front-monorepo/data-access";
import {createContext, useMemo} from "react";
import * as React from "react";
import {ListDomaine} from "./GestionCompetences/ListDomaine";
import {OneDomaine} from "./GestionCompetences/OneDomaine";

interface OneMetierGestionCompetencesCtrlProps{
    className?:string;
}

export interface LinkToComp{
    link:string;
    libelle:string;
    niveau:number;
    backColor?:string;
}

export interface MyActivite{
    id:number,
    idUserMetier?:number;
    libelle:string;
    Niveau:number;
}

export interface MyCompetence{
    id:number,
    libelle:string;
    Niveau:number;
    Activites:MyActivite[];
    Niveau2?:number;
    Niveau3?:number;
}

export interface MyFamille{
    id:number,
    libelle:string;
    Niveau:number;
    NiveauTotal?:number;
    Competences:MyCompetence[];
}

export interface MyDomaine{
    id:number,
    libelle:string;
    Niveau:number;
    NiveauTotal?:number;
    Familles:MyFamille[];
}
interface ContextMe{
    Domaines:MyDomaine[];
    UpdateNiveau?:(idx:number, newNiveau:number, idLine:number)=>void;
    TrashActi?:(idLine:number)=>void;
    Readonly?:boolean;
}

export const CompetenceOnMetierContext = createContext<ContextMe>({Domaines:[], Readonly:true})

const OneMetierGestionCompetencesCtrl = (props:OneMetierGestionCompetencesCtrlProps)=>{
    const {isLoading:loadRightR_ref, isAuth:isAuthR_ref} = useGetRight("Métiers", "write", "MetierEntreprise", "OneMetier", "referentiel")
    const { id } = useParams();
    const queryCompetencesUserMetiers = useGetCompetencesUserMetiers(parseInt(id || "-1"));
    const mutationModify = useUpdateNiveauActiviteUserMetiers(parseInt(id || "-1"));
    const mutationAdd = useAddActiviteUserMetiers(parseInt(id || "-1"));
    const mutationTrash = useDeleteActiviteUserMetiers(parseInt(id || "-1"));
    const DomainesQuery = useGetRefDomaines();
    const FamillesQuery = useGetRefFamilles();
    const CompQuery = useGetRefCompetences();
    const ActiQuery = useGetRefActvites();
    const UpdateNiveau = (idx:number, newNiveau:number, idLine:number)=>{
        if(idLine===0){
            mutationAdd.mutate({
                UserMetier:`/api/user_metiers/${(id ? parseInt(id) : -1)}`,
                niveau: newNiveau,
                Activite: "/api/activites/" + idx,
            })
        } else {
            mutationModify.mutate({idUMActivite: idLine, niveau: newNiveau})
        }
    }
    const TrashActi = (idx:number)=>{
        mutationTrash.mutate(idx)
    }
    const listDomaines = useMemo(()=>{
        const listD:MyDomaine[] = [];
        if(queryCompetencesUserMetiers.data && DomainesQuery.data && FamillesQuery.data && CompQuery.data && ActiQuery.data){
            const Domaines = queryCompetencesUserMetiers.data
                .filter(c=>c.Competence && c.Competence.Famille && c.Competence.Famille.Domaine)
                .map(c=>c.Competence && c.Competence.Famille && c.Competence.Famille.Domaine && c.Competence.Famille.Domaine.id && c.Competence.Famille.Domaine.libelle ? c.Competence.Famille.Domaine : {id:0, libelle:''}).filter(d=>d.id!==0);
            const DomainesSSD = Domaines.reduce((acc: { id:number, libelle:string }[], item)=>acc.map(i=>i.id).indexOf(item.id||0)===-1 ? [...acc, {id:item.id||0, libelle:item.libelle||''}] : acc, [])
            DomainesQuery.data.forEach(d=>{
                const CompsConcerned = queryCompetencesUserMetiers.data
                    .filter(c=>c.Competence && c.Competence.Famille && c.Competence.Famille.Domaine && c.Competence.Famille.Domaine.id === d.id);
                const ThisFamille = CompsConcerned.map(c=>c.Competence && c.Competence.Famille ? {id:c.Competence.Famille.id||0, libelle:c.Competence.Famille.libelle||''} : {id:0, libelle:''})
                const ThisFamilleSSD = FamillesQuery.data.filter(f=>f.Domaine?.id === d.id)
                const TabFamille:MyFamille[] = [];
                ThisFamilleSSD.forEach(f=>{
                    const CompsConcerned = queryCompetencesUserMetiers.data
                        .filter(c=>c.Competence && c.Competence.Famille && c.Competence.Famille && c.Competence.Famille.id === f.id);
                    const ThisComps = CompsConcerned.map(c=>c.Competence ? {id:c.Competence.id||0, libelle:c.Competence.libelle||''} : {id:0, libelle:''})
                    const ThisCompsSSD = CompQuery.data.filter(c=>c.Famille?.id === f.id)
                    const TabCompetences:MyCompetence[] = [];
                    ThisCompsSSD.forEach(c=>{
                        const myComp = queryCompetencesUserMetiers.data.find(i=>i.Competence?.id === c.id)
                        const ActivitesComp = ActiQuery.data.filter(a=>a.Competence?.id === c.id);
                        const TabActivite:MyActivite[] = [];
                        let niveauTot = 0;
                        let niveauTot2 = 0;
                        ActivitesComp.forEach(a=>{
                            let niveauThis = 0;
                            let idPartActi = 0;
                            if(myComp){
                                const Acti = myComp.UserMetierActivites;
                                if(Acti) {

                                    const myActiPart = Acti.find(act => act.Activite?.id === a.id)
                                    if(myActiPart){
                                        idPartActi  = myActiPart.id ? myActiPart.id : 0;
                                        niveauThis = myActiPart.niveau||0
                                    }
                                }
                            }
                            niveauTot+=niveauThis*(a.duree||0)/4;
                            niveauTot2+=niveauThis;
                            TabActivite.push({
                                id:a.id||0,
                                idUserMetier:idPartActi,
                                libelle:a.libelle||'',
                                Niveau:niveauThis
                            })
                        })
                        TabCompetences.push({
                            id:c.id||0,
                            libelle:c.libelle||'',
                            Activites:TabActivite.sort((a,b)=>a.Niveau > b.Niveau ? -1 : 1),
                            Niveau:niveauTot/TabActivite.length,
                            Niveau2:niveauTot2/TabActivite.length,
                            Niveau3:niveauTot2,
                        })
                    })
                    const TotalNiveau = TabCompetences.reduce((acc:number, item)=>acc + item.Niveau, 0)
                    TabFamille.push({
                        id:f.id||0,
                        libelle:f.libelle||'',
                        Competences:TabCompetences.sort((a,b)=>a.Niveau > b.Niveau ? -1 : 1),
                        Niveau: TotalNiveau/TabCompetences.length,
                        NiveauTotal:TabCompetences.reduce((acc:number,item)=>acc+(item.Niveau3||0), 0)
                    })
                })
                const TotalNiveauF = TabFamille.reduce((acc:number, item)=>acc + item.Niveau, 0)

                listD.push({
                    id:d.id||0,
                    libelle:d.libelle||'',
                    Familles:TabFamille.sort((a,b)=>a.Niveau > b.Niveau ? -1 : 1),
                    Niveau:TotalNiveauF/TabFamille.length,
                    NiveauTotal:TabFamille.reduce((acc:number,item)=>acc+(item.NiveauTotal||0), 0)
                })
            })
        }
        const MaxNiveauDomaine = Math.max(...listD.map(d=>d.Niveau))
        return listD.map(d=>{
            return {...d, Niveau:d.Niveau/MaxNiveauDomaine*5}
        }).sort((a, b)=>a.Niveau > b.Niveau ? -1 : 1);
    }, [queryCompetencesUserMetiers.data, DomainesQuery.data, FamillesQuery.data, CompQuery.data, ActiQuery.data])
    return (
        <div className={`gestion_competence_user_metier ${props.className}`}>
            <CompetenceOnMetierContext.Provider value={{Domaines:listDomaines, UpdateNiveau:UpdateNiveau, TrashActi:TrashActi, Readonly:!isAuthR_ref}}>
                <Routes>
                    <Route path={""} element={<ListDomaine loading={queryCompetencesUserMetiers.isLoading}/>}/>
                    <Route path="/:idDomaine/*" element={<OneDomaine/>} />
                    <Route path="*" element={<p>pas troue</p>} />
                </Routes>
            </CompetenceOnMetierContext.Provider>
        </div>
    )
}
export const OneMetierGestionCompetences = styled(OneMetierGestionCompetencesCtrl)`
    height: 100%;
`
