import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjLoading, MbjNoResult, MbjTable} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {getDateFR} from "@mbj-front-monorepo/utils";

interface ListeEquipesCtrlProps{
    className?:string;
    list:(components["schemas"]["Services-read.Services.Detailed_read.Employee.simple"])[];
    setOne:(item:components["schemas"]["Services-read.Services.Detailed_read.Employee.simple"])=>void;
}

const Titles = [
    { libelle: "Nom du service", Align: "isLeft" },
    { libelle: "Effectif", Align: "isCenter" },
    { libelle: "Date" },
];

const ListeEquipeCtrl = (props:ListeEquipesCtrlProps)=>{
    return (
        <MbjCardBack>
            <MbjTable titles={Titles} scrollable>
                {props.list.map((Service, i: number) => (
                    <tr className="selectable" key={`eM${i}`} onClick={()=>props.setOne(Service)}>
                        <td>{Service.libelle}</td>
                        <td className={"isCenter"}>{Service.Employees?.length}</td>
                        <td className={"isCenter"}>
                            {Service.creationAt ? getDateFR(new Date(Service.creationAt)) : "--"}
                        </td>
                    </tr>
                ))}
            </MbjTable>
        </MbjCardBack>
    )
}

export const ListeEquipe = styled(ListeEquipeCtrl)``
