import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {AjoutCVPart} from "../../../AjoutCV/AjoutCVPart";


interface AjoutParticulierByCVModalCtrlProps extends MbjModalCtrlProps {}

const AjoutParticulierByCVModalCtrl = (props:AjoutParticulierByCVModalCtrlProps)=>{
  return (
    <MbjModal {...props} title="Ajout d'un cv" fitContent maxWidth={"80%"}>
      <AjoutCVPart CloseMe={props.hide}/>
    </MbjModal>
  )
}
export const AjoutParticulierByCVModal = styled(AjoutParticulierByCVModalCtrl)``
