import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle((props) => ({
  "*": {
    margin: "0px",
    padding: "0px",
    outline: "0px",
    boxSizing: "border-box",
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

    //Scrollbar Custom pour Chrome et Safari :
    "&::-webkit-scrollbar": {
      width: "10px",
    },

    "&::-webkit-scrollbar-track": {
      background: props.theme.greyPastel,
    },

    "&::-webkit-scrollbar-thumb": {
      background: props.theme.greyLighten,
      borderRadius: "7px",
    },

    "&::-webkit-scrollbar-thumb:hover": {
      background: props.theme.grey,
    },
    "&::-webkit-scrollbar-thumb:active": {
      background: props.theme.greyDarken,
    },

    //Scrollbar Custom pour Mozilla :
    // scrollbarColor: props.theme.greyLighten + " " + props.theme.greyPastel,
  },

  "#root": {
    margin: "0 auto",
    height: "100%",
  },
  ".custom-option": {
    transition: "background 60ms",
  },
  ".custom-option:hover": {
    transitionDelay: "60ms",
    background: "#deebff",
  },
  ".custom-option.custom-select__option--is-focused": {
    background: "none",
  },
  ".custom-option.custom-select__option--is-selected": {
    background: "#2684FF",
  },
}));

export default GlobalStyle;
