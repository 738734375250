import styled from "styled-components";
import {Operation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import React, {useMemo} from "react";
import {AvatarEmp, MbjCardBack, MbjLoading, MbjNoResult, MbjRank, MbjWidgetClassic} from "@mbj-front-monorepo/ui";

interface ExpertsSuiviCtrlProps{
    className?:string;
    employee: Operation<"getEmployeeItem">;
}

interface EmployeeExpert{
    employee: { prenom:string, nom:string, id:number },
    activites:{activite:Operation<"getActiviteItem">, niveau:number, id:number}[]
}

const ExpertsSuiviCtrl = (props:ExpertsSuiviCtrlProps)=>{
    if (!props.employee?.id) throw new TypeError("employee.id is undefined");
    const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee?.id, props.employee?.UserMetier?.id);
    const myList = useMemo(()=>{
        if(!useGetMobBilanQuery.data){
            return [];
        } else {
            const myList:EmployeeExpert[] = []
            const ids:number[] = [];
            if(useGetMobBilanQuery.data.Missed){
                useGetMobBilanQuery.data.Missed.map(l=>{
                    if(l.id){
                        ids.push(l.id)
                    }
                    return l
                })
            }
            if(useGetMobBilanQuery.data.ToRised){
                useGetMobBilanQuery.data.ToRised.map(l=>{
                    if(l.id){
                        ids.push(l.id)
                    }
                    return l
                })
            }
            if(useGetMobBilanQuery.data.Experts?.forEach(e=>{
                const ActivitesCompare = e.activites;
                const ActivitesOk:{activite:Operation<"getActiviteItem">, niveau:number, id:number}[] = []
                ActivitesCompare?.forEach(a=>{
                    if(ids.indexOf(a.activite?.id || 0)!==-1 && a.activite && a.niveau && a.id){
                        ActivitesOk.push({activite: {...a.activite, Competence:undefined}, niveau:a.niveau, id:a.id})
                    }
                })
                if(ActivitesOk.length >0 && e.employee){
                    myList.push({employee: {prenom:e.employee.prenom || '', nom:e.employee.nom || '', id:e.employee.id || 0}, activites:ActivitesOk})
                }
            }))
                console.log(myList);
                return myList.sort((a, b)=>a.activites.length > b.activites.length ? -1 : 1);

        }
    }, [useGetMobBilanQuery.data])

    return (
        <div className={props.className}>
            {useGetMobBilanQuery.isLoading ? (
                <MbjLoading />
            ) : useGetMobBilanQuery.isError || !useGetMobBilanQuery.data ? (
                <p>Erreur ...</p>
            ) : (
                myList.length >0 ?
                myList?.map((item, idx:number)=>(
                    <MbjCardBack key={`OneExpert${idx}`}>
                        <div className={`oneExpert`}>
                            <div className={"name_expert"}>
                                <div className={`user_face`}><img src={AvatarEmp} alt={"avatar_part"}/></div>
                                <div className={`name_user`}>
                                    <span>{item.employee.prenom} {item.employee.nom}</span>
                                </div>
                            </div>
                            <div className={`list_expertise`}>

                                    {item.activites.map((acti, idxA:number)=>(
                                        <div className={`one_expertise_acti`} key={`oea${idxA}`}>
                                            <div className={`name_acti`}>{acti.activite.libelle}</div>
                                            <div className={`niveau_acti`}>
                                                <MbjRank maxRank={5} rank={acti.niveau} themeColor={"complementary"} isStar />
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </MbjCardBack>
                )):
                    <MbjNoResult text={"Aucun expert trouvé"}/>
            )}
        </div>
    )
}

export const ExpertsSuivi = styled(ExpertsSuiviCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  .oneExpert{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .name_expert{
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    .user_face{
      width: 40px;
      img{
        width: 100%;
      }
    }
    .name_user{
      font-size: 18px;
      color:${props=>props.theme.primary};
      font-weight: bold;
    }
  }
  .one_expertise_acti{
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    padding: 0.3rem 0.25rem;
    .name_acti{
      flex-grow: 1;
    }
    border-top: solid ${props=>props.theme.primaryPastel} 1px;
  }
`
