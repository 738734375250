import styled from "styled-components";
import {
  components, Operation, useGetRight,
  useModifyStatutSearchEmp,
  useModifyStatutSearchPart,
  useModifyStatutSearchRecl
} from "@mbj-front-monorepo/data-access";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
import {useEffect, useState} from "react";
import {InformationsParticulier} from "./InformationsParticulier";
import {InformationsEmployee} from "./InformationsEmployee";
import {MbjButton} from "@mbj-front-monorepo/ui";
import {InformationsReclassement} from "./InformationsReclassement";
import {
  GereSaveProfil
} from "../../../pages/DynamiqueEffectif/Recrutements/03_ProfilsRetenus/components/ProfilRetenuComponents/GereSaveProfil";


interface CompareCtrlProps{
  className?:string;
  isToGere?:boolean;
  SearchEmp?:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"];
  SearchPart?:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]
  SearchRecl?:components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"];
}

export interface ItemMenuResult{
  id:number;
  libelle:string;
}

const MesMenusResult:ItemMenuResult[]=[
  {id:1, libelle:"Informations générales"},
  {id:2, libelle:"Comparaison"},
]
const MesMenusResultToGere:ItemMenuResult[]=[
  {id:0, libelle:"Gestion du profil retenu"},
  {id:1, libelle:"Informations générales"},
  {id:2, libelle:"Comparaison"},
]

const CompareCtrl = (props:CompareCtrlProps)=>{
  const {isLoading:loadRightR, isAuth:isAuthW} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "Recherches", "oneRecherche")
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult>({id:1, libelle:"Les Employees"})
  const mutationREmp = useModifyStatutSearchEmp(props.SearchEmp?.id||-1);
  const mutationRPart = useModifyStatutSearchPart(props.SearchPart?.id||-1);
  const mutationRRecl = useModifyStatutSearchRecl(props.SearchRecl?.id||-1);
  const [statutCurr, setStatutCurr] = useState<number>(1);
  useEffect(()=>{
    if(props.SearchEmp){
      setStatutCurr(props.SearchEmp.statut?.id||1)
    }
    if(props.SearchPart){
      setStatutCurr(props.SearchPart.Statut?.id||1)
    }
    if(props.SearchRecl){
      setStatutCurr(props.SearchRecl.statut?.id||1)
    }
  }, [props.SearchEmp, props.SearchRecl, props.SearchPart])
  const SaveTheProfil = ()=>{
    if(props.SearchEmp){
      const datas:Operation<"putResultsSearchEmployeeItem", "requestBody">={
        statut:`/api/statut_results_searches/2`
      }
      mutationREmp.mutate(datas, {
        onSuccess:()=>{
          setStatutCurr(2)
        }
      })
    }
    if(props.SearchPart){
      const datas:Operation<"putResultsSearchParticulierItem", "requestBody">={
        Statut:`/api/statut_results_searches/2`
      }
      mutationRPart.mutate(datas, {
        onSuccess:()=>{
          setStatutCurr(2)
        }
      })
    }
    if(props.SearchRecl){
      const datas:Operation<"putResultsSearchReclassementItem", "requestBody">={
        statut:`/api/statut_results_searches/2`
      }
      mutationRRecl.mutate(datas, {
        onSuccess:()=>{
          setStatutCurr(2)
        }
      })
    }
  }
  return (
    <div className={`compare ${props.className}`}>
      {!props.isToGere &&
        <div className={"actions"}>
          {statutCurr !==1 ?
            <div className={`allready_treat`}>Ce profil est déjà en traitement</div>:
              isAuthW ?
            <MbjButton themeColor={"third"} onClick={SaveTheProfil} isPending={mutationRPart.isLoading || mutationREmp.isLoading || mutationRRecl.isLoading} size={"md"}>Retenir ce profil</MbjButton>:<></>
          }
        </div>
      }
      <div className={"MenuChoix"}>
        {!props.isToGere ?
          MesMenusResult.map((item)=>(
            <div onClick={()=>setMenuCurrent(item)} className={`one_menu_choice ${item.id === menuCurrent.id ? 'current' : ''}`}  key={`omc${item.id}`}>{item.libelle}</div>
          )):
          MesMenusResultToGere.map((item)=>(
            <div onClick={()=>setMenuCurrent(item)} className={`one_menu_choice ${item.id === menuCurrent.id ? 'current' : ''}`}  key={`omc${item.id}`}>{item.libelle}</div>
          ))
        }

      </div>
      {menuCurrent.id === 1 ?
        (
         <div className={"content_informations"}>
           {props.SearchEmp ?
             <InformationsEmployee idEmpl={props.SearchEmp.Employee?.id||-1}/> :
             props.SearchPart ?
               <InformationsParticulier idPart={props.SearchPart.Particulier?.id||-1}/>:
               <InformationsReclassement idRecl={props.SearchRecl?.Reclassement?.id||-1}/>
           }
         </div>
        ):
        menuCurrent.id === 2 ?
        <div className={"wrap_compare"}>
          <MbjCompareReferentiel
            idToCompare1={props.SearchEmp ? (props.SearchEmp?.Employee?.id||-1) : props.SearchPart ? (props.SearchPart.Particulier?.id||-1) : props.SearchRecl ? (props.SearchRecl.Reclassement?.UserMetier?.id||-1) : -1}
            idToCompare2={
              props.SearchEmp && props.SearchEmp.RecrutementSaved?.UserMetier ? (props.SearchEmp.RecrutementSaved?.UserMetier.id||-1) :
                props.SearchEmp && props.SearchEmp.RecrutementSaved?.Metier ? (props.SearchEmp.RecrutementSaved?.Metier.id||-1) :
                  props.SearchPart && props.SearchPart.RecrutementSaved?.UserMetier ? (props.SearchPart.RecrutementSaved?.UserMetier.id||-1) :
                    props.SearchPart && props.SearchPart.RecrutementSaved?.Metier ? (props.SearchPart.RecrutementSaved?.Metier.id||-1) :
                      props.SearchRecl && props.SearchRecl.RecrutementSaved?.Metier ? (props.SearchRecl.RecrutementSaved?.Metier.id||-1) :
                        props.SearchRecl && props.SearchRecl.RecrutementSaved?.UserMetier ? (props.SearchRecl.RecrutementSaved?.UserMetier.id||-1) : -1}
            typeCompared={
              props.SearchEmp && props.SearchEmp.RecrutementSaved?.UserMetier ? "EUM" :
                props.SearchEmp && props.SearchEmp.RecrutementSaved?.Metier ? "EM":
                  props.SearchPart && props.SearchPart.RecrutementSaved?.UserMetier ? "PUM" :
                    props.SearchPart && props.SearchPart.RecrutementSaved?.Metier ? "PM" :
                      props.SearchRecl && props.SearchRecl.RecrutementSaved?.Metier ? "UMM" : "UMUM"}
          />
        </div>:
          <GereSaveProfil
            ResultEmp={props.SearchEmp||null}
            ResultPart={props.SearchPart||null}
            ResultRecl={props.SearchRecl||null}
          />
      }
    </div>
  )
}

export const Compare = styled(CompareCtrl)`
  background: #eaf3f4;
  height: 100%;
  display: flex;
  flex-direction: column;
  .actions{
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1.5rem 0;
  }
  .wrap_compare{
    padding: 1.5rem;
    flex-grow: 1;
    height: 75vh;
    overflow-y: auto;
  }
  .content_informations{
    flex-grow: 1;
    height: 80vh;
    overflow-y: auto;
    padding: 1rem 0;
  }
  .MenuChoix{
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 0 auto 15px auto;
    display: flex;
    justify-content: flex-start;
    .one_menu_choice{
      padding: 0.5rem 1rem;
      &.current{
        border-bottom: solid ${props => props.theme.primary} 4px;
        font-weight: bold;
      }
      &:hover{
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`
