import styled from "styled-components";
import {useParams} from "react-router-dom";
import {components, useGetResultsSearchEmployeeBySearch} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjLoading, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {ResultEmployeeCard} from "../components/ResultEmployeeCard";
import {Compare} from "../../../../../../components/Comparateur/ComparaisonRef/Compare";

interface ResultsSalariesCtrlProps{
    className?:string;
}

const ResultsSalariesCtrl = (props:ResultsSalariesCtrlProps)=>{
    const {id} = useParams();
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(1);
    const ResultsQuery = useGetResultsSearchEmployeeBySearch(id ? parseInt(id) : 0)
    const {isShowing, toggle} = useModal();
    const [resultEmpCurr, setResultEmpCurr] = useState<components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|null>(null);
    const ClickOnCompareEmp = (result:components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>{
        setResultEmpCurr(result);
        toggle();
    }
    const listResult = useMemo(()=>{
        if(!ResultsQuery.data){
            return [];
        } else {
            return ResultsQuery.data.filter(r=>{
                const CondMin = r.score && r.score >= min;
                const CondMax = r.score && r.score <= max;
                const CondNoStatut = r.statut?.id === 1
                return CondMax && CondMin && CondNoStatut;
            })
        }
    }, [ResultsQuery.data, min, max])
    return (
        <div className={"result_emp "+props.className}>
            <div className={"infos"}>Le nombre de résultats peut varier en fonction des profils déjà traités</div>
            <div className={"wrap_results"}>
                {ResultsQuery.isLoading ? (<MbjLoading/>) : ResultsQuery.isError ? 'Error data' :
                    (
                        <div className={"wrap_result"}>
                            {listResult.map((item)=>(
                                <ResultEmployeeCard key={`r${item.id}`} Search={item} clickCompare={ClickOnCompareEmp}/>
                            ))}
                        </div>
                    )
                }
            </div>
            <MbjModal2 open={isShowing} closeMe={toggle} titre={"Comparaison"}>
                <Compare
                    SearchEmp={resultEmpCurr||undefined}
                    SearchPart={undefined}
                    SearchRecl={undefined}
                />
            </MbjModal2>
        </div>
    )
}

const ResultsSalaries = styled(ResultsSalariesCtrl)`
  padding: 2% 1%;
  .wrap_result{
    display: flex;
    flex-wrap: wrap;
  }
`


export default ResultsSalaries;
