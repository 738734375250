import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import * as React from "react";
import {CompetencesLayoutR} from "./CompetencesLayoutR";
import {ListActivitesR} from "./ListActivitesR";
import {FamilleContext} from "./OneFamilleR";
import {MyCompetence} from "../CompetencesInterfaces";


interface OneCompetenceRCtrlProps{
  className?:string;
}

const OneCompetenceRCtrl = (props:OneCompetenceRCtrlProps)=>{
  const {idCompetence} = useParams();
  const Context = useContext(FamilleContext)
  const [Competence, setCompetence] = useState<MyCompetence|null>(null)
  useEffect(() => {
    if(Context && idCompetence){
      const Competence = Context.Competences.find(c=>c.id+""===idCompetence);
      if(Competence){
        setCompetence(Competence);
      }
    }
  }, [idCompetence, Context]);
  return (
    <Routes>
      <Route element={
        <CompetencesLayoutR link={{link:'../', libelle:Competence ? Competence.libelle : '', niveau : Competence ? Competence.Niveau : 0, backColor:'#cdd9ea'}}/>}>
        <Route path="" element={<ListActivitesR Activites={Competence ? Competence.Activites : []}/>} />
        <Route path="*" element={<p>pas troue</p>} />
      </Route>
    </Routes>
  )
}

export const OneCompetenceR = styled(OneCompetenceRCtrl)`
  padding: 1% 2%;
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .title_link{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 15px 0;
  }
`
