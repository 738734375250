import React from "react";
import {
  BaseColorsTheme,
  MbjAccordionContent,
  MbjAccordionTrigger,
  MbjLoading,
  useModal,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import { Operation, useDeleteActivitePart, useUpdateNiveauActivitePart } from "@mbj-front-monorepo/data-access";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { MbjLineActivite } from "../../../atoms/MbjLineActivite/MbjLineActivite";

interface MbjParticulierLineCompetenceCtrlProps {
  className?: string;
  themeColor?: BaseColorsTheme;
  CompetencesParticulier: GetElementType<Operation<"getParticulierCompetenceCollection">>;
  idParticulier: number;
  isLoading: boolean;
  isError: boolean;
  ParticulierActivites?: GetElementType<Operation<"getParticulierCompetenceCollection">>["ParticulierActivites"];

  isEditable?: boolean;
}

const MbjParticulierLineCompetenceCtrl = (props: MbjParticulierLineCompetenceCtrlProps) => {
  const mutationActivite = useUpdateNiveauActivitePart(props.idParticulier);
  const mutationDeleteActivite = useDeleteActivitePart(props.idParticulier);
  const modalTrash = useModal();

  return (
    <React.Fragment>
      <MbjAccordionTrigger themeColor={props.themeColor}>
        <div className="accordion-trigger-content">
          <span>{props.CompetencesParticulier.Competence?.libelle}</span>
        </div>
      </MbjAccordionTrigger>
      <MbjAccordionContent>
        <div className={props.className}>
          {props.isLoading ? (
            <MbjLoading />
          ) : props.isError ? (
            "Errors"
          ) : (
            props.ParticulierActivites?.map((activiteParticulier, idx) => {
              const handleTrash = () => {
                mutationDeleteActivite.mutate(activiteParticulier.id || -1, {
                  onSettled: () => {
                    modalTrash.toggle();
                  },
                });
              };
              return (
                <MbjLineActivite
                    idActivite={activiteParticulier.Activite?.id||-1}
                  isEditable={props.isEditable}
                  niveau={activiteParticulier.niveau || 0}
                  libelle={activiteParticulier.Activite?.libelle || ""}
                  changeLevelFunction={(level) =>
                    mutationActivite.mutate({ idParticulierActivite: activiteParticulier.id || -1, niveau: level })
                  }
                  trashFunction={handleTrash}
                  modalTrash={modalTrash}
                  trashLoading={mutationDeleteActivite.isLoading}
                  key={idx}
                />
              );
            })
          )}
        </div>
      </MbjAccordionContent>
    </React.Fragment>
  );
};

export const MbjParticulierLineCompetence = styled(MbjParticulierLineCompetenceCtrl)((props) => ({
  padding: "10px",
  gap: "10px",
  display: "flex",
  flexDirection: "column",
}));
MbjParticulierLineCompetence.defaultProps = {
  themeColor: "grey",
  isEditable: true,
};
