import React, { useEffect, useMemo, useState } from "react";
import {
  MbjButton,
  MbjCardClassic,
  MbjInfo,
  MbjLoading, MbjModal2,
  MbjRangeStatic,
  MbjSearchBar,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
  MbjWidgetClassic, useModal,
} from "@mbj-front-monorepo/ui";
import { useForm } from "react-hook-form";
import {
  Operation,
  useAddNewEntretien,
  useGetActiviteEntretien, useGetCustomQuestionsByActivation,
  useGetFormationEmployees,
  useGetFormationToMetier,
  useGetMemberMe,
  useGetMetiers,
  useGetObjectifEntretiens,
  useGetTypesEvtsMarquants,
  useGetUserMetiers,
} from "@mbj-front-monorepo/data-access";
import { EntretienPdf } from "./EntretienPdf/EntretienPdf";
import { usePDF } from "@react-pdf/renderer";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { GetElementType, groupByKey } from "@mbj-front-monorepo/utils";

let nb = 0;

interface EntretienAnnuelNewFormCtrlProps {
  className?: string;
  Employee: Operation<"getEmployeeItem">;
}

interface ListActi {
  competence: string;
  activites: Operation<"getEmployeeEntretienActiviteCollection">;
}

export interface EntretienAnnuelFormData {
  liked?: string;
  noliked?: string;
  comentGeneResp?: string;
  comentGeneSalarie?: string;
  //Id of the user metier :
  EvoInterneCrtTerme?: number;
  //Id of the user metier :
  EvoInterneMoyenTerme?: number;
  //Id of the user metier :
  EvoInterneLgTerme?: number;
  //Id of the metier :
  EvoExterneCrtTerme?: number;
  //Id of the metier :
  EvoExterneMoyenTerme?: number;
  //Id of the metier :
  EvoExterneLgTerme?: number;
  Acti?: { id: string; ill?: string; appreciationResp?: string; appreciationEmp?: string }[];
  FormPast?: { id: string; appreciation?: string }[];
  ObjPast?: { id: string; resultat?: string; coment?: string }[];
  NewObj?: { libelle?: string; progression?: string; moyens?: string }[];
  ToRise?: { coment?: string }[];
  Skill?: { coment?: string }[];
  Evts?: { type?: number; coment?: string }[];
  NewForm?: { libelle?: string; avis?: string }[];
  Cq?: { id: string; comment?: string; scoreResp?: string; scoreEmp?: string }[];
}

function EntretienAnnuelNewFormCtrl({ className, Employee }: EntretienAnnuelNewFormCtrlProps) {
  if (!Employee.id) throw new TypeError("Employee.id is undefined");
  const navigate = useNavigate();
  const [textError, setTextError] = useState<JSX.Element|null>(null)
  const {isShowing:showError, toggle:toggleError} = useModal();
  const mutation = useAddNewEntretien(Employee.id || -1);
  const Today = new Date();
  const yearT = Today.getFullYear();
  const monthT = Today.getMonth() + 1 < 10 ? `0${Today.getMonth() + 1}` : Today.getMonth() + 1;
  const dayT = Today.getDate();

  const memberQuery = useGetMemberMe();
  const typeEvtsMarquantsQuery = useGetTypesEvtsMarquants();
  const { isLoading: loadAct, isError: errAct, data: activites } = useGetActiviteEntretien(Employee.id);
  const {
    isLoading: loadFormPast,

    isError: errFormPast,
    data: formationsEmployeeToEvaluate,
  } = useGetFormationEmployees(Employee.id, yearT);
  const { isLoading: loadObj, isError: errObj, data: objectifsToEvaluate } = useGetObjectifEntretiens(Employee.id);
  const { isLoading: loadUM, isError: errUM, data: user_metiers } = useGetUserMetiers();
  const { isLoading: loadMetier, isError: errMetier, data: metiers } = useGetMetiers();
  const suggestionsFormationsQuery = useGetFormationToMetier(Employee.id, Employee.UserMetier?.id);

  const cquestionsQuery = useGetCustomQuestionsByActivation(1)


  const [search, setSearch] = useState("");

  const ActiFiltered = useMemo(() => {
    const myRet: ListActi[] = [];
    const ActiFilt: GetElementType<typeof activites>[] = [];
    const competences: GetElementType<typeof activites>["Competence"][] = [];
    if (!loadAct && !errAct && activites) {
      const regex = new RegExp("" + search + "", "gi");
      activites.map((acti) => {
        if (
          acti.EmployeeActivite?.Activite?.libelle?.match(regex) ||
          search === "" ||
          acti.Competence?.libelle?.match(regex)
        ) {
          //console.log(acti.activite);
          ActiFilt.push(acti);
          if (competences.indexOf(acti.Competence) === -1) {
            competences.push(acti.Competence);
          }
        }
      });
      console.log(activites);
      competences.map((c) => {
        const actiOk = ActiFilt.filter((a) => a.Competence === c);
        myRet.push({ competence: c?.libelle || "", activites: actiOk });
      });
    }
    return myRet;
  }, [activites, loadAct, errAct, search]);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },

  } = useForm<EntretienAnnuelFormData>({
    mode: "onSubmit",
  });






  const [pdfText, setPdfText] = useState<string>("");
  const theme = useTheme();
  const [pdfEntreprise, setPdfEntreprise] = useState<typeof memberQuery.data>(memberQuery.data);
  const [pdfFormData, setPdfFormData] = useState<EntretienAnnuelFormData | undefined>(undefined);
  const [pdfEmployee, setPdfEmployee] = useState<typeof Employee>(Employee);

  const [pdfEventsPossibles, setPdfEventsPossibles] = useState<typeof typeEvtsMarquantsQuery.data>(
    typeEvtsMarquantsQuery.data
  );
  const [pdfActivitesEntretien, setPdfActivitesEntretien] = useState<typeof activites>(activites);
  const [pdfFormPasts, setPdfFormPasts] = useState<typeof formationsEmployeeToEvaluate>(formationsEmployeeToEvaluate);
  const [pdfObjPasts, setPdfObjPasts] = useState<typeof objectifsToEvaluate>(objectifsToEvaluate);
  const [pdfUserMetiers, setPdfUserMetiers] = useState<typeof user_metiers>(user_metiers);
  const [pdfMetiers, setPdfMetiers] = useState<typeof metiers>(metiers);
  const [instance, updateInstance] = usePDF({
    document: (
      <EntretienPdf
        text={pdfText}
        formData={pdfFormData}
        employeeData={pdfEmployee}
        entrepriseData={pdfEntreprise}
        eventsPossibles={pdfEventsPossibles}
        activitesEntretiens={pdfActivitesEntretien}
        formPasts={pdfFormPasts}
        objectifsEntretien={pdfObjPasts}
        user_metiers={pdfUserMetiers}
        metiers={pdfMetiers}
        theme={theme}
      />
    ),
  });

  useEffect(() => {
    //console.log("form pdfData", pdfFormData);
    updateInstance();
  }, [pdfFormData]);

  useEffect(() => {
    if (
      pdfFormData &&
      pdfEmployee &&
      memberQuery.data &&
      activites &&
      theme &&
      instance &&
      !instance.loading &&
      !instance.error &&
      instance.url
    ) {
      console.log("newTab opened !");
      console.log(instance);
      window.open(instance.url);
    }
  }, [instance]);

  const onSubmit = (data: EntretienAnnuelFormData) => {
    let nbVideComp = 0;
    let nbVideCq = 0
    data.Acti?.forEach(a=>{
      if(!a.appreciationResp || !a.appreciationEmp){
        nbVideComp++;
      }
    })

    if(nbVideComp>0){
      setTextError(<p>Toutes les compétences doivent être évaluées</p>)
      toggleError();
      return;
    }
    data.Cq?.forEach(a => {
      const hasScoreResp = !!a.scoreResp;
      const hasScoreEmp = !!a.scoreEmp;
      const hasComment = !!a.comment;

      if ((hasScoreResp && hasScoreEmp && hasComment) || (!hasScoreResp && !hasScoreEmp && !hasComment)) {
        return;
      }
      nbVideCq++;
    });

    if(nbVideCq>0){
      setTextError(<p>Traiter la ou les question(s) pour lesquelles certaines valeurs sont saisies tandis que d'autres sont laissées vides. </p>)
      toggleError();
      return;
    }
    const newData = {
      EvoAnInterne: data.EvoInterneCrtTerme ? "/api/user_metiers/" + data.EvoInterneCrtTerme : undefined,
      EvoAnExterne: data.EvoExterneCrtTerme ? "/api/metiers/" + data.EvoExterneCrtTerme : undefined,
      EvoTriInterne: data.EvoInterneMoyenTerme ? "/api/user_metiers/" + data.EvoInterneMoyenTerme : undefined,
      EvoTriExterne: data.EvoExterneMoyenTerme ? "/api/metiers/" + data.EvoExterneMoyenTerme : undefined,
      EvoLgInterne: data.EvoInterneLgTerme ? "/api/user_metiers/" + data.EvoInterneLgTerme : undefined,
      EvoLgExterne: data.EvoExterneLgTerme ? "/api/metiers/" + data.EvoExterneLgTerme : undefined,
      commentairesEmp: data.comentGeneSalarie,
      commentairesResp: data.comentGeneResp,
      EventsMarquants: data.Evts?.map((e) => {
        return {
          commentaires: e.coment,
          TypeEvtsMarquant: "/api/type_evts_marquants/" + e.type,
        };
      }),
      CompetencesEntretiens: data.Acti?.map((a) => {
        return {
          EmployeeActivite: "/api/employee_activites/" + a.id,
          niveauResp: a.appreciationResp ? parseInt(a.appreciationResp) : undefined,
          niveauEmp: a.appreciationEmp ? parseInt(a.appreciationEmp) : undefined,

          commentaires: a.ill,
        };
      }),
      cqes: data.Cq?.map((a) => {
        return {
          customQuestion: "/api/custom_questions/" + a.id,
          scoreResp: a.scoreResp ? parseInt(a.scoreResp) : undefined,
          scoreEmp: a.scoreEmp ? parseInt(a.scoreEmp) : undefined,
          comment: a.comment,
        };
      }),
      ObjectifsEntretiens: [
        ...(data.NewObj?.map((o) => {
          return {
            libelle: o.libelle,
            progression: o.progression,
            moyens: o.moyens,
          };
        }) || []),
        ...(data.ObjPast?.map((o) => {
          return {
            id: parseInt(o.id),
            resultat: o.resultat ? parseInt(o.resultat) : undefined,
          };
        }) || []),
      ],

      LikedEntretiens: [
        {
          commentaire: "",
        },
      ],
      NoLikedEntretiens: [
        {
          commentaires: "",
        },
      ],
      SkillsEntretiens: data.Skill?.map((p) => {
        return {
          commentaires: p.coment,
        };
      }),
      ToRiseEntretiens: data.ToRise?.map((p) => {
        return {
          commentaires: p.coment,
        };
      }),
    };
    console.log(data);
    console.log(newData);
    mutation.mutate(newData, {
      onSuccess: () => {
        navigate("../entretien");
      },
    });
  };

  function handlePdf(data: EntretienAnnuelFormData) {
    console.log("handlePdf 2");
    console.log(instance);
    setPdfText("Ceci est un test " + nb);
    setPdfActivitesEntretien(activites);
    setPdfEntreprise(memberQuery.data);
    setPdfEmployee(Employee);
    setPdfEventsPossibles(typeEvtsMarquantsQuery.data);
    setPdfFormPasts(formationsEmployeeToEvaluate);
    setPdfObjPasts(objectifsToEvaluate);
    setPdfUserMetiers(user_metiers);
    setPdfMetiers(metiers);
    setPdfFormData(data);
    nb++;
  }

  let actiIdx = -1;
  let cqIdx = -1;

  return (
    <div className={`wrapFormNewEntretien ${className}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="Infos">
        <MbjWidgetClassic
          title={`Entretien d'évaluation pour ${Employee.prenom} ${
            Employee.nom
          } pour l'année ${new Date().getFullYear()}`}
          direction={"column"}
        >
          <MbjCardClassic title={"Évaluation des compétences"}>
            <MbjSimpleInputContainer
              errors={errors}
              label={"Rechercher une compétence"}
              direction={"column"}
              className={`WrapSearchComp`}
            >
              <MbjSearchBar
                id={"sarchComp"}
                placeholder={"Rechercher une compétence"}
                className={"SearchBar"}
                onChange={(event) => setSearch(event.target.value)}
              />
            </MbjSimpleInputContainer>

            <div className={`WrapComp`}>
              {loadAct ? (
                <MbjLoading />
              ) : errAct ? (
                <p>Erreur ...</p>
              ) : (
                ActiFiltered.map((LA, idx: number) => (
                  <div key={`blockComp${idx}`} className={"blockComp"}>
                    <div style={{ fontSize: "15px", fontWeight: "bold", marginTop: "12px" }}>{LA.competence}</div>
                    <div className={`blockActivites`}>
                      {LA.activites.map((acti, index: number) => {
                        actiIdx++;
                        return (
                          <div className={`LineActivite`} key={index}>
                            <div className={`libActi`}>{acti.EmployeeActivite?.Activite?.libelle}</div>
                            <div className="comparaison">
                              <div className="have mb-2">
                                <div className={"leg"}>Niveau Actuel</div>
                                <MbjRangeStatic
                                  nbPoints={5}
                                  currentPoint={acti.niveauActuel || 0}
                                  customColor={"green"}
                                  height={"12px"}
                                />
                              </div>
                              <div className="need">
                                <div className={"leg"}>Niveau Requis</div>
                                <MbjRangeStatic
                                  nbPoints={5}
                                  height={"12px"}
                                  currentPoint={acti.niveauRequis || 0}
                                  customColor={"#8c8c8c"}
                                />
                              </div>
                              <div className="need" />
                            </div>
                            <div className={`illActivite`}>
                              <input
                                defaultValue={`${acti.EmployeeActivite?.id}`}
                                type={"hidden"}
                                {...register(`Acti.${actiIdx}.id`)}
                              />
                              <MbjSimpleInputContainer errors={errors}>
                                <MbjSimpleTextArea
                                  name={`Acti.${actiIdx}.ill`}
                                  id={`Acti.${actiIdx}.ill`}
                                  register={register}
                                  placeholder={
                                    "Illustration concrète et objective par des faits survenus au cours de la période"
                                  }
                                />
                              </MbjSimpleInputContainer>
                            </div>
                            <div className={`AppreResp`}>
                              <MbjInfo text={"Appréciation du responsable"} className={"infos"} />

                              <MbjSimpleInputContainer errors={errors}>
                                <MbjSimpleInput
                                  name={`Acti.${actiIdx}.appreciationResp`}
                                  id={`Acti.${actiIdx}.appreciationResp`}
                                  type={"number"}
                                  noPadding
                                  register={register}
                                  registerOptions={{
                                    min: {
                                      value: 0,
                                      message: "Ne peut être négatif",
                                    },
                                    max: {
                                      value: 5,
                                      message: "Ne peut être supérieur à 5",
                                    },
                                  }}
                                />
                              </MbjSimpleInputContainer>
                            </div>
                            <div className={`AppreEmp`}>
                              <MbjInfo text={"Appréciation du salarié"} className={"infos"} />
                              <MbjSimpleInputContainer errors={errors}>
                                <MbjSimpleInput
                                  name={`Acti.${actiIdx}.appreciationEmp`}
                                  id={`Acti.${actiIdx}.appreciationEmp`}
                                  type={"number"}
                                  noPadding
                                  register={register}
                                  registerOptions={{
                                    min: {
                                      value: 0,
                                      message: "Ne peut être négatif",
                                    },
                                    max: {
                                      value: 5,
                                      message: "Ne peut être supérieur à 5",
                                    },
                                  }}
                                />
                              </MbjSimpleInputContainer>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))
              )}
            </div>
          </MbjCardClassic>
          <MbjCardClassic title={"Questions optionnelles"}>

            <div className={`WrapComp`}>
              {cquestionsQuery.isLoading ? (
                <MbjLoading />
              ) : cquestionsQuery.isError ? (
                <p>Erreur ...</p>
              ) : (

                cquestionsQuery.data.map((cq: any, idx: number) => {
                  cqIdx++
                  return <div key={`blockComp${cq.id}`} className={"blockComp"}>
                    <div className={`blockActivites`}>
                      <div className={`LineActivite`}>
                        <div className={`libActi`}>{cq.libelle}</div>
                        <div className={`illActivite`}>
                          <input
                            defaultValue={`${cq.id}`}
                            type={"hidden"}
                            {...register(`Cq.${cqIdx}.id`)}
                          />
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleTextArea
                              name={`Cq.${cqIdx}.comment`}
                              id={`Cq.${cqIdx}.comment`}
                              register={register}
                              placeholder={
                                "Commentaire"
                              }
                            />
                          </MbjSimpleInputContainer>
                        </div>
                        <div className={`AppreResp`}>
                          <MbjInfo text={"Appréciation du responsable"} className={"infos"} />

                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleInput
                              name={`Cq.${cqIdx}.scoreResp`}
                              id={`Cq.${cqIdx}.scoreResp`}
                              type={"number"}
                              noPadding
                              register={register}
                              registerOptions={{
                                min: {
                                  value: 0,
                                  message: "Ne peut être négatif",
                                },
                                max: {
                                  value: 5,
                                  message: "Ne peut être supérieur à 5",
                                },
                              }}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                        <div className={`AppreEmp`}>
                          <MbjInfo text={"Appréciation du salarié"} className={"infos"} />
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleInput
                              name={`Cq.${cqIdx}.scoreEmp`}
                              id={`Cq.${cqIdx}.scoreEmp`}
                              type={"number"}
                              noPadding
                              register={register}
                              registerOptions={{
                                min: {
                                  value: 0,
                                  message: "Ne peut être négatif",
                                },
                                max: {
                                  value: 5,
                                  message: "Ne peut être supérieur à 5",
                                },
                              }}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                      </div>
                    </div>
                  </div>;
                })
              )}
            </div>
          </MbjCardClassic>
        </MbjWidgetClassic>

        <MbjButton size={"sm"} themeColor={"primary"} isPending={mutation.isLoading} className={"submitButon"}>
          Enregistrer et envoyer pour signature
        </MbjButton>

        {/*
                    instance.loading ? (
                    <div>Loading ...</div>
                ) : instance.error ? (
                    <div>Something went wrong: {instance.error}</div>
                ) : instance.url ? (
                    <a href={instance.url} download="test.pdf">
                        Download
                    </a>
                ) : (
                    <React.Fragment />
                )*/}
      </form>
      <MbjModal2 open={showError} titre={"Erreurs"} Wd={"500px"} closeMe={toggleError}>
        {textError &&
          <div className={`my_errors`} style={{padding:"1rem 1rem 2rem 1rem", textAlign:"center", fontSize:"18px"}}>
            {textError}
          </div>
        }
      </MbjModal2>
      {/*<ModalTreatNew  isShowing={isShowing} hide={toggle} title={"Traitement en cours"}  />*/}
    </div>
  );
}

export const EntretienAnnuelNewForm = styled(EntretienAnnuelNewFormCtrl)((props) => ({
  ".Term":{fontSize:"14px", margin:"8px 0", fontWeight:"bold", borderBottom:"solid #ccc 1px"},
  ".submitButon": {
    marginTop: "1.1rem",
  },
  ".NoList": {
    padding: "0.5rem",
    margin:"8px auto",
    color:props.theme.dark
  },

  ".dynamicFieldLine": {
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    //borderBottom: "solid #ccc 1px",
    gap: "12px",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",

    button: {
      alignSelf: "center",
    },

    "&.formation": {
      justifyContent: "space-between",

      ".EvaFormation": {
        flexGrow: 1,
      },
    },
  },

  ".LineObjPast": {
    display: "flex",
    width: "100%",
    justifyContent: "start",
    alignItems: "stretch",
    ".LibObj": {
      padding: "0.5rem",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      input: {
        position: "absolute",
      },
    },
    ".Result": {
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      input: {
        width: "50px",
        height: "50px",
        display: "block",
        textAlign: "center",
        margin: "auto",
      },
    },
    ".ComentObj": {
      padding: "0.5rem",
      flexGrow: 1,
    },
  },
  ".SubBlock": {
    marginBottom: "15px",
  },

  ".WrapSearchComp": {
    padding: "1rem 0",
    borderBottom: "solid #ccc 2px",
  },
  ".LineActivite": {
    display: "flex",
    padding: "0.5rem 0",
    borderBottom: "solid 1px" + props.theme.greyLighten,
    ".libActi": {
      padding: "0.25rem",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    ".illActivite": {
      padding: "0.5rem",
      width: "30%",
    },
    ".comparaison": {
      padding: "0.5rem",
      width: "20%",
    },
    ".AppreResp, .AppreEmp": {
      padding: "0.5rem",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      ".infos": {
        position: "absolute",
        top: 0,
        right: 0,
      },
      input: {
        width: "70px",
        height: "50px",
        display: "block",
        textAlign: "center",
        margin: "auto",
      },
    },
  },
}));
