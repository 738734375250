import { useGetEntretienRecapNoSignMember } from "@mbj-front-monorepo/data-access";
import { MbjCardBack, MbjIconButton, MbjLoading, MbjModal2, MbjTable, useModal } from "@mbj-front-monorepo/ui";
import { BiLineChartDown } from "react-icons/bi";
import styled from "styled-components";
import { EntretienStats } from "./components/EntretienStats";
import * as React from "react";
import { useEffect, useState } from "react";

interface RecapInfoEntretienAnnuelCtrlProps {
  className?: string;
}

const titleTab = [{ libelle: "Nombre de collaborateurs" }, { libelle: "Nombre de managers" }, { libelle: "Action" }];

const RecapInfoEntretienAnnuelCtrl = (props: RecapInfoEntretienAnnuelCtrlProps) => {
  const recapQuery = useGetEntretienRecapNoSignMember();
  const { isShowing: isShowingDetail, toggle: toggleDetail } = useModal();
  const [alert, setAlert] = useState('')
  useEffect(() => {
    setTimeout(() => setAlert(''), 5000)
  }, [alert])

  return (
    <MbjCardBack title={"Nombre de salariés et de managers n'ayant pas signé leur évaluation"}>
      <div className={`obj ${props.className}`}>
        {alert && <div className="alert-ms">{alert}</div>}
        <MbjTable titles={titleTab}>
          {recapQuery.isLoading ? (
            <tr>
              <td colSpan={titleTab.length}>
                <MbjLoading />
              </td>
            </tr>
          ) : recapQuery.isError ? (
            <tr>
              <td colSpan={titleTab.length}>
                <p>Erreur</p>
              </td>
            </tr>
          ) : recapQuery.data?.length > 0 ? (
            recapQuery.data?.map((r: any, idx: number) => (
              <tr key={idx}>
                <td className="isCenter">{r.nombre_employees}</td>
                <td className="isCenter">{r.nombre_responsable}</td>
                <td className="isCenter">
                  <MbjIconButton
                    size={"sm"}
                    onClick={toggleDetail}
                    ariaLabel={"Stats"}
                    icon={<BiLineChartDown />}
                    themeColor={"third"}
                    isRound={false}
                  />
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={titleTab.length}>Aucun résultat</td>
            </tr>
          )}
        </MbjTable>
      </div>
      <MbjModal2 open={isShowingDetail} closeMe={toggleDetail} titre={"Informations détaillés"} Wd={"80%"}>
        <div style={{ padding: "0 1rem", height: "max-content" }}>
          <EntretienStats onToggle={toggleDetail} onAlert={setAlert}/>
        </div>
      </MbjModal2>
    </MbjCardBack>
  );
};

export const RecapInfoEntretienAnnuel = styled(RecapInfoEntretienAnnuelCtrl)`
  .alert-ms {
    text-align: center;
    color: #fff;
    background: #396c71;
    font-size: 15px;
    padding: 1em 0;
    line-height: 5px;
    border-radius: 4px;
    margin: 1em 0;
  }
`;
