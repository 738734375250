import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {
  components,
  useDeleteMemberMetierReclassement, useFindMatchingAllianceMembers, useGetFindSearchReclassment,
  useGetMemberMetierReclassements, useGetRight,
} from "@mbj-front-monorepo/data-access";
import { useEffect, useMemo, useState } from "react";
import {
    MbjAccordion, MbjAccordionContent, MbjAccordionItem, MbjAccordionTrigger,
    MbjButton,
    MbjCardClassic, MbjConfirmModal,
    MbjFlex, MbjIconButton, MbjLabel, MbjLoading, MbjModal2,
    MbjNavPath,
    MbjRadioGroup, MbjRadioGroupIndicator, MbjRadioGroupItem, MbjSearchBar, MbjTable,
    MbjWidgetClassic,
    useModal
} from "@mbj-front-monorepo/ui";
import {getDateFR} from "@mbj-front-monorepo/utils";
import * as React from "react";
import {CollaborateurReclasseItem} from "./components/CollaborateurReclasséItem";
import {NouveauReclassement} from "./components/NouveauReclassement";
import {BiEdit, BiLineChartDown} from "react-icons/bi";
import {BsTrash} from "react-icons/bs";
import {ReclassementStatistiques} from "./components/ReclassementStatistiques";
import {ProxyITemTypes, MetierProxyItem} from "./components/MetierProxyItem";

interface ReclassementsCtrlProps{
    className?:string;
}
const Titles = [
    { libelle: "Collaborateur", Align: "isLeft" },
    { libelle: "Date d'entrée", Align: "isCenter" },
    { libelle: "Mobilité exprimée", Align: "isCenter" },
    { libelle: "Retiré", Align: "isCenter" },
    { libelle: "Proposé", Align: "isCenter" },
    { libelle: "Accepté", Align: "isCenter" },
    { libelle: "Action", Align: "isCenter" },
];

const MatchProxyTitles = [
  {libelle: 'Raison sociale', Align: 'isCenter'},
  {libelle: 'Poste', Align: 'isCenter'},
  {libelle: 'salaire moyen nationale', Align: 'isCenter'},
  {libelle: 'Score', Align: 'isCenter'},
]

const SearchInfoTitles = [
  {libelle: 'Raison sociale', Align: 'isLeft'},
]

const ReclassementsCtrl = ({className}:ReclassementsCtrlProps)=>{
    const {isLoading:loadRightW, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Reclassements")
    const navigate = useNavigate();
    const myReclassementsQuery = useGetMemberMetierReclassements();
    const [myCurrent, setMyCurrent] = useState<components["schemas"]["MemberMetierReclassement-read.MemberMetierReclassement_read.ReclassementEmployees_read.UserMetier_read.Metier_read.Employee.simple"]|null>(null)
    const [refChoice, setRefChoice] = useState("metier");
    const [query, setQuery] = useState("");
    const { isShowing, toggle } = useModal();
    const { isShowing:isShowingNew, toggle:toggleNew } = useModal();
    const { isShowing:isShowingStats, toggle:toggleStats } = useModal();
    const [isTrashing, setIsTrashing] = useState(false);
    const [idReclSelected, setIdReclSelected] = useState<number | null>(null);
    const mutation = useDeleteMemberMetierReclassement();
    //const matchProxyResult = useFindMatchingAllianceMembers();


    const ConfirmTrash = () => {
        if (idReclSelected) {
            setIsTrashing(true);
            mutation.mutate(idReclSelected, {
                onSuccess: () => {
                    setIsTrashing(false);
                    toggle();
                },
            });
        }
    };
    const MemberMetierReclassementFiltered = useMemo(() => {
        if (
            myReclassementsQuery === undefined ||
            myReclassementsQuery.data === null ||
            myReclassementsQuery.data === undefined
        ) {
            return [];
        }
        return myReclassementsQuery.data?.filter((MMRItem) => {
            if (refChoice === "metier") {
                if (query === "") {
                    return MMRItem;
                } else if (MMRItem.UserMetier?.namePerso?.toLowerCase().includes(query.toLowerCase())) {
                    return MMRItem;
                }
            } else if (refChoice === "collaborateur") {
                if (!MMRItem.ReclassementEmployees) {
                    return;
                }
                if (query === "") {
                    return MMRItem;
                }
                for (let i = 0; i < MMRItem.ReclassementEmployees.length; i++) {
                    const fullname =
                        MMRItem.ReclassementEmployees[i].Employee?.nom?.toLowerCase() +
                        " " +
                        MMRItem.ReclassementEmployees[i].Employee?.prenom?.toLowerCase();
                    if (fullname.toLowerCase().includes(query.toLowerCase())) {
                        return MMRItem;
                    }
                }
            }
            return undefined;
        });
    }, [query, myReclassementsQuery]);

    function filtrerEmployees(employee: any) {
        if (refChoice === "collaborateur") {
            const fullname = employee.Employee.nom.toLowerCase() + " " + employee.Employee.prenom.toLowerCase();
            return fullname.toLowerCase().includes(query.toLowerCase());
        }
        return true;
    }
    const handleClickTrash = (e: any, id: number) => {
        e.stopPropagation();
        setIdReclSelected(id);
        toggle();
    };
    const ClickStats = (e:any,id:number)=>{
        e.stopPropagation();
        const my = myReclassementsQuery.data?.find(r=>r.id === id);
        if(my){
            setMyCurrent(my);
            toggleStats();
        }
    }


    return (
        <div className={"MesReclassements" + className} style={{overflowY: "scroll"}}>
            <div className="top" style={{display:"flex", justifyContent:"flex-end"}}>
                {isAuthWrite &&
                    <MbjButton size={"sm"} onClick={toggleNew}>
                        Ajouter un nouveau reclassement
                    </MbjButton>
                }
            </div>
            <MbjFlex direction={"column"}>
                <MbjWidgetClassic title={"Mes reclassements"}>
                    <MbjCardClassic className={"searchCard"}>
                        <div className={"searchContent"}>
                            <div className={"refChoiceContainer"}>
                                <span>Recherche sur :</span>
                                <MbjRadioGroup
                                    defaultValue="metier"
                                    aria-label="Choix du type de recherche"
                                    onValueChange={(value) => setRefChoice(value)}
                                    className={"radioContainer"}
                                >
                                    <div>
                                        <MbjRadioGroupItem value="metier" id="r1">
                                            <MbjRadioGroupIndicator />
                                        </MbjRadioGroupItem>
                                        <MbjLabel htmlFor="r1" themeColor={"dark"} size={"very-small"}>
                                            métier
                                        </MbjLabel>
                                    </div>
                                    <div>
                                        <MbjRadioGroupItem value="collaborateur" id="r2">
                                            <MbjRadioGroupIndicator />
                                        </MbjRadioGroupItem>
                                        <MbjLabel htmlFor="r2" themeColor={"dark"} size={"very-small"}>
                                            collaborateur
                                        </MbjLabel>
                                    </div>
                                </MbjRadioGroup>
                            </div>
                            <MbjSearchBar
                                placeholder={"Rechercher un " + (refChoice === "metier" ? "métier" : "collaborateur")}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                        </div>
                    </MbjCardClassic>
                </MbjWidgetClassic>
                <MbjWidgetClassic title={"Détails des reclassements"}>
                    <MbjAccordion type="multiple" defaultValue={["item-0"]} className={"accordéon"}>
                        {myReclassementsQuery.isLoading ? (
                            <MbjLoading />
                        ) : myReclassementsQuery.isError ? (
                            <p>Erreur...</p>
                        ) : (
                            MemberMetierReclassementFiltered.map((MMRItem, idx: number) => (
                                <MbjAccordionItem value={`item-${idx}`} key={MMRItem.id}>
                                    <MbjAccordionTrigger>
                                        <div className="accordion-trigger-content">
                      <span>
                        <span>{MMRItem.UserMetier?.namePerso || "--"}</span>
                        <span>
                          - Reclassement créée le{" "}
                            {MMRItem.reclassementAt ? getDateFR(new Date(MMRItem.reclassementAt)) : "--"}
                        </span>
                      </span>
                                            <span>
                        <span>
                          Volume : <strong>{MMRItem.volume}</strong> dont {MMRItem.ReclassementEmployees?.length || 0}{" "}
                            profils référencé(s)
                        </span>
                      </span>
                                            {isAuthWrite &&
                                                <div className={`wrapper_actions`} style={{display:"flex", gap:"8px"}}>
                                                    <MbjIconButton
                                                        size={"sm"}
                                                        onClick={(e) => handleClickTrash(e, MMRItem.id || -1)}
                                                        ariaLabel={"clôturer"}
                                                        icon={<BsTrash />}
                                                        themeColor={"warning"}
                                                        isRound={false}
                                                    />
                                                    <MbjIconButton
                                                        size={"sm"}
                                                        onClick={(e) => ClickStats(e,MMRItem.id || -1)}
                                                        ariaLabel={"Stats"}
                                                        icon={<BiLineChartDown />}
                                                        themeColor={"third"}
                                                        isRound={false}
                                                    />

                                                </div>
                                            }
                                        </div>
                                    </MbjAccordionTrigger>
                                    <MbjAccordionContent>
                                        <MbjTable titles={Titles} themeColor={"white"} noBorderRadius>
                                            {MMRItem.ReclassementEmployees?.filter(filtrerEmployees).map(
                                                (reclassementEmployee, idx: number) => (
                                                    <CollaborateurReclasseItem
                                                        reclassementEmployee={reclassementEmployee}
                                                        key={reclassementEmployee.id}
                                                    />
                                                )
                                            )}
                                        </MbjTable>
                                    </MbjAccordionContent>
                                </MbjAccordionItem>
                            ))
                        )}
                    </MbjAccordion>
                </MbjWidgetClassic>
                {/*<MbjWidgetClassic title={"Proximité vers les métiers d'autres entreprises de l'alliance"}>
                  <MbjAccordion type="multiple" defaultValue={["item-0"]} className={"accordéon"} >
                    {matchProxyResult.isLoading ? (
                      <MbjLoading />
                    ) : matchProxyResult.isError ? (
                      <p>Erreur...</p>
                    ) : (
                      <MbjAccordionItem value="matchProxyResult">
                        <MbjAccordionTrigger>
                          <div className="accordion-trigger-content">
                            <span>
                              <span>Informations sur la proximité vers les métiers d'autres entreprises de l'alliance</span>
                            </span>
                          </div>
                        </MbjAccordionTrigger>
                        <MbjAccordionContent>
                          <MbjTable titles={MatchProxyTitles} themeColor={"white"} noBorderRadius>
                            {matchProxyResult.isSuccess && matchProxyResult?.data?.map((item: ProxyITemTypes) => (
                              <MetierProxyItem matchProxyItem={item} key={item.id} />
                            ))}
                          </MbjTable>
                        </MbjAccordionContent>
                      </MbjAccordionItem>
                    )}
                  </MbjAccordion>
                </MbjWidgetClassic>*/}
            </MbjFlex>
            <MbjConfirmModal
                isShowing={isShowing}
                hide={toggle}
                title="Confirmation de clôture"
                confirmText={"Voulez-vous vraiment clôturer ce reclassement ?"}
                confirmFunction={ConfirmTrash}
                isLoading={isTrashing}
            />
            <MbjModal2 open={isShowingNew} closeMe={toggleNew} titre={"Nouveau reclassement"} Wd={"40%"} ovisible={true}>
                <div style={{padding:"0 1rem", height:"max-content"}}>
                    <NouveauReclassement/>
                </div>
            </MbjModal2>
            <MbjModal2 open={isShowingStats} closeMe={toggleStats} titre={"Statistiques"} Wd={"80%"}>
                <div style={{padding:"0 1rem", height:"max-content"}}>
                    {myCurrent &&
                        <ReclassementStatistiques idRecl={myCurrent.id||-1} idMetier={myCurrent.UserMetier?.Metier?.id||-1}/>
                    }
                </div>
            </MbjModal2>
        </div>
    );
}

export const Reclassements = styled(ReclassementsCtrl)((props) => ({
    padding: "1% 2%",
    ".top": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "15px",

        ".right": {
            display: "flex",
            flexDirection: "row",
            gap: "10px",
        },
    },
    ".searchCard": {
        overflow: "visible",

        ".searchContent": {
            padding: "0.5em 1em",
            ".refChoiceContainer": {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "15px",

                ".radioContainer": {
                    display: "flex",
                    alignItem: "center",
                    gap: "5%",
                    margin: "10px 0px",

                    "& > div": {
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",

                        label: { whiteSpace: "nowrap" },
                    },
                },
            },
        },
    },
    ".accordion-trigger-content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexGrow: 1,
        paddingRight: "25px",

        "span:first-child": {
            marginRight: "15px",
        },
    },
    ".accordéon": {},
    ".fake-btn-trash": {
        "&.hover": {
            cursor: "pointer",
        },
        padding: "0.3rem 1.2rem",
        display: "flex",
        alignItems: "center",
        borderRadius: "0.5rem",
        background: "#FF0000",
        color: "white",
        border: "solid 1px transparent",
        fontSize: "16px",
    },
}));
