import * as React from "react";
import { useEffect } from "react";
import {
  MbjButton, MbjCardBack,
  MbjCardClassic,
  MbjCardClassicCtrlProps,
  MbjEmailSimpleInput,
  MbjHeading,
  MbjLoading,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { Operation, useGetParticulier, useMutateParticulier } from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import { getDateEN } from "@mbj-front-monorepo/utils";
import styled from "styled-components";

interface MbjParticulierInformationsCardCtrlProps extends MbjCardClassicCtrlProps {
  /**
   * Classe html de la carte :
   */
  className?: string;

  particulierId?: number;

  isEditable?: boolean;
  isMobile?:boolean;
}

const MbjParticulierInformationsCardCtrl = (props: MbjParticulierInformationsCardCtrlProps) => {
  const particulierQuery = useGetParticulier(props.particulierId);
  const mutation = useMutateParticulier(props.particulierId || -1);

  const defaultValues = {
    nom: particulierQuery.data?.nom,
    prenom: particulierQuery.data?.prenom,
    email: particulierQuery.data?.email,
    telephone: particulierQuery.data?.telephone,
    adresse: particulierQuery.data?.adresse,
    codepostal: particulierQuery.data?.codepostal,
    ville: particulierQuery.data?.ville,
    dateNaissance: particulierQuery.data?.dateNaissance
      ? getDateEN(new Date(particulierQuery.data.dateNaissance))
      : null,
  };

  const methods = useForm<Operation<"putParticulierItem", "requestBody">>({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const resetValues = (defaultValues: any) => {
    methods.reset(defaultValues);
  };

  useEffect(() => {
    resetValues(defaultValues);
  }, [methods.reset, particulierQuery.data]);

  const onSubmit = (data: Operation<"putParticulierItem", "requestBody">) => {
    const datas = {
      ...data,
      dateNaissance: data.dateNaissance ? data.dateNaissance.toString() : null,
    };
    console.log(datas);
    mutation.mutate(datas);
  };
  return (
      <MbjCardBack noShadow={props.isMobile}>
        <div className={props.className}>
          {particulierQuery.isLoading ? (
            <MbjLoading />
          ) : particulierQuery.error ? (
            <p>Erreur ...</p>
          ) : (
            <form onSubmit={methods.handleSubmit(onSubmit)} className="Infos">
              <MbjHeading>Coordonnées</MbjHeading>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom *"}>
                  <MbjSimpleInput
                    name={"nom"}
                    id={"nom"}
                    noPadding
                    register={methods.register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Nom requis",
                      },
                    }}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Prénom *"}>
                  <MbjSimpleInput
                    name={"prenom"}
                    id={"prenom"}
                    noPadding
                    register={methods.register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Prénom requis",
                      },
                    }}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Date de naissance"}>
                  <MbjSimpleInput
                    type={"date"}
                    name={"dateNaissance"}
                    id={"birthAt"}
                    noPadding
                    register={methods.register}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Email *"}>
                  <MbjEmailSimpleInput
                    name={"email"}
                    id={"email"}
                    noPadding
                    register={methods.register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Email requis",
                      },
                    }}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Téléphone fixe"}>
                  <MbjSimpleInput
                    name={"telephone"}
                    id={"telephone1"}
                    noPadding
                    register={methods.register}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Adresse"}>
                  <MbjSimpleInput
                    name={"adresse"}
                    id={"adresse"}
                    noPadding
                    register={methods.register}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Code postal"}>
                  <MbjSimpleInput
                    name={"codepostal"}
                    id={"codepostal"}
                    noPadding
                    register={methods.register}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={methods.formState.errors} label={"Ville"}>
                  <MbjSimpleInput
                    name={"ville"}
                    id={"ville"}
                    noPadding
                    register={methods.register}
                    disabled={!props.isEditable}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"interactContainer"}>
                {methods.formState.isDirty && (
                  <MbjButton type="button" onClick={() => resetValues(defaultValues)} themeColor={"warning"}>
                    Réinitialiser
                  </MbjButton>
                )}
                <MbjButton isPending={mutation.isLoading}>Confirmer</MbjButton>
              </div>
            </form>
          )}
        </div>
      </MbjCardBack>
  );
};
export const MbjParticulierInformationsCard = styled(MbjParticulierInformationsCardCtrl)((props) => ({
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexWrap: "wrap",
      alignItems: "start",
      gap: "20px",
    },
    ".title": {
      marginTop: "20px",
    },
    ".interactContainer": {
      display: "flex",
      flexDirection: "row",
      gap: "15px",
      alignSelf: "end",
    },
  },
}));
MbjParticulierInformationsCard.defaultProps = {
  isEditable: true,
};
