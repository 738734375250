import styled from "styled-components";
import {useGetBesoinsFormationEntreprise} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjCardClassic, MbjCardClassicCtrlProps, MbjLoading, MbjNumber} from "@mbj-front-monorepo/ui";
import React from "react";

interface BesoinDashCardCtrlProps{
  className?:string;
}

const BesoinDashCardCtrl = (props:BesoinDashCardCtrlProps)=>{
  const BesoinsQuery = useGetBesoinsFormationEntreprise();
  return (
    <MbjCardBack>
      {BesoinsQuery.isLoading ? (
        <MbjLoading />
      ) : BesoinsQuery.error ? (
        <p>Erreur ...</p>
      ) : (
          <MbjNumber number={BesoinsQuery.data.length} legend={"Compétence nécessitant une formation"} themeColor={"primary"}/>
        )
      }
    </MbjCardBack>
  )
}

export const BesoinDashCard = styled(BesoinDashCardCtrl)`
`
