import styled from "styled-components";
import {
  createSelectColumn,
  MbjButton, MbjConfirmModal,
  MbjFlex,
  MbjLink,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea, MbjTableV2, MbjWidgetClassic, useModal
} from "@mbj-front-monorepo/ui";
import {useLocation} from "react-router-dom";
import {useDeleteCV, useGetCvTheque, useGetRight} from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {useMemo} from "react";

interface MaCvThequeCtrlProps{
  className?:string;
}

const MaCvThequeCtrl = (props:MaCvThequeCtrlProps)=>{
  const {isLoading:loadRight, isAuth:isAuthRead} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "cvTeque", "oneCvTheque")
  const {isLoading:loadRightW, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "cvTeque", "oneCvTheque")
  const mutationDelete = useDeleteCV();
  const { isShowing: isShowingDeleteCv, toggle: toggleDeleteCV } = useModal();
  const CvQuery = useGetCvTheque();
  const myList = useMemo(()=>{
    if(!CvQuery.data){
      return [];
    } else {
      return CvQuery.data.filter(cv=>!cv.alliances)
    }
  }, [CvQuery.data])

  const columnHelper = createColumnHelper<GetElementType<typeof CvQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.nom, {
          header: "Nom",
          cell: (info) => isAuthRead ? <MbjLink to={info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink> : <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor((row) => row.prenom, {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Localisation",
      columns: [
        // columnHelper.accessor((row) => row.adresse || "--", {
        //   header: "Adresse",
        // }),
        columnHelper.accessor((row) => row.codepostal || "--", {
          header: "Code Postal",
        }),
        columnHelper.accessor((row) => row.ville || "--", {
          header: "Ville",
        }),
      ],
    }),
  ];

  const table = useReactTable({
    data: myList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
  });
  const DeleteOne = ()=>{
    const ids = table.getSelectedRowModel().rows.map((row) => row.original.id || 0)
    //console.log(ids);
    ids.forEach(id=>{
      mutationDelete.mutate(id, {
        onSuccess:()=>{
          toggleDeleteCV();
        }
      })
    })

  }

  return (

    <div className={`ma_cv_theque ${props.className}`}>
      <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {CvQuery.isLoading ? (
          <MbjLoading />
        ) : CvQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
              <div>
                {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                  <MbjButton themeColor={"warning"} onClick={toggleDeleteCV} isPending={false}>
                    Supprimer {table.getSelectedRowModel().rows.length} cv(s)
                  </MbjButton>
                )}

              </div>
            </div>
            <MbjScrollArea overflowX={"auto"} maxHeight={"350px"}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
      <MbjConfirmModal
          isShowing={isShowingDeleteCv}
          hide={toggleDeleteCV}
          title="Confirmation de suppression de cv(s)"
          confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} cv(s) ?`}
          confirmFunction={DeleteOne}
          isLoading={mutationDelete.isLoading}
      />
    </div>
  )
}

export const MaCvTheque = styled(MaCvThequeCtrl)`
  padding: 1% 2%;
  display: flex!important;
  flex-direction: column;
  height: 100%;

  .ResultNumber{
    font-size:20px;
  }
  .space-between{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
`

