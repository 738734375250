import * as React from "react";
import { useState } from "react";

import {
  MbjButton,
  MbjCardClassic,
  MbjErrorData,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  useGetEntretienRecapNoSignEmployees,
  useGetEntretienRecapNoSignResp,
  useRelaunchEntretien,
} from "@mbj-front-monorepo/data-access";

interface EntretienStatsCtrlProps {
  className?: string;
  onToggle: () => void;
  onAlert: any
}

function EntretienStatsCtrl(props: EntretienStatsCtrlProps) {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const noSignEmployees = useGetEntretienRecapNoSignEmployees();
  const noSignResp = useGetEntretienRecapNoSignResp();
  const relaunchMutation = useRelaunchEntretien()
  const handleRelauch = () => {
    if (selectedItems.length === 0) {
      return
    }
    setLoading(true)
    const emails = selectedItems.map((item: any) => {
      const data = JSON.parse(item)
      return data.email
    })
    const emailsUniques: string[] = [...new Set(emails)];
    relaunchMutation.mutate(emailsUniques, {
      onSuccess: (data) => {
        setLoading(false)
        const checkboxes = document.querySelectorAll<HTMLInputElement>('input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
          checkbox.checked = false;
        });
        props.onToggle()
        setSelectedItems([])
        props.onAlert(data)
      }
    })
  }
  const handlerCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isSelected = e.target.checked
    const value = e.target.value
    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
      return
    }
    setSelectedItems((prevData) => prevData.filter((item) => item !== value));
  };

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Liste des collaborateurs n'ayant pas signé leur évaluation"}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="noSigns">
              {noSignEmployees.isLoading ? (
                <MbjLoading />
              ) : noSignEmployees.isError ? (
                <MbjErrorData />
              ) : (
                noSignEmployees.data?.length > 0 && (
                  <div className="noSignEmployees">
                    <MbjLabel className="mblabel">Salariés </MbjLabel>
                    {noSignEmployees.data.map((emp: any) => (
                      <div className="user" key={emp.id}>
                        <input
                          type="checkbox"
                          value={JSON.stringify(emp)}
                          onChange={handlerCheckBox}
                          checked={selectedItems.some(i => i === JSON.stringify(emp))}
                        />
                        <label>{emp.nom + " " + emp.prenom}</label>
                      </div>
                    ))}
                  </div>
                )
              )}
              {noSignResp.isLoading ? (
                <MbjLoading />
              ) : noSignResp.isError ? (
                <MbjErrorData />
              ) : (
                noSignResp.data?.length > 0 && (
                  <div className="noSignResp">
                    <MbjLabel className="mblabel">Responsables </MbjLabel>
                    {noSignResp.data.map((resp: any) => (
                      <div className="user" key={resp.id}>
                        <input
                          type="checkbox"
                          value={JSON.stringify(resp)}
                          onChange={handlerCheckBox}
                          checked={selectedItems.some(i=> i === JSON.stringify(resp))}
                        />
                        <label>{resp.nom}</label>
                      </div>
                    ))}
                  </div>
                )
              )}
            </div>
            {((noSignEmployees.data && noSignEmployees.data?.length > 0) ||
              (noSignResp.data && noSignResp.data?.length > 0)) && (
              <MbjButton themeColor={"primary"} onClick={handleRelauch} isPending={loading}>Signer</MbjButton>
            )}
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const EntretienStats = styled(EntretienStatsCtrl)((props) => ({
  padding: "1% 2%",
  ".mblabel": {
    marginBottom: "10px",
  },
  ".noSigns": {
    display: "flex",
    justifyContent: "space-between",
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".user label": {
    marginLeft: "10px",
    lineHeight: "1.6",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
