import {
  MbjButton,
  MbjLoading, MbjCardClassic,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useCreateCustomQuestion,
  useGetCustomQuestions,
} from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface AddQuestionCtrlProps extends MbjModalCtrlProps {
  onToggle: () => void;
}

const AddQuestionCtrl = (props: AddQuestionCtrlProps) => {
  const questionsQuery = useGetCustomQuestions();
  const mutation = useCreateCustomQuestion();

  const HandleSend = (data: any) => {
    mutation.mutate(
      { libelle: data.libelle }, {
        onSuccess: () => props.onToggle()
      })
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
  });

  return (
    <div style={{padding: "1em"}}>
      <MbjCardClassic title="Ajout" basis={"100%"}>
      {questionsQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjSimpleInputContainer errors={errors} label={"Libellé de la question à ajouter *"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
                minLength: {
                  value: 3,
                  message: "Libellé trop court",
                },
                validate: {
                  isExist: (value) => {
                    const TabExist = questionsQuery.data?.filter((question: any) => question.libelle === value);
                    return !(!!TabExist && TabExist.length > 0) || "Cette question existe déjà";
                  },
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Ajouter
          </MbjButton>
        </form>
      )}
      </MbjCardClassic>
    </div>
  );
};

export const AddQuestion = styled(AddQuestionCtrl)`

  .serviceAddForm {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      align-self: end;
      margin-top: 10px;
    }
  }
`;
