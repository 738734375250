import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import {MesAlliances} from "./MesAlliances";
import {OneAllianceRooter} from "./OneAlliance/OneAllianceRooter";

export function MesAlliancesRooter(){
    return (
        <Routes>
            <Route path="" element={<MesAlliances />} />
            <Route path={":id/*"} element={<OneAllianceRooter/>}/>
        </Routes>
    )
}
