import styled from "styled-components";
import React, { useState } from "react";
import {
  useDeleteFormationPlanning,
  useGetFormationPlanningMemberWithoutMoyenFormation,
  useGetRight,
} from "@mbj-front-monorepo/data-access";
import {
  createSelectColumn, MbjTableV2,
  MbjLoading,
  MbjButton, MbjScrollArea, useModal, MbjIconButton, MbjConfirmModal,
  MbjModal2
} from "@mbj-front-monorepo/ui";

import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { BiSolidSchool } from "react-icons/bi";
import { SelectionSolution } from "./SelectionSolution";

interface SolutionsFormationCtrlProps{
  className?:string;
}

interface Info {
  activite: string,
  fpIDs: string,
  actID: number
}

const SolutionsFormationCtrl = (props:SolutionsFormationCtrlProps) => {
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs")
  const formationPlannings = useGetFormationPlanningMemberWithoutMoyenFormation()
  const {isShowing: isShowingDetail, toggle: toggleDetail} = useModal();
  const [info, setInfo] = useState<Info>({activite: '', fpIDs: '', actID: 0 })

  const {isShowing: isShowingDeleteFormation, toggle: toggleDeleteFormation} = useModal();
  const columnHelper = createColumnHelper<GetElementType<typeof formationPlannings.data>>();
  const deleteFormation = useDeleteFormationPlanning()
  const formatDate = (d: string) => {
    const date = new Date(d);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const HandleFormClick = (item: any) => {
    setInfo({
      activite: item.activite,
      fpIDs: item.liste_ids_fp,
      actID: item.activiteID
    })
    toggleDetail()
  }

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row: any) => row.activite, {
      header: "Compétence",
    }),
    columnHelper.accessor((row: any) =>  !row.souhait_at ? 'Non planifiée' : formatDate(row.souhait_at) , {
      header: "Date de formation",
    }),
    columnHelper.accessor((row: any) => row.nombre_formations, {
      header: "Nombre de formations planifiées",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
      cell: (props: any) => <div className="btn-actions">
        <MbjIconButton
          size={"sm"}
          onClick={() => HandleFormClick(props.row?.original)}
          ariaLabel={"Stats"}
          icon={<BiSolidSchool />}
          themeColor={"primary"}
          isRound={false}
        />
      </div>,
    }),

  ];
  const table = useReactTable({
    data: formationPlannings?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })


  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {formationPlannings.isLoading ? (
        <MbjLoading />
      ) : formationPlannings.isError ? (
        <p>Error: {formationPlannings.error.message}</p>
      ) : (
        <>
          <div className={"space-between"}>
            <div className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ?  `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
            <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
              {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                <>
                  <MbjButton themeColor={"warning"} isPending={false} onClick={toggleDeleteFormation}>
                    Retirer {table.getSelectedRowModel().rows.length} planification(s)
                  </MbjButton>
                </>
              )}
            </div>
          </div>
          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true}/>
          </MbjScrollArea>
        </>
      )}
      <MbjConfirmModal
        isShowing={isShowingDeleteFormation}
        hide={toggleDeleteFormation}
        title="Confirmation de suppression"
        confirmText={`Etes-vous sûr de vouloir retirer ${table.getSelectedRowModel().rows.length} cette ligne ?`}
        confirmFunction={() => {
          table.getSelectedRowModel().rows.map((row: any) => {
            const fpId = row.original.liste_ids_fp
            if (fpId.includes(',')) {
               const tabItems = fpId.split(',')
               const ids = tabItems.map((item: string) => parseInt(item, 10))
              return deleteFormation.mutate(ids,
                {
                  onSuccess: () => {
                    toggleDeleteFormation();
                    table.toggleAllRowsSelected(false);
                  },
                }
              );
            }
            return deleteFormation.mutate(fpId,
              {
                onSuccess: () => {
                  toggleDeleteFormation();
                  table.toggleAllRowsSelected(false);
                },
              }
            );
          })
        }}
        isLoading={deleteFormation.isLoading}
      />
      <MbjModal2 open={isShowingDetail} closeMe={toggleDetail} titre={"Informations détaillées"} Wd={"80%"}>
        <div style={{padding:"0 1rem", height:"max-content"}}>
          <SelectionSolution info={info} onToggle={toggleDetail}/>
        </div>
      </MbjModal2>
    </div>

  )
}
export const SolutionsFormation = styled(SolutionsFormationCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
  }

  .plprg {
    margin-bottom: 0.5rem;
  }

  .plprg_select {
    display: block;
    margin: 20px 10px 20px 0;
  }
`
;
