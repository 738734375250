import styled from "styled-components";

interface MbjRepartSliderCtrlProps{
    className?:string;
    tauxDec:number;
    libelle:string;
}

const MbjRepartSliderCtrl = (props:MbjRepartSliderCtrlProps)=>{
    return (
        <div className={`wrapper_metier ${props.className}`}>
            <div className={`slidy`} style={{width: `${(props.tauxDec || 0) * 100}%`}}/>
            <div className="libelle"><span>{props.libelle}</span></div>
        </div>
    )
}

export const MbjRepartSlider = styled(MbjRepartSliderCtrl)`
  position: relative;
  height: 26px;
  flex-shrink: 0;
  .slidy{
    background: ${props=>props.theme.thirdPastel};
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  .libelle{
    position: absolute;
    z-index: 2;
    left: 10px;
    width: calc(100% - 10px);
    overflow: hidden;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    span{
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      display: block;
      overflow: hidden
    }
  }
`
