import React, { useEffect, useMemo, useState } from "react";
import {
  CustomSelect,
  MbjAccordion,
  MbjAccordionContent,
  MbjAccordionItem,
  MbjAccordionTrigger,
  MbjButton,
  MbjCardClassic,
  MbjFlex,
  MbjIconButton,
  MbjInfo,
  MbjLoading, MbjModal2,
  MbjRangeStatic,
  MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
  MbjWidgetClassic, useModal,
} from "@mbj-front-monorepo/ui";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  Operation,
  useAddNewEntretien,
  useGetActiviteEntretien, useGetCustomQuestionsByActivation,
  useGetFormationEmployees,
  useGetFormationToMetier,
  useGetMemberMe,
  useGetMetiers,
  useGetObjectifEntretiens,
  useGetTypesEvtsMarquants,
  useGetUserMetiers,
} from "@mbj-front-monorepo/data-access";
import { BsTrash } from "react-icons/bs";
import { LineSuggestionFormation } from "./LineSuggestionFormation";
import { EntretienPdf } from "./EntretienPdf/EntretienPdf";
import { usePDF } from "@react-pdf/renderer";
import styled, { useTheme } from "styled-components";
import { useNavigate } from "react-router-dom";
import { GetElementType, groupByKey } from "@mbj-front-monorepo/utils";

let nb = 0;

interface EntretienWithoutCompetencesFormCtrlProps {
  className?: string;
  Employee: Operation<"getEmployeeItem">;
}

interface ListActi {
  competence: string;
  activites: Operation<"getEmployeeEntretienActiviteCollection">;
}

export interface EntretienAnnuelFormData {
  liked?: string;
  noliked?: string;
  comentGeneResp?: string;
  comentGeneSalarie?: string;
  //Id of the user metier :
  EvoInterneCrtTerme?: number;
  //Id of the user metier :
  EvoInterneMoyenTerme?: number;
  //Id of the user metier :
  EvoInterneLgTerme?: number;
  //Id of the metier :
  EvoExterneCrtTerme?: number;
  //Id of the metier :
  EvoExterneMoyenTerme?: number;
  //Id of the metier :
  EvoExterneLgTerme?: number;
  FormPast?: { id: string; appreciation?: string }[];
  ObjPast?: { id: string; resultat?: string; coment?: string }[];
  NewObj?: { libelle?: string; progression?: string; moyens?: string }[];
  ToRise?: { coment?: string }[];
  Skill?: { coment?: string }[];
  Evts?: { type?: number; coment?: string }[];
  NewForm?: { libelle?: string; avis?: string }[];
  Cq?: { id: string; comment?: string; scoreResp?: string; scoreEmp?: string }[];

}

function EntretienWithoutCompetencesFormCtrl({ className, Employee }: EntretienWithoutCompetencesFormCtrlProps) {
  if (!Employee.id) throw new TypeError("Employee.id is undefined");
  const navigate = useNavigate();
  const [textError, setTextError] = useState<JSX.Element|null>(null)
  const {isShowing:showError, toggle:toggleError} = useModal();
  const mutation = useAddNewEntretien(Employee.id || -1);
  const Today = new Date();
  const yearT = Today.getFullYear();
  const monthT = Today.getMonth() + 1 < 10 ? `0${Today.getMonth() + 1}` : Today.getMonth() + 1;
  const dayT = Today.getDate();

  const memberQuery = useGetMemberMe();
  const typeEvtsMarquantsQuery = useGetTypesEvtsMarquants();
  const { isLoading: loadAct, isError: errAct, data: activites } = useGetActiviteEntretien(Employee.id);
  const {
    isLoading: loadFormPast,

    isError: errFormPast,
    data: formationsEmployeeToEvaluate,
  } = useGetFormationEmployees(Employee.id, yearT);
  const { isLoading: loadObj, isError: errObj, data: objectifsToEvaluate } = useGetObjectifEntretiens(Employee.id);
  const { isLoading: loadUM, isError: errUM, data: user_metiers } = useGetUserMetiers();
  const { isLoading: loadMetier, isError: errMetier, data: metiers } = useGetMetiers();
  const suggestionsFormationsQuery = useGetFormationToMetier(Employee.id, Employee.UserMetier?.id);
  const groupSuggestion = useMemo(
    () => groupByKey(suggestionsFormationsQuery.data?.FormationsCourtesMetier || [], "id"),
    [suggestionsFormationsQuery.data]
  );
  const cquestionsQuery = useGetCustomQuestionsByActivation(1)


  const [search, setSearch] = useState("");

  const ActiFiltered = useMemo(() => {
    const myRet: ListActi[] = [];
    const ActiFilt: GetElementType<typeof activites>[] = [];
    const competences: GetElementType<typeof activites>["Competence"][] = [];
    if (!loadAct && !errAct && activites) {
      const regex = new RegExp("" + search + "", "gi");
      activites.map((acti) => {
        if (
          acti.EmployeeActivite?.Activite?.libelle?.match(regex) ||
          search === "" ||
          acti.Competence?.libelle?.match(regex)
        ) {
          //console.log(acti.activite);
          ActiFilt.push(acti);
          if (competences.indexOf(acti.Competence) === -1) {
            competences.push(acti.Competence);
          }
        }
      });
      console.log(activites);
      competences.map((c) => {
        const actiOk = ActiFilt.filter((a) => a.Competence === c);
        myRet.push({ competence: c?.libelle || "", activites: actiOk });
      });
    }
    return myRet;
  }, [activites, loadAct, errAct, search]);

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },

  } = useForm<EntretienAnnuelFormData>({
    mode: "onSubmit",
  });


  const {
    fields: eventsField,
    append: eventsAppend,
    remove: eventsRemove,
  } = useFieldArray({
    control,
    name: "Evts",
  });
  //Points forts du salarié :
  const {
    fields: pointsFortsField,
    append: pointsFortsAppend,
    remove: pointsFortsRemove,
  } = useFieldArray({
    control,
    name: "Skill",
  });
  const {
    fields: ameliosField,
    append: ameliosAppend,
    remove: ameliosRemove,
  } = useFieldArray({
    control,
    name: "ToRise",
  });
  const {
    fields: objectifsField,
    append: objectifsAppend,
    remove: objectifsRemove,
  } = useFieldArray({
    control,
    name: "NewObj",
  });

  const {
    fields: formationsField,
    append: formationsAppend,
    remove: formationsRemove,
  } = useFieldArray({
    control,
    name: "NewForm",
  });

  const [pdfText, setPdfText] = useState<string>("");
  const theme = useTheme();
  const [pdfEntreprise, setPdfEntreprise] = useState<typeof memberQuery.data>(memberQuery.data);
  const [pdfFormData, setPdfFormData] = useState<EntretienAnnuelFormData | undefined>(undefined);
  const [pdfEmployee, setPdfEmployee] = useState<typeof Employee>(Employee);

  const [pdfEventsPossibles, setPdfEventsPossibles] = useState<typeof typeEvtsMarquantsQuery.data>(
    typeEvtsMarquantsQuery.data
  );
  const [pdfActivitesEntretien, setPdfActivitesEntretien] = useState<typeof activites>(activites);
  const [pdfFormPasts, setPdfFormPasts] = useState<typeof formationsEmployeeToEvaluate>(formationsEmployeeToEvaluate);
  const [pdfObjPasts, setPdfObjPasts] = useState<typeof objectifsToEvaluate>(objectifsToEvaluate);
  const [pdfUserMetiers, setPdfUserMetiers] = useState<typeof user_metiers>(user_metiers);
  const [pdfMetiers, setPdfMetiers] = useState<typeof metiers>(metiers);
  const [instance, updateInstance] = usePDF({
    document: (
      <EntretienPdf
        text={pdfText}
        formData={pdfFormData}
        employeeData={pdfEmployee}
        entrepriseData={pdfEntreprise}
        eventsPossibles={pdfEventsPossibles}
        activitesEntretiens={pdfActivitesEntretien}
        formPasts={pdfFormPasts}
        objectifsEntretien={pdfObjPasts}
        user_metiers={pdfUserMetiers}
        metiers={pdfMetiers}
        theme={theme}
      />
    ),
  });

  useEffect(() => {
    //console.log("form pdfData", pdfFormData);
    updateInstance();
  }, [pdfFormData]);

  useEffect(() => {
    if (
      pdfFormData &&
      pdfEmployee &&
      memberQuery.data &&
      activites &&
      theme &&
      instance &&
      !instance.loading &&
      !instance.error &&
      instance.url
    ) {
      console.log("newTab opened !");
      console.log(instance);
      window.open(instance.url);
    }
  }, [instance]);

  const onSubmit = (data: EntretienAnnuelFormData) => {
    let nbVideCq = 0
    data.Cq?.forEach(a => {
      const hasScoreResp = !!a.scoreResp;
      const hasScoreEmp = !!a.scoreEmp;
      const hasComment = !!a.comment;

      if ((hasScoreResp && hasScoreEmp && hasComment) || (!hasScoreResp && !hasScoreEmp && !hasComment)) {
        return;
      }
      nbVideCq++;
    });

    if(nbVideCq>0){
      setTextError(<p>Traiter la ou les question(s) pour lesquelles certaines valeurs sont saisies tandis que d'autres sont laissées vides. </p>)
      toggleError();
      return;
    }
    const newData = {
      EvoAnInterne: data.EvoInterneCrtTerme ? "/api/user_metiers/" + data.EvoInterneCrtTerme : undefined,
      EvoAnExterne: data.EvoExterneCrtTerme ? "/api/metiers/" + data.EvoExterneCrtTerme : undefined,
      EvoTriInterne: data.EvoInterneMoyenTerme ? "/api/user_metiers/" + data.EvoInterneMoyenTerme : undefined,
      EvoTriExterne: data.EvoExterneMoyenTerme ? "/api/metiers/" + data.EvoExterneMoyenTerme : undefined,
      EvoLgInterne: data.EvoInterneLgTerme ? "/api/user_metiers/" + data.EvoInterneLgTerme : undefined,
      EvoLgExterne: data.EvoExterneLgTerme ? "/api/metiers/" + data.EvoExterneLgTerme : undefined,
      commentairesEmp: data.comentGeneSalarie,
      commentairesResp: data.comentGeneResp,
      EventsMarquants: data.Evts?.map((e) => {
        return {
          commentaires: e.coment,
          TypeEvtsMarquant: "/api/type_evts_marquants/" + e.type,
        };
      }),
      cqes: data.Cq?.map((a) => {
        return {
          customQuestion: "/api/custom_questions/" + a.id,
          scoreResp: a.scoreResp ? parseInt(a.scoreResp) : undefined,
          scoreEmp: a.scoreEmp ? parseInt(a.scoreEmp) : undefined,
          comment: a.comment,
        };
      }),
      ObjectifsEntretiens: [
        ...(data.NewObj?.map((o) => {
          return {
            libelle: o.libelle,
            progression: o.progression,
            moyens: o.moyens,
          };
        }) || []),
        ...(data.ObjPast?.map((o) => {
          return {
            id: parseInt(o.id),
            resultat: o.resultat ? parseInt(o.resultat) : undefined,
          };
        }) || []),
      ],
      isPro: true,
      LikedEntretiens: [
        {
          commentaire: data.liked,
        },
      ],
      NoLikedEntretiens: [
        {
          commentaires: data.noliked,
        },
      ],
      SkillsEntretiens: data.Skill?.map((p) => {
        return {
          commentaires: p.coment,
        };
      }),
      ToRiseEntretiens: data.ToRise?.map((p) => {
        return {
          commentaires: p.coment,
        };
      }),
    };
    console.log(data);
    console.log(newData);
    mutation.mutate(newData, {
      onSuccess: () => {
        navigate("../entretien");
      },
    });
  };

  function handlePdf(data: EntretienAnnuelFormData) {
    console.log("handlePdf 2");
    console.log(instance);
    setPdfText("Ceci est un test " + nb);
    setPdfActivitesEntretien(activites);
    setPdfEntreprise(memberQuery.data);
    setPdfEmployee(Employee);
    setPdfEventsPossibles(typeEvtsMarquantsQuery.data);
    setPdfFormPasts(formationsEmployeeToEvaluate);
    setPdfObjPasts(objectifsToEvaluate);
    setPdfUserMetiers(user_metiers);
    setPdfMetiers(metiers);
    setPdfFormData(data);
    nb++;
  }
  let cqIdx = -1;


  return (
    <div className={`wrapFormNewEntretien ${className}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="Infos">
        <MbjWidgetClassic
          title={`Entretien professionel pour ${Employee.prenom} ${
            Employee.nom
          } pour l'année ${new Date().getFullYear()}`}
          direction={"column"}
        >

          <MbjCardClassic title={"Événements marquants durant la période"} basis={"100%"}>
            <div style={{padding:"24px"}}>
              {eventsField.map((item, index) => (
                <div key={item.id} className={`dynamicFieldLine`}>
                  <MbjSimpleInputContainer errors={errors} label={"Type d'événement"} direction={"column"}>
                    <Controller
                      name={`Evts.${index}.type`}
                      control={control}
                      rules={{ required: { value: true, message: "Ce champ est requis" } }}
                      render={({ field }) => (
                        <CustomSelect
                          isLoading={typeEvtsMarquantsQuery.isLoading}
                          options={typeEvtsMarquantsQuery.data}
                          getOptionLabel={(item) => item.libelle || ""}
                          getOptionValue={(item) => item.id + ""}
                          isClearable={true}
                          onChange={(selectedOption, triggeredAction) => {
                            if (selectedOption) {
                              field.onChange(selectedOption.id);
                            }
                            if (triggeredAction.action === "clear") {
                              field.onChange(null);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>
                  <MbjSimpleInputContainer errors={errors} label={"Précision"} direction={"column"}>
                    <MbjSimpleTextArea
                      name={`Evts.${index}.coment`}
                      id={`Evts.${index}.coment`}
                      noPadding
                      register={register}
                    />
                  </MbjSimpleInputContainer>

                  <MbjIconButton
                    onClick={() => eventsRemove(index)}
                    ariaLabel={"Supprimer l'événement"}
                    icon={<BsTrash />}
                    themeColor={"warning"}
                    size={"sm"}
                  />
                </div>
              ))}
              <MbjButton
                size={"xs"}
                type={"button"}
                themeColor={"secondary"}
                onClick={() => {
                  eventsAppend({ coment: undefined });
                }}
              >
                Nouvel événement
              </MbjButton>
            </div>
          </MbjCardClassic>

          <MbjCardClassic title={"Bilan de la période (ce que j’ai pensé de cette période)"} basis={"100%"}>
            <div style={{padding:"24px", display:"flex", gap:"12px", flexDirection:"column"}}>
              <MbjSimpleInputContainer errors={errors} label={"Ce qui m'a plu"} direction={"column"}>
                <MbjSimpleTextArea
                  name={`liked`}
                  id={`liked`}
                  height={"80px"}
                  resize={"none"}
                  noPadding
                  register={register}
                  registerOptions={{
                    required: false,
                  }}
                />
              </MbjSimpleInputContainer>
              <MbjSimpleInputContainer errors={errors} label={"Ce qui m'a déplu"} direction={"column"}>
                <MbjSimpleTextArea
                  name={`noliked`}
                  id={`noliked`}
                  height={"80px"}
                  resize={"none"}
                  noPadding
                  register={register}
                  registerOptions={{
                    required: false,
                  }}
                />
              </MbjSimpleInputContainer>
            </div>
          </MbjCardClassic>
          <MbjCardClassic title={"Questions optionnelles"}>

            <div className={`WrapComp`}>
              {cquestionsQuery.isLoading ? (
                <MbjLoading />
              ) : cquestionsQuery.isError ? (
                <p>Erreur ...</p>
              ) : (

                cquestionsQuery.data.map((cq: any, idx: number) => {
                  cqIdx++
                  return <div key={`blockComp${cq.id}`} className={"blockComp"}>
                    <div className={`blockActivites`}>
                      <div className={`LineActivite`}>
                        <div className={`libActi`}>{cq.libelle}</div>
                        <div className={`illActivite`}>
                          <input
                            defaultValue={`${cq.id}`}
                            type={"hidden"}
                            {...register(`Cq.${cqIdx}.id`)}
                          />
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleTextArea
                              name={`Cq.${cqIdx}.comment`}
                              id={`Cq.${cqIdx}.comment`}
                              register={register}
                              placeholder={
                                "Commentaire"
                              }
                            />
                          </MbjSimpleInputContainer>
                        </div>
                        <div className={`AppreResp`}>
                          <MbjInfo text={"Appréciation du responsable"} className={"infos"} />

                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleInput
                              name={`Cq.${cqIdx}.scoreResp`}
                              id={`Cq.${cqIdx}.scoreResp`}
                              type={"number"}
                              noPadding
                              register={register}
                              registerOptions={{
                                min: {
                                  value: 0,
                                  message: "Ne peut être négatif",
                                },
                                max: {
                                  value: 5,
                                  message: "Ne peut être supérieur à 5",
                                },
                              }}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                        <div className={`AppreEmp`}>
                          <MbjInfo text={"Appréciation du salarié"} className={"infos"} />
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleInput
                              name={`Cq.${cqIdx}.scoreEmp`}
                              id={`Cq.${cqIdx}.scoreEmp`}
                              type={"number"}
                              noPadding
                              register={register}
                              registerOptions={{
                                min: {
                                  value: 0,
                                  message: "Ne peut être négatif",
                                },
                                max: {
                                  value: 5,
                                  message: "Ne peut être supérieur à 5",
                                },
                              }}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                      </div>
                    </div>
                  </div>;
                })
              )}
            </div>
          </MbjCardClassic>


          <div style={{width:"100%", display:"flex", flexDirection:"row", gap:"24px"}}>
            <MbjCardClassic title={"Développement des compétences"} basis={"calc(50% - 12px)"}>
              <div className={`bodyBlock`}>
                <div className={`SubBlock`}>
                  <div style={{fontSize:"15px", fontWeight:"bold"}}>Formation(s) suivie(s) au cours de l'année écoulée</div>
                  <div className={`bodySubBlock`}>
                    {loadFormPast ? (
                      <MbjLoading />
                    ) : errFormPast ? (
                      "erreurs de chargement"
                    ) : (
                      formationsEmployeeToEvaluate?.map((FE, idx: number) => (
                        <div className={`dynamicFieldLine formation`} key={idx}>
                          <div className={`NameFormation`}>
                            {FE.nomPerso} {FE.FormationContinuCourtes ? `(${FE.FormationContinuCourtes.libelle})` : ""}
                          </div>
                          <div className={`DateFormation`}>
                            {FE.formationAt ? new Date(FE.formationAt).toLocaleDateString() : ""}
                          </div>
                          <div className={`EvaFormation`}>
                            <input type={"hidden"} defaultValue={FE.id} {...register(`FormPast.${idx}.id`)} />
                            <MbjSimpleInputContainer errors={errors}>
                              <MbjSimpleTextArea
                                name={`FormPast.${idx}.appreciation`}
                                id={`FormPast.${idx}.appreciation`}
                                register={register}
                                placeholder={"Evaluation de la formation"}
                              />
                            </MbjSimpleInputContainer>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
                <div className={`SubBlock`}>
                  <h5>Points forts du salarié</h5>
                  <div className={`bodySubBlock`}>
                    {pointsFortsField.map((item, index) => (
                      <div className={`dynamicFieldLine`} key={item.id}>
                        <MbjSimpleInputContainer errors={errors} direction={"column"}>
                          <MbjSimpleTextArea
                            name={`Skill.${index}.coment`}
                            id={`Skill.${index}.coment`}
                            placeholder={"Description du point fort"}
                            noPadding
                            register={register}
                            registerOptions={{
                              required: {
                                value: true,
                                message: "Description requise",
                              },
                            }}
                          />
                        </MbjSimpleInputContainer>
                        <MbjIconButton
                          onClick={() => pointsFortsRemove(index)}
                          ariaLabel={"Supprimer le point fort"}
                          icon={<BsTrash />}
                          themeColor={"warning"}
                          size={"sm"}
                        />
                      </div>
                    ))}
                    <MbjButton
                      margin={"12px 0"}
                      size={"xs"}
                      type={"button"}
                      themeColor={"secondary"}
                      onClick={() => {
                        pointsFortsAppend({ coment: undefined });
                      }}
                    >
                      Nouveau point fort
                    </MbjButton>
                  </div>
                </div>
                <div className={`SubBlock`}>
                  <h5>Axes d'améliorations du salarié</h5>
                  <div className={`bodySubBlock`}>
                    {ameliosField.map((item, index) => (
                      <div className={`dynamicFieldLine`} key={item.id}>
                        <MbjSimpleInputContainer errors={errors} direction={"column"}>
                          <MbjSimpleTextArea
                            name={`ToRise.${index}.coment`}
                            id={`ToRise.${index}.coment`}
                            placeholder={"Description de l'axe d'amélioration"}
                            noPadding
                            register={register}
                            registerOptions={{
                              required: {
                                value: true,
                                message: "Description requise",
                              },
                            }}
                          />
                        </MbjSimpleInputContainer>
                        <MbjIconButton
                          onClick={() => ameliosRemove(index)}
                          ariaLabel={"Supprimer l'amélioration"}
                          icon={<BsTrash />}
                          themeColor={"warning"}
                          size={"sm"}
                        />
                      </div>
                    ))}
                    <MbjButton
                      margin={"12px 0"}
                      size={"xs"}
                      type={"button"}
                      themeColor={"secondary"}
                      onClick={() => {
                        ameliosAppend({ coment: undefined });
                      }}
                    >
                      Ajouter un Axe d'amélioration
                    </MbjButton>
                  </div>
                </div>
              </div>
            </MbjCardClassic>
            <MbjCardClassic title={"Réalisation et fixation des objectifs"} basis={"calc(50% - 12px)"}>
              <div className={`SubBlock`}>
                <div style={{fontSize:"15px", fontWeight:"bold"}}>Objectifs pour la période évaluée</div>
                <div className={"Explaination"}>
                  <p>
                    Le responsable résume les objectifs fixés pour la période évaluée, commente leur réalisation et
                    attribue une note.
                  </p>
                  <p>(1=Non Atteint, 2=Partiellement atteint, 3=Atteint, 4=A dépassé l'objectif)</p>
                </div>
                <div className={`bodySubBlock`}>
                  {loadObj ? (
                    <MbjLoading />
                  ) : errObj ? (
                    "erreurs..."
                  ) : (
                    objectifsToEvaluate?.map((obj, idx: number) => (
                      <div className={`LineObjPast`} key={`LineObjPast${idx}`}>
                        <div className={`LibObj`}>
                          <span>{obj.libelle}</span>
                          <input type={"hidden"} defaultValue={obj.id} {...register(`ObjPast.${idx}.id`)} />
                        </div>
                        <div className={`Result`}>
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleInput
                              name={`ObjPast.${idx}.resultat`}
                              id={`ObjPast.${idx}.resultat`}
                              type={"number"}
                              noPadding
                              register={register}
                              registerOptions={{
                                min: {
                                  value: 1,
                                  message: "Ne peut être inférieur à 1",
                                },
                                max: {
                                  value: 4,
                                  message: "Ne peut être supérieur à 4",
                                },
                              }}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                        <div className={`ComentObj`}>
                          <MbjSimpleInputContainer errors={errors}>
                            <MbjSimpleTextArea
                              name={`ObjPast.${idx}.coment`}
                              id={`ObjPast.${idx}.coment`}
                              placeholder={"Eléments explicatifs"}
                              register={register}
                            />
                          </MbjSimpleInputContainer>
                        </div>
                      </div>
                    ))
                  )}
                  {!loadObj && objectifsToEvaluate?.length === 0 && (
                    <div className={`NoList`}>Aucun objectif à évaluer</div>
                  )}
                </div>
              </div>
              <div className={`SubBlock`}>
                <div style={{fontSize:"15px", fontWeight:"bold"}}>Objectifs pour la période suivante</div>
                <div className={"Explaination"}>
                  <p>
                    En tenant compte de la situation du salarié, il fixe les nouveaux objectifs et s’entend avec lui sur
                    les moyens qui seront alloués
                  </p>
                </div>
                <div className={`bodySubBlock`}>
                  {objectifsField.map((item, index) => (
                    <div key={item.id} className={`dynamicFieldLine`}>
                      <MbjSimpleInputContainer errors={errors} direction={"column"} label={"Libellé"}>
                        <MbjSimpleInput
                          name={`NewObj.${index}.libelle`}
                          id={`NewObj.${index}.libelle`}
                          type={"text"}
                          noPadding
                          register={register}
                          registerOptions={{
                            required: {
                              value: true,
                              message: "Libellé requis",
                            },
                          }}
                        />
                      </MbjSimpleInputContainer>

                      <MbjSimpleInputContainer errors={errors} direction={"column"} label={"Progression envisagée"}>
                        <MbjSimpleTextArea
                          name={`NewObj.${index}.progression`}
                          id={`NewObj.${index}.progression`}
                          noPadding
                          register={register}
                        />
                      </MbjSimpleInputContainer>

                      <MbjSimpleInputContainer errors={errors} direction={"column"} label={"Moyens matériels"}>
                        <MbjSimpleTextArea
                          name={`NewObj.${index}.moyens`}
                          id={`NewObj.${index}.moyens`}
                          noPadding
                          register={register}
                        />
                      </MbjSimpleInputContainer>
                      <MbjIconButton
                        onClick={() => objectifsRemove(index)}
                        ariaLabel={"Supprimer l'objectif"}
                        icon={<BsTrash />}
                        themeColor={"warning"}
                        size={"sm"}

                      />
                    </div>
                  ))}
                  <MbjButton
                    margin={"12px 0"}
                    size={"xs"}
                    type={"button"}
                    themeColor={"secondary"}
                    onClick={() => {
                      objectifsAppend({ moyens: undefined });
                    }}
                  >
                    Nouvel Objectif
                  </MbjButton>
                </div>
              </div>
            </MbjCardClassic>
          </div>
          <div style={{width:"100%", display:"flex", gap:"24px"}}>
            <MbjCardClassic title={"Suggestion de formations courtes"} noPadding basis={"calc(40% - 12px)"}>
              {suggestionsFormationsQuery.isLoading ? (
                <MbjLoading />
              ) : suggestionsFormationsQuery.isError ? (
                <p>Erreur ...</p>
              ) : (
                <MbjScrollArea maxHeight={"100%"}>
                  <MbjAccordion type="multiple" defaultValue={["item-0"]} className={"accordéon"}>
                    {Object.keys(groupSuggestion).map((key: string, index: number) => {
                      const item = groupSuggestion[key];
                      return (
                        <MbjAccordionItem value={`item-${index}`} key={index}>
                          <MbjAccordionTrigger>
                            <div className="accordion-trigger-content">{item?.[0]?.activiteLibelle}</div>
                          </MbjAccordionTrigger>
                          <MbjAccordionContent>
                            <div style={{padding:"8px"}}>
                              {item?.map((suggestion, index: number) => (
                                <LineSuggestionFormation suggestion={suggestion} key={index} />
                              ))}
                            </div>
                          </MbjAccordionContent>
                        </MbjAccordionItem>
                      );
                    })}
                  </MbjAccordion>
                </MbjScrollArea>
              )}
            </MbjCardClassic>
            <MbjCardClassic title={"Évolution professionnelle"} basis={"calc(50% - 12px)"}>
              <div className={`SubBlock`}>
                <div style={{fontSize:"15px", fontWeight:"bold"}}>Vos besoins de formation</div>
                <div className={`bodySubBlock`}>
                  {formationsField.map((item, index) => (
                    <div key={item.id} className={`dynamicFieldLine`}>
                      <MbjSimpleInputContainer errors={errors} direction={"column"} label={"Libellé"}>
                        <MbjSimpleInput
                          name={`NewForm.${index}.libelle`}
                          id={`NewForm.${index}.libelle`}
                          type={"text"}
                          noPadding
                          register={register}
                          registerOptions={{
                            required: {
                              value: true,
                              message: "Libellé requis",
                            },
                          }}
                        />
                      </MbjSimpleInputContainer>
                      <MbjSimpleInputContainer errors={errors} label={"Avis du responsable"} direction={"column"}>
                        <MbjSimpleTextArea
                          name={`NewForm.${index}.avis`}
                          id={`NewForm.${index}.avis`}
                          noPadding
                          register={register}
                          registerOptions={{
                            required: {
                              value: true,
                              message: "Avis requis",
                            },
                          }}
                        />
                      </MbjSimpleInputContainer>
                      <MbjIconButton
                        onClick={() => formationsRemove(index)}
                        ariaLabel={"Supprimer la formation"}
                        icon={<BsTrash />}
                        themeColor={"warning"}
                        size={"sm"}
                      />
                    </div>
                  ))}
                  <MbjButton
                    margin={"12px 0"}
                    size={"xs"}
                    type={"button"}
                    themeColor={"secondary"}
                    onClick={() => {
                      formationsAppend({ avis: undefined });
                    }}
                  >
                    Nouvelle formation
                  </MbjButton>
                </div>
              </div>
              <div className={`SubBlock`}>
                <div style={{fontSize:"14px", margin:"8px 0", fontWeight:"bold"}}>Souhaits d'évolutions professionnelles</div>
                <div className={` bodySubBlock`} style={{display:"flex", flexDirection:"column", gap:"12px"}}>
                  <div className={"Term"}>À court terme (1 an) :</div>
                  <MbjSimpleInputContainer errors={errors} label={"Évolution interne"} direction={"column"}>
                    <Controller
                      name={`EvoInterneCrtTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          isLoading={loadUM}
                          placeholder={"Choisir un métier interne"}
                          options={user_metiers}
                          getOptionLabel={(um) => um.namePerso || ""}
                          getOptionValue={(um) => um.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>

                  <MbjSimpleInputContainer errors={errors} label={"Mobilité Externe"} direction={"column"}>
                    <Controller
                      name={`EvoExterneCrtTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          placeholder={"Choisir un métier"}
                          isLoading={loadMetier}
                          options={metiers}
                          getOptionLabel={(m) => m.libelle || ""}
                          getOptionValue={(m) => m.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>

                  <div className={"Term"}>À moyen terme (3 ans) :</div>
                  <MbjSimpleInputContainer errors={errors} label={"Évolution interne"} direction={"column"}>
                    <Controller
                      name={`EvoInterneMoyenTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          isLoading={loadUM}
                          placeholder={"Choisir un métier interne"}
                          options={user_metiers}
                          getOptionLabel={(um) => um.namePerso || ""}
                          getOptionValue={(um) => um.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>

                  <MbjSimpleInputContainer errors={errors} label={"Mobilité Externe"} direction={"column"}>
                    <Controller
                      name={`EvoExterneMoyenTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          placeholder={"Choisir un métier"}
                          options={metiers}
                          isLoading={loadMetier}
                          getOptionLabel={(m) => m.libelle || ""}
                          getOptionValue={(m) => m.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>
                  <div className={"Term"}>À long terme (+ de 3 ans) :</div>
                  <MbjSimpleInputContainer errors={errors} label={"Évolution interne"} direction={"column"}>
                    <Controller
                      name={`EvoInterneLgTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          isLoading={loadUM}
                          placeholder={"Choisir un métier interne"}
                          options={user_metiers}
                          getOptionLabel={(um) => um.namePerso || ""}
                          getOptionValue={(um) => um.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>

                  <MbjSimpleInputContainer errors={errors} label={"Mobilité Externe"} direction={"column"}>
                    <Controller
                      name={`EvoExterneLgTerme`}
                      control={control}
                      render={({ field }) => (
                        <CustomSelect
                          placeholder={"Choisir un métier"}
                          isLoading={loadMetier}
                          options={metiers}
                          getOptionLabel={(m) => m.libelle || ""}
                          getOptionValue={(m) => m.id + ""}
                          isClearable={true}
                          onChange={(e) => {
                            if (e) {
                              field.onChange(e.id);
                            }
                          }}
                        />
                      )}
                    />
                  </MbjSimpleInputContainer>
                </div>
              </div>
            </MbjCardClassic>
          </div>
          <MbjCardClassic title={"Commentaires généraux"} basis={"35%"}>
            <div style={{padding:"24px", display:"flex", flexDirection:"column", gap:"12px"}}>
              <MbjSimpleInputContainer errors={errors} label={"Commentaires du responsable"}>
                <MbjSimpleTextArea name={`comentGeneResp`} id={`comentGeneResp`} register={register} height={"80px"} />
              </MbjSimpleInputContainer>

              <MbjSimpleInputContainer errors={errors} label={"Commentaires du salarié"}>
                <MbjSimpleTextArea name={`comentGeneSalarie`} id={`comentGeneSalarie`} register={register} height={"80px"} />
              </MbjSimpleInputContainer>
            </div>
          </MbjCardClassic>

        </MbjWidgetClassic>

        <MbjButton size={"sm"} themeColor={"primary"} isPending={mutation.isLoading} className={"submitButon"}>
          Enregistrer et envoyer pour signature
        </MbjButton>

        {/*
                    instance.loading ? (
                    <div>Loading ...</div>
                ) : instance.error ? (
                    <div>Something went wrong: {instance.error}</div>
                ) : instance.url ? (
                    <a href={instance.url} download="test.pdf">
                        Download
                    </a>
                ) : (
                    <React.Fragment />
                )*/}
      </form>
      <MbjModal2 open={showError} titre={"Erreurs"} Wd={"500px"} closeMe={toggleError}>
        {textError &&
          <div className={`my_errors`} style={{padding:"1rem 1rem 2rem 1rem", textAlign:"center", fontSize:"18px"}}>
            {textError}
          </div>
        }
      </MbjModal2>
      {/*<ModalTreatNew  isShowing={isShowing} hide={toggle} title={"Traitement en cours"}  />*/}
    </div>
  );
}

export const EntretienWithoutCompetencesForm = styled(EntretienWithoutCompetencesFormCtrl)((props) => ({
  ".Term":{fontSize:"14px", margin:"8px 0", fontWeight:"bold", borderBottom:"solid #ccc 1px"},
  ".submitButon": {
    marginTop: "1.1rem",
  },
  ".NoList": {
    padding: "0.5rem",
    margin:"8px auto",
    color:props.theme.dark
  },

  ".dynamicFieldLine": {
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    //borderBottom: "solid #ccc 1px",
    gap: "12px",
    paddingBottom: "0.5rem",
    marginBottom: "0.5rem",

    button: {
      alignSelf: "center",
    },

    "&.formation": {
      justifyContent: "space-between",

      ".EvaFormation": {
        flexGrow: 1,
      },
    },
  },

  ".LineObjPast": {
    display: "flex",
    width: "100%",
    justifyContent: "start",
    alignItems: "stretch",
    ".LibObj": {
      padding: "0.5rem",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      input: {
        position: "absolute",
      },
    },
    ".Result": {
      padding: "0.5rem",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      input: {
        width: "50px",
        height: "50px",
        display: "block",
        textAlign: "center",
        margin: "auto",
      },
    },
    ".ComentObj": {
      padding: "0.5rem",
      flexGrow: 1,
    },
  },
  ".SubBlock": {
    marginBottom: "15px",
  },

  ".WrapSearchComp": {
    padding: "1rem 0",
    borderBottom: "solid #ccc 2px",
  },
  ".LineActivite": {
    display: "flex",
    padding: "0.5rem 0",
    borderBottom: "solid 1px" + props.theme.greyLighten,
    ".libActi": {
      padding: "0.25rem",
      width: "30%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    ".illActivite": {
      padding: "0.5rem",
      width: "30%",
    },
    ".comparaison": {
      padding: "0.5rem",
      width: "20%",
    },
    ".AppreResp, .AppreEmp": {
      padding: "0.5rem",
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      ".infos": {
        position: "absolute",
        top: 0,
        right: 0,
      },
      input: {
        width: "70px",
        height: "50px",
        display: "block",
        textAlign: "center",
        margin: "auto",
      },
    },
  },
}));
