import * as React from "react";
import styled from "styled-components";
import {
  MbjIconButton, MbjLoading, MbjModal2,
  MbjSwitch,
  MbjSwitchThumb, MbjTable,
  MbjTooltip,
  MbjTooltipArrow,
  MbjTooltipContent,
  MbjTooltipTrigger, useModal,
} from "@mbj-front-monorepo/ui";

import { getDateFR } from "@mbj-front-monorepo/utils";
import {
  Operation, useGetTopRecrutementByEmployee,
  useMutateAcceptReclassementEmployee,
  useMutateProposeReclassementEmployee,
} from "@mbj-front-monorepo/data-access";
import { BiLineChartDown, BiSolidBullseye } from "react-icons/bi";
import { ReclassementStatistiques } from "./ReclassementStatistiques";
import { TopRecrutementUserMetier } from "./TopRecrutementUserMetier";
import { TopRecrutementEmployee } from "./TopRecrutementEmployee";

interface CollaborateurReclasseItemCtrlProps {
  className?: string;

  reclassementEmployee: NonNullable<Operation<"getMemberMetierReclassementCollection">[0]["ReclassementEmployees"]>[0];
}

const TopMetierTitles = [
  { libelle: "Zone d'emploi", Align: "isCenter" },
  { libelle: "Metier", Align: "isCenter" },
  { libelle: "Raison sociale", Align: "isCenter" },
  { libelle: "Distance (en km)", Align: "isCenter" },
];

function CollaborateurReclasseItemCtrl(props: CollaborateurReclasseItemCtrlProps) {
  const mutationPropose = useMutateProposeReclassementEmployee(props.reclassementEmployee.id || -1);
  const mutationAccept = useMutateAcceptReclassementEmployee(props.reclassementEmployee.id || -1);
  const { isShowing, toggle } = useModal();

  const empStats = () => toggle()

  return (
    props.reclassementEmployee && (
      <>
        <tr>
          <td className="isCenter">
            {props.reclassementEmployee.Employee?.nom?.toUpperCase()} {props.reclassementEmployee.Employee?.prenom}
          </td>
          <td className="isCenter">
            {props.reclassementEmployee.Employee?.entryAt
              ? getDateFR(new Date(props.reclassementEmployee.Employee?.entryAt))
              : "Non renseignée"}
          </td>
          <td className="isCenter">{props.reclassementEmployee.Employee?.isMobilite ? "✅" : "❌"}</td>
          <td className="isCenter"></td>
          <td className="isCenter">
            <MbjSwitch
              defaultChecked={props.reclassementEmployee.isProposed}
              onCheckedChange={() => mutationPropose.mutate(!props.reclassementEmployee.isProposed)}
            >
              <MbjSwitchThumb />
            </MbjSwitch>
          </td>
          <td className="isCenter">
            <MbjTooltip delayDuration={150}>
              <MbjTooltipTrigger asChild>
                <MbjSwitch
                  defaultChecked={props.reclassementEmployee.isAccepted}
                  onCheckedChange={() => mutationAccept.mutate(!props.reclassementEmployee.isAccepted)}
                >
                  <MbjSwitchThumb />
                </MbjSwitch>
              </MbjTooltipTrigger>

              <MbjTooltipContent sideOffset={5}>
                {props.reclassementEmployee.isAccepted
                  ? "Ce profil ne sera plus visible auprès des membres de vos alliances une fois décoché."
                  : "Ce profil sera visible auprès des membres de vos alliances une fois coché."}
                <MbjTooltipArrow />
              </MbjTooltipContent>
            </MbjTooltip>
          </td>
          <td className="isCenter">
            <MbjIconButton
              size={"sm"}
              ariaLabel={"Stats"}
              icon={<BiSolidBullseye />}
              themeColor={"primary"}
              isRound={false}
              onClick={() => empStats()}
            />
          </td>
        </tr>
        <MbjModal2 open={isShowing} closeMe={toggle} titre={"Statistiques"} Wd={"80%"}>
          <div style={{ padding: "0 1rem", height: "max-content" }}>
            <TopRecrutementEmployee id={props.reclassementEmployee?.Employee?.id} />
          </div>
        </MbjModal2>
      </>
    )
  );
}

export const CollaborateurReclasseItem = styled(CollaborateurReclasseItemCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
