import { Navigate, Route, Routes } from "react-router-dom";
import CollaborateursDashboard from "./Dashboard/CollaborateursDashboard";
import React from "react";
import {CollaborateursListing} from "./Listing/CollaborateursListing";
import {OneCollaborateurRooter} from "./OneCollaborateur/OneCollaborateurRooter";
import {FormationRooter} from "./Formation/FormationRooter";
import {PilotageRHRooter} from "./PilotageRH/PilotageRHRooter";
import {PrivateRouteSub} from "../../components/PrivateRouteSub";
import {NouvelleFormation} from "./NouvelleFormation/NouvelleFormation";
import {EquipeManagerMain} from "./EquipeManager/EquipeManagerMain";
import { SearchCompetence } from "./SearchCompetence/SearchCompetence";

export function CollaborateursRooter(){
    return (
        <Routes>
            <Route element={<PrivateRouteSub Onglet={"Collaborateurs"}/>}>
                <Route path="" element={<Navigate to="dashboard" />} />
                <Route path={"dashboard"} element={<CollaborateursDashboard/>}/>
                <Route path={"listingc"} element={<CollaborateursListing/>}/>
                <Route path={"pilotagerh/*"} element={<PilotageRHRooter/>}/>
                <Route path={"formation/*"} element={<FormationRooter/>}/>
                <Route path={"new_formation"} element={<NouvelleFormation/>}/>
                <Route path={"equipe_manager"} element={<EquipeManagerMain/>}/>
                <Route path={":id/*"} element={<OneCollaborateurRooter/>}/>
                <Route path={"search_competence"} element={<SearchCompetence/>}/>
            </Route>
        </Routes>
    )
}
