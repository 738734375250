import styled from "styled-components";
import {MbjLineCompareCible} from "../../atoms/MbjActivite/MbjLineCompareCible";
import {BaseColorsTheme} from "../../../assets/styles/ThemeType";
import * as React from "react";
import {MbjLineCompareCibleMobile} from "../../atoms/MbjActivite/MbjLineCompareCibleMobile";

interface MbjCompareActiMobileCtrlProps {
  className?:string;
  listActi:{libelle:string, id:number, niveau:number, niveauCible:number}[];
  colorNiveau:BaseColorsTheme;
  colorNiveauCible:BaseColorsTheme;
  ActionToClick?:(id:number)=>void;
}

const MbjCompareActiMobileCtrl = ({className, listActi, ActionToClick, colorNiveauCible, colorNiveau}: MbjCompareActiMobileCtrlProps) => {
  return (
    <div className={`CompareActiMobile ${className}`}>
      <div className={`wrap_list`}>
        <div className={`in_list`}>
          {listActi.sort((a, b) => a.libelle > b.libelle ? 1 : -1).map(acti => (
            <MbjLineCompareCibleMobile
              key={`LieA${acti.id}`}
              idActivite={acti.id}
              libelle={acti.libelle}
              niveau={acti.niveau}
              niveauCible={acti.niveauCible}
              actionClick={ActionToClick}
              colorNiveau={colorNiveau}
              colorNiveauCible={colorNiveauCible}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export const MbjCompareActiMobile = styled(MbjCompareActiMobileCtrl)`
  .in_list{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`
