import styled from "styled-components";
import {BadgeActiStick} from "../../Informations/OneCollaborateurBadges";
import {MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {GestionBadge} from "./GestionBadge";

interface StickerBadgeResumeCtrlProps{
    className?:string;
    Badge:BadgeActiStick
    switchable:boolean;
    readonly :boolean;
}

const StickerBadgeResumeCtrl = ({className, Badge, switchable, readonly}:StickerBadgeResumeCtrlProps)=>{
    const {isShowing, toggle} = useModal();
    const handleClick = ()=>{
        if(!readonly){
            toggle();
        }
    }
    return (
        <div className={`stick_badge ${className} ${Badge.actif ? 'actif' : 'inactif'} ${readonly ? 'readonly' : ''}`} onClick={handleClick}>
            {Badge.libelle}
            <MbjModal2 open={isShowing} closeMe={toggle} titre={"Gestion du badge "+Badge.libelle} Wd={"40%"}>
                <GestionBadge Badge={Badge} isSwitchable={switchable}/>
            </MbjModal2>
        </div>
    )
}

export const StickerBadgeResume = styled(StickerBadgeResumeCtrl)`
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  margin-bottom: 6px;
  font-weight: bold;
  &:hover{

    cursor: ${props=>props.switchable && !props.readonly ? 'pointer' : 'auto'};
  }
  &.actif {
    border: solid 1px #7f9a77;
    background: #d9efd3;
    color: #4e604a;
  }

  &.inactif {
    border: solid 1px #a4a4a4;
    background: #f3f2f2;
    color: #a4a4a4;
  }

`
