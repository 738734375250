import styled from "styled-components";
import {ObjectifsSection} from "../../../components/ObjectifsSection";

interface DynamiqueEffectifDashboardCtrlProps{
    className?:string;
}

const DynamiqueEffectifDashboardCtrl = (props:DynamiqueEffectifDashboardCtrlProps)=>{
    return (
        <div className={`dyn_dash pageStd ${props.className}`}>
          <ObjectifsSection titre={"Objectif section dynamique de l'effectif"}>
            <p>Avec My-Better-Job décuplez l’efficacité de votre processus de recrutement.</p>
            <br/>
            <p>Vous trouverez ici des viviers pour vos projets de recrutement que vous ne trouverez sur aucun jobboard
              :</p>
            <ol>
              <li>Ceux de vos collaborateurs qui répondent au mieux à vos projets de recrutements actuels</li>
              <li>Les salariés, au sein des entreprises de vos alliances, qui sont amenés à évoluer
                professionnellement.
              </li>
              <li>Dans ces entreprises partenaires, les métiers en déclin et dont les compétences qu’ils mettent en
                œuvre coïncident avec vos besoins d’embauche. Vous pouvez proposer vos projets aux DRH concernés, qui
                pourront les relayer auprès de salariés éventuellement intéressés
              </li>
              <li>Votre cvthèque, et les CV que reçoivent les entreprises de vos alliances et qu’elles proposent aux
                candidats de partager avec vous. Gérez les CV que vous recevez grâce à l’intelligence artificielle et
                analysez leur compatibilité avec tous les métiers de l’entreprise
              </li>
              <li>Toutes les formations certifiantes (initiales ou continues) qui préparent, dans un rayon de 200 km
                autour de votre site, au métier que vous cherchez à pourvoir
              </li>
            </ol>
            <br/>
            <p>Le menu « Profils retenus » regroupe les CV que vous avez présélectionnés et vous donne la possibilité
              d’entrer en contact avec les candidats en question, puis de gérer toute l’information que vous recueillez
              sur ceux qui vous intéressent.</p>
            <br/>
            <p>« Analyse recrutement » vous aidera à éviter de rencontrer ou de multiplier les difficultés de
              recrutement, en vous proposant de modifier votre recherche par exemple.</p>
            <p>Avec l’onglet « Reclassements » informez les entreprises partenaires de la vôtre au sein de vos alliances
              si l’un des métiers de votre organisation est appelé à réduire son effectif. Si, au cours d’un entretien
              périodique, un des salariés qui l’exercent se montre intéressé pour envisager un reclassement hors de
              votre entreprise, son profil apparaîtra dans les résultats des recherches de collaborateurs de ces
              partenaires, s’il est pertinent.</p>
          </ObjectifsSection>
        </div>
    )
}

const DynamiqueEffectifDashboard = styled(DynamiqueEffectifDashboardCtrl)`
  ol{
    margin-left: 20px;
    margin-top: 12px;
    li{
      margin-bottom: 6px;
    }
  }
`


export default DynamiqueEffectifDashboard;
