import styled from "styled-components";
import {useForm} from "react-hook-form";
import {useAddAlliance} from "@mbj-front-monorepo/data-access";
import {
    MbjAutocomplete,
    MbjButton,
    MbjEmailSimpleInput,
    MbjSimpleInput,
    MbjSimpleInputContainer
} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface FormNewAllianceCtrlProps{
    className?:string;
    close:()=>void;
}

const FormNewAllianceCtrl = (props:FormNewAllianceCtrlProps)=>{
    const mutation = useAddAlliance();
    const methods = useForm({
        mode:"onChange",
        defaultValues:{
            name:'',
        }
    })
    const onSubmit = (datas:any)=>{
        mutation.mutate(datas.name, {
            onSuccess:()=>{
                props.close();
            }
        })
    }
    return (
        <div className={`new_alliance_form ${props.className}`}>
            <form onSubmit={methods.handleSubmit(onSubmit)} className="Infos">
                <div className={"rowContainer"}>
                    <MbjSimpleInputContainer errors={methods.formState.errors} label={"Nom de l'alliance *"}>
                        <MbjSimpleInput
                            name={"name"}
                            id={"name"}
                            type={"text"}
                            noPadding
                            register={methods.register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Nom requis",
                                },
                                minLength: {
                                    value: 2,
                                    message: "Nom trop court",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>

                </div>
                <div className={`wrap_sender`}>
                    <MbjButton isPending={mutation.isLoading} alignSelf={"flex-end"}>
                        Créer l'alliance
                    </MbjButton>
                </div>
            </form>
        </div>
    )
}

export const FormNewAlliance = styled(FormNewAllianceCtrl)`
    span{
      display: block;
    }
  .wrap_sender{
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
  }
`
