import styled from "styled-components";
import {components, useAddNewMemberEquipeManager, useGetEmployees} from "@mbj-front-monorepo/data-access";
import {useMemo, useRef, useState} from "react";
import {useDebounce} from "@mbj-front-monorepo/utils";
import {GrClose} from "react-icons/gr";
import {OneCardActiToChoice} from "../../NouvelleFormation/components/OneCardActiToChoice";
import * as React from "react";
import {BsTrash} from "react-icons/bs";
import {MbjButton, MbjIconButton} from "@mbj-front-monorepo/ui";

interface ModalAddMemberCtrlProps {
  className?:string;
  idEquipe:number;
  closeMe:()=>void;
}

const ModalAddMemberCtrl = ({className, idEquipe, closeMe}: ModalAddMemberCtrlProps) => {
  const EmployeesQuery = useGetEmployees();
  const [saveError, setSaveError] = useState(false);
  const mutation = useAddNewMemberEquipeManager();
  const refSearch = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('')
  const searchDebounce = useDebounce(search, 500);
  const [listEmplChoice, setListEmplChoice] = useState<(components["schemas"]["Employee-read.Employee.detailed_read.employeeBadge_read.Services_read.UserMetier"])[]>([]);
  const HandleChangeSearch = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value)
    }
  }
  const listResult = useMemo(()=>{
    if(!EmployeesQuery.data){
      return [];
    } else if(searchDebounce === ''){
      return EmployeesQuery.data.filter(e=>listEmplChoice.map(ex=>ex.id).indexOf(e.id)===-1)
    } else {
      const regex = new RegExp(""+searchDebounce+"", 'gi');
      return EmployeesQuery.data.filter(e=>(`${e.prenom} ${e.nom}`).match(regex) && listEmplChoice.map(ex=>ex.id).indexOf(e.id)===-1)
    }
  }, [EmployeesQuery.data, searchDebounce, listEmplChoice])
  const RemoveMember = (id:number)=>{
    setListEmplChoice(eqs=>[...eqs].filter(e=>e.id!==id))
  }
  const AddMember = (item:components["schemas"]["Employee-read.Employee.detailed_read.employeeBadge_read.Services_read.UserMetier"])=>{
    setListEmplChoice(eqs=>[...eqs, item])
  }
  const SaveMe = ()=>{
    const TabToSave:any[] = [];
    listEmplChoice.forEach(e=>{
      TabToSave.push({
        Employee:`/api/employees/${e.id||0}`,
        addAt:(new Date().toISOString()),
        EquipeManager:`/api/equipe_managers/${idEquipe}`
      })
    })
    TabToSave.forEach(d=>{
      mutation.mutate(d, {
        onSuccess:()=>{
          closeMe();
        }, onError:()=>{
          setSaveError(true);
        }
      })
    })
  }
  return (
    <div className={`content_modal_add_member_equipe_manager ${className}`}>
      <div className="wrap_search">
        <input type="text" ref={refSearch} value={search} onChange={HandleChangeSearch}
               placeholder={"Rechercher un collaborateur"}/>
        {searchDebounce.length > 0 &&
          <div className="closeSearch" onClick={() => setSearch('')}><GrClose/></div>
        }
      </div>
      <div className="wrapper_result">
        <div className="wrap_result">
          {listResult.map((item) => (
            <div className={`one_employee_result`} key={`OneEmpResult${item.id}`} onClick={()=>AddMember(item)}>
              {item.prenom} {item.nom}
            </div>
          ))}
        </div>
      </div>
      <div className="list_choice">
        <p className={"titre_list_choice"}>Collaborateurs choisis : </p>
        <div className="wrapper_lister">
          <div className="wrap_lister">
            {listEmplChoice.map((item) => (
              <div className={`one_employee_choice`} key={`OneEmpChoice${item.id}`}  onClick={() => RemoveMember(item.id || 0)}>
                <div className="name">{item.prenom} {item.nom}</div>
                <div className="trash_me">
                  <div className="wrapper_remove">
                    <GrClose/>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {saveError &&
        <div className="errorSave">Une erreur est survenue, merci de retenter plus tard</div>
      }
      <div className="wrap_sender">
        <MbjButton size={"sm"} themeColor={"primary"} onClick={SaveMe} disabled={mutation.isLoading}>
          Ajouter les membres
        </MbjButton>
      </div>
    </div>
  )
}

export const ModalAddMember = styled(ModalAddMemberCtrl)`
  .wrap_sender {
    padding: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
  .one_employee_choice{
    display: flex;
    justify-content: flex-start;
    padding: 0.25rem 0.25rem 0.25rem 0.5rem;
    gap: 12px;
    border-radius: 8px;
    background: ${props => props.theme.secondaryPastel};
    width: max-content;
    align-items: center;
    &:hover{
      filter:brightness(90%);
      cursor: pointer;
    }
  }
  .wrapper_remove{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
    .one_employee_result{
      padding: 0.25rem 0.5rem;
      border-radius: 4px;
      font-weight: bold;
      &:hover{
        cursor: pointer;
        background: ${props => props.theme.greyPastel};
      }
    }
  .titre_list_choice {
    padding: 0.25rem 0.5rem;
    font-weight: bold;
    color: #989696;
  }

  .wrapper_result, .wrapper_lister {
    padding: 0.5rem 0;
    border-bottom: solid ${props => props.theme.primary} 1px;
  }

  .wrap_search {
    width: 100%;
    position: relative;
    padding: 0.5rem 0.25rem 0.5rem 0.75rem;
    border-bottom: solid ${props => props.theme.primary} 1px;

    input {
      width: 100%;
      height: 32px;
      padding-left: 0.25rem;
      border: none;
      background: white;
      border-radius: 4px;
    }
  }

  .closeSearch {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    background: red;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;

    svg {
      fill: white;
      stroke: white;
      margin: auto;
    }
  }

  .wrap_result {
    max-height: 350px;
    padding: 1rem;
    overflow-y: auto;
  }
  .wrap_lister{
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding: 1rem;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 120px;
  }
`
