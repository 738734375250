import { useQuery } from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";
import { useGetMemberMe } from "../member";

export const useGetNiveaux = () => {
  const promise = () => fetchApi("/api/niveau_etudes", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["niveaux_etudes"], promise, {});
};

export const useGetDisciplines = () => {
  const promise = () => fetchApi("/api/disciplines", { method: "get" });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["disciplines"], promise, {});
};

export const useGetFormationContinueCourtes = (activityID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/fccof/get_info_fccof/{activityID}", { method: "get", params: {activityID}, query: { memberID: memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["get_info_fccof", activityID, memberId], promise, {
    enabled: !!memberId && !!activityID,
  });
};

export const useGetFormationExpertsListe = (activityID: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employee_activites/list_experts_by_activity/{activityID}", { method: "get",  params: {activityID}, query: { memberID: memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["list_experts_by_activity", activityID, memberId], promise, {
    enabled: !!memberId && !!activityID,
  });
};
