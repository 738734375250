import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { MbjLabel } from "../MbjLabel/MbjLabel";
import { FieldErrors } from "react-hook-form";

interface MbjSimpleInputContainerCtrlProps {
  /**
   * Classe HTML du container ?
   */
  className?: string;

  direction?: "row" | "column";

  label?: string;
  labelSize?: "big" | "small" | "medium" | "very-small";
  labelShrink?: string;
  flex?: string;
  /**
   * Input :
   */
  children: any;
  /**
   * htmlFor
   */
  htmlFor?: string;
  /**
   * Erreurs du formulaire :
   */
  errors: FieldErrors;
}

function MbjSimpleInputContainerCtrl(props: MbjSimpleInputContainerCtrlProps) {
  return (
    <div className={props.className}>
      {props.label ? (
        <div className={"label-input-container"}>
          <MbjLabel
            htmlFor={props.htmlFor ? props.htmlFor : props.children.props.id}
            size={props.labelSize || "very-small"}
            themeColor={"primary"}
          >
            {props.label}
          </MbjLabel>
          {props.children}
        </div>
      ) : (
        props.children
      )}
      <ErrorMessage
        errors={props.errors}
        name={props.children.props.name}
        render={({ message }) => <p className="error-messages">{message}</p>}
      />
    </div>
  );
}

export const MbjSimpleInputContainer = styled(MbjSimpleInputContainerCtrl)((props) => ({
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  textAlign: "start",
  flex: props.flex,

  "& .label-input-container": {
    display: "flex",
    flexDirection: props.direction,
    alignItems: props.direction === "row" ? "center" : "stretch",
    gap: props.direction === "row" ? "12px" : "3px",

    label: {
      flexShrink: props.labelShrink,
    },
  },

  "p.error-messages": {
    textAlign: "start",
    color: "#bf1650",
    fontSize: "16px",
  },

  "& p.error-messages:before": {
    display: "inline",
    content: '"⚠ "',
  },

  "input, textarea": { width: "100%" },
}));
MbjSimpleInputContainer.defaultProps = {
  direction: "column",
};
