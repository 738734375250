import styled from "styled-components";
import {useAddConversation, useAddRoom, useGetRooms, useMe} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {MbjFlex, MbjIconButton, MbjSimpleInput} from "@mbj-front-monorepo/ui";
import {IoSend} from "react-icons/io5";
import {useForm} from "react-hook-form";
import {FormSendMessage} from "./FormSendMessage";

interface SendMessageRetenuCtrlProps{
  className?:string;
  idUser?:number|null;
  email?:string|null;
}

const SendMessageRetenuCtrl = (props:SendMessageRetenuCtrlProps)=>{
  const mutationAddRoom = useAddRoom();
  const RoomsQuery = useGetRooms();
  const [idRoom, setIdRoom] = useState<number|null>(0)
  useEffect(()=>
  {
    if(RoomsQuery.data && props.idUser){

      const MYRoom = RoomsQuery.data.filter(r=> {
        const members = r.Members?.map(m=>m.userId);
        if(members?.indexOf(props.idUser||0)!==-1){
          console.log('find room');
          return true;
        } else {
          console.log(r);
          console.log(members)
          console.log(props.idUser);
        }
        return false;
      })
      console.log(props.idUser);
      if(MYRoom.length>0){
        console.log('MYROOM>>>'+MYRoom[0].id);
        setIdRoom(MYRoom[0].id||0);
      } else {
        console.log('iduser // '+props.idUser);
        mutationAddRoom.mutate([props.idUser])
      }
    } else{
      console.log('hhhh>>'+props.idUser)
    }
  }, [RoomsQuery.data, props.idUser])

  return (
    <div className={`send_message_ret ${props.className}`}>
      {RoomsQuery.isLoading ? "Recherche d'une conversation avec l'utilisateur" :
        mutationAddRoom.isLoading ? "Création de le conversation":
          !props.idUser ? (props.email ?
          <p>Cet utilisateur n'est pas membre de Mbj vous pouvez lui envoyer un email  <a href={"mailto:"+props.email}>{props.email}</a></p>:
                <p>Vous ne pouvez pas envoyer de message pour le moment</p>
            ):
          <FormSendMessage idRoom={idRoom+""}/>
      }
    </div>
  )
}

export const SendMessageRetenu = styled(SendMessageRetenuCtrl)`

`
