import styled from "styled-components";
import {useEffect, useMemo, useRef, useState} from "react";

interface MbjSearchBarMobileCtrlProps {
  className?:string;
  setSearch:(s:string)=>void;
  searchCurr:string;
  placeholder:string;
  suggestions:{id:number, libelle:string}[];
  setItem:(item:{id:number, libelle:string})=>void;
}

const MbjSearchBarMobileCtrl = ({className, searchCurr, setSearch, placeholder, suggestions, setItem}: MbjSearchBarMobileCtrlProps) => {
  const refSearch = useRef<HTMLInputElement>(null)
  const refObj = useRef<HTMLDivElement>(null);
  const [listOpened, setListOpened] = useState(false);
  const handleChange = ()=>{
    if(refSearch.current){
      setSearch(refSearch.current.value)
    }
  }
  const listfiltered = useMemo(()=>{
    const regex = new RegExp(""+searchCurr+"", 'gi');
    return suggestions.filter(s=>{
      return searchCurr === '' || s.libelle.match(regex);
    })
  }, [suggestions, searchCurr])
  useEffect(() => {
    function handleClickOutside(event:any) {
      if (refObj.current && !refObj.current.contains(event.target)) {
        setListOpened(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refObj]);
  const SetItemMe = (item:{id:number, libelle:string})=>{
    setSearch('');
    setItem(item);
    setListOpened(false);
  }
  return (
    <div className={`search_bar_mobile ${className}`} ref={refObj}>
      <input type={"text"} ref={refSearch} value={searchCurr} onChange={handleChange} placeholder={placeholder} onFocus={()=>setListOpened(true)}/>
      <div className={`list_suggestion ${listOpened ? 'opened' : 'false'}`}>
        {listfiltered.length === 0 ?
          <p className={"no_result"}>Aucun résultat</p>:
          <>
          {listfiltered.map(item=>(
            <div className="one_choice" key={`RSea${item.id}`} onClick={()=>SetItemMe(item)}>{item.libelle}</div>
            ))}
          </>
        }
      </div>
    </div>
  )
}

export const MbjSearchBarMobile = styled(MbjSearchBarMobileCtrl)`
    width: 100%;
  position: relative;
  padding: 1rem 0.25rem;
  input{
    width: 100%;
    height: 32px;
    padding-left: 5px;
    border-radius: 4px;
    border:solid 1px ${props => props.theme.primary};
  }
  .list_suggestion{
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    overflow-y: auto;
    height: 0;
    max-height: 300px;
    z-index: 2;
    background: white;
    &.opened{
      height: 300px;
    }
  }
  .one_choice{
    padding: 0.25rem;
  }
`
