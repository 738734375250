import styled from "styled-components";

import {useGetEmployeesCompetences} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";

import {MbjLoading} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {ExpertCard} from "./ExpertCard";



interface ExpertWidgetCtrlProps{
  className?:string;
  Expert:ExpertOne;
  idActivite:number;
}

const ExpertWidgetCtrl = (props:ExpertWidgetCtrlProps)=>{
  const CompetenceQuery = useGetEmployeesCompetences(props.Expert.employee.id);
  const [niveau, setNiveau] = useState(0)
  useEffect(()=>{
    if(CompetenceQuery.data){
      let niveau = 0;
      CompetenceQuery.data.map(c=>{
        c.EmployeeActivites?.map(ea=>{
          if(ea.Activite?.id === props.idActivite && ea.niveau && ea.niveau > niveau){
            niveau = ea.niveau;
          }
        })
      })
      setNiveau(niveau);
    }
  }, [CompetenceQuery.data, props.idActivite])
  return (
    <div className={`ExpertWidget ${props.className}`}>
      {CompetenceQuery.isLoading ? <MbjLoading />:
        <ExpertCard ExpertOne={{...props.Expert, niveau:niveau}} niveauActuel={0}/>
      }
    </div>
  )
}

export const ExpertWidget = styled(ExpertWidgetCtrl)`
  width: 100%;
`
