import styled from "styled-components";
import {MbjFlex, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {StatsPlDashCard} from "../Components/StatsPlDashCard";
import {BesoinDashCard} from "../Components/BesoinDashCard";
import {ObjectifSectionFormationCollab} from "../Components/ObjectifSectionFormationCollab";

interface DashboardFormationCollaborateurCtrlProps{
    className?:string;
}

const DashboardFormationCollaborateurCtrl = (props:DashboardFormationCollaborateurCtrlProps)=>{
    return (
        <div className={`dash_form_collab ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"column"}>
                <ObjectifSectionFormationCollab/>
                <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
                    <MbjWidgetClassic basis={"25%"}>
                        <BesoinDashCard/>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic grow={1} bodyDirection={"column"} gap={"10px"}>
                        <StatsPlDashCard/>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>
            </MbjWidgetClassic>
        </div>
    )
}

export const DashboardFormationCollaborateur = styled(DashboardFormationCollaborateurCtrl)`
    padding: 2%;
`
