import styled from "styled-components";
import {Navigate, Route, Routes, To} from "react-router-dom";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { Plannification } from "./by_planification/Plannification";
import { SolutionsFormation } from "./by_solution_formation/SolutionsFormation";
import { PlanFormation } from "./by_plan_formation/PlanFormation";

interface PlanificationRooterCtrlProps{
  className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Planification', to:"by_planification"},
  {libelle: 'Sélection solution de formation', to:"by_solution_formation"},
  {libelle: 'Plan de formation', to:"by_moyen_formation"},
]

const PlanificationRooterCtrl = (props:PlanificationRooterCtrlProps)=>{
  return (
    <Routes>
      <Route
        element={
          <InternSubDesktopLayout
            Links={TabSubMenus}
          />
        }
      >
        <Route path="" element={<Navigate to="by_planification" />} />
        <Route path="/by_planification" element={<Plannification />} />
        <Route path="/by_solution_formation" element={<SolutionsFormation />} />
        <Route path="/by_moyen_formation" element={<PlanFormation />} />
      </Route>
    </Routes>
  )
}

export const PlanificationRooter = styled(PlanificationRooterCtrl)``
