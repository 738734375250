import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  name: string
  id: number
  name_perso: string
  salaire: number
  score: number
}

interface MatchingOtherEntrepriseCtrlProps {
  className?: string;
  item: ITemTypes
}



function MatchingOtherEntrepriseCtrl(props: MatchingOtherEntrepriseCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.name}</td>
      <td className="isCenter">{props.item.name_perso}</td>
      <td className="isCenter">{props.item.salaire}</td>
      <td className="isCenter">{Math.floor(props.item.score * 100)}</td>
    </tr>
  );
}

export const MatchingOtherEntreprise = styled(MatchingOtherEntrepriseCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
