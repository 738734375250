import styled from "styled-components";
import {Operation, useGetPutInactiveMember} from "@mbj-front-monorepo/data-access";
import React from "react";
import {AvatarFact, MbjButton} from "@mbj-front-monorepo/ui";
import {BiUser} from "react-icons/bi";
import {MdLocationOn, MdOutlineAlternateEmail} from "react-icons/md";
import {BsFillChatFill} from "react-icons/bs";
import {SendMessageMember} from "../../../../../../components/Messagerie/SendMessageMember";

interface ContentModalOneMembreCtrlProps{
    className?:string;
    Member:Operation<"getMemberItem">;
    AllianceMembre:Operation<"getAllianceMemberItem">;
    isMine:boolean;
}

const ContentModalOneMembreCtrl = ({className, Member, AllianceMembre, isMine}:ContentModalOneMembreCtrlProps)=>{
    const mutation = useGetPutInactiveMember();
    const TrashUnTrashMember = ()=>{
        mutation.mutate({isActive:!AllianceMembre.isActive, id:Member.id||0})
    }
    const handleError = (e:any)=>{
        e.target.src = AvatarFact;
    }
    const getAdresseComplete = ()=>{
        const Adresse = Member.adresse;
        const cp = Member.codePostal;
        const ville = Member.ville;
        if(Adresse && cp && ville){
            return `${Adresse} ${cp} ${ville}`
        }
        return 'nc';
    }
    return (
        <div className={`content_modal_membre ${className}`}>
            <div className={`info_membre`}>
                <div className={`title_membre`}>
                    <div className={`logo`}>
                        <img
                            src={`${process.env["REACT_APP_API_URL_ROOT"]}${Member.logoUrl}`}
                            alt={"avatar_logo"}
                            onError={handleError}
                        />
                    </div>
                    <div className={`name`}>{Member.name}</div>
                </div>
                <div className={`infos_ge`}>
                    <div className={`group_infos`}>
                        <div className={`line_infos`}>
                            <div className={'icon_place'}><MdOutlineAlternateEmail/></div>
                            <div className={`lib_infos`}>{Member.email||''}</div>
                        </div>
                        <div className={`line_infos`}>
                            <div className={'icon_place'}><MdLocationOn/></div>
                            <div className={`lib_infos`}>{getAdresseComplete()}</div>
                        </div>
                        {Member.description &&
                            <div className={`line_infos`}>
                                <div className={'icon_place'}><BsFillChatFill/></div>
                                <div className={`lib_infos`}>{Member.description}</div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`infos_alliance block_infos`}>
                    <h4>Informations Alliance</h4>
                    <div className={`line_infos`}>
                        <div className={'intro_place'}>Membre depuis</div>
                        <div className={`lib_infos`}>{AllianceMembre.creationAt ? new Date(AllianceMembre.creationAt).toLocaleDateString() : 'nc'}</div>
                    </div>
                </div>
                {Member.userId &&
                    <div className={`message block_infos`}>
                        <h4>Envoyé un message au membre</h4>
                        <SendMessageMember
                            idUser={Member.userId}
                            email={Member.email}
                        />
                    </div>
                }
                {isMine &&
                    <div className={`action_on_membre`}>
                        <MbjButton
                            onClick={TrashUnTrashMember}
                            size={"sm"}
                            themeColor={AllianceMembre.isActive ? 'warning' : 'third'}

                        >
                            {AllianceMembre.isActive ? 'Rendre inactif' : 'Rendre Actif'}
                        </MbjButton>
                    </div>
                }
            </div>
        </div>
    )
}

export const ContentModalOneMembre = styled(ContentModalOneMembreCtrl)`
  padding: 1rem;
  .block_infos{
    margin-bottom: 12px;
  }
  .title_membre{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    .logo{
      width: 40px;
      img{
        width: 100%;
      }
    }
    .name{
      font-weight: bold;
    }
  }
  .line_infos{
    display: flex;
    gap:8px;
    align-items: center;
    .icon_place{
      color:${props=>props.theme.third};
    }
  }
  .icon_place{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .group_infos{
    margin: 5px 0 10px 8px;
  }
`
