import { fetchApi, useGetMemberMe } from "@mbj-front-monorepo/data-access";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetCustomQuestions = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/custom_questions", {
    method: "get",
    query: { member: memberId + "" }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["custom_questions", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetCustomQuestion = (id: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/custom_questions/{id}", {
    method: "get",
    query: { member: memberId + "" },
    params: { id: id + "" }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["custom_question", { memberId }, id], promise, {
    enabled: !!memberId && !!id,
  });
};


export const useGetCustomQuestionsByActivation = (isActive: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/custom_questions", {
    method: "get",
    query: { member: memberId + "", isActive: isActive }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["custom_questions_is_active", { memberId }, isActive], promise, {
    enabled: !!memberId && !!isActive,
  });
};

export const useDeleteCustomQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: (id: number) => {
        return fetchApi("/api/custom_questions/{id}", { method: "delete", params: { id: id + "" } });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["custom_questions"]);
        queryClient.invalidateQueries(["custom_questions_is_active"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
};

export const useUpdateStatusCustomQuestions = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: (info: {id: number, isActive: boolean }) => {
        return fetchApi("/api/custom_questions/{id}", {
          method: "put",
          params: {id: info.id},
          json: {"isActive": info.isActive}
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["custom_questions"]);
        queryClient.invalidateQueries(["custom_questions_is_active"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
};


export const useUpdateLibelleCustomQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation(
    {
      mutationFn: (info: {id: number, libelle: string }) => {
        return fetchApi("/api/custom_questions/{id}", {
          method: "put",
          params: {id: info.id},
          json: {"libelle": info.libelle}
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["custom_questions"]);
        queryClient.invalidateQueries(["custom_questions_is_active"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
};


export const useCreateCustomQuestion = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  return useMutation(
    {
      mutationFn: (info: {libelle: string }) => {
        return fetchApi("/api/custom_questions", {
          method: "post",
          json: {"libelle": info.libelle, "member": "/api/members/" + memberId}
        })
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["custom_questions"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  )
};


