import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetTopCompetences} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {MainCompetenceItem} from "./mainCompetenceItem";

interface CompPcpaleCtrlProps{
    className?:string;
}

const CompPcpaleCtrl = (props:CompPcpaleCtrlProps)=>{
    const topCompetencesQuery = useGetTopCompetences();
    return (
        <MbjCardBack title={"Les expertises de l'entreprise"} height={"100%"}>
            <div className={`CompPcpale ${props.className}`}>
                {topCompetencesQuery.isLoading ? <MbjLoading/> : topCompetencesQuery.isError ? <MbjErrorData/> :
                    (
                        topCompetencesQuery.data?.filter(t=>t.niveau && t.niveau===5).map((competence, i: number) =>
                            i > 4 ? (
                                <React.Fragment key={competence.id} />
                            ) : (
                                <MainCompetenceItem competence={competence} key={competence.id} />
                            )
                        )
                    )
                }
            </div>
        </MbjCardBack>
    )
}

export const CompPcpale = styled(CompPcpaleCtrl)`
  max-height: 320px;
  overflow-y: auto;
`
