import styled from "styled-components";
import {Outlet, To} from "react-router-dom";
import {MbjLinkStyle2} from "../../../atoms/MbjLink/MbjLinkStyle2";
import {ReactNode} from "react";

interface InternSubDesktopLayoutCtrlProps{
    className?:string;
    Links:{libelle:string, to:To}[];
    contentContext?:ReactNode;
}

const InternSubDesktopLayoutCtrl = (props:InternSubDesktopLayoutCtrlProps)=>{
    return (
        <div className={`sub_Content ${props.className}`}  id={"SectionContentSub"}>
            {props.contentContext &&
                props.contentContext
            }
            <div className={`content_menu`}>
                {props.Links.map((item, idx:number)=>(
                    <MbjLinkStyle2 fontSize={"18px"} to={item.to} key={"link_sub"+idx} isNavLink>{item.libelle}</MbjLinkStyle2>
                ))}
            </div>
            <div className={"sub_section_const"}>
                <Outlet/>
            </div>
        </div>
    )
}

export const InternSubDesktopLayout = styled(InternSubDesktopLayoutCtrl)`
  padding: 2% 2%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  .content_menu{
    display: flex;
    padding: 0.5rem;
    gap: 20px;
  }
  .sub_section_const{
    flex-grow: 1;
    overflow-y: auto;
  }
`
