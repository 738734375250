import styled from "styled-components";
import {
  useGetUserMetiers, useGetUserMetiersMetrix,
} from "@mbj-front-monorepo/data-access";
import { useMemo, useState } from "react";
import {
  CustomSelect,
  MbjButton,
  MbjLoading,
  MbjLoadingMessage, MbjScrollArea,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import { Controller, useForm } from "react-hook-form";
import * as React from "react";
import { useQueryClient } from "@tanstack/react-query";

interface MatrixCompetencesCtrlProps {
  className?:string;
}

interface OneUseM{
  id:number;
  libelle:string;
  score?:number;
  salaire?:number;
}

interface OneActiThis{
  id:number;
  libelle:string;
}

const MatrixCompetencesCtrl = (props: MatrixCompetencesCtrlProps) => {
  const [metiers, setMetiers] = useState({metier1: 0, metier2: 0})
  const CompQuery = useGetUserMetiersMetrix(metiers.metier1, metiers.metier2)

  const TabClass = ['--null', '--very-bad', '--bad', '--almost-good', '--good', '--good']
  const listActi = useMemo(()=>{
    const myList:OneActiThis[] = [];
    if(CompQuery.data){
      const myActs = CompQuery.data[0].ActiviteScorings?.map((as: any)=>as.activite);
      console.log(myActs ? myActs.length : '!!')
      console.log(CompQuery.data.length)
      if(myActs){
        myActs.forEach((item: any)=> {
          const acti: OneActiThis = {
            id: item?.id||0,
            libelle: item?.libelle||'',
          };
          myList.push(acti);
        })
      }

    }
    return myList;
  }, [CompQuery.data])
  const metiersQuery = useGetUserMetiers();
  const [alert, setAlert] = useState('')
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const HandleSend = (data: any) => {
    setMetiers({ metier1: 0,  metier2: 0 });
    if (!data.metier1 || !data.metier2) {
      setAlert('Veuillez sélectionner deux métiers différents !');
      return;
    }
    if (data.metier1.id === data.metier2.id) {
      setAlert('Veuillez sélectionner deux métiers différents !');
      return;
    }
    setMetiers({ metier1: data.metier1.id, metier2: data.metier2.id });
    setAlert('');
  };

  return (

    <div className={`matrix_all_metier ${props.className}`}>
      <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
        {alert && <p className="alert">{alert}</p>}
        <div className="metiers">
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner un métier"} htmlFor={"metier1"}>
            <Controller
              name={"metier1"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"metier1"}
                  isLoading={metiersQuery.isLoading}
                  options={metiersQuery.data}
                  getOptionLabel={(metier: any) => metier.namePerso}
                  getOptionValue={(metier: any) => metier.id}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner un second métier"} htmlFor={"metier2"}>
            <Controller
              name={"metier2"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"metier2"}
                  isLoading={metiersQuery.isLoading}
                  options={metiersQuery.data}
                  getOptionLabel={(metier: any) => metier.namePerso}
                  getOptionValue={(metier: any) => metier.id}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} type="submit" isPending={CompQuery.isLoading}>Rechercher</MbjButton>
        </div>

      </form>
      {CompQuery.isFetching ? <>
          <MbjLoadingMessage>
            <p>L'affichage peut prendre une dizaine de secondes.</p>
            <p>La visualisation est mise à jour quotidiennement.</p>
          </MbjLoadingMessage>
          <MbjLoading />
        </> : <table>
          <thead>
          <tr>
            <th></th>
            {CompQuery.data?.map((item: any, idx: any) => (
              <th key={`H${item.id}${idx}`}>
                {item.userMetier?.namePerso || ""}
              </th>
            ))}
          </tr>
          </thead>
          <tbody>
          {listActi.map((actiItem, idx) => (
            <tr key={`LMetrix${actiItem.id}${idx}`}>
              <td>{actiItem.libelle}</td>
              {CompQuery.data?.map((cpItem: any, index: any) => {
                const actiS = cpItem.ActiviteScorings?.[idx]; // On utilise l'index ici pour associer directement
                return (
                  <td key={`Cell${cpItem.id}${actiItem.id}${index}`}>
                    <div className={`myCell ${TabClass[(actiS?.niveau || 0)]}`}>
                      {actiS?.niveau || "-"}
                    </div>
                  </td>
                );
              })}
            </tr>
          ))}
          </tbody>
        </table>
      }
    </div>

  )
}

export const MatrixCompetences = styled(MatrixCompetencesCtrl)`
  width: 100%;
  padding-top: 20px;

  .myCell {
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;

    &.--null {
      background: #e2dcdc;
      color: ${props => props.theme.grey};
    }

    &.--very-bad {
      background: ${props => props.theme.warningPastel};
      color: ${props => props.theme.warningDarken};
    }

    &.--bad {
      background: ${props => props.theme.complementaryPastel};
      color: ${props => props.theme.complementaryDarken};
    }

    &.--almost-good {
      background: ${props => props.theme.secondaryPastel};
      color: ${props => props.theme.secondaryDarken};
    }

    &.--good {
      background: ${props => props.theme.thirdLighten};
      color: ${props => props.theme.thirdDarken};
    }
  }

  .wrap_tabx {
    margin: auto;
    width: 78vw;
    max-height: 600px;
    background: white;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
  }

  table {
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      tr {

        th {
          background: white;
          position: sticky;
          top: 0;
          text-align: center;
          font-size: 13px;
          min-width: 110px;
          padding: 0.25rem 0.5rem;
          border-bottom: solid ${props => props.theme.primary} 1px;

          &:first-child {
            min-width: 280px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          &:first-child {
            font-weight: bold;
          }

          padding: 0.25rem;
        }
      }
    }
  }

  .cell_compare {
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;

    &.good {
      background: ${props => props.theme.thirdLighten};
    }

    &.same {
      background: ${props => props.theme.primaryPastel};
    }

    &.bad {
      background: ${props => props.theme.warningPastel};
    }
  }

  .metiers {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  .metiers div {
    padding-right: 5px;
  }
  .sendButton {
    align-self: end;
  }

  form {
    margin-bottom: 10px;
  }

  .alert {
    font-size: 15px;
    text-align: center;
    font-style: italic;
    color: #942222;
  }

  .matrix_all_metier {padding-right: 10px}
`
