import styled from "styled-components";
import {SearchActivitesSearchComp} from "./components/SearchActivitesSearchComp";
import {useState} from 'react'
interface SearchCompetenceCtrlProps {
 className?:string;
}
const SearchCompetenceCtrl = (props: SearchCompetenceCtrlProps) => {
  const [executeSearch, setExecuteSearch] = useState(false);
  const overflowValue = executeSearch ? 'auto': 'visible'
  return (
    <div className={`search_comp ${props.className}`} style={{ overflowY: overflowValue }}>
        <SearchActivitesSearchComp onSearch={setExecuteSearch}/>
    </div>
  )
}

export const SearchCompetence = styled(SearchCompetenceCtrl)`
  padding: 1% 2%;

`
