import React from "react";
import styled from "styled-components";
import { Link, NavLink, To } from "react-router-dom";

interface MbjLinkStyle2CtrlProps {
  /**
   * Couleur utilisée pour représenter l'ordre d'importance du lien sur la page :
   */
  themeColor?: "primary" | "secondary" | "third" | "complementary" | "white" | "dark";
  /**
   * A string representation of the Link location, created by concatenating the location’s pathname, search, and hash properties.
   * https://v5.reactrouter.com/web/api/Link/to-string
   */
  to?: To;

  /**
   * Le texte doit-il être en gras ?
   */
  isBold?: boolean;
  /**
   * Taille de la police du lien
   */
  fontSize?: string;
  /**
   * Texte
   */
  text?: string;
  children?: React.ReactNode;

  /**
   * Classe HTML du lien ?
   */
  className?: string;
  /**
   * Fonction réagissant au lien ?
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * Le lien doit-il être désactivé ?
   */
  disabled?: boolean;
  /**
   * Le lien est-il un lien de navigation ?
   */
  isNavLink?: boolean;
}
function MbjLinkStyle2Ctrl({ themeColor, isNavLink, isBold, to, onClick, ...props }: MbjLinkStyle2CtrlProps) {
  function handleClick(e: any) {
    if (props.disabled) {
      return;
    } else {
      if (onClick) {
        return onClick(e);
      } else {
        return;
      }
    }
  }
  if (to && !props.disabled) {
    if (!isNavLink) {
      return (
        <Link to={to} {...props} style={{fontSize:props.fontSize}}>
          {props.children || props.text}
        </Link>
      );
    } else {
      return (
        <NavLink to={to} {...props}>
          {props.children || props.text}
        </NavLink>
      );
    }
  } else {
    return (
      <div onClick={handleClick} {...props}>
        {props.children || props.text}
      </div>
    );
  }
}

/**
 * Composant lien :
 */
export const MbjLinkStyle2 = styled(MbjLinkStyle2Ctrl)((props) => ({
  cursor: "pointer",
  color:
    props.themeColor === "primary"
      ? props.theme.primary
      : props.themeColor === "secondary"
      ? props.theme.secondary
      : props.themeColor === "third"
      ? props.theme.third
      : props.themeColor === "complementary"
      ? props.theme.complementary
      : props.themeColor === "white"
      ? props.theme.background2
      : props.themeColor === "dark"
      ? props.theme.dark
      : "none",
  fontWeight: props.isBold ? "bold" : "normal",
  fontSize: props.fontSize+" !important",
  textDecoration: "none",
  borderBottom:"transparent 3px solid",

  "&.active": {
    color:
      props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : props.themeColor === "white"
        ? props.theme.background2
        : props.themeColor === "dark"
        ? props.theme.dark
        : "none",
    textDecoration: "none",
    fontWeight: "bold",
    fontSize:props.fontSize,
    borderWidth:"0 0 3px 0",
    borderColor:
        props.themeColor === "primary"
            ? props.theme.primary
            : props.themeColor === "secondary"
                ? props.theme.secondary
                : props.themeColor === "third"
                    ? props.theme.third
                    : props.themeColor === "complementary"
                        ? props.theme.complementary
                        : props.themeColor === "white"
                            ? props.theme.background2
                            : props.themeColor === "dark"
                                ? props.theme.dark
                                : "none",
    borderStyle:"solid"
  },

  "&:hover, &:focus": {
    textDecoration: "none",
    borderWidth:"0 0 3px 0",
    borderColor:
        props.themeColor === "primary"
            ? props.theme.primary
            : props.themeColor === "secondary"
                ? props.theme.secondary
                : props.themeColor === "third"
                    ? props.theme.third
                    : props.themeColor === "complementary"
                        ? props.theme.complementary
                        : props.themeColor === "white"
                            ? props.theme.background2
                            : props.themeColor === "dark"
                                ? props.theme.dark
                                : "none",
    borderStyle:"solid",
    color:
      props.themeColor === "primary"
        ? props.theme.primary
        : props.themeColor === "secondary"
        ? props.theme.secondary
        : props.themeColor === "third"
        ? props.theme.third
        : props.themeColor === "complementary"
        ? props.theme.complementary
        : props.themeColor === "white"
        ? props.theme.background2
        : props.themeColor === "dark"
        ? props.theme.dark
        : "none",
  },
  "&:active": {
    color:
      props.themeColor === "primary"
        ? props.theme.primaryPastel
        : props.themeColor === "secondary"
        ? props.theme.secondaryPastel
        : props.themeColor === "third"
        ? props.theme.thirdPastel
        : props.themeColor === "complementary"
        ? props.theme.complementaryPastel
        : props.themeColor === "white"
        ? "lightgray"
        : props.themeColor === "dark"
        ? "lightgray"
        : "none",
  },
}));
MbjLinkStyle2.defaultProps = {
  themeColor: "primary",
  isBold: false,
  disabled: false,
  isNavLink: false,
};
