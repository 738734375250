import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjTable, MbjLabel,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  useGetEmployeeByUserID,
  useGetOneEquipeManager,
} from "@mbj-front-monorepo/data-access";
import { MemberTeam } from "./MemberTeam";

interface DetailsEquipeCtrlProps {
  className?: string;
  teamId: number;
  teamUserId: number;
}

const Collaborators = [
  { libelle: "Collaborateurs", Align: "isCenter" },
  { libelle: "Action", Align: "isCenter" },
];



function DetailsEquipeCtrl(props: DetailsEquipeCtrlProps) {

  const teamManagerQuery = useGetOneEquipeManager(props.teamId)
  const teamHeadManager = useGetEmployeeByUserID(props.teamUserId)

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Information sur l'équipe"}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="Nom">
              <MbjLabel>Nom de l'équipe </MbjLabel>
              {teamManagerQuery.isLoading ? <MbjLoading /> : <div className="data">{teamManagerQuery.data?.nom}</div>}
            </div>
            <div className="Nom">
              <MbjLabel>Manager </MbjLabel>
              {teamHeadManager.isLoading ? <MbjLoading /> : teamHeadManager.isSuccess ? teamHeadManager.data.map((item: any, index: number) => <div
                className="data" key={index.toString()}>{item.prenom + " " + item.nom}</div>) : 'Aucun manager'}
            </div>
          </MbjCardClassic>
          {<MbjCardClassic title="Membres de l'équipe" basis={"100%"}>
            {teamManagerQuery.isLoading ?
              <MbjLoading /> : teamManagerQuery.data?.memberEquipeManagers && teamManagerQuery.data?.memberEquipeManagers?.length > 0 ?
                <MbjTable titles={Collaborators} themeColor={"primary"} noBorderRadius>
              {teamManagerQuery.data?.memberEquipeManagers?.map((item: any) => <MemberTeam key={item.id} item={item} />)}
              </MbjTable>:
              <p>Aucun membre pour le moment</p>
              }
          </MbjCardClassic>}
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const DetailsEquipe = styled(DetailsEquipeCtrl)((props) => ({
  padding: "1% 2%",

  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
