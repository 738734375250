import styled from "styled-components";
import {BaseColorsTheme} from "@mbj-front-monorepo/ui";

interface MbjNumberCtrlProps{
    className?:string;
    themeColor:BaseColorsTheme;
    number: number;
    legend?:string;
    noInfo?:boolean;
}

export const MbjNumberCtrl = (props:MbjNumberCtrlProps)=>{
    return (
        <div className={`mbjNumber ${props.className}`}>
            <div className="content_number">
              {props.noInfo ?
                <div className={"indispo"}>Information non disponible</div> :
                <div className={`number_place`}>{props.noInfo ? 'Indisponible' : props.number}</div>
              }
              {props.legend &&
                <div className={`legend_place`}>{props.legend}</div>
                }
            </div>
        </div>
    )
}

export const MbjNumber = styled(MbjNumberCtrl)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  .indispo{
    text-align: center;
    font-size: 14px;
    font-style: italic;
    color:${props => props.theme.lightText}
  }
  .number_place{
    font-size: 42px;
    color:${props=> props.theme[props.themeColor]};
    text-align: center;
    font-weight: bold;

  }
  .legend_place{
    text-align: center;
    padding: 0.5rem;
  }
`
