import { fetchApi } from "../../services/fetchApi";
import {useQuery} from "@tanstack/react-query";


export const useGetMapInfosByIdMetier = (idMetier:number)=>{
  const promise = () =>
    fetchApi("/api/map_infos", { method: "get", query: { idMetier: idMetier } });
  return useQuery(["map_infos", { idMetier }], promise, {
    enabled: idMetier!==0,
  });
}

export const useGetMapInfosAll = ()=>{
  const promise = () =>
    fetchApi("/api/map_infos", { method: "get" });
  return useQuery(["map_infos_all"], promise);
}
