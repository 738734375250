import styled from "styled-components";
import { BlockPlannif } from "../Plannification/by_planification/Plannification";
import { FiUsers } from "react-icons/fi";

interface OnePlannifROCtrlProps{
  className?:string;
  BlockPlannif:BlockPlannif
}

const OnePlannifROCtrl = (props:OnePlannifROCtrlProps)=>{


  return (
    <div className={`block_plannif ${props.className}`}>
      <div className="content_block">
        <div className={`activite_place`}>{props.BlockPlannif.Activite.libelle}</div>
        <div className={`nb_pers_place`}>{props.BlockPlannif.list.length}<FiUsers/> ({props.BlockPlannif.MemberConcerned.join(', ')})</div>
      </div>
    </div>
  )
}
export const OnePlannifRO = styled(OnePlannifROCtrl)`
  padding: 0.2rem;
  &:hover{
    cursor: grab;
  }
  .content_block{
    border-radius: 6px;
    padding: 0.25rem;
    background: ${props => props.theme.primary};
    color:white;
    .activite_place{
      font-size: 14px;
      font-weight: bold;
    }
    .nb_pers_place{
      display: flex;
      justify-content: flex-start;
      gap: 4px;
      align-items: center;
    }
  }
`
