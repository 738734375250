import styled from "styled-components";
import {useContext} from "react";
import {DomaineContext} from "./OneDomaine";

import {LinkComp} from "./LinkComp";
import * as React from "react";

interface ListFamilleCtrlProps{
  className?:string;
}

const ListFamilleCtrl = (props:ListFamilleCtrlProps)=>{
  const context = useContext(DomaineContext);
  return (
    <div className={`list_domaines ${props.className}`}>
      <h1>Profil de compétence par Famille dans le domaine {context.Domaine ? context.Domaine.libelle : ''}</h1>
      <div className={`wrap_list`}>
        {context.Familles.sort((a,b)=>(a.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) > (b.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) ? -1 : 1).map(item=>(
          <LinkComp to={item.id + ""} libelle={item.libelle} key={`one_linkF${item.id}`} niveau={(item.NiveauTotal||0)/(context.Domaine?.NiveauTotal||1) } isN={true}/>
        ))}
      </div>
    </div>
  )
}

export const ListFamille = styled(ListFamilleCtrl)`
  padding: 1% 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wrap_list{
    flex-grow: 1;
    overflow: auto;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
`
