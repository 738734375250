import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import {MbjCardBack, MbjCardClassic, MbjNavPath, MbjWidgetClassic} from "@mbj-front-monorepo/ui";

interface LegalMentionsCtrlProps {
  className?: string;
}

function LegalMentionsCtrl(props: LegalMentionsCtrlProps) {
  const location = useLocation();
  return (
    <div className={"Legal Mentions" + props.className}>
      <MbjWidgetClassic title={"Mentions légales"}>
        <MbjCardBack title={"Version du 02/08/2022"}>
          <p>
            La société MBJ développement (ci-après « MBJ »), société immatriculée auprès du Registre du Commerce et des
            Sociétés de Lille Métropole, sous le numéro 831 273 461 et dont le siège social se situe 20 rue des Tours,
            59800 LILLE, développe et exploite le Site à destination des entreprises et des particuliers, permettant
            l’adhésion à des services de mise en relation des professionnels.
          </p>

          <p>Nom du Directeur ou co-directeur de la publication du Site : VERHAEGHE OLIVIER</p>

          <p>
            Dénomination sociale, adresse et numéro de téléphone de l’hébergeur canadien : EX2, CP 70161 Québec STN
            Québec-centre, Québec, QC, G2J 0A2, CANADA.
          </p>
        </MbjCardBack>
      </MbjWidgetClassic>
    </div>
  );
}
export const LegalMentions = styled(LegalMentionsCtrl)((props) => ({
  padding: "1% 2%",
  overflowY: "auto",
}));
