import styled from "styled-components";
import {
  Operation,
  useAddFormationPlanning,
  useGetFormationPlanningOne
} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjIconButton} from "@mbj-front-monorepo/ui";
import React, {useEffect, useState} from "react";
import {BsFillCalendarCheckFill, BsTrash} from "react-icons/bs";

interface WidgetInscriptionCtrlProps{
  className?:string;
  idEmployee:number;
  idActivite:number;
  listPlanification:{idEmployee:number, idActi:number}[];
}

const WidgetInscriptionCtrl = (props:WidgetInscriptionCtrlProps)=>{
  const [isPlannif, setIsPlannif] = useState(true);
  useEffect(() => {
    const my = props.listPlanification.filter(p=>p.idEmployee === props.idEmployee && p.idActi === props.idActivite)
    setIsPlannif(my.length>0)
  }, [props.listPlanification, props.idEmployee, props.idActivite]);
  const mutation = useAddFormationPlanning();
  const handleClickAdd = ()=>{
      const data:Operation<"postFormationPlanningCollection", "requestBody"> = {
        Employee:`api/employees/${props.idEmployee}`,
        Activite:`api/activites/${props.idActivite}`,
      }
      mutation.mutate(data)
  }

  return (
    <div className={`widget_inscription`}>
      <MbjIconButton
          size={"sm"}
          onClick={handleClickAdd}
          ariaLabel={"Programmer"}
          tooltip={!isPlannif ? "Programmer" : "Déjà programmé"}
          icon={<BsFillCalendarCheckFill />}
          themeColor={"third"}
          isRound={false}
          disabled={isPlannif}
      />
    </div>
  )
}

export const WidgetInscription = styled(WidgetInscriptionCtrl)``
