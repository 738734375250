import styled from "styled-components";
import {
    components,
    Operation,
    useGetAlliance,
    useGetAllianceMembers,
    useGetMemberMe
} from "@mbj-front-monorepo/data-access";
import {useParams} from "react-router-dom";
import {useEffect, useMemo, useState} from "react";
import {
    ItemMenuChoixOnPage,
    MbjErrorData,
    MbjLoading,
    MbjMenuChoixOnPage,
    MbjModal2,
    useModal
} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {CarAllianceMember} from "./components/CarAllianceMember";
import {AllianceInvitationsCurrent} from "./AllianceInvitationsCurrent";
import {ContentModalOneMembre} from "./components/ContentModalOneMembre";


interface OneAllianceMembresCtrlProps{
    className?:string;
}

const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Membres Actifs'},
    {id:1, libelle:'Membres Inactifs'},
]

const MenusMine:ItemMenuChoixOnPage[] = [...Menus, {id:2, libelle:"Invitations"}]

const OneAllianceMembresCtrl = (props:OneAllianceMembresCtrlProps)=>{
    const {id} = useParams();
    const allianceQuery = useGetAlliance(id ? parseInt(id) : 0);
    const MembresQuery = useGetAllianceMembers(id ? parseInt(id) : 0)
    const MeMember = useGetMemberMe();
    const [isMine, setIsMine] = useState(false);
    const [MyMenus, setMyMenus] = useState<ItemMenuChoixOnPage[]>(Menus);
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>(MyMenus[0])
    const [MembreCurrent, setMembreCurrent] = useState<Operation<"getMemberItem">|null>(null)
    const [AllianceMembreCurrent, setAllianceMembreCurrent] = useState<Operation<"getAllianceMemberItem">|null>(null);
    const {isShowing, toggle} = useModal();
    useEffect(() => {
        if(isMine){
            setMyMenus(MenusMine)
        } else {
            setMyMenus(Menus)
        }
    }, [isMine]);
    useEffect(() => {
        if(MeMember.data && allianceQuery.data){
            setIsMine((MeMember.data.id||0) === (allianceQuery.data?.Owner?.id||0) )
        }
    }, [MeMember.data, allianceQuery.data]);
    const listActif = useMemo(()=>{
        let list : (components["schemas"]["AllianceMember-read.allianceMember.collection_read.Member"])[] = [];
        if(MembresQuery.data){
            list = MembresQuery.data.filter(m=>m.isActive)
        }
        return list;
    },  [MembresQuery.data])
    const listInactif = useMemo(()=>{
        let list : (components["schemas"]["AllianceMember-read.allianceMember.collection_read.Member"])[] = [];
        if(MembresQuery.data){
            list = MembresQuery.data.filter(m=>!m.isActive)
        }
        return list;
    },  [MembresQuery.data])
    const ClickItem = (item:undefined|Operation<"getMemberItem">)=>{
        if(item){
            const myAM = MembresQuery.data?.find(m=>m.Member?.id === item.id);
            if(myAM) {
                setMembreCurrent(item);
                setAllianceMembreCurrent(myAM);
                toggle();
            }
        } else {
            setMembreCurrent(null);
        }
    }
    return (
        <div className={`alliance_membres ${props.className}`}>
            {MembresQuery.isLoading ? <MbjLoading/> : MembresQuery.isError ? <MbjErrorData/> :
                (
                    <>
                        <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={MyMenus}/>
                        <div className={"wrap_list"}>
                            {MenuCurrent.id === 0 ?
                                <div className={"list_active is_list"}>
                                    {listActif.map(item=>(
                                        <CarAllianceMember key={'Actif_'+item.id} AllianceMembre={item} setCurrent={ClickItem}/>
                                    ))}
                                </div>:
                                MenuCurrent.id === 1 ?
                                    <div className={"list_inactive is_list"}>
                                        {listInactif.map(item=>(
                                            <CarAllianceMember key={'Inactif_'+item.id} AllianceMembre={item} setCurrent={ClickItem}/>
                                        ))}
                                    </div>:
                                    <AllianceInvitationsCurrent/>
                            }
                        </div>
                    </>
                )
            }
            <MbjModal2 open={isShowing} closeMe={toggle} titre={"Membre"} Wd={"35%"}>
                {MembreCurrent && AllianceMembreCurrent &&
                    <ContentModalOneMembre isMine={isMine} Member={MembreCurrent} AllianceMembre={AllianceMembreCurrent}/>
                }
            </MbjModal2>
        </div>
    )
}

export const OneAllianceMembres = styled(OneAllianceMembresCtrl)`
    padding: 2%;
    height: 100%;
  display: flex;
  flex-direction: column;
  .wrap_list{
    flex-grow: 1;
    overflow-y: auto;
  }
  .is_list{
    display: flex;
    flex-wrap: wrap;
  }
`
