import styled, {keyframes} from "styled-components";
import {BaseColorsTheme} from "../../../assets/styles/ThemeType";
import * as React from "react";

interface MbjLineCompareCibleMobileCtrlProps{
    className?:string;
    idActivite:number;
    libelle:string;
    niveau:number;
    niveauCible:number;
    actionClick?:(id:number)=>void;
    colorNiveau:BaseColorsTheme;
    colorNiveauCible:BaseColorsTheme;
}

const MbjLineCompareCibleMobileCtrl = (props:MbjLineCompareCibleMobileCtrlProps)=>{
    const ClickLine = ()=>{
      if(props.actionClick){
        props.actionClick(props.idActivite);
      }
    }
    return (
        <div className={`wrap_line_compare ${props.className}`} onClick={ClickLine}>
            <div className={`libelle`}>
                <div className={`content_libelle`}>
                    <span>{props.libelle} ({props.niveau}/{props.niveauCible})</span>
                </div>
            </div>
            <div className={`wrapper_wrapper`}>
                <div className={`wrapper_niveau`}>
                    <div className={`wrappy_niveau_cible`} style={{width:(props.niveauCible/5*100)+"%"}}/>
                    <div className={`wrappy_niveau`} style={{width:(props.niveau/5*100)+"%"}}/>
                </div>
            </div>
        </div>
    )
}

const Progress = () => keyframes`
    from {
        width: 0;
    }

`;

export const MbjLineCompareCibleMobile = styled(MbjLineCompareCibleMobileCtrl)`
  border-top: solid ${props=>props.theme.greyLighten} 1px;
  padding-bottom: 8px;
  .libelle{
    font-size: 14px;
    font-weight: bold;
    color:${props=>props.theme.dark};
    width: 100%;
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 8px;
    .content_libelle{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 6px;
    }
  }
  .wrapper_wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
  .grid{
    display: flex;
    z-index: 2;
    position: absolute;
    left: 0;
    top:0;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    .grid_nivo{
      border-right: dashed ${props=>props.theme.grey} 1px;
      width: 20%;
    }
  }
  .wrapper_niveau{
    width: 100%;
    height: 20px;
    border-radius: 4px;
    background: #ccc;
    overflow: hidden;
    z-index: 3;
    position: absolute;
    left: 0;
    top:50%;
    transform: translateY(-50%);
  }
  .wrappy_niveau{
    position: absolute;
    left: 0;
    top: 0;
    height: 50%;
    background: ${props=>props.theme[props.colorNiveau]};
    transition: width 0.3s;
    animation: ${Progress} 1.5s ease-out;
    border-radius: 4px;
    &.invisible{
      opacity: 0;
    }
  }
  .wrappy_niveau_cible{
    position: absolute;
    left: 0;
    top: 50%;
    height: 50%;
    background: ${props=>props.theme[props.colorNiveauCible]};
    transition: width 0.3s;
    animation: ${Progress} 1.5s ease-out;
    border-radius: 4px;
    &.invisible{
      opacity: 0;
    }
  }
`
