import styled from "styled-components";
import React, { useRef, useState } from "react";
import {
  useAddFormationPlanning,
  useGetByCompetenceEmployeeNeedsForm,
  useGetRight,
} from "@mbj-front-monorepo/data-access";
import {
  MbjTableV2,
  MbjLoading,
  MbjCardClassic,
  MbjButton, MbjScrollArea, useModal, MbjModal2, MbjIconButton,
} from "@mbj-front-monorepo/ui";

import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import { BiCalendar, BiLineChartDown } from "react-icons/bi";
import { StatistiquesPriorisation } from "../Components/StatistiquesPriorisation";
import { PlanificationPriorisation } from "../Components/PlanificationPriorisation";
import { OneMonthForIn, TabMois } from "../../Plannification/by_planification/Plannification";

interface AnalyseByCompetenceCtrlProps{
  className?:string;
}

const AnalyseByCompetenceCtrl = (props:AnalyseByCompetenceCtrlProps) => {
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs")
  const empNeedsForm = useGetByCompetenceEmployeeNeedsForm()
  const mutationPost = useAddFormationPlanning()
  const {isShowing: isShowingDetail, toggle: toggleDetail} = useModal();
  const {isShowing: isShowingCalendar, toggle: toggleCalendar} = useModal();
  const TabMoisCpnt:OneMonthForIn[] = [];
  const refSelect = useRef<HTMLSelectElement>(null)
  const [moisChoix, setMoisChoix] = useState<OneMonthForIn|null>(null)
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const [info, setInfo] = useState({
    activite: '',
    activite_id:  0,
    id: 0,
    liste_ids_employes: '',
    nombre_employees: 0
  })
  const columnHelper = createColumnHelper<GetElementType<typeof empNeedsForm.data>>();

  const columns = [
    columnHelper.accessor((row: any) => row.activite, {
      header: "Compétence",
    }),
    columnHelper.accessor((row: any) => row.nombre_employes, {
      header: "Nombre de collaborateurs",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
      cell: (props: any) => <div className="btn-actions">
        <MbjIconButton
          size={"sm"}
          onClick={() => handleCalendar(props.row?.original)}
          ariaLabel={"Stats"}
          icon={<BiCalendar />}
          themeColor={"primary"}
          isRound={false}
        />
      </div>,
    }),
  ];
  const table = useReactTable({
    data: empNeedsForm?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })

  const handleCalendar = (item: any) => {
    toggleCalendar()
    setInfo({
      activite: item.activite,
      activite_id:  item.activite_id,
      id: item.id,
      liste_ids_employes: item.liste_ids_employes,
      nombre_employees: item.nombre_employees
    })
  }


  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  const handleChangeSelect = ()=> {
    if(refSelect.current){
      const myREfy = refSelect.current ? refSelect.current.value : '';
      const my = TabMoisCpnt.find(tm=>tm.date.toISOString() === myREfy)
      if(my) {
        setMoisChoix(my)
      }
    }
  }
  const handleSubmit = ()=>{
    const IDs = info.liste_ids_employes.split(',');
    const empIDs = IDs.map(Number)
    const date = !moisChoix ? null : new Date(moisChoix.date.toISOString().slice(0, 10) + "T12:00:00").toISOString();
    for (const empID of empIDs) {
      const data: any = {
        souhaitAt: date,
        Employee: `/api/employees/${empID}`,
        Activite: `/api/activites/${info.activite_id}`,
        is_diffusable: false,
      }
      mutationPost.mutate(data, {
        onSuccess:()=> toggleCalendar()
      })
    }
    //const date = !moisChoix ? null : moisChoix.date.toISOString().slice(0,10);
    /*const data = table.getSelectedRowModel().rows.map((row: any) => ({
      Employee: `/api/employees/${row.original.empID}`,
      Activite: `/api/activites/${row.original.actID}`,
      souhaitAt: date,
      is_diffusable: false,
    }))
    addManyFormationPlanning.mutate(data, {
      onSuccess: () => {
        toggleAllPlanification()
        table.toggleAllRowsSelected(false);
      }
    })*/
  }



  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {empNeedsForm.isLoading ? (
        <MbjLoading />
      ) : empNeedsForm.isError ? (
        <p>Error: {empNeedsForm.error.message}</p>
      ) : (
        <>
          <MbjScrollArea overflowX={"auto"}>
            <MbjTableV2 table={table} Multiple={true}/>
          </MbjScrollArea>
        </>
      )}

      <MbjModal2 open={isShowingDetail} closeMe={toggleDetail} titre={"Informations détaillés"} Wd={"80%"}>
        <div style={{padding:"0 1rem", height:"max-content"}}>
          <StatistiquesPriorisation info={info} />
        </div>
      </MbjModal2>
      <MbjModal2 open={isShowingCalendar} closeMe={toggleCalendar} titre={"Planification"} Wd={"80%"}>
        <div style={{ padding: "0 1rem", height: "max-content" }}>
          <MbjCardClassic title="Programmer une formation" basis={"100%"}>
            <div className="blk-plan">
              <select className="plprg_select" ref={refSelect} value={moisChoix ? moisChoix.date.toISOString() : ""}
                      onChange={handleChangeSelect}>
                {TabMoisCpnt.map((item, idx) => (
                  <option value={item.date.toISOString()}
                          key={`choice_date_nopl${idx}`}>{item.month.libShort} {item.date.getFullYear()}</option>
                ))}
              </select>
              <MbjButton size={"md"} onClick={() => handleSubmit()} isPending={mutationPost.isLoading}>
                Planifier
              </MbjButton>
            </div>
          </MbjCardClassic>
        </div>
      </MbjModal2>
    </div>

  )
}
export const AnalyseByCompetence = styled(AnalyseByCompetenceCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
  }

  .plprg {
    margin-bottom: 0.5rem;
  }

  .plprg_select {
    display: block;
    margin: 20px 10px 20px 0;
  }
`
;
