import * as React from "react";

import {
  MbjCardClassic,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic,
  MbjTable, MbjLabel, MbjButton,
} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {
  Operation, useAddFormationPlanning,
  useGetCheckFormationForEmployeeNeedsForm,
} from "@mbj-front-monorepo/data-access";
import { useRef, useState } from "react";
import { OneMonthForIn, TabMois } from "../../Plannification/by_planification/Plannification";

interface PlanificationPriorisationCtrlProps {
  className?: string;
  info: any
  onToggleCalendar: any
}


function PlanificationPriorisationCtrl(props: PlanificationPriorisationCtrlProps) {
  const TabMoisCpnt:OneMonthForIn[] = [];
  const refSelect = useRef<HTMLSelectElement>(null)
  const [moisChoix, setMoisChoix] = useState<OneMonthForIn|null>(null)
  const today = new Date();
  const firstDate = new Date(today.getFullYear(), today.getMonth(), 1);
  const mutationPost = useAddFormationPlanning()
  for(let i=0; i<12; i++) {
    const DateDecale = new Date(firstDate.getTime() + 86400000 * i * 32);
    const ThisDate = new Date(DateDecale.getFullYear(), DateDecale.getMonth(), 1);
    const monthThis = DateDecale.getMonth();
    const MyMonth = TabMois.find(m=>m.num === monthThis);
    if(MyMonth){
      TabMoisCpnt.push({
        month:MyMonth,
        date:ThisDate
      })
    }
  }
  const handleChangeSelect = ()=> {
    if(refSelect.current){
      const myREfy = refSelect.current ? refSelect.current.value : '';
      const my = TabMoisCpnt.find(tm=>tm.date.toISOString() === myREfy)
      if(my) {
        setMoisChoix(my)
      }
    }
  }

  const handleSubmit = ()=>{
    const date = !moisChoix ? null : moisChoix.date.toISOString().slice(0,10);
    const data: any = {
      souhaitAt: date,
      Employee: `/api/employees/${props.info.empID}`,
      Activite: `/api/activites/${props.info.actID}`,
      is_diffusable: false,
    }
    mutationPost.mutate(data, {
      onSuccess:()=> {
        console.log('traité')
        props.onToggleCalendar()
      }
    })
  }

  const fempQuery = useGetCheckFormationForEmployeeNeedsForm(props.info.empID, props.info.actID)

  return (
    <div className={"PriorisationDetail" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Planifier "}>
          <MbjCardClassic title="Informations" basis={"100%"}>
            <div className="Nom">
              <MbjLabel>Nom et prénom du collaborateur </MbjLabel>
              <div className="data">{props.info.nom + ' ' + props.info.prenom }</div>
              <MbjLabel>Activité </MbjLabel>
              <div className="data">{props.info.activite }</div>
              <MbjLabel>Service </MbjLabel>
              <div className="data">{props.info.service }</div>
              <MbjLabel>Métier </MbjLabel>
              <div className="data">{props.info.namePerso }</div>
            </div>
          </MbjCardClassic>
          <MbjCardClassic title="Programmer une formation" basis={"100%"}>
            {fempQuery.isLoading ? <MbjLoading /> : fempQuery.isSuccess &&
                <div className="blk-plan">
                  <MbjLabel className="plprg">Programmer une formation</MbjLabel>
                  <select className="plprg_select" ref={refSelect} value={moisChoix ? moisChoix.date.toISOString() : ""} onChange={handleChangeSelect}>
                    {TabMoisCpnt.map((item, idx) => (
                      <option value={item.date.toISOString()} key={`choice_date_nopl${idx}`}>{item.month.libShort} {item.date.getFullYear()}</option>
                    ))}
                  </select>
                  <MbjButton size={"sm"} onClick={() => handleSubmit()} isPending={mutationPost.isLoading}>
                    Planifier
                  </MbjButton>
                </div>
            }

          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}

export const PlanificationPriorisation = styled(PlanificationPriorisationCtrl)((props) => ({
  padding: "1% 2%",
   ".blk-plan": {
    margin: '1rem 0'
   },
  ".plprg": {
    marginBottom: ".5rem"
  },
  ".plprg_select": {
    marginRight: "10px",
  },
  ".Infos": {
    padding: "0.5em 1em",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
