import styled from "styled-components";
import {ReactNode} from "react";
import {ZoneSearch} from "./ZoneSearch";
import * as React from "react";
import {
  MbjButton
} from "@mbj-front-monorepo/ui";
import { ZoneCompetenceSearch } from "./ZoneCompetenceSearch";

interface WrapResultBesoinCtrlProps{
    className?:string;
    children:ReactNode;
    search?:string;
    setSearch:(s:string)=>void;
    onCheckAllHandler? : any
    btnTitle? : string
    compItems?: number[];
    onSendEmps? : any
    alert?: string
    dropList?: any
}

const errors = {}

const WrapResultBesoinCtrl = ({className, children, search, setSearch, onCheckAllHandler, btnTitle, compItems, onSendEmps, alert, dropList}:WrapResultBesoinCtrlProps)=>{


  return (
      <div className={`wrapper_result ${className}`}>
        {/*<ZoneSearch search={search} setSearch={setSearch} />*/}
        <ZoneCompetenceSearch dropList={dropList} search={search} setSearch={setSearch} />
        {onCheckAllHandler &&
          <div className="ckbtn">
            <MbjButton size="md" themeColor="grey" onClick={onCheckAllHandler}>{btnTitle}</MbjButton>
            {compItems && compItems.length > 0 &&
              <MbjButton size="md" themeColor="primary" onClick={() => onSendEmps(compItems)}>Ajouter à l'analyse et
                priorisation</MbjButton>}
          </div>}
        {alert && <div className="alert-ms">{alert}</div>}
        <div className={`wrap_list`}>
          {children}
        </div>
      </div>
    )
}

export const WrapResultBesoin = styled(WrapResultBesoinCtrl)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  .wrap_list {
    padding: 0 12px;
    flex-grow: 1;
    overflow-y: auto;
  }

  .wrap_list {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .ckbtn {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    margin: 10px 0;
  }

  .alert-ms {
    text-align: center;
    color: #fff;
    background: #396c71;
    font-size: 15px;
    padding: 1em 0;
    line-height: 5px;
    border-radius: 4px;
    margin: 1em 0;
  }
`
