import styled from "styled-components";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {Route, To, Routes, Navigate, useParams} from "react-router-dom";
import {useGetMemberMe, useGetParticulier, useGetSearchOne} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {DiffusionAllianceCV} from "./components/DiffusionAllianceCV";
import {CompetencesOneCvTheque} from "./04_Competences/CompetencesOneCvTheque";
import {DashboardOneCvTheque} from "./01_Dashboard/DashboardOneCvTheque";
import {SettingInfosCvTheque} from "./02_SettingInfo/SettingInfosCvTheque";
import {CvOneCvTheque} from "./03_CV/CvOneCvTheque";
import {CompatibiliteOneCvTheque} from "./05_Compatibilites/CompatibiliteOneCvTheque";

interface OneCvThequeRooterCtrlProps {
    className?:string;
}
const TabSubMenus:{libelle:string, to:To, isMine:boolean}[] = [
    {libelle: 'Tableau de bord', to:"dashboard", isMine:false},
    {libelle: 'Informations', to:"setting_info", isMine:true},
    {libelle: 'cv', to:"cv", isMine:true},
    {libelle: 'Competences', to:"competences", isMine:false},
    {libelle: 'Compatibilités Métiers entreprise', to:"compatibilites", isMine:false},
]


const OneCvThequeRooterCtrl = ({className}:OneCvThequeRooterCtrlProps)=>{
    const { id } = useParams();
    const particulierQuery = useGetParticulier(parseInt(id || "-1"));
    const [isMine, setIsMine] = useState(false);
    const memberQuery = useGetMemberMe();
    useEffect(() => {
        if(memberQuery.data && particulierQuery.data){
            console.log(particulierQuery.data.MemberId === memberQuery.data.id);
            setIsMine(particulierQuery.data.MemberId === memberQuery.data.id)
        }
    }, [memberQuery.data, particulierQuery.data]);
    return (
        <div className={`one_recherche_rooter ${className}`}>
            <Routes>
                <Route
                    element={
                        <InternSubDesktopLayout
                            Links={!isMine ? TabSubMenus.filter(t=>t.isMine === false) : TabSubMenus}
                            contentContext={
                            <div style={{padding:"0.5rem 0", fontSize:"18px", marginBottom:"15px"}}>Details : <span style={{fontWeight:"bold"}}>{particulierQuery.isLoading ? '...' : particulierQuery.data ? `${particulierQuery.data.prenom} ${particulierQuery.data.nom}` : 'nc'}</span></div>}
                        />
                    }
                >
                    <Route path="" element={<Navigate to="dashboard" />} />
                    <Route path="/dashboard" element={<DashboardOneCvTheque />} />
                    <Route path="/setting_info" element={<SettingInfosCvTheque />} />
                    <Route path="cv" element={<CvOneCvTheque />} />
                    <Route path="/competences/*" element={<CompetencesOneCvTheque />} />
                    <Route path="/compatibilites" element={<CompatibiliteOneCvTheque />} />
                </Route>
            </Routes>
        </div>
    )
}

export const OneCvThequeRooter = styled(OneCvThequeRooterCtrl)`
    height: 100%;
`
