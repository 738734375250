import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  id: number,
  name: string,
  metier_libelle?: string
  um_libelle?: string
  score?: number
}

interface ListeEntrepriseAllianceCtrlProps {
  className?: string;
  item: ITemTypes
}


function ListeEntrepriseAllianceCtrl(props: ListeEntrepriseAllianceCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.name}</td>
      <td className="isCenter">{props.item?.metier_libelle ? props.item?.metier_libelle : props.item?.um_libelle}</td>
      <td className="isCenter">{props.item?.score !== undefined ? Math.floor(props.item?.score * 100) : ''}</td>
    </tr>
  );
}

export const ListeEntrepriseAlliance = styled(ListeEntrepriseAllianceCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
