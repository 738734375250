import styled from "styled-components";
import {MbjCardBack, MbjSwitch, MbjSwitchThumb} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {useGetEmployee, useMutateEmployee} from "@mbj-front-monorepo/data-access";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";

interface MobiliteCollabSwitchCtrlProps{
    className?:string;
    readonly:boolean;
}

const MobiliteCollabSwitchCtrl = (props:MobiliteCollabSwitchCtrlProps)=>{
    const { id } = useParams();
    const mutationChange = useMutateEmployee(parseInt(id || "-1"))
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if(employeeQuery.data){
            const Employee = employeeQuery.data;
            const isMobile = Employee.isMobilite ? Employee.isMobilite === true : false;
            setIsMobile(isMobile)
        }
    }, [employeeQuery.data]);
    return (
        <MbjCardBack title={"Mobilité du collaborateur"}>
            <div className={`mobilite_switch ${props.className}`}>
                <span>{isMobile ? "Le collaborateur est déclaré en mobilité" : "Le collaborateur n'est pas déclaré en mobilité"}</span>
                {!props.readonly &&
                <MbjSwitch
                    defaultChecked={isMobile}
                    onCheckedChange={() => mutationChange.mutate({isMobilite:!isMobile})}
                >
                    <MbjSwitchThumb />
                </MbjSwitch>
                }
            </div>
        </MbjCardBack>
    )
}

export const MobiliteCollabSwitch = styled(MobiliteCollabSwitchCtrl)`
    display: flex;
    justify-content: flex-start;
  align-items: center;
  gap: 10px;
`
