import styled from "styled-components";
import {MbjCardBack, MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { NavLink } from "react-router-dom";

interface ObjectifSectionCollabCtrlProps{
  className?:string
}

const ObjectifSectionCollabCtrl = (props:ObjectifSectionCollabCtrlProps)=>{
  return (
    <MbjCardBack title={"Objectifs de la section Collaborateurs"}>
      <div className={`obj ${props.className}`}>
        <p>Depuis l’onglet « Mes collaborateurs », renseignez les coordonnées et le métier de l’entreprise d’un salarié,
          puis décrivez son CV afin que My-Better-Job le traduise en portefeuille de compétences. Il proposera ensuite
          l’analyse des mobilités internes et externes possibles et repérera les besoins de professionnalisation du
          salarié pour occuper pleinement son poste actuel.</p>
        <br />
        <p>La page d’accueil « Informations » synthétise les données-clés du collaborateur et permet de lui attribuer
          des « badges », reconnaissant ses responsabilités et ses capacités (expert, formateur interne,
          manager-évaluateur).</p>
        <br />
        <p>Ce dossier Collaborateurs propose également un support d’entretien périodique, couvrant l’évaluation des
          compétences et les possibilités d’évolution. Cette étape débouche sur un processus de validation par signature
          électronique.</p>
        <br />
        <p>L’onglet « Besoins de formation » cumule l’ensemble des écarts entre les compétences des collaborateurs et
          leurs fiches de postes. Il permet d’analyser et de trier ces écarts et de planifier les formations.</p>
        <br />
        <p>Enfin, « Pilotage RH » produit des indicateurs d’aide à la gestion et à l’analyse des compétences des
          collaborateurs.</p>
        <br />
        <NavLink className="lk" to="/app/entreprise/neo4j/collaborateur" style={{textDecoration: 'none', color: '#fff'}}>
          <MbjButton>Distribution de l'âge de vos collaborateurs</MbjButton>
        </NavLink>
      </div>
    </MbjCardBack>
  )
}

export const ObjectifSectionCollab = styled(ObjectifSectionCollabCtrl)`
`
