import styled from "styled-components";
import React, { useEffect, useState } from "react";
import {GetElementType, useDebounce} from "@mbj-front-monorepo/utils";
import {
  useGetEmployeesByActivity,
  useSearchActivites,
} from "@mbj-front-monorepo/data-access";
import {
  MbjButton,
  MbjListItem,
  MbjLoading,
  MbjScrollArea,
  MbjSearchBar,
  MbjSimpleInputContainer, useModal,
} from "@mbj-front-monorepo/ui";
import {useForm} from "react-hook-form";
import { ResultComp } from "./ResultComp";
import { ModalSendToEquipe } from "./ModalSendToEquipe";

interface SearchActivitesSearchCompCtrlProps{
    className?:string;
    onSearch:(search:boolean)=>void;
}

interface Employee {
  id: number;
  nom: string;
  prenom: string;
  salary?: number;
  entryAt?: string;
  email?: string;
  codePostal?: string;
  Max_niveau: number
}
 type ActInfo = {
    id: number,
    libelle: string,
    fullLibelle: string
 }

const SearchActivitesSearchCompCtrl = ({className, onSearch}:SearchActivitesSearchCompCtrlProps)=>{
    const {isShowing, toggle} = useModal();
    const [activity, setActivity] = useState('');
    const [alert, setAlert] = useState('');
    const [search, setSearch] = useState("");
    const [myStore, setMyStore] = useState<ActInfo[]>([]);
    const debouncedSearch = useDebounce(search, 500);
    const { isLoading, isError, data: activites } = useSearchActivites(debouncedSearch);
    const [resetting, setReseting] = useState(false)
    const employeeByActivity = useGetEmployeesByActivity(activity)
    const {
        register,
        formState: { errors },
    } = useForm();
  useEffect(() => {
    if (alert) {
      const timeout = setTimeout(() => setAlert(''), 5000)
      return () => clearTimeout(timeout)
    }

  }, [alert]);
  const [selectedItems, setSelectedItems] = useState<number[]>([]);

  const ClickOnActi = (acti:GetElementType<typeof activites>)=>{

      const idExists = myStore.some(item => item.id === acti.id);
      if (acti.id && acti.libelle && !idExists) {
            setMyStore([...myStore, {id: acti.id, libelle: acti.libelle.substring(0, 15) + '...', fullLibelle: acti.libelle}])
      }
    }

    const handleUnlinkStore =  (id: number) => {
        setMyStore(myStore.filter((activity: ActInfo) => activity.id !== id))
    }

    const handleSearch = () => {
      if (myStore.length === 0) {
        return
      }
      const data = []
      for (const store of myStore) {
        data.push(store.id)
      }
      const param = data.join(',')
      setSearch('')
      setActivity(param)
      onSearch(true)
    }

    const handleReset = () => {
      setMyStore([])
      setActivity('')
      onSearch(false)
    }

  function HandlerCheckBox(e: React.ChangeEvent<HTMLInputElement>){
    const isSelected = e.target.checked;
    const value = parseInt(e.target.value);

    if( isSelected ){
      setSelectedItems( [...selectedItems, value ] )
    }else{
      setSelectedItems((prevData)=>{
        return prevData.filter((id)=>{
          return id!==value
        })
      })
    }
  }

  function checkAllHandler(){
   if(selectedItems.length === employeeByActivity?.data?.length){
     setSelectedItems([])
     return
    }
    const empIDs = employeeByActivity?.data.map((emp: Employee) => emp.id);
    setSelectedItems(empIDs)
  }


  const btnTitle = selectedItems.length === employeeByActivity?.data?.length ? 'Déselectionner' : 'Tout sélectionner'

    return (
        <div className={`search_acti ${className}`} >
          {myStore.length > 0 &&
            <div className="box-search">
              <div className="main-bxact">
                {myStore.map((activity: ActInfo) => <a title={activity.fullLibelle} key={activity.id} className="bx-activity" onClick={() => handleUnlinkStore(activity.id)}>{activity.libelle}</a>)}
              </div>
            </div>
          }
          {myStore.length > 0 && <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <MbjButton size="md" themeColor="warning" onClick={handleReset}>Réinitialiser </MbjButton>
            <MbjButton size="md" onClick={handleSearch}>Rechercher </MbjButton>
          </div>}
          <div className={"zoneSearch"} style={{ paddingTop: "24px" }}>
            <MbjSimpleInputContainer errors={errors} direction={"column"}>
              <MbjSearchBar
                id={"sarchComp"}
                placeholder={"Rechercher une compétence"}
                className={"SearchBar"}
                onChange={(event) => setSearch(event.target.value)}
                resetting={resetting}
              />
            </MbjSimpleInputContainer>
            {search !== "" &&
              <div className="resultats">
                {search === "" ? (
                  <React.Fragment />
                ) : isLoading ? (
                  <MbjLoading />
                ) : isError ? (
                  "Errors"
                ) : activites.length === 0 ? (
                  <h1>Aucun résultat trouvé </h1>
                ) : (
                  <>
                    <MbjScrollArea maxHeight={"390px"}>
                      {activites?.slice(0, 10).map((activite, idx: number) => (
                        <MbjListItem className="one-choice-activite" onClick={() => ClickOnActi(activite)}
                                     key={idx}>
                          {activite.libelle}
                        </MbjListItem>
                      ))}
                    </MbjScrollArea>

                  </>
                )}

              </div>
            }
          </div>
          {employeeByActivity.isFetching && <MbjLoading />}
          {employeeByActivity.isSuccess && employeeByActivity?.data?.length === 0 && <h1>Aucun résultat trouvé</h1>}
          {employeeByActivity.isSuccess && employeeByActivity?.data?.length > 0 && <div className="btnchkAll">
            <h1>Liste des collaborateurs </h1>
            <div className="blockbtnchk"><MbjButton size="md" themeColor="grey" onClick={checkAllHandler}>{btnTitle}</MbjButton></div>
            {selectedItems.length > 0 &&
              <div className="blockbtnchk"><MbjButton size="md" themeColor="primary" onClick={toggle}>Ajouter à une
                équipe</MbjButton></div>}
          </div>}
          {alert && <div className="alert-ms">{alert}</div>}
          {employeeByActivity.isSuccess && employeeByActivity?.data?.length > 0 && employeeByActivity?.data?.map((emp: Employee) => {
            const fullname = emp.nom + " " + emp.prenom

            return <ResultComp
              key={emp.id}
              libelle={fullname}
              niveau={emp.Max_niveau}
              to={`/app/collaborateurs/${emp.id}/informations`}
              id={emp.id}
              selectedItems={selectedItems}
              onCheckboxHandler={HandlerCheckBox}
            />
          })}
          <ModalSendToEquipe isShowing={isShowing} hide={toggle} selectedItems={selectedItems} onSetSelectedItems={setSelectedItems} onSetAlert={setAlert}/>
        </div>
    )
}

export const SearchActivitesSearchComp = styled(SearchActivitesSearchCompCtrl)`
  margin-bottom: 20px;
  .bx-activity:hover {cursor: pointer;}
  .bx-activity {
    padding: .3em 1.5em .3em .5em;
    border: 1px solid #07172d;
    margin-right: .5em;
    border-radius: 4px;
    background: #07172d;
    color: #fff;
    flex-wrap: wrap;
    margin-bottom: 5px;
    display: block;
  }
  .main-bxact {display: flex; justify-content: flex-start;margin-bottom: 10px;flex-wrap: wrap;}
  .zoneSearch {
    position: relative;
  }

  .blockbtnchk {
    align-self: center;
  }

  .btnchkAll {
    display: flex;
    justify-content: space-between;
  }

  .box-search {
    height: 90px;
    border-radius: 4px;
    border: 1px solid #07172d;
    background: #fff;
    padding: 8px;
    margin-bottom: 10px;
    overflow-y: scroll;
  }
  h1{
    font-size: 18px;
    margin: 20px 0;
  }

  .resultats {
    position: absolute;
    left: 0;
    top: 100%;
    background: white;
    padding: 0.2rem;
    z-index: 2;
    width: 100%;
    box-shadow: 1px 1px 3px rgba(0,0,0,.25);
  }
  .alert-ms {
    text-align: center;
    color: #fff;
    background: #396c71;
    font-size: 15px;
    padding: 1em 0;
    line-height: 5px;
    border-radius: 4px;
    margin: 1em 0;
  }
`
