import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {createContext, useContext, useEffect, useState} from "react";
import * as React from "react";
import {OneFamilleR} from "./OneFamilleR";
import {ListFamilleR} from "./ListFamilleR";
import {CompetencesLayoutR} from "./CompetencesLayoutR";
import {CompetenceRooterContext} from "../CompRooter";
import {MyDomaine, MyFamille} from "../CompetencesInterfaces";


interface OneDomaineRCtrlProps{
    className?:string;
}

interface ContextDomaine{
    Familles:MyFamille[];
    Domaine:MyDomaine|null;
}

export const DomaineContext = createContext<ContextDomaine>({Familles:[], Domaine:null})

const OneDomaineRCtrl = (props:OneDomaineRCtrlProps)=>{
    const {idDomaine} = useParams();
    const Context = useContext(CompetenceRooterContext)
    const [Domaine, setDomaine] = useState<MyDomaine|null>(null)
    useEffect(() => {
        if(Context && idDomaine){
            const dom = Context.Domaines.find(d=>d.id+""===idDomaine);
            if(dom){
                setDomaine(dom);
            }
        }
    }, [idDomaine, Context]);
    return (
        <DomaineContext.Provider value={{Familles:Domaine ? Domaine.Familles : [], Domaine:Domaine ? Domaine : null}}>
            <Routes>
                <Route element={
                    <CompetencesLayoutR link={{link:'../', libelle:Domaine ? Domaine.libelle : '', niveau : Domaine ? Domaine.Niveau : 0}}/>}>
                    <Route path="" element={<ListFamilleR/>} />
                    <Route path="/:idFamille/*" element={<OneFamilleR/>} />
                    <Route path="*" element={<p>pas troue</p>} />
                </Route>
            </Routes>
        </DomaineContext.Provider>
    )
}

export const OneDomaineR = styled(OneDomaineRCtrl)`
  padding: 1% 2%;
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .title_link{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 15px 0;
  }
`
