import styled from "styled-components";
import {Operation, useGetOFAutoFormation} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjLoading, MbjModal, MbjNoResult, useModal} from "@mbj-front-monorepo/ui";
import React, {useState} from "react";
import {CoordOF} from "../Competences/Components/CoordOF";

interface FormationsSuiviCtrlProps{
    className?:string;
    employee: Operation<"getEmployeeItem">;
}

export interface orgaFormation{
    siret:string;
    email:string;
    adresse:string;
    codePostal:string;
    tel:string;
    contact:string;
    nom:string;
    ville:string;
}

const FormationsSuiviCtrl = (props:FormationsSuiviCtrlProps)=>{
    if (!props.employee.id) throw new TypeError("employee.id is undefined");
    const OfQuery = useGetOFAutoFormation(props.employee.id)
    const { isShowing, toggle } = useModal();
    const [ofCurr, setOfCurr] = useState<orgaFormation|null>(null)
    const ClickOF = (OF:any)=>{
        setOfCurr({
            siret:OF.siret||'nc',
            email:OF.email||'nc',
            adresse:OF.adresse||'nc',
            codePostal:OF.Zipcode||'nc',
            tel:OF.tel||'nc',
            contact:OF.contact||'nc',
            nom:OF.rs,
            ville:OF.ville
        })
        toggle();
    }
    return (
        <div className={`formations_suivi ${props.className}`}>
            {OfQuery.isLoading ? (
                <MbjLoading />
            ) : OfQuery.isError || !OfQuery.data ? (
                <p>Erreur ...</p>
            ) : (
                <>
                    {OfQuery.data.OFActivites &&
                        OfQuery.data.OFActivites.map((item, idx)=>(
                            <MbjCardBack  key={`one_of_form${idx}`}>
                                <div className={`one_of_form`}>
                                    <div className={`titre_of`} onClick={()=>ClickOF(item.organismeFormation)}>{item.organismeFormation ? (item.organismeFormation.rs || 'nc') : 'nc' } <span className={"distance"}>({item.distance}km)</span></div>
                                    <div className={`wrap-list-fc`}>
                                        {item.formationsCourtesForm?.map((fcc, idxFFC:number)=>(
                                            <div className={`one_formation_fcc`} key={`Form_${idx}_${idxFFC}`}>
                                                <div className={`titre_fcc`}>{fcc.formationContinuCourtes?.libelle}</div>
                                                <div className={`wrap_list_activite_form`}>
                                                    {fcc.activites?.map((activite, idxA)=>(
                                                        <div className={`one_acti_form`} key={`acti_form_${idx}_${idxFFC}_${idxA}`}>{activite.libelle}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </MbjCardBack>
                        ))
                    }
                    {OfQuery.data?.OFActivites?.length === 0 &&
                        <MbjNoResult text={"Aucune formation trouvées"}/>
                    }
                    <MbjModal hide={toggle} isShowing={isShowing} title="Coordonnées organisme formation">
                        <div className={`wrap_coord`}>
                            <CoordOF titre={"Nom"} infos={<span>{ofCurr?.nom}</span>}/>
                            <CoordOF titre={"Adresse"} infos={<span>{ofCurr?.codePostal} {ofCurr?.codePostal}</span>}/>
                            <CoordOF titre={"Téléphone"} infos={<span>{ofCurr?.tel}</span>}/>
                            {ofCurr && ofCurr.email!=='nc' &&
                                <CoordOF titre={"Email"} infos={<div className={`link_mail`} onClick={(e) => {window.location.href ='mailto:'+ofCurr?.email;}}>{ofCurr.email}</div>}/>
                            }
                        </div>
                    </MbjModal>
                </>
            )
            }
        </div>
    )
}

export const FormationsSuivi = styled(FormationsSuiviCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  .titre_of{
    padding: 0.5rem 0;
    font-weight: bold;
    color:${props => props.theme.primary};
    font-size: 16px;
    .distance{
      color: black;
    }
  }
  .wrap-list-fc{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    .titre_fcc{
      font-weight: bold;
    }
    .one_acti_form{
      padding-left: 12px;
    }
  }

`
