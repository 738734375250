import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import DynamiqueEffectifDashboard from "./Dahboard/DynamiqueEffectifDashboard";
import {RecrutementsRooter} from "./Recrutements/RecrutementsRooter";
import {Reclassements} from "./Reclassements/Reclassements";
import ChiffresCles from "./ChiffresCles/ChiffresCles";

export function DynamiqueEffectifRooter(){
    return (
        <Routes>
            <Route path="" element={<Navigate to="dashboard" />} />
            <Route path={"dashboard"} element={<DynamiqueEffectifDashboard/>}/>
            <Route path={"recrutements/*"} element={<RecrutementsRooter/>}/>
            <Route path={"reclassements"} element={<Reclassements/>}/>
            <Route path={"chiffrecles"} element={<ChiffresCles/>}/>
        </Routes>
    )
}
