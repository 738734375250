import styled from "styled-components";
import {MbjCardBack, MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetRepartitionMetiers} from "@mbj-front-monorepo/data-access";

interface RepartitionMetiersCtrlProps{
    className?:string;
}

const RepartitionMetiersCtrl = (props:RepartitionMetiersCtrlProps)=>{
    const { isLoading, isError, data: repartitions } = useGetRepartitionMetiers();
    return (
      <MbjCardBack height={"100%"}>
            <div className={`repartition_metier ${props.className}`}>
                {isLoading ?
                    <MbjLoading/>:
                    (
                        <div className={`wrapper_repart`}>
                            {repartitions?.sort((a,b)=>(a.taux||0) > (b.taux||0) ? -1 : 1).map((item, idx)=>(
                                <div className={`wrapper_metier`} key={`one_Met${idx}`}>
                                    <div className={`slidy_repart`} style={{width: `${(item.taux || 0) * 100}%`}}/>
                                    <div className="libelleMetier"><span>{item.champPro} ({item.nbOccurence})</span></div>
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
        </MbjCardBack>
    )
}

export const RepartitionMetiers = styled(RepartitionMetiersCtrl)`
  .wrapper_repart{
    border-left: solid ${props => props.theme.primary} 4px;
    padding-left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 3px;
    height: 100%;
    overflow-y: auto;
    max-height: 410px;
  }
  .wrapper_metier{
    position: relative;
    height: 26px;
    flex-shrink: 0;
    .slidy_repart{
      background: ${props=>props.theme.thirdPastel};
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
    .libelleMetier{
      position: absolute;
      z-index: 2;
      left: 10px;
      width: calc(100% - 10px);
      overflow: hidden;
      font-size: 13px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      span{
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
        overflow: hidden
      }
    }
  }
`
