import styled from "styled-components";
import {components, useGetAllSearchByMember} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {ItemMenuChoixOnPage, MbjChoixMenu, MbjLoading, MbjMenuChoixOnPage, MbjModal2} from "@mbj-front-monorepo/ui";
import {Compare} from "../../../../components/Comparateur/ComparaisonRef/Compare";
import {ProfilRetenusList} from "./ProfilRetenusList";
import * as React from "react";

interface ProfilsRetenusCtrlProps{
    className?:string;
}

export interface ItemMenuResult{
    id:number;
    libelle:string;
}

const MesMenusResult:ItemMenuResult[]=[
    {id:1, libelle:"Metiers internes"},
    {id:2, libelle:"Autres Metiers"},
]

const Menus:ItemMenuChoixOnPage[] = [
    {id:1, libelle:"Metiers internes"},
    {id:2, libelle:"Autres Metiers"},
]

const ProfilsRetenusCtrl = (props:ProfilsRetenusCtrlProps)=>{
    const SearchQuery = useGetAllSearchByMember();
    const [MyMenus, setMyMenus] = useState<ItemMenuChoixOnPage[]>(Menus)
    const [menuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>(MyMenus[0])
    const [openCompare, setOpenCompare] = useState(false);
    const [resultEmpCurr, setResultEmpCurr] = useState<components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"]|null>(null);
    const [resultPartCurr, setResultPartCurr] = useState<components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|null>(null)
    const [resultReclCurr, setResultReclCurr] = useState<components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|null>(null)
    const listSearchUserMetier = useMemo(() => {
        if (!SearchQuery.data) {
            return [];
        } else {
            return SearchQuery.data.filter((s) => !!s.UserMetier);
        }
    }, [SearchQuery.data]);
    const listSearchMetier = useMemo(() => {
        if (!SearchQuery.data) {
            return [];
        } else {
            return SearchQuery.data.filter((s) => s.Metier);
        }
    }, [SearchQuery.data]);
    useEffect(() => {
        setMyMenus(Menus.map(i=>{
            return i.id === 1 ? {...i, libelle:i.libelle+ " ("+listSearchUserMetier.length+")"} :  {...i, libelle:i.libelle+ " ("+listSearchMetier.length+")"}
        }))
    }, [listSearchUserMetier, listSearchMetier]);

    const getNbResult = (id:number)=>{
        if(id === 1){
            return listSearchUserMetier.length;
        }
        return listSearchMetier.length;
    }
    const switchCompare = ()=>{
        setOpenCompare(o=>!o);
    }
    const ClickCompare = (type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>{
        console.log('CLICK comp')
        if(type === 'Emp'){
            setResultEmpCurr(result);
            setResultPartCurr(null);
            setResultReclCurr(null);
            switchCompare();
        } else if(type === 'Part'){
            setResultPartCurr(result);
            setResultEmpCurr(null);
            setResultReclCurr(null);
            switchCompare();
        } else {
            setResultPartCurr(null);
            setResultEmpCurr(null);
            setResultReclCurr(result);
            switchCompare();
        }
    }
    return (
        <div className={`profils_retenus ${props.className}`}>
            <div className={"content"}>
                {SearchQuery.isLoading ? (<MbjLoading/>) : SearchQuery.isError ? 'Error data' :
                    (
                        <>
                            <MbjMenuChoixOnPage current={menuCurrent} setChoix={setMenuCurrent} Items={MyMenus}/>
                            <div className={"wrap_result"}>
                                {menuCurrent?.id === 1 ?
                                    <ProfilRetenusList searchs={listSearchUserMetier} clickCompare={ClickCompare}/>:
                                    <ProfilRetenusList searchs={listSearchMetier} clickCompare={ClickCompare}/>
                                }
                            </div>
                        </>
                    )
                }
            </div>
            <MbjModal2 open={openCompare} closeMe={switchCompare} titre={"Comparaison"}>
                <Compare
                    SearchEmp={resultEmpCurr||undefined}
                    SearchPart={resultPartCurr||undefined}
                    SearchRecl={resultReclCurr||undefined}
                    isToGere={true}
                />
            </MbjModal2>
        </div>
    )
}

const ProfilsRetenus = styled(ProfilsRetenusCtrl)`
    padding: 2%;
  height: 100%;
  .content{
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 10px;
    height: 100%;
  }
  .wrap_result{
    flex-grow: 1;
    height: 0;
    overflow-y: auto;
  }
`


export default ProfilsRetenus;
