import {Route, Routes} from "react-router-dom";
import Recherches from "../01_Recherches/Recherches";
import * as React from "react";
import {OneCvThequeRooter} from "./OneCvTheque/OneCvThequeRooter";
import {CvThequeListing} from "./CvThequeListing";


export const CvThequeRooter = ()=>{
    return (
        <Routes>
            <Route path="" element={<CvThequeListing />} />
            <Route path={":id/*"} element={<OneCvThequeRooter />} />
        </Routes>
    )
}

