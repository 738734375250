import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  id: number,
  ZE: string,
  metierMbj: string,
  rs: string,
  distance: number,
}

interface TopRecrutementUserMetierCtrlProps {
  className?: string;
  item: ITemTypes
}


function TopRecrutementUserMetierCtrl(props: TopRecrutementUserMetierCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.rs}</td>
      <td className="isCenter">{ Math.floor(props.item.distance)}</td>
    </tr>
  );
}

export const TopRecrutementUserMetier = styled(TopRecrutementUserMetierCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
