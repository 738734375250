import styled from "styled-components";
import {MbjCardBack, MbjNumber, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {InformationsCard} from "./InformationsCard";
import {useGetStatsEffectifs} from "@mbj-front-monorepo/data-access";
import {RepartitionMetiers} from "./RepartitionMetiers";
import {ListMetiers} from "./ListMetiers";
import {CompPcpale} from "./CompPcpale";
import {CompToDev} from "./CompToDev";

interface EntrepriseDashboardCtrlProps{
    className?:string;
}

const EntrepriseDashboardCtrl = (props:EntrepriseDashboardCtrlProps)=>{
    const statsEffectifQuery = useGetStatsEffectifs();
    return (
        <div className={`entreprise_dash pageStd ${props.className}`}>
          <div className={"grid"}>
            <div className={"my-row"} style={{
              gridColumn:`1/1`,
              gridRow:`1/4`,
            }}>
              <InformationsCard/>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `2/2`,
              gridRow: `1/2`,
            }}>
              <MbjCardBack height={"100%"}>
                <MbjNumber
                  number={statsEffectifQuery.data && statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].nbMetiers || 0 : 0}
                  legend={"Métiers différents"} themeColor={"primary"}/>
              </MbjCardBack>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `2/2`,
              gridRow: `2/3`,
            }}>
              <MbjCardBack height={"100%"}>
                <MbjNumber
                  number={statsEffectifQuery.data && statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].effectif || 0 : 0}
                  legend={"Effectif total"} themeColor={"primary"}/>
              </MbjCardBack>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `2/2`,
              gridRow: `3/3`,
            }}>
              <MbjCardBack height={"100%"}>
                <MbjNumber
                  number={statsEffectifQuery.data && statsEffectifQuery.data.length > 0 ? statsEffectifQuery.data[0].salaireAvg || 0 : 0}
                  noInfo={!statsEffectifQuery.data || statsEffectifQuery.data.length===0 || statsEffectifQuery.data[0].salaireAvg ===0}
                  legend={"Salaire moyen"} themeColor={"primary"}/>
              </MbjCardBack>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `3/3`,
              gridRow: `1/4`,
            }}>
              <RepartitionMetiers/>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `1/1`,
              gridRow: `4`,
            }}>
              <ListMetiers/>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `2/2`,
              gridRow: `4`,
            }}>
              <CompPcpale/>
            </div>
            <div className={"my-row"} style={{
              gridColumn: `3/3`,
              gridRow: `4`,
            }}>
              <CompToDev/>
            </div>
          </div>
        </div>
    )
}

const EntrepriseDashboard = styled(EntrepriseDashboardCtrl)`
  .grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-template-rows: 150px 150px 150px 350px;
  }
`


export default EntrepriseDashboard;
