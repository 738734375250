import styled from "styled-components";
import React from "react";
import { MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import {ObjectifSectionCollab} from "./ObjectifSectionCollab";
import {EssaiPageHTML} from "./EssaiPageHTML";
import { RecapInfoEntretienAnnuel } from "./RecapInfoEntretienAnnuel";

interface CollaborateursDashboardCtrlProps{
    className?:string;
}

const CollaborateursDashboardCtrl = (props:CollaborateursDashboardCtrlProps)=>{
    return (
        <div className={`collabs_dash pageStd ${props.className}`}>
          <MbjWidgetClassic bodyDirection={"column"}  bodyGap={"10px"} justify={"flex-start"}>
            <MbjWidgetClassic bodyDirection={"row"}>
              <MbjWidgetClassic basis={"100%"}>
                <ObjectifSectionCollab/>
              </MbjWidgetClassic>
              <MbjWidgetClassic basis={"100%"}>
                <RecapInfoEntretienAnnuel />
              </MbjWidgetClassic>
            </MbjWidgetClassic>
          </MbjWidgetClassic>
        </div>
    )
}

const CollaborateursDashboard = styled(CollaborateursDashboardCtrl)``


export default CollaborateursDashboard;
