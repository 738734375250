import styled from "styled-components";
import {BaseColorsTheme} from "@mbj-front-monorepo/ui";

interface MbjLinkActionCtrlProps{
    className?:string;
    themeColor:BaseColorsTheme;
    libelle:string;
    action:()=>void;
}

const MbjLinkActionCtrl = (props:MbjLinkActionCtrlProps)=>{
    return (
        <button className={`link ${props.className}`} type={"button"} onClick={()=>props.action()}>{props.libelle}</button>
    )
}

export const MbjLinkAction = styled(MbjLinkActionCtrl)`
  display: inline-block;
  border:none;
  background: transparent;
  color:${props=>props.theme[props.themeColor]};
  text-decoration: underline;
  &:hover{
    cursor: pointer;
    font-weight: bold;
  }
`
