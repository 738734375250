import styled from "styled-components";
import {MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ErrorLoadingCtrlProps{
    className?:string;
    closeMe:()=>void;
}

const ErrorLoadingCtrl = ({className, closeMe}:ErrorLoadingCtrlProps)=>{
    return (
        <div className={`error_load ${className}`}>
            <h2>!! Votre Cv n'a pas été scanné</h2>
            <div className={`body_error`}>
                <p>Quelque chose s'est mal passé merci de vérifier les points suivants</p>
                <ul className={"list_verif"} style={{margin:"15px 0"}}>
                    <li>Veuillez vérifier la taille de votre document (3 pages maximum)</li>
                    <li>Veuillez vérifier le format PDF, DOC, DOCX, JPEG, PNG</li>
                </ul>
                <p>Si votre fichier est conforme, réessayer d'ici 2min, le service peut être encombré</p>
            </div>
            <div className={`footer_error`} style={{display:"flex", justifyContent:"center"}}>
                <MbjButton size={"md"} type="button" onClick={()=> closeMe()} themeColor={"primary"}>
                    Fermer
                </MbjButton>
            </div>
        </div>
    )
}

export const ErrorLoading = styled(ErrorLoadingCtrl)`
  padding: 1rem;
  width: 60%;
  margin:auto;
  h2{
    color: ${props => props.theme.warning};
    text-align: center;
  }
  .body_error{
    margin : 15px auto;
    font-size: 16px;
  }
`
