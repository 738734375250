import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {MbjCirclePercent} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ProximitePartUserMetierCardCtrlProps{
  className?:string;
  Proximite: components["schemas"]["ParticulierProxiUserMetier-read.partproxiusermetier_read.UserMetier_read.Metier"]
  ClickOnIt:(id:number)=>void;
}

const ProximitePartUserMetierCardCtrl = (props:ProximitePartUserMetierCardCtrlProps)=>{
  if(!props.Proximite || !props.Proximite.score){
    return null;
  } else {
  return (
      <div className={`proxiCard ${props.className}`} onClick={()=>props.ClickOnIt(props.Proximite.UserMetier && props.Proximite.UserMetier.id ? props.Proximite.UserMetier.id : 0)}>
        <div className={"content_proxi"}>
          <div className={`name_metier`}>{props.Proximite.UserMetier?.namePerso}</div>
          <div className={`Pc_place ${(props.Proximite.score||0) > 0.8 ? 'good' : (props.Proximite.score||0) > 0.7 ? 'medium' : 'bad'}`}>{Math.round((props.Proximite.score||0)*100)}%</div>
        </div>
      </div>
    )
  }
}

export const ProximitePartUserMetierCard = styled(ProximitePartUserMetierCardCtrl)`
  width: 50%;
  padding: 5px;
  &:hover{
    .content_proxi{
      box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
      cursor: pointer;
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }
  .content_proxi{
    background: white;
    padding: 2rem 1rem;
    display: flex;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 100%;
  }
  .name_metier{
    font-size: 16px;
    width: 60%;
    font-weight: bold;
  }
  .score{
    width: 70px;
    height: 70px;
    flex-shrink: 0;
  }
  .Pc_place{
    position: absolute;
    right: 5px;
    top: -5px;
    font-size: 40px;
    &.good{
      color: ${props => props.theme.thirdDarken};
    }
    &.medium{
      color: ${props => props.theme.warningPastel};
    }
    &.bad{
      color: ${props => props.theme.warningDarken};
    }
  }
`
