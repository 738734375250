import {fetchApi, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {useQuery} from "@tanstack/react-query";


export const useGetAllProxiUM = ()=>{
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id||0;
  const promise = () => fetchApi("/api/user_metier_proxi_user_metiers", { method: "get", query:{"UserMetier.Member":memberId, "UserMetierFrom.Member":memberId} });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["um_proxi_all"], promise);
}

export const useGetAllProxiUMWID = (id:number)=>{
  const promise = () => fetchApi("/api/user_metier_proxi_user_metiers", { method: "get", query:{"UserMetier.Member":id, "UserMetierFrom.Member":id} });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["um_proxi_all"], promise, {
    enabled:id!==0
  });
}
