import styled from "styled-components";
import {Navigate, Route, Routes, To} from "react-router-dom";
import {InternSubDesktopLayout} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {BesoinsByCompetences} from "./01_ByCompetences/BesoinsByCompetences";
import {BesoinsByEquipe} from "./02_ByEquipe/BesoinsByEquipe";
import {BesoinsByMetier} from "./03_ByMetier/BesoinsByMetier";
import {BesoinsByNiveau} from "./04_ByNiveau/BesoinsByNiveau";

interface BesoinsRooterCtrlProps{
    className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
    {libelle: 'Par compétence', to:"by_competences"},
    {libelle: 'Par Service', to:"by_service"},
    {libelle: 'Par Métier de l\'entreprise', to:"by_metier"},
    {libelle: 'Par Niveau d\'écart', to:"by_niveau"},
]

const BesoinsRooterCtrl = (props:BesoinsRooterCtrlProps)=>{
    return (
        <Routes>
            <Route
                element={
                    <InternSubDesktopLayout
                        Links={TabSubMenus}
                    />
                }
            >
                <Route path="" element={<Navigate to="by_competences" />} />
                <Route path="/by_competences" element={<BesoinsByCompetences />} />
                <Route path="/by_service" element={<BesoinsByEquipe />} />
                <Route path="/by_metier" element={<BesoinsByMetier />} />
                <Route path="/by_niveau" element={<BesoinsByNiveau />} />
            </Route>
        </Routes>
    )
}

export const BesoinsRooter = styled(BesoinsRooterCtrl)``
