import styled from "styled-components";
import {OnActiChoice} from "../NouvelleFormation";
import {FaPlus} from "react-icons/fa";
import {FaRegTrashCan} from "react-icons/fa6";

interface OneCardActiToRemoveCtrlProps {
  className?:string;
  Activite:OnActiChoice;
  RemoveActivite:(a:OnActiChoice)=>void;
}

const OneCardActiToRemoveCtrl = (props: OneCardActiToRemoveCtrlProps) => {
  return (
    <div className={"acti_to_add "+props.className } onClick={()=>props.RemoveActivite(props.Activite)}>
      <div className="libActi">{props.Activite.libelle}</div>
      <div className="wrap_trash">
        <FaRegTrashCan />
      </div>
    </div>
  )
}

export const OneCardActiToRemove = styled(OneCardActiToRemoveCtrl)`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  border-radius: 4px;
  .libActi{
    flex-grow: 1;
    padding: 0 0.25rem;
  }
  .wrap_trash{
    width: 0;
    position: relative;
    overflow: hidden;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &:hover{
    cursor: pointer;
    background: ${props => props.theme.complementaryPastel};
    .wrap_trash{
      transition: width 0.3s;
      width: 32px;
    }
  }
`
