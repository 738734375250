import styled from "styled-components";
import {
  MbjButton,
  MbjCardClassic,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useAddSettingsEntretien,
  useEditSettingsEntretien,
  useGetSettingEntretien,
} from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";

interface FrequenceCtrlProps {
  className?: string;
}

const FrequenceCtrl = ({ className }: FrequenceCtrlProps) => {
  const [viewForm, setViewForm] = useState(false);
  const addSettingEntretien = useAddSettingsEntretien();
  const editSettingEntretien = useEditSettingsEntretien();
  const settingEntretien = useGetSettingEntretien();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data: any) => {
    const form = document.getElementById("settingProForm") as HTMLFormElement;
    const result = {frequenceEval: +data.frequenceEval}
    if (settingEntretien?.data) {
      editSettingEntretien.mutate(result, {
        onSuccess: () => {
          if (form) {
            form.reset();
          }
          setViewForm(false);
        },
      });
      return;
    }
    addSettingEntretien.mutate(result, {
      onSuccess: () => {
        if (form) {
          form.reset();
        }
        setViewForm(false);
      },
    });
  };

  useEffect(() => {
    setValue("frequenceEval", settingEntretien?.data?.frequenceEval);
  }, [settingEntretien, setValue]);

  return (
    <div style={{ padding: "1em" }} className={className}>
      <MbjCardClassic className="settingPro" title={"Fréquence des entretiens d'évaluations"} basis={"60%"}>
        {!viewForm ? (
          <>
            <p className="settingTitle">Fréquence actuelle</p>
            <p className="settingInfo">{settingEntretien?.data?.frequenceEval ? settingEntretien?.data?.frequenceEval + " mois": "A définir"}</p>
            <MbjButton className={"changeView"} type={"button"} themeColor={"secondary"} onClick={() => setViewForm(true)}>
              {settingEntretien?.data?.frequenceEval ? "Editer" : "Ajouter"}
            </MbjButton>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} id="settingProForm">
            <MbjSimpleInputContainer errors={errors} label={"Période (mois)"}>
              <MbjSimpleInput
                name="frequenceEval"
                id={"frequenceEval"}
                type={"number"}
                min={0}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Définir la frequence",
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <div className="btn-frm">
              <MbjButton className={"btnback"} type={"button"} themeColor={"secondary"} onClick={() => setViewForm(false)}>
                Retour
              </MbjButton>
              <MbjButton
                className={"sendButton"}
                type={"submit"}
                isPending={settingEntretien?.data ? editSettingEntretien.isLoading : addSettingEntretien.isLoading}
              >
                {settingEntretien?.data?.frequenceEval ? "Editer" : "Ajouter"}
              </MbjButton>
            </div>
          </form>
        )}
      </MbjCardClassic>
    </div>
  );
};

export const FrequenceEval = styled(FrequenceCtrl)`
  .settingTitle {
    font-size: 15px;
    font-weight: bold;
  }

  .btnback {
    margin-right: 10px;
  }
  .changeView {
    margin-bottom: 10px;
  }

  .settingInfo {
    color: #396c71;
    font-style: italic;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 1.5em;
    margin-top: 0.5em;
  }

  .btn-frm {
    margin-top: 10px;
  }
`;
