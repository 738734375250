import * as React from "react";
import styled from "styled-components";

export interface ITemTypes  {
  id: number,
  ZE: string,
  Metier_mbj: string,
  rs: string,
  distance: number,
}

interface TopRecrutementDetailEmployeeCtrlProps {
  className?: string;
  item: ITemTypes
}


function TopRecrutementDetailEmployeeCtrl(props: TopRecrutementDetailEmployeeCtrlProps) {

  return (
    <tr>
      <td className="isCenter">{props.item.ZE}</td>
      <td className="isCenter">{props.item.Metier_mbj}</td>
      <td className="isCenter">{props.item.rs}</td>
      <td className="isCenter">{ Math.floor(props.item.distance)}</td>
    </tr>
  );
}

export const TopRecrutementDetailEmployee = styled(TopRecrutementDetailEmployeeCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
