import styled from "styled-components";
import {useParams} from "react-router-dom";
import {
    useConvertEmployeeToUser,
    useGetAllDroits,
    useGetEmployee,
    useGetOneUser
} from "@mbj-front-monorepo/data-access";
import {useEffect, useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";
import {GestionOneDroits} from "./components/GestionOneDroits";

interface GestionDroitsCtrlProps{
    className?:string;
}

const GestionDroitsCtrl = ({className}:GestionDroitsCtrlProps)=>{
    const { id } = useParams();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const [idUser, setIdUser] = useState(0)
    const UserQuery = useGetOneUser(idUser);
    const mutationTo = useConvertEmployeeToUser(id ? parseInt(id) : 0)
    const DroitsQuery = useGetAllDroits();
    const mutationConvert = useConvertEmployeeToUser(parseInt(id || "-1"));
    useEffect(() => {
        if(employeeQuery.data){
            setIdUser(employeeQuery.data.userId||0);
        }
    }, [employeeQuery.data]);
    const handleClickConvert = ()=>{
        mutationConvert.mutate();
    }
    return (
        <div className={`gestion_droits ${className}`}>
            <h1>Gestion des droits</h1>

            {employeeQuery.data && idUser === 0 &&
                <div className={`no_account`}>
                <p>Le collaborateur n'a aucun accès à My-better-job, vous pouvez lui attribuer un compte en cliquant sur le bouton ci-dessous</p>
                <MbjButton size={"sm"} onClick={handleClickConvert} isPending={mutationConvert.isLoading}>Créer un compte utilisateur</MbjButton>
                </div>
            }
            {
                UserQuery.data &&
                <div className={`gestion_droits`}>
                    <p>Il vous est possible d'attribuer un ou plusieurs profils de droit à ce collaborateur</p>
                    {
                        DroitsQuery.data?.sort((a,b)=>(a.ordreEdit||0)> (b.ordreEdit||0) ? 1 : -1).map(item=>(
                            <GestionOneDroits User={UserQuery.data} Droit={item} key={`on_D${item.id}`}/>
                        ))
                    }
                </div>

            }
        </div>
    )
}

export const GestionDroits = styled(GestionDroitsCtrl)`
    padding: 1% 2%;
  .gestion_droits{
    p{
      margin: 15px 0;
    }
  }
  .no_account{
    p{
      margin: 15px 0;
    }
  }
`
