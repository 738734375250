import styled from "styled-components";
import {components, usePostDemandeContact} from "@mbj-front-monorepo/data-access";
import { AiOutlineIdcard } from "react-icons/ai";
import {IoLocationSharp} from "react-icons/io5";
import {BsFillTelephoneOutboundFill} from "react-icons/bs";
import {GiPathDistance} from "react-icons/gi";
import {MbjButton, useModal} from "@mbj-front-monorepo/ui";
import {ModalNewDemandeContact} from "./ModalNewDemandeContact";
import {useState} from "react";
import {MdOutlineAlternateEmail, MdOutlinePhonelinkRing} from "react-icons/md";
import {CgWebsite} from "react-icons/cg";

interface CardFormationCtrlProps{
    className?:string;
    Formation:components["schemas"]["FormationContinuOF-read.reasult_formation"];
}

export interface MyOF{
  idOF:number, idFC:number, libelleOF:string, libelleFormation:string, telephone?:string, adresse?:string, email?:string, siteWeb?:string, distance?:string
}

const CardFormationCtrl = ({className, Formation}:CardFormationCtrlProps)=>{
    const {isShowing, toggle} = useModal();
    const [askerCurr, setAskerCurr] = useState<MyOF|null>(null)
    const mutation = usePostDemandeContact();
    const ClickFormation = (id:number)=>{
        const myOF = Formation.OrganismeFormation?.find(of=>of.id === id);
        if(myOF) {
          const asker:MyOF = {idOF: myOF.id||0, idFC: Formation.formationContinu?.id||0, libelleOF: myOF.rs||'', libelleFormation: Formation.formationContinu?.libelle||''}
          if(myOF.adresse) asker.adresse = `${myOF.adresse} ${myOF.codePostal} ${myOF.ville}`
          if(myOF.telephone) asker.telephone = myOF.telephone
          if(myOF.email) asker.email = myOF.email
          if(myOF.siteWeb) asker.siteWeb = myOF.siteWeb
          if(myOF.distance) asker.distance = Math.round(myOF.distance)+"km"
            setAskerCurr(asker)
            SendDemande(asker);
            toggle();
        }
    }
    const SendDemande = (MyAsker:MyOF)=>{
        const myData={
            FormationConcerned: `/api/formation_continus/${MyAsker.idFC}`,
            Organisme: `/api/organisme_formations/${MyAsker.idOF}`,
            contexte: "Demande suite à recrutement",
            commentaires: "intra"
        }
        mutation.mutate(myData)
    }
    return (
        <div className={`one_card_formation ${className}`}>
            <div className="titreformation">{Formation.formationContinu?.libelle}</div>
            <div className="presentationformation">{Formation.formationContinu?.presentation}</div>
            <h3>Liste des établissements proposant cette formation</h3>
            <div className="wrap_orga">
                {Formation.OrganismeFormation?.map((item, idx:number)=>(
                    <div className={`one_orga`} key={`onO${idx}`}>
                        <div className={`informations_orga`}>
                            <div className={`one_infos first_style`}>
                                <div className="icon"><AiOutlineIdcard/></div>
                                <div className="info">{item.rs} {item.siret ? item.siret : ''}</div>
                            </div>
                            {item.adresse &&
                                <div className={`one_infos`}>
                                    <div className="icon"><IoLocationSharp/></div>
                                    <div className="info">{item.adresse} {item.codePostal} {item.ville}</div>
                                </div>
                            }
                            {item.distance &&
                            <div className={`one_infos`}>
                                <div className="icon"><GiPathDistance/></div>
                                <div className="info">{Math.round(item.distance)}km</div>
                            </div>
                            }
                          {/*{item.email &&*/}
                          {/*  <div className={`one_infos`}>*/}
                          {/*    <div className="icon"><MdOutlineAlternateEmail /></div>*/}
                          {/*    <div className="info">{item.email}</div>*/}
                          {/*  </div>*/}
                          {/*}*/}
                          {/*{item.telephone &&*/}
                          {/*  <div className={`one_infos`}>*/}
                          {/*    <div className="icon"><MdOutlinePhonelinkRing /></div>*/}
                          {/*    <div className="info">{item.telephone}</div>*/}
                          {/*  </div>*/}
                          {/*}*/}
                          {/*{item.siteWeb &&*/}
                          {/*  <div className={`one_infos`}>*/}
                          {/*    <div className="icon"><CgWebsite /></div>*/}
                          {/*    <div className="info">{item.siteWeb}</div>*/}
                          {/*  </div>*/}
                          {/*}*/}

                            {/*{item.telephone &&*/}
                            {/*    <div className={`one_infos`}>*/}
                            {/*        <div className="icon"><BsFillTelephoneOutboundFill/></div>*/}
                            {/*        <div className="info">{item.telephone}</div>*/}
                            {/*    </div>*/}
                            {/*}*/}
                        </div>
                        <div className={`action`}>
                            <MbjButton size={"sm"} themeColor={"primary"} onClick={()=>ClickFormation(item.id||0)}>Cet établissement m'intéresse</MbjButton>
                        </div>
                    </div>
                ))}
            </div>
            <ModalNewDemandeContact isShowing={isShowing} hide={toggle} MyAsker={askerCurr}/>
        </div>
    )
}

export const CardFormation = styled(CardFormationCtrl)`
    background: white;
    border-radius: 6px;
    margin-bottom: 10px;
  padding: 0.5rem 1rem;
  .titreformation{
    font-size: 20px;
  }
  .one_orga{
    margin-left: 15px;
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
  }
  .one_infos{
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    align-items: center;
    &.first_style{
      .info{
        font-weight: bold;
        color:${props=>props.theme.primary}
      }
    }
    .icon{
      font-size: 18px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
`
