import styled from "styled-components";

import {NavLink, Outlet} from "react-router-dom";
import {LinkToComp} from "../CompetencesInterfaces";


interface CompetencesLayoutRCtrlProps{
  className?:string;
  link:LinkToComp;
}

const CompetencesLayoutRCtrl = (props:CompetencesLayoutRCtrlProps)=>{

  return (
    <div className={`layouts ${props.className}`}>
      <div className={`links_places`}>
        <div className={`content_link`}>
          <NavLink className={`link_pathy`} to={props.link.link}><span>{props.link.libelle}</span></NavLink>
        </div>
      </div>
      <div className={`content`}>
        <Outlet/>
      </div>
    </div>
  )
}

export const CompetencesLayoutR = styled(CompetencesLayoutRCtrl)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  .content{
    flex-grow: 1;
  }
  .links_places{
    width: 50px;
    flex-shrink: 0;
    position: relative;
    background: ${props => props.link.backColor ? props.link.backColor :  "#ccc"};
    .content_link{
      position: absolute;
      transform-origin: 0 0;
      transform: rotate(-90deg);
      top: 100%;
      left: 0;
      width: 800px;
      height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .link_pathy{
    padding: 0 2rem;
    font-size: 22px;
    text-decoration: none;
    color: black;
    &:visited{
      color: black;
    }
  }
`
