import styled from "styled-components";
import {components, Operation, useGetMember} from "@mbj-front-monorepo/data-access";
import {AvatarFact, MbjCardBack, MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";
import React from "react";

interface CarAllianceMemberCtrlProps{
    className?:string;
    AllianceMembre:components["schemas"]["AllianceMember-read.allianceMember.collection_read.Member"];
    setCurrent:(item:Operation<"getMemberItem">|undefined)=>void;
}

const CarAllianceMemberCtrl = ({className, AllianceMembre, setCurrent}:CarAllianceMemberCtrlProps)=>{
    const MembreQuery = useGetMember(AllianceMembre.Member ? AllianceMembre.Member.id||0 : 0)
    const handleError = (e:any)=>{
        e.target.src = AvatarFact;
    }
    return (
        <div className={`card_alliance_member ${className}`}>
            <MbjCardBack height={"100%"} isClickable={true} onClick={()=>setCurrent(MembreQuery.data)}>
                {MembreQuery.isLoading ? <MbjLoading/> : MembreQuery.isError ? <MbjErrorData/> :
                    <div className={`info_membre`}>
                        <div className={`title_membre`}>
                            <div className={`logo`}>
                                <img
                                    src={`${process.env["REACT_APP_API_URL_ROOT"]}${MembreQuery.data?.logoUrl}`}
                                    alt={"avatar_logo"}
                                    onError={handleError}
                                />
                            </div>
                            <div className={`name`}>{MembreQuery.data.name}</div>
                        </div>
                    </div>
                }
            </MbjCardBack>
        </div>
    )
}
export const CarAllianceMember = styled(CarAllianceMemberCtrl)`
    padding: 10px;
    .title_membre{
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      align-items: center;
      .logo{
        width: 36px;
        img{
          width: 100%;
        }
      }
      .name{
        font-weight: bold;
      }
    }
`
