import styled from "styled-components";
import {
  useGetAllProxiUMWID,
  useGetMemberMe,
} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";

interface MatrixAllMetiersCtrlProps {
  className?:string;
}

interface OneUseM{
  id:number;
  libelle:string;
  score?:number;
  salaire?:number;
}
interface OneLineUM{
  userMetier:OneUseM,
  list:OneUseM[]
}

const MatrixAllMetiersCtrl = (props: MatrixAllMetiersCtrlProps) => {
  const memberQuery = useGetMemberMe();
  const [MemberId, setMemberId] = useState(0)
  const ProxiQuery = useGetAllProxiUMWID(MemberId);
  useEffect(() => {
    if(memberQuery.data){
      setMemberId(memberQuery.data.id||0)
    }
  }, [memberQuery.data]);
  const listUserMetier = useMemo(()=>{
    const myList:OneUseM[] = []
    if(ProxiQuery.data){
      const ids:number[] = [];
      ProxiQuery.data.map(i=>i.UserMetierFrom).sort((a, b)=>(a?.namePerso||'') > (b?.namePerso||'') ? 1 : -1).forEach(i=>{
        if(i && ids.indexOf(i.id||0)===-1) {
          myList.push({id: i.id || 0, libelle: i.namePerso || '', salaire:i.Metier?.salaire})
          ids.push(i.id||0)
        }
      })
    }
    return myList;
  }, [ProxiQuery.data])
  const myTabList = useMemo(()=>{
    const myList:OneLineUM[] = []
    if(listUserMetier && ProxiQuery.data){
        listUserMetier.forEach(i=>{
          const Mys = ProxiQuery.data.filter(p=>p.UserMetierFrom?.id === i.id)
          const UserMTo:OneUseM[] = Mys.sort((a, b)=>(a?.UserMetier?.namePerso||'') > (b?.UserMetier?.namePerso||'') ? 1 : -1).map(x=>{
            return {id:x.UserMetier?.id||0, libelle:x.UserMetier?.namePerso||'', score:x.score||0, salaire:x.UserMetier?.Metier?.salaire}
          })
          myList.push({
            userMetier:i,
            list:UserMTo
          })
        })
    }
    return myList
  }, [listUserMetier, ProxiQuery.data])
  return (
    <div className={`matrix_all_metier ${props.className}`}>
      {ProxiQuery.isLoading ? <MbjLoading/> :
        <div className="wrap_tabx">
          <table>
            <thead>
            <tr>
              <th></th>
              {listUserMetier.map((item, idx) => (
                <th key={`H${item.id}${idx}`}>{item.libelle}</th>
              ))}
            </tr>
            </thead>
            <tbody>
            {myTabList.map((item, idx) => (
              <tr key={`LMetrix${item.userMetier.id}${idx}`}>
                <td>{item.userMetier?.libelle || ''}</td>
                {item.list?.map((itemB, idx) => (
                  <td key={`Cell${item.userMetier.id}${itemB.id}${idx}`}>
                    <div className={`myCell ${item.userMetier.id === itemB.id ? 'same' : (item.userMetier.salaire||0)< (itemB.salaire||0) ? 'no-progress' : (itemB.score||0)< 0.3  ? '--grey' : (itemB.score||0)<0.6 ? '--white' : (itemB.score||0)<0.8 ? '--blue' : (itemB.score||0)<0.9 ? '--light-green' : '--dark-green'}`}>{itemB.score ? (Math.round(itemB.score*100))+"%" : 0+"%"}</div>
                  </td>
                ))}
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      }
    </div>
  )
}

export const MatrixAllMetiers = styled(MatrixAllMetiersCtrl)`
  width:100%;
  padding-top: 20px;
  .myCell{
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    border:solid transparent 1px;
    &.same{
      background: black;
      color:white;
    }
    &.good{
      background: ${props=>props.theme.thirdDarken};
      color:white;
    }
    &.no-progress{
      background: ${props=>props.theme.grey};
      color:${props=>props.theme.greyLighten};
    }
    &.--grey{
      background: ${props=>props.theme.grey};
      color:white;
    }
    &.--white{
      background: white;
      color:${props=>props.theme.dark};
      border:${props=>props.theme.dark} solid 1px;
    }
    &.--blue{
        background: ${props=>props.theme.secondaryPastel};
        color:${props=>props.theme.dark};
    }
    &.--light-green{
      background: ${props=>props.theme.thirdPastel};
      color:${props=>props.theme.thirdDarken};
    }
    &.--dark-green{
      background: ${props=>props.theme.thirdDarken};
      color:${props=>props.theme.dark};
    }
    &.bad{
      background: ${props=>props.theme.warningPastel};
      color:white;
    }
  }
  .wrap_tabx{
    margin: auto;
    width:78vw;
    max-height: 600px;
    background: white;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
  }
  table{
    border-spacing : 0;
    border-collapse : collapse;
    thead{
      tr{

        th{
          background: white;
          position: sticky;
          top:0;
          text-align: center;
          font-size: 13px;
          min-width: 110px;
          padding: 0.25rem 0.5rem;
          border-bottom: solid ${props=>props.theme.primary} 1px;
          &:first-child{
            min-width: 280px;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          &:first-child{
            font-weight: bold;
          }
          padding: 0.25rem;
        }
      }
    }
  }
  .cell_compare{
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    &.good{
      background: ${props=>props.theme.thirdLighten};
    }
    &.same{
      background: ${props=>props.theme.primaryPastel};
    }
    &.bad{
      background: ${props=>props.theme.warningPastel};
    }
  }
`
