import {
  CustomSelect,
  MbjButton, MbjLabel,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
   useAddNewMemberEquipeManager,
  useGetAllListEquipeManager,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as React from "react";
interface ModalSendOneCollaborateurToEquipeCtrlProps extends MbjModalCtrlProps {
  employee: any;
}

const ModalSendOneCollaborateurToEquipeCtrl = (props: ModalSendOneCollaborateurToEquipeCtrlProps) => {
  const userEquipesQuery = useGetAllListEquipeManager();
  const mutation = useAddNewMemberEquipeManager();

  const HandleSend = (data: any) => {
    mutation.mutate(
      {
        equipeManager: `/api/equipe_managers/${data.manager.id}`,
        employee: `/api/employees/${props.employee.id}`
      },
      {
        onSettled: () => {
          props.hide();
        },
      }
    );

  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <MbjModal {...props} title="Ajout de collaborateur(s) à une équipe" maxWidth={"600px"}>
      {userEquipesQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjLabel>{props.employee.prenom + '  ' + props.employee.nom}</MbjLabel>
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner une équipe"} htmlFor={"manager"}>
            <Controller
              name={"manager"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"userId"}
                  isLoading={userEquipesQuery.isLoading}
                  options={userEquipesQuery.data}
                  getOptionLabel={(team: any) => team.nom}
                  getOptionValue={(team: any) => team.id}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Ajouter
          </MbjButton>
        </form>
      )}
    </MbjModal>
  );
};

export const ModalSendOneCollaborateurToEquipe = styled(ModalSendOneCollaborateurToEquipeCtrl)((props) => ({
  ".serviceAddForm": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "end",
    },
  },
}));
