import styled from "styled-components";
import {useContext} from "react";

import * as React from "react";
import {FamilleContext} from "./OneFamilleR";
import {LinkCompR} from "./LinkCompR";

interface ListCompetencesRCtrlProps{
  className?:string;
}

const ListCompetencesRCtrl = (props:ListCompetencesRCtrlProps)=>{
  const context = useContext(FamilleContext);
  return (
    <div className={`list_domaines ${props.className}`}>
        <h1>Profil de compétence par Compétence dans la famille "{context.Famille ? context.Famille.libelle : ''}"</h1>
        <div className={`wrap_list`}>
            {context.Competences.map(item=>(
              <LinkCompR to={item.id + ""} libelle={item.libelle} key={`one_linkF${item.id}`} niveau={item.Niveau2 ? item.Niveau2 : item.Niveau}/>
            ))}
        </div>
    </div>
  )
}

export const ListCompetencesR = styled(ListCompetencesRCtrl)`
  padding: 1% 2%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .wrap_list{
    flex-grow: 1;
    overflow: auto;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
`
