import styled from "styled-components";
import {MbjButton, MbjFlex, MbjLoading, MbjScrollArea, MbjSearchBar} from "@mbj-front-monorepo/ui";
import { ConversationsPreview } from "./ConversationsPreview";
import { useSearchParams } from "react-router-dom";
import { useGetRooms } from "@mbj-front-monorepo/data-access";
import { useState } from "react";
import {FaPlus} from "react-icons/fa";
import * as React from "react";

interface ConversationsListeCtrlProps {
  className?: string;
  width?: string;
  newConversation:()=>void;
}

const ConversationsListeCtrl = (props: ConversationsListeCtrlProps) => {
  const [searchParams] = useSearchParams();
  const roomsQuery = useGetRooms();
  const [search, setSearch] = useState<string>("");

  if (roomsQuery.isLoading) return <MbjLoading />;

  if (roomsQuery.isError) return <div>{roomsQuery.error.message}</div>;

  if (roomsQuery.data.length === 0) return <div>Aucune conversations</div>;

  return (
    <MbjFlex className={props.className} direction={"column"} wrap={"nowrap"} height={"100%"}>
      <div className={`wrap_top`}>
        <MbjButton isfully size={"sm"} themeColor={"third"} onClick={props.newConversation}>
          Nouvelle conversation
        </MbjButton>
        <MbjSearchBar placeholder={"Rechercher une conversation"} onChange={(event) => setSearch(event.target.value)} />
      </div>
      <MbjScrollArea>
        <MbjFlex direction={"column"} gap={"0"} className={"conversations-container"}>
          {roomsQuery.data
            .filter(
              (room) =>
                room.Members?.some((member) => member.name?.toLowerCase().includes(search.toLowerCase())) ||
                room.lastConversation?.content?.toLowerCase().includes(search.toLowerCase())
            )
            .map((room) => (
              <ConversationsPreview
                key={room.id}
                Room={room}
                active={parseInt(searchParams.get("id") ?? "-1") === room.id}
              />
            ))}
        </MbjFlex>
      </MbjScrollArea>
    </MbjFlex>
  );
};

export const ConversationsListe = styled(ConversationsListeCtrl)`
  width: ${props=>props.width ? props.width : "25%"};
  background: white;
  .wrap_top{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;
