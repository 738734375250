import styled from "styled-components";
import {MbjMobileMenuSwitch} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {NavLink} from "react-router-dom";

interface MbjMobileItemSwitchCtrlProps {
  className?:string;
  MbjSwitch:MbjMobileMenuSwitch;
  isCurrent:boolean
}

const MbjMobileItemSwitchCtrl = ({className, MbjSwitch, isCurrent}: MbjMobileItemSwitchCtrlProps) => {
  return (
    <NavLink className={`mbjSwitchItem ${className} ${isCurrent ? '--current' : ''}`} to={MbjSwitch.to}>
      {MbjSwitch.libelle}
    </NavLink>
  )
}

export const MbjMobileItemSwitch = styled(MbjMobileItemSwitchCtrl)`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-bottom: solid transparent 2px;
  flex-grow: 1;
  text-decoration: none;
  text-align: center;
  color:${props=>props.theme.primary};
  &.--current{
    font-weight: bold;
    border-bottom: solid ${props => props.theme.primary} 2px;
  }
`
