import styled from "styled-components";
import {useGetAllEquipeManager, useGetConnected} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {GestionOneEquipeManager} from "./GestionOneEquipeManager";
import {FormNewTeamManager} from "./components/FormNewTeamManager";

interface EquipeManagerMainCtrlProps {
  className?:string;
}
export interface EquipeManager{
  id:number;
  nom:string;
  Employees:{id:number, prenom:string, nom:string, idEq:number}[]
}

const EquipeManagerMainCtrl = (props: EquipeManagerMainCtrlProps) => {

  const EquipesManagerQuery = useGetAllEquipeManager();
  const UserConnectedQuery = useGetConnected();
  const listEquipe = useMemo(()=>{
    const list:EquipeManager[] = [];
    if(EquipesManagerQuery.data && UserConnectedQuery.data){
        const EquipesConcerned = EquipesManagerQuery.data.filter(em=>em.User && em.User.id && em.User.id === UserConnectedQuery.data.id)
        EquipesConcerned.forEach(em=>{
          const ListEmp:{id:number, prenom:string, nom:string, idEq:number}[] = [];
          em.memberEquipeManagers?.forEach(mem=>{
            ListEmp.push({
              idEq:mem.id||0,
              prenom:mem.Employee?.prenom||'',
              nom:mem.Employee?.nom||'',
              id:mem.Employee?.id||0
            })
          })
          list.push({
            id:(em.id||0),
            nom:(em.nom||''),
            Employees:ListEmp
          })
        })
    }
    return list;
  }, [EquipesManagerQuery.data, UserConnectedQuery.data])

  return (
    <div className={"EquipeManager "+props.className}>
      <h1>Gestion des équipes de management</h1>
      <h2>Vos Equipes</h2>
      {listEquipe.map(item=>(
        <GestionOneEquipeManager Equipe={item} key={`GestEq${item.id}`}/>
      ))}
      <h2>Création d'une équipe</h2>
      <FormNewTeamManager/>
    </div>
  )
}

export const EquipeManagerMain = styled(EquipeManagerMainCtrl)`
  padding: 1% 2%;
  h1{
    color: ${props => props.theme.primary};
  }
  h2{
    margin: 25px 0 15px 0;
  }
`
