import styled from "styled-components";
import {ContextMe, MyDomaine} from "./CompetencesInterfaces";
import {createContext} from "react";
import {Route, Routes} from "react-router-dom";
import * as React from "react";
import {OneDomaineR} from "./components/OneDomaineR";
import {ListDomaineR} from "./components/ListDomaineR";

interface CompRooterCtrlProps{
    className?:string;
    listDomaines:MyDomaine[];
    ClickNiveau:(id:number, newNiveau:number, idLine:number)=>void;
    Trash:(id:number)=>void;
    loading:boolean;
    ReadOnly?:boolean;
    isEmployeeContext?:boolean;

}
export const CompetenceRooterContext = createContext<ContextMe>({Domaines:[]})
const CompRooterCtrl = (props:CompRooterCtrlProps)=>{
    return (
        <div className={`gestion_competence ${props.className}`}>
            <CompetenceRooterContext.Provider value={{Domaines:props.listDomaines, ClickNiveau:props.ClickNiveau, Trash:props.Trash, ReadOnly:props.ReadOnly, isEmployeeContext:props.isEmployeeContext}}>
                <Routes>
                    <Route path={""} element={<ListDomaineR loading={props.loading || props.listDomaines.length===0}/>}/>
                    <Route path="/:idDomaine/*" element={<OneDomaineR/>} />
                    <Route path="*" element={<p>pas troue</p>} />
                </Routes>
            </CompetenceRooterContext.Provider>
        </div>
    )
}

export const CompRooter = styled(CompRooterCtrl)`
  height: 100%;
`
