import styled from "styled-components";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {useGetEmployee, useGetRight} from "@mbj-front-monorepo/data-access";
import {InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import {FaUserCircle} from "react-icons/fa";
import * as React from "react";
import {OneCollaborateurInformations} from "./Informations/OneCollaborateurInformations";
import {OneCollaborateurCV} from "./Cv/OneCollaborateurCV";
import {OneCollaborateurGestionCompetences} from "./Competences/OneCollaborateurGestionCompetences";
import {MobilitesRoot} from "./Mobilites/MobilitesRoot";
import {OneCollaborateurSuivi} from "./Suivi/OneCollaborateurSuivi";
import {EntretienPeriodique} from "./EntretienPeriodique/EntretienPeriodique";
import {EntretienAnnuelNew} from "./EntretienPeriodique/EntretienAnnuelNew/EntretienAnnuelNew";
import BadgeMain from "./Badges/BadgeMain";
import {GestionDroits} from "./GestionDroits/GestionDroits";
import { EntretienAnnuelPro } from "./EntretienPeriodique/EntretienAnnuelNew/EntretienAnnuelPro";

interface OneCollaborateurRooterCtrlProps{
  className?:string;
}

const OneCollaborateurRooterCtrl = (props:OneCollaborateurRooterCtrlProps)=>{
  const { id } = useParams();
  const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const {isLoading:loadRightR_infos, isAuth:isAuthR_infos} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "infos")
    const {isLoading:loadRightR_cv, isAuth:isAuthR_cv} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "cv")
    const {isLoading:loadRightR_comp, isAuth:isAuthR_comp} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "competences")
    const {isLoading:loadRightR_badge, isAuth:isAuthR_badge} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "BadgesExpert")
    const {isLoading:loadRightR_mob, isAuth:isAuthR_mob} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "mobilité")
    const {isLoading:loadRightR_suivi, isAuth:isAuthR_suivi} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "suivi")
    const {isLoading:loadRightR_entre, isAuth:isAuthR_entre} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "entretien")
    const {isLoading:loadRightR_droits, isAuth:isAuthR_droits} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab", "droits")

  return (
    <Routes>
      <Route
        element={
          <InternDesktopLayout
            type="Entreprise"
            mbjSectionMenu={
              <MbjSectionMenu
                className={"custom-sectionMenu " + props.className}
                otherContent={
                  <div className={"custom-content-menu"}>
                    {employeeQuery.data ? (
                      <>
                        <FaUserCircle className={"icon-profile-menu"} />
                        <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                          {employeeQuery.data?.nom?.toUpperCase() + " " + employeeQuery.data?.prenom}
                        </MbjHeading>
                      </>
                    ) : (
                      <MbjLoading noMargin={true} height={"25px"} />
                    )}
                  </div>
                }
              >
                  {isAuthR_infos &&
                        <MbjLink to="informations">Informations</MbjLink>
                  }
                  {isAuthR_cv &&
                    <MbjLink to="cv">CV</MbjLink>
                  }
                  {isAuthR_comp &&
                    <MbjLink to="competences">Compétences</MbjLink>
                  }
                  {isAuthR_badge &&
                    <MbjLink to="badges">Badges Expert</MbjLink>
                  }
                  {isAuthR_mob &&
                    <MbjLink to="mobilites">Mobilités</MbjLink>
                  }
                {/*<MbjLink to="formation">Formation</MbjLink>*/}
                {/*<MbjLink to="badges">Badges</MbjLink>*/}
                  {isAuthR_suivi &&
                    <MbjLink to="suivi">Suivi</MbjLink>
                  }
                  {isAuthR_entre &&
                    <MbjLink to="entretien">Entretiens</MbjLink>
                  }
                  {isAuthR_droits && employeeQuery.data &&
                      <MbjLink to="droits">Droits</MbjLink>
                  }
              </MbjSectionMenu>
            }
          />
        }
      >
        <Route path="" element={<Navigate to="informations" />} />
        <Route path="/informations" element={<OneCollaborateurInformations />} />
        <Route path="/cv" element={<OneCollaborateurCV />} />
        <Route path="/competences/*" element={<OneCollaborateurGestionCompetences />} />
        <Route path="/badges" element={<BadgeMain />} />
        <Route path="/mobilites" element={<MobilitesRoot />} />
        <Route path="/suivi/*" element={<OneCollaborateurSuivi/>}/>
        <Route path="/entretien" element={<EntretienPeriodique />} />
        <Route path="/droits" element={<GestionDroits />} />
        <Route path="/entretien/evaluation" element={<EntretienAnnuelNew />} />
        <Route path="/entretien/pro" element={<EntretienAnnuelPro />} />
        {/*<Route path="/mobilites_internes" element={<MobilitesInterne />} />*/}
        {/*<Route path="/mobilites/:idMetier" element={<MobiliteDetails />} />*/}
        {/*/!*<Route path="/formation" element={<Formation />} />*!/*/}
        {/*/!*<Route path="/badges" element={<Badges />} />*!/*/}
        {/*{} />}*/}
        {/*{<Route path="/entretien" element={<EntretienPeriodique />} />}*/}
        {/*{}*/}
        {/*<Route path="*" element={<NotFound />} />*/}
      </Route>
    </Routes>
  );
}

export const OneCollaborateurRooter = styled(OneCollaborateurRooterCtrl)`
  min-height: fit-content;
  justify-content: space-between;
  .custom-content-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .icon-profile-menu{
      fill: ${props=>props.theme.primaryDarken};
      height: 25px;
      width: 25px;
    }
  }
`
