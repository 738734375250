import styled from "styled-components";
import {useLocation} from "react-router-dom";
import {
  createSelectColumn,
  MbjButton, MbjConfirmModal,
  MbjFlex,
  MbjLink, MbjLoading,
  MbjModal,
  MbjNavPath, MbjScrollArea, MbjTableV2,
  MbjWidgetClassic,
  useModal
} from "@mbj-front-monorepo/ui";
import {useDeleteEmployee, useGetEmployees, useGetRight} from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {AjoutCollaborateurModal} from "../../Metiers/Listing/AjoutCollaborateurModal/AjoutCollaborateurModal";
import {AjoutCollaborateurByCVModal} from "../../Metiers/Listing/AjoutCollaborateurByCV/AjoutCollaborateurByCVModal";
import {CiUnlock} from "react-icons/ci";
import {ModalDistribAcces} from "./ModalDistribAcces";

interface CollaborateursListingCtrlProps{
  className?:string;
}

const CollaborateursListingCtrl = (props:CollaborateursListingCtrlProps)=>{
  const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs")
  const {isLoading:loadRightR, isAuth:isAuthRead} = useGetRight("Collaborateurs", "read", "Mes collaborateurs", "OneCollab")
  const location = useLocation();

  const { isShowing: isShowingNewEmployee, toggle: toggleNewEmployee } = useModal();
  const { isShowing: isShowingNewEmployeeCV, toggle: toggleNewEmployeeCV } = useModal();
  const { isShowing: isShowingNewEmployeeSaisie, toggle: toggleNewEmployeeSaisie } = useModal();
  const { isShowing: isShowingDeleteEmployee, toggle: toggleDeleteEmployee } = useModal();
  const { isShowing: isShowingUsers, toggle: toggleUsers } = useModal();

  const employeesQuery = useGetEmployees();
  const deleteEmployee = useDeleteEmployee();

  const columnHelper = createColumnHelper<GetElementType<typeof employeesQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.nom, {
          header: "Nom",
          cell: (info) => isAuthRead ? <MbjLink to={'../'+info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink> : <span>{info.getValue()}</span>,
        }),
        columnHelper.accessor((row) => row.prenom, {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Informations liées au poste",
      columns: [
        columnHelper.accessor((row) => row.UserMetier?.namePerso || "--", {
          header: "Poste",
        }),
        columnHelper.accessor((row) => row.Service?.libelle || "--", {
          header: "Service",
        }),
        columnHelper.accessor((row) => row.salary, {
          header: "Salaire",
          filterFn: "inNumberRange",
          cell: (info) => (info.getValue() ? info.getValue() + " €" : "--"),
        }),
        columnHelper.accessor((row) => row.entryAt, {
          header: "Date d'entrée",
          cell: (info) => {
            const value = info.getValue();
            return value ? getDateFR(new Date(value)) : "--";
          },
        }),
      ],
    }),
    columnHelper.group({
      header:"Accès compte",
      columns: [
        columnHelper.accessor((row) => row.userId===null ? "NON" : "OUI", {
          header: "Accès",
        })
        ]
    })
  ];

  const table = useReactTable({
    data: employeesQuery?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
    sortingFns: {
      MySortPrenom: (rowA: any, rowB: any, columnId: any): number =>
        rowA.getValue(columnId).value.localeCompare(rowB.getValue(columnId).value)
      //rowA.getValue(columnId).value < rowB.getValue(columnId).value ? 1 : -1,
    },
  });
  const handleChoixNew = (choix:"cv"|"emp")=>{
    toggleNewEmployee();
    if(choix === "cv") {
      toggleNewEmployeeCV();
    } else {
      toggleNewEmployeeSaisie();
    }
  }
  const HideSaisie = ()=>{
    toggleNewEmployeeSaisie();
    //toggleNewEmployee();
  }
  const HideAddCv = ()=>{
    toggleNewEmployeeCV();
    //toggleNewEmployee();

  }
  return (
    <div className={`collaborateurs_listing  pageStd  ${props.className}`}>
      <MbjFlex justify={"space-between"}>
        <MbjModal isShowing={isShowingNewEmployee} hide={toggleNewEmployee} title={"Choix mode d'ajout"} >
          <MbjFlex width={"100%"} direction={"column"} gap={"20px"}>
            <MbjButton size={"lg"} type="button" onClick={()=>handleChoixNew('emp')} themeColor={"primary"} isfully={true}>
              Saisir l'employé
            </MbjButton>
            <MbjButton size={"lg"} type="button" onClick={()=>handleChoixNew("cv")} themeColor={"primary"} isfully={true}>
              Scanner un cv
            </MbjButton>
          </MbjFlex>
        </MbjModal>
        <AjoutCollaborateurModal isShowing={isShowingNewEmployeeSaisie} hide={HideSaisie} />
        <AjoutCollaborateurByCVModal isShowing={isShowingNewEmployeeCV} hide={HideAddCv} />
      </MbjFlex>
      <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {employeesQuery.isLoading ? (
          <MbjLoading />
        ) : employeesQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
              <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite) && (
                  <MbjButton themeColor={"warning"} onClick={toggleDeleteEmployee} isPending={false}>
                    Supprimer {table.getSelectedRowModel().rows.length} collaborateur(s)
                  </MbjButton>
                )}
                {isAuthWrite &&
                  <>
                  <MbjButton onClick={toggleNewEmployee} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                    Ajouter un collaborateur
                  </MbjButton>
                    <MbjButton onClick={toggleUsers} leftIcon={<CiUnlock />} alignSelf={"flex-end"}>
                      Distribuer les accès
                    </MbjButton>
                  </>
                }
              </div>
            </div>
            <MbjScrollArea overflowX={"auto"}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
      <MbjConfirmModal
        isShowing={isShowingDeleteEmployee}
        hide={toggleDeleteEmployee}
        title="Confirmation de suppression de collaborateur(s)"
        confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} collaborateur(s) ?`}
        confirmFunction={() => {
          deleteEmployee.mutate(
            table.getSelectedRowModel().rows.map((row) => row.original.id || 0),
            {
              onSuccess: () => {
                toggleDeleteEmployee();
                table.toggleAllRowsSelected(false);
              },
            }
          );
        }}
        isLoading={deleteEmployee.isLoading}
      />
      <MbjModal isShowing={isShowingUsers} hide={toggleUsers} title={"Droits d'accès"}>
        <ModalDistribAcces closeMe={toggleUsers}/>
      </MbjModal>
    </div>
  )
}

export const CollaborateursListing = styled(CollaborateursListingCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  .space-between{
    display:flex;
    justify-content:space-between;
    align-items: center;
  }
  .ResultNumber{
    font-size: 20px;
  }
`
