import styled from "styled-components";
import {AiOutlineUser} from "react-icons/ai";
import {BiBuildingHouse} from "react-icons/bi";
import {FaArrowAltCircleDown, FaSuitcase} from "react-icons/fa";
import React from "react";
import {TrashSearch} from "./TrashSearch";
import {MbjButton, MbjCardBack, MbjConfirmModal, MbjIconButton, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import {useNavigate} from "react-router-dom";
import {BsTrash} from "react-icons/bs";

import {useGetResultsSearchFormationBySearch, useGetRight, useTrashSearch} from "@mbj-front-monorepo/data-access";
import {PiStudent} from "react-icons/pi";
import {ModalRefreshSearch} from "./ModalRefreshSearch";
import {IoRefresh} from "react-icons/io5";
import {FiRefreshCcw} from "react-icons/fi";

interface OneResumeSearchCtrlProps{
    className?:string;
    item:{id:number, libelle:string, nbResultReclassement:number, nbResultParticulier:number, nbResultEmployee:number, nbResultInterne:number, updateAt:string|undefined};
    styles:{col1:string, col2:string, col3:string, col4:string};
}

const OneResumeSearchCtrl = ({className, item, styles}:OneResumeSearchCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "Recherches", "")
    const {isLoading:loadRightR, isAuth:isAuthR} = useGetRight("Dynamique de l'effectif", "read", "Recrutements", "Recherches", "oneRecherche")
    const FormationsQuery = useGetResultsSearchFormationBySearch(item.id)
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const { isShowing: showRefresh, toggle: toggleRefresh } = useModal();
  const mutationDelete = useTrashSearch();
    const navigate = useNavigate();
    const ClickOn = (e:any)=>{
      console.log('ici');
      e.stopPropagation();
      if(isAuthR) {
          console.log('is auth')
          navigate("../recherches/" + item.id)
      }
    }
    const HandleConfirmDelete = ()=>{
        mutationDelete.mutate(item.id, {
          onSuccess:()=>{
            toggleConfirm();
          }
        });
    }
    return (
      <>
        <div className={`line_resume_search ${className}`} onClick={ClickOn}>
            <MbjCardBack Flight>
                <div className={`in_line_resume_search`}>
                    <div className={`lib_search`} style={{width:styles.col1}}>{item.libelle}</div>
                    <div className={`maj_search`} style={{width:styles.col3}}>
                        Mise à jour : {item.updateAt ? new Date(item.updateAt).toLocaleDateString() + " " + new Date(item.updateAt).toLocaleTimeString() : 'Pas encore  évaluée'}
                    </div>
                  <div className={`results_search`} style={{width: styles.col2}}>
                    <div className={`info resume_part`}>
                      <div className={"Icon_resume"}>
                        <FaSuitcase/>
                        <div className={`tool_tips`}>Mobilité alliance</div>
                      </div>
                      <span className={"nbResult"}>{item.nbResultEmployee || 0}</span>
                    </div>
                    <div className={`info resume_part`}>
                      <div className={"Icon_resume"}>
                        <BiBuildingHouse/>
                        <div className={`tool_tips`}>Reclassements</div>
                      </div>
                      <span className={"nbResult"}>{item.nbResultReclassement || 0}</span>
                    </div>
                    <div className={`info resume_part`}>
                      <div className={"Icon_resume"}>
                        <AiOutlineUser/>
                        <div className={`tool_tips`}>CV thèque</div>
                      </div>
                      <span className={"nbResult"}>{item.nbResultParticulier || 0}</span>
                    </div>


                    <div className={`info resume_part`}>
                      <div className={"Icon_resume"}>
                        <PiStudent/>
                        <div className={`tool_tips`}>Formation</div>
                      </div>
                      <span className={"nbResult"}>{FormationsQuery.isLoading ? '...' : FormationsQuery.data?.FormationContinusOFS?.length}</span>
                    </div>
                  </div>
                  <div className={`wrapper_trash`}>
                    {isAuthWrite &&
                      <div className="wrap_btns" style={{display: "flex", gap: "5px"}}>
                        <MbjIconButton
                          size={"sm"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            console.log('coucou');
                            toggleConfirm();
                                }}
                                ariaLabel={"Supprimer le recrutement"}
                                icon={<BsTrash />}
                                themeColor={"warning"}
                                isRound={false}
                        />
                        <MbjIconButton
                          size={"sm"}
                          onClick={(e: any) => {
                            e.stopPropagation();
                            console.log('coucou');
                            toggleRefresh();
                          }}
                          ariaLabel={"Rafraichir"}
                          icon={<FiRefreshCcw />}
                          themeColor={"primary"}
                          isRound={false}
                        />
                      </div>
                      }
                  </div>
                </div>
            </MbjCardBack>

        </div>
          <MbjConfirmModal
              isShowing={ShowConfirm}
              hide={toggleConfirm}
              title="Confirmation suppression d'un recrutement"
              confirmText={"Voulez-vous vraiment supprimer ce recrutement ?"}
              confirmFunction={HandleConfirmDelete}
              isLoading={mutationDelete.isLoading}
          />
          <MbjModal2 open={showRefresh} closeMe={toggleRefresh} Wd={"500px"} titre={"Rafraichissement"}>
            <ModalRefreshSearch idSearch={item.id} closeMe={toggleRefresh}/>
          </MbjModal2>
          </>
    )
}

export const OneResumeSearch = styled(OneResumeSearchCtrl)`
  transition: transform .3s;
  position: relative;

  .nbResult {
    width: 32px;
    text-align: center;
    background: #d3e2f8;
    font-weight: bold;
    height: 32px;
    display: flex;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    flex-direction: column;
    justify-content: center;
  }

  .wrapper_trash {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-10px);
    transition: transform .3s;
  }

  .lib_search {
    font-size: 18px;
    color: ${props => props.theme.primary};
    font-weight: bold;
  }

  .maj_search {
    font-size: 13px;
    font-weight: bold;
  }

  .resume_part {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .results_search {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 18px;
    padding: 1rem;
  }

  .Icon_resume {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background: #ebe8e8;
    position: relative;

    svg {
      margin: auto;
    }

    &:hover {
      .tool_tips {
        display: block
      }

      cursor: pointer;
    }

    .tool_tips {
      display: none;
      padding: 0.5rem;
      font-size: 14px;
      position: absolute;
      left: 50%;
      top: calc(100% + 6px);
      width: max-content;
      background: black;
      color: white;
      border-radius: 4px;
      transform: translateX(-50%);
    }
  }
`
