import { fetchApi, Operation } from "../../services/fetchApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useGetStagiaireByOF = (OFId: number | undefined) => {
  const promise = () => fetchApi("/api/stagiaires", { method: "get", query: { OrganismeFormationMBJ: OFId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaires", { OFId }], promise, {
    enabled: !!OFId && OFId !== 0,
  });
};

export const useGetOneStagiaire = (id: number) => {
  const promise = () => fetchApi("/api/stagiaires/{id}", { method: "get", params: { id: id + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["stagiaire", { id }], promise, {
    enabled: id !== 0,
  });
};

export const useMutateStagiaire = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: any) => {
      return fetchApi("/api/stagiaires/{id}", { method: "put", params: { id: id + "" }, json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["stagiaire"]);
        toast.success("Apprenant modifié !", {});
      },
      onError: (error: any) => {
        console.log(error);
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddStagiaire = (organismeId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postStagiaireCollection", "requestBody">) => {
      return fetchApi("/api/stagiaires", {
        method: "post",
        json: { ...data, OrganismeFormationMBJ: `/api/organisme_formation_m_b_js/${organismeId}` },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires"]);
        toast.success("stagiaire ajouté !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
export const useDeleteStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (stagiaireId: number | number[]) => {
      if (Array.isArray(stagiaireId)) {
        return Promise.all(
          stagiaireId.map((stagiaireId) =>
            fetchApi("/api/stagiaires/{id}", { method: "delete", params: { id: stagiaireId + "" } })
          )
        );
      } else {
        return fetchApi("/api/stagiaires/{id}", { method: "delete", params: { id: stagiaireId + "" } });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["stagiaires"]);
        toast.success("Apprenant(s) supprimé(s)", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetStagiaireXp = (stagiaireId?: number) => {
  const promise = () => fetchApi("/api/stagiaire_xps", { method: "get", query: { Stagiaire: stagiaireId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["experiences_stagiaire", { employee: stagiaireId }],
    promise,
    {
      enabled: !!stagiaireId,
    }
  );
};

export const useAddXpProStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postStagiaireXpCollection", "requestBody">) => {
      return fetchApi("/api/stagiaire_xps", { method: "post", json: data });
    },
    {
      onSuccess: (newXp) => {
        queryClient.invalidateQueries(["experiences_stagiaire"]);
        queryClient.setQueryData(["experiences_stagiaire", newXp.id], newXp);
        toast.success("Expérience ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateXpProStagiaire = (stagiaireXpId: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putStagiaireXpItem", "requestBody">) => {
      return fetchApi("/api/stagiaire_xps/{id}", { method: "put", json: data, params: { id: stagiaireXpId + "" } });
    },
    {
      onSuccess: (newExperience) => {
        queryClient.invalidateQueries(["experiences_stagiaire"]);
        queryClient.setQueryData(["experiences_stagiaire", newExperience.id], newExperience);
        toast.success("Expérience modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteXpProStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (stagiaireXpId: number) => {
      return fetchApi("/api/stagiaire_xps/{id}", { method: "delete", params: { id: stagiaireXpId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["experiences_stagiaire"]);
        toast.success("Expérience supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetStagiaireFi = (stagiaireId?: number) => {
  const promise = () => fetchApi("/api/stagiaire_fis", { method: "get", query: { Stagiaire: stagiaireId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["fis_stagiaire", { employee: stagiaireId }], promise, {
    enabled: !!stagiaireId,
  });
};

export const useMutateFiStagiaire = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putEmployeeFIItem", "requestBody">) => {
      return fetchApi("/api/stagiaire_fis/{id}", { method: "put", json: data, params: { id: id + "" } });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["fis_stagiaire"]);
        queryClient.setQueryData(["fis_stagiaire", newFi.id], newFi);
        toast.success("Formation modifiée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useAddFiStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postStagiaireFiCollection", "requestBody">) => {
      return fetchApi("/api/stagiaire_fis", { method: "post", json: data });
    },
    {
      onSuccess: (newFi) => {
        queryClient.invalidateQueries(["fis_stagiaire"]);
        queryClient.setQueryData(["fis_stagiaire", newFi.id], newFi);
        toast.success("Formation ajoutée !", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteFiStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (stagiaireFiId: number) => {
      return fetchApi("/api/stagiaire_fis/{id}", { method: "delete", params: { id: stagiaireFiId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["fis_stagiaire"]);
        toast.success("formation supprimée", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetStagiaireCompetence = (idStagiaire: number) => {
  const promise = () => fetchApi("/api/stagiaire_competences", { method: "get", query: { Stagiaire: idStagiaire } });
  return useQuery(["competences_stagiaire", { idStagiaire }], promise, {
    enabled: idStagiaire !== 0,
  });
};

export const useGetProximitesMetier = (idStagiaire: number) => {
  const promise = () => fetchApi("/api/stagiaire_proxi_metiers", { method: "get", query: { Stagiaire: idStagiaire } });
  return useQuery(["proxi_metiers_stagiaires", idStagiaire], promise, {
    enabled: idStagiaire !== 0,
  });
};

export const useGetProximiteMetier = (idProxi: number) => {
  const promise = () => fetchApi("/api/stagiaire_proxi_metiers/{id}", { method: "get", params: { id: idProxi + "" } });
  return useQuery(["proxi_metier_stagiaire", idProxi], promise, {
    enabled: idProxi !== 0,
  });
};

export const useGetConsultProxiMetierStagiaire = (idStagiaire: number) => {
  const promise = () =>
    fetchApi("/api/stagiaire_proxi_consults", {
      method: "get",
      query: { "StagiaireProxiMetier.Stagiaire": idStagiaire + "" },
    });
  return useQuery(["proxi_stagiaire_consult", idStagiaire], promise, {
    enabled: idStagiaire !== 0,
  });
};

export const useSaveConsultProxiMetierStagiaire = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postStagiaireProxiConsultCollection", "requestBody">) => {
      return fetchApi("/api/stagiaire_proxi_consults", { method: "post", json: data });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["sessions"]);
        queryClient.invalidateQueries(["proxi_stagiaire_consult"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useRefreshProxiMetierStagiaire = (idStagiaire?: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      return fetchApi("/api/stagiaire_proxi_metiers", {
        method: "get",
        query: { Stagiaire: idStagiaire || -1, Refresh: 1 },
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(["proxi_metier_stagiaire", { idStagiaire }], data);
        queryClient.invalidateQueries(["proxi_metier_stagiaire", { idStagiaire }]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetStagiaireRecrutement = (idStagiaire: number) => {
  const promise = () =>
    fetchApi("/api/stagiaire_recrutements/{id}", { method: "get", params: { id: idStagiaire + "" } });
  return useQuery(["stagiaire_recrutement", idStagiaire], promise, {
    enabled: idStagiaire !== 0,
  });
};

export const useGetStagiaireRecrutementAll = (idOF: number) => {
  const promise = () => fetchApi("/api/stagiaire_recrutements", { method: "get", query: { OF: idOF } });
  return useQuery(["stagiaire_recrutements", idOF], promise, {
    enabled: idOF !== 0,
  });
};
