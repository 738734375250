import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";
import { useGetMemberMe } from "../member";


export const useGetAllEquipeManager = () => {
  const promise = () => fetchApi("/api/equipe_managers", { method: "get"});
  return useQuery(["equipe_managers"], promise);
};



export const useGetAllListEquipeManager = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/equipe_managers/by_member/{id}", { method: "get", params: { id: memberId },});
  return useQuery(["equipe_managers_liste"], promise, {enabled: !!memberId});
};

export const useGetAllListEquipeManagerByEmployee = (employeeId: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/equipe_managers/by_employee/{id}/from_member/{memberID}",
    { method: "get", params: { memberID: memberId, id: employeeId },});
  return useQuery(["equipe_managers_byemployee_from_member", employeeId, memberId], promise, {enabled: !!memberId && !!employeeId});
};
export const useGetOneEquipeManager = (teamID: number) => {
  const promise = () => fetchApi("/api/equipe_managers/{id}", { method: "get", params: { id: teamID + "" }});
  return useQuery(["equipe_managers_one_detail", teamID], promise, {
    enabled: !!teamID,
  });
};

export const useGetEmployeeByUserID = (userId: number) => {
  const promise = () => fetchApi("/api/employees", { method: "get", query: { userId: userId  } });
  return useQuery(["employees_by_userid", { userId }], promise, {
    enabled: !!userId,
  });
};


export const useGetListPotentialsManager = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employees/list_team_managers/{id}", { method: "get",  params: { id: memberId },});
  return useQuery(["team_list_managers", { memberId }], promise, {
    enabled: !!memberId,
  });
};


export const useGetListPotentialsLeadService = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/employees/list_service_team/{id}", { method: "get",  params: { id: memberId },});
  return useQuery(["team_list_managers", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useAddNewEquipeManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: any) => {
      return fetchApi("/api/equipe_managers", {
        method: "post",
        json: { ...data },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_liste"]);
        toast.success("Equipe créé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};



export const usePutEquipeManager = (idEquipe:number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"putEquipeManagerItem", "requestBody">) => {
      return fetchApi("/api/equipe_managers/{id}", {
        method: "put",
        params: {id:idEquipe+""},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_liste"]);
        toast.success("Equipe modifiée !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useTrashEquipeManager = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (idEquipe:number) => {
      return fetchApi("/api/equipe_managers/{id}", {
        method: "delete",
        params: {id:idEquipe+""},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_liste"]);
        queryClient.invalidateQueries(["equipe_managers_byemployee_from_member"]);
        toast.success("Equipe supprimée !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};


export const useEditLeadManager = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: any) => {
      return fetchApi("/api/equipe_managers/{id}", {
        method: "put",
        json: data,
        params: {id: id + ""}
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["equipe_managers"]);
        queryClient.invalidateQueries(["equipe_managers_liste"]);
        queryClient.invalidateQueries(["employees_by_userid"]);
        toast.success("Equipe créé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
