import * as React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { MbjButton, MbjDragDropFile, MbjLoadingMessage, MbjWidgetClassic } from "@mbj-front-monorepo/ui";
import { InfoWait } from "./component/InfoWait";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BsCheck } from "react-icons/bs";
import { ErrorLoading } from "./component/ErrorLoading";
import { queryClient } from "../../main/App";

async function GetResultAPi(datas: { file: File | null }) {
  const myFormData = new FormData();
  if (datas.file) {
    myFormData.append("file", datas.file);
    const token = window.localStorage.getItem("__user_token__");
    const response = await fetch(`${process.env["REACT_APP_API_URL"]}/api/metiers/send_fdp`, {
      method: "POST",
      headers: {
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
        Authorization: `Bearer ${token}`,
      },
      body: myFormData,
    });
    if (response.ok) {
      queryClient.invalidateQueries(["user_metiers_statistiques"]);
    }
    return await response.json();
  } else {
    return;
  }
}

const useGetMyApi = (file: File | null) => {
  return useQuery(["fdposte", file ? file.name : ""], () => GetResultAPi({ file: file }), { enabled: !!file });
};

interface AjoutFDPosteCtrlProps {
  className?: string;
  CloseMe?: () => void;
}

export interface WaitingZone {
  libelle: string;
  timeToStop: number;
  index: number;
}

const TabWait: WaitingZone[] = [
  { libelle: "Enregistrement du/des fichier(s)", timeToStop: 1, index: 1 },
  { libelle: "Conversion en pdf", timeToStop: 2, index: 2 },
  { libelle: "OCR", timeToStop: 7, index: 3 },
  { libelle: "Interpretation-extraction des compétences du document", timeToStop: 16, index: 4 },
  { libelle: "Interprétation des formations sur reférences MBJ", timeToStop: 21, index: 5 },
  { libelle: "Sauvegarde des résultats", timeToStop: 20, index: 6 },
  { libelle: "Mise en forme des resultats pour l'insertion dans la base de données", timeToStop: 20, index: 7 },
  { libelle: "Insertion des résultats dans la BDD ", timeToStop: 20, index: 8 },
];

const AjoutFDPosteCtrl = (props: AjoutFDPosteCtrlProps) => {
  const queryClient = useQueryClient();
  const [cv, setCV] = useState<File | null>(null);
  const [indexFinish, setIndexFinish] = useState(1);
  const [endLoading, setEndLoading] = useState(false);
  const [keyUp, setKeyUp] = useState("");
  const [loadOnError, setLoadOnError] = useState(false);
  const CvQuery = useGetMyApi(cv);
  const navigate = useNavigate();
  const setFinish = (index: number) => {
    console.log("set index finish >>" + index);
    setIndexFinish(index);
  };
  useEffect(() => {
    console.log(indexFinish);
  }, [indexFinish]);
  const handleNewCV = () => {
    setKeyUp(Math.random().toString(36));
    setEndLoading(false);
    setCV(null);
    setIndexFinish(1);
  };
  const goCollaborateurs = () => {
    setEndLoading(false);
    setCV(null);
    setIndexFinish(1);
    setLoadOnError(false);
    if (props.CloseMe) {
      props.CloseMe();
    } else {
      navigate("/entreprise/collaborateurs");
    }
  };
  useEffect(() => {
    if (CvQuery.data && CvQuery.data.response === '{"response":1}' && cv && indexFinish > 1) {
      queryClient.invalidateQueries(["cvtheque"]);
      setEndLoading(true);
    }
  }, [CvQuery.data, cv]);
  const CloseErrorLoad = () => {
    setEndLoading(false);
    setCV(null);
    setIndexFinish(1);
    setLoadOnError(false);
  };
  return (
    <div className={"AjoutFDPoste" + props.className}>
      <MbjWidgetClassic title="" bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {endLoading}
        {!endLoading && !cv && !loadOnError && (
          <MbjDragDropFile
            key={keyUp}
            accept={
              "image/png, image/jpeg, image/jpg, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document "
            }
            full={true}
            onUpload={(files) => {
              setCV(files[0]);
              console.log(files[0]);
            }}
            count={1}
            formats={["png", "jpeg", "jpg", "pdf", "docx", "doc"]}
          />
        )}
        {cv && CvQuery.isLoading && !loadOnError && (
          <div className={`loading-wait`}>
            <MbjLoadingMessage>
              <p>Le temps de traitement du document est d'environ 30 secondes.</p>
            </MbjLoadingMessage>
            {TabWait.map((item: WaitingZone) => (
              <InfoWait
                WZ={item}
                key={`oneItemZone${item.index}`}
                setFinish={setFinish}
                currentIndex={indexFinish}
                index={item.index}
              />
            ))}
          </div>
        )}
        {endLoading && !loadOnError && (
          <div className={`Block_end`}>
            <div className={`wrap_icon`}>
              <div className={`Rond_icon`}>
                <BsCheck />
              </div>
            </div>
            <div className={`text`}>
              La fiche de poste à été correctement traitée, vous retrouverez les informations dans les métiers de
              l'entreprise
            </div>
            <div className={`wrap_action`}>
              <MbjButton size={"md"} type="button" onClick={handleNewCV} themeColor={"primary"}>
                Nouvelle fiche de poste
              </MbjButton>
              <MbjButton size={"md"} type="button" onClick={goCollaborateurs} themeColor={"primary"}>
                Fermer
              </MbjButton>
            </div>
          </div>
        )}
        {loadOnError && <ErrorLoading closeMe={CloseErrorLoad} />}
      </MbjWidgetClassic>
    </div>
  );
};

export const AjoutFDPoste = styled(AjoutFDPosteCtrl)((props) => ({
  padding: "1% 2%",
  display: "flex !important",
  flexDirection: "column",
  height: "100%",
  ".wrap_action": {
    display: "flex",
    gap: "15px",
    justifyContent: "center",
    margin: "15px auto",
  },
  ".Block_end": {
    width: "80%",
    margin: "auto",
    padding: "2rem",
    //background:"#aee1a8",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    gap: "10px",
    ".text": {
      textAlign: "center",
      fontSize: "18px",
      color: "#177117",
    },
    ".Rond_icon": {
      width: "90px",
      height: "90px",
      border: "solid #177117 1px",
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      margin: "auto",
      svg: {
        margin: "auto",
        fontSize: "47px",
        color: "#177117",
      },
    },
  },
}));
