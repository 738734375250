import styled from "styled-components";
import {
    components,
    useGetResultsSearchFormationBySearch,
    useGetSearchOne,
    useGetUserMetierFormation
} from "@mbj-front-monorepo/data-access";
import {useParams} from "react-router-dom";
import {MbjLoading, useModal} from "@mbj-front-monorepo/ui";
import {CardFormation} from "./CardFormation";
import {useEffect, useMemo, useState} from "react";

interface VivierFormationCtrlProps{
    className?:string;
}

const VivierFormationCtrl = (props:VivierFormationCtrlProps)=>{
    const {id} = useParams();
    const FormationsQuery = useGetResultsSearchFormationBySearch(id ? parseInt(id) : -1)
    const formationsSorted = useMemo(()=>{
        const list:(components["schemas"]["FormationContinuOF-read.reasult_formation"])[] = [];
        if(FormationsQuery.data && FormationsQuery.data.FormationContinusOFS){
            const myTab:{id:number, dist:number}[]=[];
            FormationsQuery.data.FormationContinusOFS.forEach((i)=>{
                const OFS = i.OrganismeFormation;
                const OFSSorted = OFS?.sort((a, b)=>(a.distance||0)>(b.distance||0) ? 1 :-1);
                myTab.push({id:i.id||0, dist:OFSSorted ? (OFSSorted[0].distance||0) : 2000})
            })
            const myTabSorted = myTab.sort((a, b)=>a.dist > b.dist ? 1 : -1);
            myTabSorted.forEach(a=>{
                const my = FormationsQuery.data?.FormationContinusOFS?.find(e=>e.id === a.id)
                if(my){
                    list.push(my);
                }
            })
        }
        return list;
    }, [FormationsQuery.data])
    return (
        <div className={`vivier_formation ${props.className}`}>
            {FormationsQuery.isLoading ? <MbjLoading/>:
                formationsSorted.map(item=>(
                    <CardFormation Formation={item} key={`one_vivier${item.id}`}/>
                ))
            }
        </div>
    )
}

export const VivierFormation = styled(VivierFormationCtrl)`
  padding: 2% 1%;
`

