import {useGetRight} from "@mbj-front-monorepo/data-access";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import React from "react";
import {MbjLoading} from "@mbj-front-monorepo/ui";

interface PrivateRouteSubProps{
    Onglet:string;
    SubMenu?:string,
    SubPage?:string,
    SubPage2?:string
}

export function PrivateRouteSub(props: PrivateRouteSubProps){
    const location = useLocation();
    const {isLoading, isAuth} = useGetRight(props.Onglet, "read", props.SubMenu, props.SubPage, props.SubPage2)
    return isLoading ? <Outlet /> : isAuth ? <Outlet /> : <p>{isAuth ? 'o' : 'n'}</p>;
}
