import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  MbjButton, MbjListItem, MbjLoading, MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer,
  MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import {
  useAddFi,
  useGetDisciplines,
  useGetNiveaux,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import { getDateEN, GetElementType } from "@mbj-front-monorepo/utils";
import { useQueryClient } from "@tanstack/react-query";

interface MbjEmployeeFiNewFormIACtrlProps {
  className?: string;
  idEmployee: number;
  hideModal?: () => void;
  hideChoiceModal?: () => void;
  isMobile?:boolean;
}

const MbjEmployeeFiNewFormIACtrl = (props: MbjEmployeeFiNewFormIACtrlProps) => {
  const queryClient = useQueryClient();
  const niveauxQuery = useGetNiveaux();
  const disciplinesQuery = useGetDisciplines();
  const [search, setSearch] = useState('')
  const [result, setResult] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    mode: "onChange",
  });


  const onSubmit = async (data: any) => {
    setLoading(true)
    data.employee = props.idEmployee
    const url = `${process.env["REACT_APP_API_URL"]}/api/custom/formations_certifiantes_insertion`
    fetch(url, {
      method: 'POST',
      headers: {
        "Accept": 'application/json'
      },
      body: JSON.stringify({data})
    })
      .then((res: any) => {
        if (props?.hideModal) {
          props.hideModal();
        }
        if (props?.hideChoiceModal) {
          props.hideChoiceModal();
        }
        queryClient.invalidateQueries(["formation_initiales"]);
      })
      .catch((err: any) => console.log(err))
      .finally(() => setLoading(false))
  };
  const handleSearchLibelle = async (search: string) => {
    setSearch(search)
    try {
      const url = `${process.env["REACT_APP_API_URL"]}/api/custom/formations_certifiantes?phrase=${search}&employee_id=${props.idEmployee}`
      const req = await fetch(url, {
        headers: {
          "Accept": 'application/json'
        }
      });
      setResult(await req.json())
    } catch (error) {
      console.error('Erreur lors de la requête:', error);
      throw error;
    }
  }


  const clickOnSearchLibelle = (formation: {libelle: string}) => {
    setValue('nom', formation.libelle)
    setSearch('')
  }

  return (
    <div className={`FormXpPro ${props.className}`}>
      <form onSubmit={handleSubmit(onSubmit)} className="formNewFormationInitiale">
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Saisir le libellé de la fomation"}>
            <MbjSimpleTextArea name={`description`} id={`description`} noPadding register={register} onChange={(e) =>handleSearchLibelle( e.target.value)} />
          </MbjSimpleInputContainer>
        </div>
        <div className={"rowContainer"}>
          <MbjSimpleInputContainer errors={errors} label={"Nom exact du diplôme *"}>
            <MbjSimpleInput
              name={"nom"}
              id={"nom"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Nom requis",
                },
                minLength: {
                  value: 5,
                  message: "Nom trop court",
                },
              }}
            />
          </MbjSimpleInputContainer>

          <MbjSimpleInputContainer errors={errors} label={"Date d'obtention *"}>
            <MbjSimpleInput
              name={"obtentionAt"}
              id={"obtentionAt"}
              type={"date"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Date d'obtention requise",
                },
              }}
            />
          </MbjSimpleInputContainer>
        </div>

        <div className="zoneSearch">
          {search !== "" &&
            <div className="resultats">
              {search === "" ? (
                <React.Fragment />
              ) : result.length === 0 ? (
                <MbjLoading />
              ) : (
                <>
                  <MbjScrollArea maxHeight={"390px"}>
                    {result.reduce((acc: any, curr: any) => acc.concat(curr), []).map((formation: any, idx: number) => (
                      <MbjListItem className="one-choice-activite" onClick={() => clickOnSearchLibelle(formation)}
                                   key={idx}>
                        {formation.libelle}
                      </MbjListItem>
                    ))}
                  </MbjScrollArea>

                </>
              )}

            </div>
          }
        </div>
        <MbjButton isPending={loading}>Ajouter la formation</MbjButton>
      </form>
    </div>
  );
};

export const MbjEmployeeFiNewFormIA = styled(MbjEmployeeFiNewFormIACtrl)((props) => ({
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    ".on_col":{
      display: "flex",
      flexDirection:"column",
      gap: "10px",
    },
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      gap: "20px",
      flexWrap: "wrap",
    },
    ".resultats": {
      position: "absolute",
      left: "0",
      top: "100%",
      background: "white",
      padding: "0.2rem",
      zIndex: "2",
      width: "100%",
      boxShadow: "1px 1px 3px rgba(0,0,0,.25)",
    },
    "zoneSearch": {
      position: "relative"
    },
    ".niveauInfos": {
      "& > *": {
        flex: 1,
      },
    },
    button: {
      alignSelf: "flex-end",
    },
  },
}));
