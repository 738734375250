import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjListItem, MbjLoading} from "@mbj-front-monorepo/ui";
import {useGetUserMetiers} from "@mbj-front-monorepo/data-access";
import * as React from "react";

interface ListMetiersCtrlProps{
    className?:string;
}

const ListMetiersCtrl = (props:ListMetiersCtrlProps)=>{
    const userMetiersQuery = useGetUserMetiers();
    return (
        <MbjCardBack title={"liste de vos métiers"} height={"100%"}>
            <div className={`list_metiers ${props.className}`}>
                {userMetiersQuery.isLoading ? <MbjLoading/> : userMetiersQuery.isError ? <MbjErrorData/>:
                    (
                        userMetiersQuery.data?.map((userMetier) => {
                                return (
                                    <MbjListItem key={userMetier.id}>
                                        <span>{userMetier.namePerso}</span>
                                    </MbjListItem>
                                );
                            })
                    )
                }
            </div>
        </MbjCardBack>
    )
}

export const ListMetiers = styled(ListMetiersCtrl)`
`
