import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetAlliance, useGetStats} from "@mbj-front-monorepo/data-access";
import {MbjCardBack, MbjErrorData, MbjLoading, MbjNumber, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {CardInformationOneAlliance} from "./components/CardInformationOneAlliance";

interface AllianceInformationsCtrlProps{
    className?:string;
}

const AllianceInformationsCtrl = (props:AllianceInformationsCtrlProps)=>{
    const { id } = useParams();
    const AllianceQuery = useGetAlliance(parseInt(id || "-1"));
    const StatsAllianceQuery = useGetStats(parseInt(id || "-1"));
    return (
        <div className={`one_alliance_info ${props.className}`}>
            {AllianceQuery.isLoading ? <MbjLoading/> : AllianceQuery.isError ? <MbjErrorData/> :
                <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
                    <MbjWidgetClassic basis={"47%"}>
                        <CardInformationOneAlliance Alliance={AllianceQuery.data}/>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic grow={1} bodyDirection={"row"} gap={"10px"}>
                        <MbjWidgetClassic basis={`calc(47% - 10px)`}>
                            <MbjCardBack>
                                <MbjNumber number={AllianceQuery.data && AllianceQuery.data.nbMembre ? AllianceQuery.data.nbMembre : 0} themeColor={"third"} legend={"Membres"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={`calc(47% - 10px)`}>
                            <MbjCardBack>
                                <MbjNumber number={StatsAllianceQuery.data && StatsAllianceQuery.data.nbEmployees ? StatsAllianceQuery.data.nbEmployees : 0} themeColor={"third"} legend={"Employées"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={`calc(47% - 10px)`}>
                            <MbjCardBack>
                                <MbjNumber number={StatsAllianceQuery.data && StatsAllianceQuery.data.nbSearch ? StatsAllianceQuery.data.nbSearch : 0} themeColor={"third"} legend={"Recrutements"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={`calc(47% - 10px)`}>
                            <MbjCardBack>
                                <MbjNumber number={StatsAllianceQuery.data && StatsAllianceQuery.data.nbReclassements ? StatsAllianceQuery.data.nbReclassements : 0} themeColor={"third"} legend={"Reclassements"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>
            }
        </div>
    )
}

export const AllianceInformations = styled(AllianceInformationsCtrl)`
    padding: 3% 2%;
`
