import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading, MbjNoResult, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetXp} from "@mbj-front-monorepo/data-access";
import {MbjEmployeeXpCard} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface OneCollaborateurXpResumeCtrlProps{
    className?:string;
}

const OneCollaborateurXpResumeCtrl = (props:OneCollaborateurXpResumeCtrlProps)=>{
    const {id} = useParams();
    const xpQuery = useGetXp(parseInt(id ? id : ""));
    return (
        <MbjWidgetClassic bodyDirection={"column"} gap={"10px"} basis={"100%"}>
            {xpQuery.isLoading ? <MbjLoading/> : xpQuery.isError ? <MbjErrorData/> :
                xpQuery.data.length > 0 ?
                    <>
                    {xpQuery.data?.map((xp) =>(
                        <MbjCardBack key={xp.id}>
                            <MbjEmployeeXpCard EmployeeXp={xp} isReadOnly/>
                        </MbjCardBack>
                    ))}
                    </>:
                    <MbjCardBack title={"Expériences professionelles"}>
                        <MbjNoResult text={"Aucune expérience pour le moment"}/>
                    </MbjCardBack>

            }
        </MbjWidgetClassic>
    )
}

export const OneCollaborateurXpResume = styled(OneCollaborateurXpResumeCtrl)``
