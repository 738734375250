import styled from "styled-components";
import {components, useGetMemberServices, useGetRight} from "@mbj-front-monorepo/data-access";
import {useModal} from "@mbj-front-monorepo/ui";
import {useState} from "react";
import * as React from "react";

interface EntrepriseSettingsCtrlProps{
  className?:string;
}


const EntrepriseSettingsCtrl = (props:EntrepriseSettingsCtrlProps)=>{

  return (
    <div className={`entreprise_equipe pageStd ${props.className}`}>
    </div>
  )
}

const EntrepriseSettings = styled(EntrepriseSettingsCtrl)``


export default EntrepriseSettings;
