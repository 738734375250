import styled from "styled-components";
import * as React from "react";
import {MbjLineCompareCible} from "../../atoms/MbjActivite/MbjLineCompareCible";
import {BaseColorsTheme} from "../../../assets/styles/ThemeType";


interface MbjTabloCompareActiCtrlProps{
    className?:string;
    listActi:{libelle:string, id:number, niveau:number, niveauCible:number}[];
    ActionClickOne:(id:number)=>void;
    colorNiveau:BaseColorsTheme;
    colorNiveauCible:BaseColorsTheme;
}


const MbjTabloCompareActiCtrl = (props:MbjTabloCompareActiCtrlProps)=>{
    return (
        <div className={`list_actvites ${props.className}`}>
            <div className={"head"}>
                <div className="lib_item"></div>
                <div className="lib_nivo">Connaissance théorique, sans mise en pratique de l'activité</div>
                <div className="lib_nivo">Mise en oeuvre en situation normale mais supervision nécessaire</div>
                <div className="lib_nivo">Mise en oeuvre autonome et capacité à régler les dysfonctionnements</div>
                <div className="lib_nivo">Mise en oeuvre autonome y compris en situation complexe.</div>
                <div className="lib_nivo">Expert reconnu de l'activité</div>
                <div style={{width:"10px"}} />
            </div>
            <div className={`wrap_list`}>
                <div className={`in_list`}>
                    {props.listActi.sort((a,b)=>a.libelle > b.libelle ? 1 : -1).map(acti=>(
                        <MbjLineCompareCible
                            key={`LieA${acti.id}`}
                            idActivite={acti.id}
                            libelle={acti.libelle}
                            niveau={acti.niveau}
                            niveauCible={acti.niveauCible}
                            actionClick={props.ActionClickOne}
                            colorNiveau={props.colorNiveau}
                            colorNiveauCible={props.colorNiveauCible}
                        />
                    ))}
                </div>
            </div>
        </div>
    )
}

export const MbjTabloCompareActi = styled(MbjTabloCompareActiCtrl)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .wrap_list{
    flex-grow: 1;
    overflow-y: scroll;
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .lib_nivo{
    width: ${`${60/5}%`};
    text-align: center;
    font-size: 12px;
    padding: 0.5rem;
    border-right: dashed black 1px;
  }
  .lib_item{
    width: 40%;
  }
`
