import styled from "styled-components";
import {ItemMenuChoixOnPage} from "../../../../index";


interface MbjMenuChoixOnPageCtrlProps{
    className?:string;
    Items:ItemMenuChoixOnPage[];
    setChoix:(i:ItemMenuChoixOnPage)=>void;
    current:ItemMenuChoixOnPage;
    size?:string;
}

const MbjMenuChoixOnPageCtrl = (props:MbjMenuChoixOnPageCtrlProps)=>{

    return (
        <div className={`menu_choix_on_page ${props.className}`}>
            {props.Items.map((item)=>(
                <div onClick={()=>props.setChoix(item)} className={`one_menu_choice ${item.id === props.current.id ? 'current' : ''}`}  key={`omc${item.id}`}>{item.libelle} {item.nbResult ? `(${item.nbResult})` : ''}</div>
            ))}
        </div>
    )
}

export const MbjMenuChoixOnPage = styled(MbjMenuChoixOnPageCtrl)`
  margin: 15px auto;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  .one_menu_choice{
    padding: 0.5rem 1rem;
    font-size: ${props=>props.size ? props.size : "18px"};
    &.current{
      border-bottom: solid ${props => props.theme.primary} 4px;
      font-weight: bold;
    }
    &:hover{
      font-weight: bold;
      cursor: pointer;
    }
  }
`
