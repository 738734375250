import styled from "styled-components";
import {GetElementType} from "@mbj-front-monorepo/utils";
import {components, Operation, useChangeDroit} from "@mbj-front-monorepo/data-access";
import {MbjSwitch, MbjSwitchThumb} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface GestionOneDroitsCtrlProps{
    className?:string;
    User:components["schemas"]["User-read.User.simple"];
    Droit:components["schemas"]["Droits.jsonld"];
}

const GestionOneDroitsCtrl = ({className, User, Droit}:GestionOneDroitsCtrlProps)=>{
    const mutation = useChangeDroit();
    return (
      <div className={`gestion_one_droit ${className}`}>

        <div className="wrap_switch">
          <span className={`lib_droit`}>{Droit.libelle}</span>
          <MbjSwitch
            defaultChecked={User.droits?.indexOf(Droit.id + "" || "0") !== -1}
            onCheckedChange={() => mutation.mutate({id: User.id || 0, idDroit: Droit.id})}
          >
            <MbjSwitchThumb/>
          </MbjSwitch>
        </div>
        <div className="descr">{Droit.description}</div>
      </div>
    )
}

export const GestionOneDroits = styled(GestionOneDroitsCtrl)`
    margin-bottom: 12px;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  .wrap_switch{
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    padding: 0.25rem 0;
  }
  .lib_droit{
    width: 120px;
    font-weight: bold;
  }
`
