import {useMutation, useQueryClient} from "@tanstack/react-query";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useAddSearchsCompNoFind = () => {
  return useMutation(
    (data: Operation<"postSearchsCompNoFindCollection", "requestBody">) => {
      return fetchApi("/api/searchs_comp_no_finds", {
        method: "post",
        json: { ...data },
      });
    }
  );
};
