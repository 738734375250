import styled from "styled-components";
import {
  components, Operation, useGetRight,
  useModifyStatutSearchEmp,
  useModifyStatutSearchPart,
  useModifyStatutSearchRecl
} from "@mbj-front-monorepo/data-access";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
import {useEffect, useState} from "react";
import {InformationsParticulier} from "./InformationsParticulier";
import {InformationsEmployee} from "./InformationsEmployee";
import {MbjButton} from "@mbj-front-monorepo/ui";
import {InformationsReclassement} from "./InformationsReclassement";
import {
  GereSaveProfil
} from "../../../pages/DynamiqueEffectif/Recrutements/03_ProfilsRetenus/components/ProfilRetenuComponents/GereSaveProfil";


interface CompareInterneCtrlProps{
  className?:string;
  idEmp?:number;
  UserMetierId?:number;
  MetierId?:number;
}

export interface ItemMenuResult{
  id:number;
  libelle:string;
}

const MesMenusResult:ItemMenuResult[]=[
  {id:1, libelle:"Informations générales"},
  {id:2, libelle:"Comparaison"},
]
const MesMenusResultToGere:ItemMenuResult[]=[
  {id:1, libelle:"Informations générales"},
  {id:2, libelle:"Comparaison"},
]

const CompareInterneCtrl = (props:CompareInterneCtrlProps)=>{
  const {isLoading:loadRightR, isAuth:isAuthW} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "Recherches", "oneRecherche")
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult>({id:1, libelle:"Informations générales"})


  return (
    <div className={`compare ${props.className}`}>
      <div className={"MenuChoix"}>
        {MesMenusResult.map((item) => (
          <div onClick={() => setMenuCurrent(item)}
               className={`one_menu_choice ${item.id === menuCurrent.id ? 'current' : ''}`}
               key={`omc${item.id}`}>{item.libelle}</div>
        ))}
      </div>
      {menuCurrent.id === 1 ?
        (
          <div className={"content_informations"}>
            <InformationsEmployee idEmpl={props.idEmp || -1}/>
          </div>
        ) :
        <div className={"wrap_compare"}>
          <MbjCompareReferentiel
            idToCompare1={props.idEmp || -1}
            idToCompare2={
              props.UserMetierId ? (props.UserMetierId || -1) :
                props.MetierId ? (props.MetierId || -1) : -1
            }
            typeCompared={
              props.UserMetierId ? "EUM" :
                props.MetierId ? "EM" : "UMUM"}
          />
        </div>
      }
    </div>
  )
}

export const CompareInterne = styled(CompareInterneCtrl)`
  background: #eaf3f4;
  height: 100%;
  display: flex;
  flex-direction: column;

  .actions {
    display: flex;
    justify-content: flex-end;
    padding: 0.5rem 1.5rem 0;
  }

  .wrap_compare {
    padding: 1.5rem;
    flex-grow: 1;
    height: 75vh;
    overflow-y: auto;
  }

  .content_informations {
    flex-grow: 1;
    height: 80vh;
    overflow-y: auto;
    padding: 1rem 0;
  }

  .MenuChoix {
    width: 100%;
    padding: 0.5rem 1.5rem;
    margin: 0 auto 15px auto;
    display: flex;
    justify-content: flex-start;

    .one_menu_choice {
      padding: 0.5rem 1rem;

      &.current{
        border-bottom: solid ${props => props.theme.primary} 4px;
        font-weight: bold;
      }
      &:hover{
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
`
