import styled from "styled-components";
import {MbjCardBack, MbjLoading, MbjNumber, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {ObjectifSectionMetier} from "./ObjectifSectionMetier";
import {RepartitionMetiers} from "../../Entreprise/Dashboard/RepartitionMetiers";
import {RepartitionEffectifMetier} from "./RepartitionEffectifMetier";
import {useGetMetiers, useGetUserMetiers} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";

interface MetiersDashboardCtrlProps{
    className?:string;
}

const MetiersDashboardCtrl = (props:MetiersDashboardCtrlProps)=>{
    const UserMetierQuery = useGetUserMetiers();
    const MetierQuery = useGetMetiers();
    const NouveauxMetiers = useMemo(()=>{
      const UserMetiersConcerned = UserMetierQuery.data?.filter(um=>um.namePerso !== um.Metier?.libelle)
      return UserMetiersConcerned?.length||0
    }, [UserMetierQuery.data])
    return (
        <div className={`metiers_dash pageStd ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"column"}  bodyGap={"10px"} justify={"flex-start"}>
                <MbjWidgetClassic bodyDirection={"row"}>
                    <MbjWidgetClassic basis={"100%"}>
                        <ObjectifSectionMetier/>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>
                <div style={{height:"500px"}}>
                <MbjWidgetClassic bodyDirection={"row"} bodyGap={"10px"}>
                    <MbjWidgetClassic basis={"calc(33.33% - 7px)"}  bodyDirection={"column"} bodyGap={"10px"}  >
                        <MbjWidgetClassic grow={1}>
                            <MbjCardBack>
                              {UserMetierQuery.isLoading ?
                                <MbjLoading/>:
                                <MbjNumber number={UserMetierQuery.data?.length||0} legend={"Métiers décrits"} themeColor={"primary"}/>
                              }

                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic grow={1}>
                            <MbjCardBack>
                              {UserMetierQuery.isLoading ?
                                <MbjLoading/>:
                                <MbjNumber number={NouveauxMetiers} legend={"Nouveaux métiers"} themeColor={"primary"}/>
                              }
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic grow={1}>
                            <MbjCardBack>
                              {MetierQuery.isLoading ?
                                <MbjLoading/>:
                                <MbjNumber number={MetierQuery.data?.length||0} legend={"Fiches métiers"} themeColor={"primary"}/>
                              }
                            </MbjCardBack>
                        </MbjWidgetClassic>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic basis={"calc(33.33% - 7px)"}>
                        <RepartitionEffectifMetier/>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic basis={"calc(33.33% - 7px)"}>
                        <RepartitionMetiers/>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>
                </div>
            </MbjWidgetClassic>
        </div>
    )
}

const MetiersDashboard = styled(MetiersDashboardCtrl)``


export default MetiersDashboard;
