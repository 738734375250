import styled from "styled-components";
import {Navigate, Route, Routes} from "react-router-dom";
import {InternDesktopLayout, MbjLink, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import * as React from "react";
import { NotFound } from "../../../components/NotFound/NotFound";
import EntrepriseSettings from "./EntrepriseSettings";
import { Questions } from "./Questions";
interface SettingsRooterCtrlProps{
  className?:string;
}

const SettingsRooterCtrl = (props:SettingsRooterCtrlProps)=>{
  return (
    <div className={"Formation_rooter" + props.className}>
      <Routes>
        <Route
          element={
            <InternDesktopLayout
              type="Entreprise"
              mbjSectionMenu={
                <MbjSectionMenu>
                    <MbjLink to="dashboard">Tableau de bord</MbjLink>
                    <MbjLink to="entretien">Gestions des entretiens</MbjLink>
                </MbjSectionMenu>
              }
            />
          }
        >
          <Route path="" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<EntrepriseSettings />} />
          <Route path="entretien" element={<Questions />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </div>
  );
}

export const SettingsRooter = styled(SettingsRooterCtrl)`
  height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

