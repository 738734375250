import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetEmployees} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import {MbjCardBack, MbjErrorData, MbjLoading} from "@mbj-front-monorepo/ui";

interface OneMetierEmployeeCtrlProps{
    className?:string;
}

interface OneEmpMetier{
    id:number,
    prenom:string;
    nom:string;
}

const OneMetierEmployeeCtrl = (props:OneMetierEmployeeCtrlProps)=>{
    const {id} = useParams();
    const EmployeesQuery = useGetEmployees()
    const listEmployee = useMemo(()=>{
        const list:OneEmpMetier[] = []
        if(EmployeesQuery.data){
            const idSearch = id ? parseInt(id) : -1;
            const myEmps = EmployeesQuery.data.filter(e=>(e.UserMetier?.id||0) === idSearch)
            myEmps.forEach(e=>{
                list.push({id:e.id||0, prenom:e.prenom||'nc', nom:e.nom||'nc'})
            })
        }
        return list
    }, [EmployeesQuery.data, id])

    return (
        <MbjCardBack title={"Employés dans le métier"}>
            <div className={`list_emp_metier ${props.className}`}>
                {EmployeesQuery.isLoading ? <MbjLoading/> : EmployeesQuery.isError ? <MbjErrorData/> :
                    listEmployee.map(item=>(
                        <div className={`one_emp`} key={`one_empM${item.id}`}>{item.prenom} {item.nom}</div>
                    ))
                }
            </div>
        </MbjCardBack>
    )
}

export const OneMetierEmployee = styled(OneMetierEmployeeCtrl)`
  .one_emp{
    padding: 0.25rem;
    border-bottom: ${props => props.theme.primaryPastel} solid 1px;
    font-weight: bold;
  }
`
