import {MbjCardBack} from "@mbj-front-monorepo/ui";
import styled from "styled-components";
import {ReactNode, useMemo} from "react";
import {FaStar} from "react-icons/fa";

interface OneBadgeStickCtrlProps{
    className?:string;
    libelle:string;
    niveau?:number;
}

const OneBadgeStickCtrl = ({className, libelle, niveau}:OneBadgeStickCtrlProps)=>{
    const myStars:ReactNode[] = useMemo(()=>{
        const myRealNivo = niveau ? niveau : 0;
        const myTabStars:ReactNode[] = [];
        for(let i=1; i<=5; i++){
            myTabStars.push(<FaStar className={`star ${ myRealNivo>=i ? 'fully' : 'empty'}`} key={`s${i}`}/>);
        }
        return myTabStars;
    }, [niveau])
    return (

            <div className={`${className}`}>
                <MbjCardBack height={"100%"}>
                    <div className={`libelle`}>{libelle}</div>
                    {niveau &&
                        <div className={`niveau`}>{myStars}</div>
                    }
                </MbjCardBack>
            </div>

    )
}

export const OneBadgeStick = styled(OneBadgeStickCtrl)`
  padding: 0.5rem;
  flex-basis: 33.33%;
  .libelle{
    font-weight: bold;
    margin-bottom: 8px;
  }
  .star{
    fill: #ccc;
    &.fully{
      fill : ${props=>props.theme.primaryDarken};
    }
  }
`
