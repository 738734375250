import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {createContext, useContext, useEffect, useState} from "react";
import * as React from "react";
import {OneFamille} from "./OneFamille";
import {ListFamille} from "./ListFamille";
import {CompetencesLayout} from "./CompetencesLayout";
import {CompetenceOnMetierContext, MyDomaine, MyFamille} from "../OneMetierGestionCompetences";

interface OneDomaineCtrlProps{
  className?:string;
}

interface ContextDomaine{
  Familles:MyFamille[];
  Domaine:MyDomaine|null;
}

export const DomaineContext = createContext<ContextDomaine>({Familles:[], Domaine:null})

const OneDomaineCtrl = (props:OneDomaineCtrlProps)=>{
  const {idDomaine} = useParams();
  const Context = useContext(CompetenceOnMetierContext)
  const [Domaine, setDomaine] = useState<MyDomaine|null>(null)
  useEffect(() => {
    if(Context && idDomaine){
      const dom = Context.Domaines.find(d=>d.id+""===idDomaine);
      if(dom){
        setDomaine(dom);
      }
    }
  }, [idDomaine, Context]);
  return (
    <DomaineContext.Provider value={{Familles:Domaine ? Domaine.Familles : [], Domaine:Domaine ? Domaine : null}}>
      <Routes>
        <Route element={
          <CompetencesLayout link={{link:'../', libelle:Domaine ? Domaine.libelle : '', niveau : Domaine ? Domaine.Niveau : 0}}/>}>
          <Route path="" element={<ListFamille/>} />
          <Route path="/:idFamille/*" element={<OneFamille/>} />
          <Route path="*" element={<p>pas troue</p>} />
        </Route>
      </Routes>
    </DomaineContext.Provider>
  )
}

export const OneDomaine = styled(OneDomaineCtrl)`
  padding: 1% 2%;
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .title_link{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 15px 0;
  }
`
