import styled from "styled-components";
import {
  ItemMenuChoixOnPage,
  MbjButton, MbjCardClassic, MbjErrorData, MbjFlex,
  MbjLoading, MbjMenuChoixOnPage,
  MbjModal,
  MbjNoResult,
  MbjWidgetClassic,
  useModal,
} from "@mbj-front-monorepo/ui";
import {useLocation, useParams} from "react-router-dom";
import { useGetFiPart, useGetFiPartIsCourte, useGetParticulier, useGetXpPart } from "@mbj-front-monorepo/data-access";
import {GoPlus} from "react-icons/go";
import {
  MbjParticulierFiCard, MbjParticulierFiCourteNewForm,
  MbjParticulierFiNewForm, MbjParticulierFiNewFormIA,
  MbjParticulierXpCard,
  MbjParticulierXpNewForm,
} from "@mbj-front-monorepo/data-ui";
import * as React from "react";
import {useState} from "react";
import {BsDownload} from "react-icons/bs";

interface CvOneCvThequeCtrlProps{
    className?:string;
}

const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Expériences professionnelles'},
    {id:1, libelle:'Formations Certifiantes'},
    {id:2, libelle:'Autres Formations'},
]

const CvOneCvThequeCtrl = (props:CvOneCvThequeCtrlProps)=>{
    const { isShowing: isShowingAddXp, toggle: toggleAddXp } = useModal();
    const { isShowing: isShowingAddFi, toggle: toggleAddFi } = useModal();
    const { isShowing: showChoice, toggle: toggleChoice } = useModal();
     const { isShowing: showLibelle, toggle: toggleLibelle } = useModal();
    const { isShowing: showNewCourteFi, toggle: toggleNewCourteFi } = useModal();
    const { id } = useParams();
    const particulierQuery = useGetParticulier(parseInt(id || "-1"));
    const xpQuery = useGetXpPart(id ? parseInt(id) : undefined);
    const FisQuery = useGetFiPart(id ? parseInt(id) : undefined);
    const FisCourteQuery = useGetFiPartIsCourte(id ? parseInt(id) : undefined);
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>({id:0, libelle:'Expériences professionnelles'})
    const DownloadCV = ()=>{
        window.open(`${process.env["REACT_APP_API_URL_ROOT"]}/documents/${particulierQuery.data?.CvDocument}`)
    }
    return (
        <div className={"cv_cv_theque " + props.className}>

            {xpQuery.isLoading || FisQuery.isLoading || particulierQuery.isLoading ? (
                <MbjLoading />
            ) : xpQuery.isError || FisQuery.isError || particulierQuery.isError ? (
                <MbjErrorData/>
            ) : (
                <div className={`wrap_page_cv`}>

                    <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={Menus}/>
                    <div className={`content_cv`}>
                        {MenuCurrent.id === 0 &&  <MbjWidgetClassic justify={"center"} bodyDirection={"column"}>
                                <MbjFlex justify={"flex-end"}>
                                    <MbjButton  leftIcon={<GoPlus />} onClick={toggleAddXp} alignSelf={"flex-end"}  themeColor={"third"}>
                                        Ajouter une expérience
                                    </MbjButton>
                                    {particulierQuery.data?.CvDocument &&
                                        <MbjButton leftIcon={<BsDownload/>} themeColor={"primary"} onClick={DownloadCV}>Télécharger le cv</MbjButton>
                                    }
                                </MbjFlex>
                                {xpQuery.data && xpQuery.data.length > 0 ? (
                                    xpQuery.data?.map((xp, index) => <MbjParticulierXpCard ParticulierXp={xp} key={xp.id} />)
                                ) : (
                                    <MbjNoResult text={"Aucune expérience trouvée"} />
                                )}
                                <MbjModal
                                    isShowing={isShowingAddXp}
                                    hide={toggleAddXp}
                                    fitContent
                                    title="Nouvelle expérience professionnelle"
                                >
                                    <MbjParticulierXpNewForm idParticulier={particulierQuery.data?.id || -1} hideModal={toggleAddXp} />
                                </MbjModal>
                            </MbjWidgetClassic>}
                        {MenuCurrent.id === 1 && <MbjWidgetClassic bodyDirection={"column"}>
                          <MbjFlex justify={"flex-end"}>
                            <MbjButton leftIcon={<GoPlus />} onClick={toggleChoice} alignSelf={"flex-end"}
                                       themeColor={"third"}>
                              Ajouter une formation certifiante
                            </MbjButton>
                            {particulierQuery.data?.CvDocument &&
                              <MbjButton leftIcon={<BsDownload />} themeColor={"primary"} onClick={DownloadCV}>Télécharger
                                le cv</MbjButton>
                            }
                          </MbjFlex>

                          {FisQuery.data && FisQuery.data.length > 0 ? (
                            FisQuery.data?.map((fi, index) => <MbjParticulierFiCard ParticulierFi={fi} key={fi.id} />)
                          ) : (
                            <MbjNoResult text={"Aucune formations initiales trouvés"} />
                          )}
                          <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                            <MbjButton onClick={toggleChoice} themeColor={"third"} leftIcon={<GoPlus />}>
                              Ajouter une formation certifiante
                            </MbjButton>
                          </div>

                          <MbjModal isShowing={showChoice} hide={toggleChoice} title={"Choix mode d'ajout"} >
                            <MbjFlex width={"100%"} direction={"column"} gap={"20px"}>
                              <MbjButton size={"lg"} type="button" onClick={toggleAddFi} themeColor={"primary"} isfully={true}>
                                Renseigner la formation
                              </MbjButton>
                              <MbjButton size={"lg"} type="button" onClick={toggleLibelle} themeColor={"primary"} isfully={true}>
                                Saisir le libellé
                              </MbjButton>
                            </MbjFlex>
                          </MbjModal>
                          <MbjModal isShowing={isShowingAddFi} hide={toggleAddFi} fitContent title="Nouvelle formation certifiante">
                            <MbjParticulierFiNewForm idParticulier={particulierQuery.data?.id || -1} hideModal={toggleAddFi} />
                          </MbjModal>
                          <MbjModal isShowing={showLibelle} hide={toggleLibelle} fitContent title="Nouvelle formation certifiante depuis le libellé">
                            <MbjParticulierFiNewFormIA idParticulier={particulierQuery.data?.id || -1} hideModal={toggleLibelle} hideChoiceModal={toggleChoice} />
                          </MbjModal>
                        </MbjWidgetClassic>}
                      {MenuCurrent.id === 2 && <MbjWidgetClassic bodyDirection={"column"} basis={"45%"}>
                        <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
                          <MbjButton onClick={toggleNewCourteFi} themeColor={"third"} leftIcon={<GoPlus />}>
                              Ajouter une autre formation
                            </MbjButton>
                          </div>
                          {FisCourteQuery.data && FisCourteQuery.data?.length > 0 ? (
                            <MbjFlex direction={"column"} gap={"20px"}>
                              {FisCourteQuery.isLoading ? (
                                <MbjLoading />
                              ) : FisCourteQuery.isError ? (
                                <p>Error...</p>
                              ) : (
                                FisCourteQuery.data?.map((fi, idx: number) => <MbjParticulierFiCard ParticulierFi={fi} key={fi.id}  />)
                              )}
                            </MbjFlex>
                          ) : (
                            <MbjCardClassic>
                              <div style={{padding:"20px"}}>
                                <MbjNoResult text={"Aucune autre formation"} />
                              </div>
                            </MbjCardClassic>
                          )}
                      </MbjWidgetClassic>}
                      <MbjModal isShowing={showNewCourteFi} hide={toggleNewCourteFi} fitContent title="Nouvelle formation courte">
                        <MbjParticulierFiCourteNewForm  hideModal={toggleNewCourteFi} idParticulier={particulierQuery.data?.id || -1}  />
                      </MbjModal>
                    </div>
                </div>
            )}
        </div>
    )
}

export const CvOneCvTheque = styled(CvOneCvThequeCtrl)`
    padding: 1%;
    height: 100%;
    .wrap_page_cv{
      display: flex;
      flex-direction: column;
      height: 100%;
      .content_cv{
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px;
      }
    }
`
