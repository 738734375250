import styled from "styled-components";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {MbjItemMenuMobile2} from "../../../atoms/MbjItemMenu/mobile/MbjItemMenuMobile2";


interface MbjTabsMobileCtrlProps {
  className?:string;
  Blocks:MbjBlockMenu[];
}

const MbjTabsMobileCtrl = (props: MbjTabsMobileCtrlProps) => {
  return (
    <div className={`mbj_tabs ${props.className}`}>
      <div className="in_tab">
        {props.Blocks.map((item, idx)=>(
          <div className="square_tab" key={`onTab${idx}`}>
            <MbjItemMenuMobile2 Block={item}/>
          </div>
        ))}
      </div>
    </div>
  )
}

export const MbjTabsMobile = styled(MbjTabsMobileCtrl)`
  padding: 0.5rem;
  background: ${props => props.theme.dark};
  flex-shrink: 0;
  .in_tab{
    display: flex;
    justify-content: space-between;
  }
  .square_tab{
    width: 80px;
    height: 80px;
    position: relative;
  }
`
