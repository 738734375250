import styled from "styled-components";
import {FaCheck} from "react-icons/fa";
import * as React from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";

interface ModalSuccessInCtrlProps {
  className?:string;
}

const ModalSuccessInCtrl = (props: ModalSuccessInCtrlProps) => {
  const ClickClose = ()=>{
    window.location.reload();
  }
  return (
    <div className={props.className}>
      <div className="wrap_success">
        <div className="wrap_sign">
          <FaCheck/>
        </div>
        <p className="success">Tous les niveaux sont validés !</p>
        <div className="wrap_sender">
          <MbjButton size={"lg"} type="button" onClick={ClickClose} themeColor={"primary"}>
            Fermer
          </MbjButton>
        </div>
      </div>
    </div>
  )
}

export const ModalSuccessIn = styled(ModalSuccessInCtrl)`
  .wrap_sender{
    display: flex;
    justify-content: center;
    padding: 0.5rem;
  }
  .wrap_success {
    p {
      font-size: 17px;
      text-align: center;
      padding: 1rem;
    }
  }

  .wrap_sign {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 15px auto;
    background: ${props => props.theme.thirdLighten};
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:white;
    svg{
      font-size: 24px;
      margin: auto;
      fill:white;
    }

  }
`
