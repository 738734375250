import styled from "styled-components";
import {ItemSearchRetenu} from "./ItemSearchRetenu";
import {TitleProfilRetenu} from "./components/ProfilRetenuComponents/TitleProfilRetenu";
import {ItemMenuResult} from "./ProfilsRetenus";
import {MbjChoixMenu, MbjLoading} from "@mbj-front-monorepo/ui";
import {useEffect, useState} from "react";
import {
  components,
  useGetResultsSearchEmployeeBySearch, useGetResultsSearchParticulierBySearch,
  useGetResultsSearchReclassementBySearch
} from "@mbj-front-monorepo/data-access";
import {ProfilRetenuEmp} from "./components/ProfilRetenuComponents/ProfilRetenuEmp";
import {ProfilRetenuRecl} from "./components/ProfilRetenuComponents/ProfilRetenuRecl";
import {ProfilRetenuParticulier} from "./components/ProfilRetenuComponents/ProfilRetenuParticulier";

interface ProfilRetenusOneCtrlProps{
  className?:string;
  libelle:string;
  id:number;
  UserMetierId:number;
  MetierId:number;
  clickCompare:(type:"Emp"|"Part"|"Recl", result:components["schemas"]["ResultsSearchParticulier-resultssearchparticulier.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.particulier_statutresult.read"]|components["schemas"]["ResultsSearchReclassement-resultssearchreclassement.read_searchs.read_read.Member_read.UserMetier_read.Metier_statutresult.read_read.MemberMetierReclassement_read.ReclassementEmployees_read.Employee.simple"]|components["schemas"]["ResultsSearchEmployee-resultssearchemployees.read_searchs.read_read.Member_read.UserMetier_read.Metier_read.Employee.simple_statutresult.read"])=>void;
}

const MesMenusResult:ItemMenuResult[]=[
  {id:1, libelle:"les salariés en mobilité"},
  {id:2, libelle:"les métiers et les formations de vos alliances"},
  {id:3, libelle:"la cvthèque"},
]

const ProfilRetenusOneCtrl = (props:ProfilRetenusOneCtrlProps)=>{
  const TabIdStatut = [2,4,5]
  const ResultsQueryEmp = useGetResultsSearchEmployeeBySearch(props.id)
  const ResultsQueryRecl = useGetResultsSearchReclassementBySearch(props.id)
  const ResultsQueryPart = useGetResultsSearchParticulierBySearch(props.id)
  const [dataLoading, setDataLoading] = useState<boolean>(true)
  const [dataError, setDataError] = useState<boolean>(false)
  const [menuCurrent, setMenuCurrent] = useState<ItemMenuResult|null>(MesMenusResult.find(m=>m.id === 1)||null)
  useEffect(()=>{
    if(ResultsQueryEmp.isLoading || ResultsQueryRecl.isLoading || ResultsQueryPart.isLoading){
      setDataLoading(true);
    } else {
      setDataLoading(false)
    }
  }, [ResultsQueryEmp.isLoading, ResultsQueryRecl.isLoading, ResultsQueryPart.isLoading])
  useEffect(()=>{
    if(ResultsQueryEmp.isError || ResultsQueryRecl.isError || ResultsQueryPart.isError){
      setDataError(true);
    } else {
      setDataError(false)
    }
  }, [ResultsQueryEmp.isError, ResultsQueryRecl.isError, ResultsQueryPart.isError])
  const getNbResult = (id:number)=>{
    if(id === 1){
      if(ResultsQueryEmp.isLoading || ResultsQueryEmp.isError){
        return 0
      } else {
        return ResultsQueryEmp.data.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).length
      }
    } else if(id === 2){
      if(ResultsQueryRecl.isLoading || ResultsQueryRecl.isError){
        return 0
      } else {
        return ResultsQueryRecl.data.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).length
      }
    } else {
      if(ResultsQueryPart.isLoading || ResultsQueryPart.isError){
        return 0
      } else {
        return ResultsQueryPart.data.filter(r=> TabIdStatut.indexOf(r.Statut?.id||0) !== -1).length
      }
    }
  }
  return (
    <ItemSearchRetenu>
      <TitleProfilRetenu libelle={props.libelle}/>
      {dataLoading ? <MbjLoading/> : dataError ? 'Errors...':
        <>
          <MbjChoixMenu current={menuCurrent} getNbResult={getNbResult} setChoice={setMenuCurrent} choices={MesMenusResult}/>
          {menuCurrent?.id === 1 ?
            <div className={"wrap_results"}>
              {ResultsQueryEmp.data?.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).length === 0 &&
                <p>Aucun profil</p>
              }
              {
                ResultsQueryEmp.data?.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).map(item=>(
                  <ProfilRetenuEmp  key={`PRE${item.id}`} ResultEmp={item} clickCompare={props.clickCompare}/>
                ))
              }
            </div>:
            menuCurrent?.id === 2 ?
              <div className={"wrap_results"}>
                {ResultsQueryRecl.data?.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).length === 0 &&
                  <p>Aucun profil</p>
                }
                {
                  ResultsQueryRecl.data?.filter(r=> TabIdStatut.indexOf(r.statut?.id||0) !== -1).map(item=>(
                    <ProfilRetenuRecl key={`PRR${item.id}`} ResultRecl={item} clickCompare={props.clickCompare}/>
                  ))
                }
              </div>:
              <div className={"wrap_results"}>
                {ResultsQueryPart.data?.filter(r=> TabIdStatut.indexOf(r.Statut?.id||0) !== -1).length === 0 &&
                  <p>Aucun profil</p>
                }
                {
                  ResultsQueryPart.data?.filter(r=> TabIdStatut.indexOf(r.Statut?.id||0) !== -1).map(item=>(
                    <ProfilRetenuParticulier key={`PRP${item.id}`} ResultPart={item} clickCompare={props.clickCompare}/>
                  ))
                }
              </div>
          }
        </>
      }
    </ItemSearchRetenu>
  )
}
export const ProfilRetenusOne =styled(ProfilRetenusOneCtrl)`

`
