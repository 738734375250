import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjLoading, MbjNoResult, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetFI, useGetXp} from "@mbj-front-monorepo/data-access";
import {MbjEmployeeFiCard, MbjEmployeeXpCard} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface OneCollaborateurFiResumeCtrlProps{
    className?:string;
}

const OneCollaborateurFiResumeCtrl = (props:OneCollaborateurFiResumeCtrlProps)=>{
    const {id} = useParams();
    const FisQuery = useGetFI(parseInt(id||''));
    return (
        <MbjWidgetClassic bodyDirection={"column"} gap={"10px"} basis={"100%"}>
            {FisQuery.isLoading ? <MbjLoading/> : FisQuery.isError ? <MbjErrorData/> :
                FisQuery.data.length > 0 ?
                    <>
                        {FisQuery.data?.map((fi) =>(
                            <MbjCardBack key={fi.id}>
                                <MbjEmployeeFiCard EmployeeFi={fi} isReadOnly/>
                            </MbjCardBack>
                        ))}
                    </>:
                    <MbjCardBack title={"Expériences professionelles"}>
                        <MbjNoResult text={"Aucune Formation initiale pour le moment"}/>
                    </MbjCardBack>

            }
        </MbjWidgetClassic>
    )
}

export const OneCollaborateurFiResume = styled(OneCollaborateurFiResumeCtrl)``
