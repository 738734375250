import styled from "styled-components";
import {components, useGetMemberMe} from "@mbj-front-monorepo/data-access";
import {AvatarFact, MbjButton, MbjCardBack} from "@mbj-front-monorepo/ui";
import React, {useEffect, useState} from "react";
import {AiOutlineSend} from "react-icons/ai";

interface CardInformationOneAllianceCtrlProps{
    className?:string;
    Alliance:components["schemas"]["Alliance-read.Alliance_read.Member"];
}

const CardInformationOneAllianceCtrl = (props:CardInformationOneAllianceCtrlProps)=>{
    const MemberQuery = useGetMemberMe();
    const [isMine, setIsMine] = useState(false);
    useEffect(() => {
        if(MemberQuery.data){
            setIsMine(MemberQuery.data?.id === props.Alliance.Owner?.id)
        }
    }, [MemberQuery.data, props.Alliance]);
    const handleError = (e:any)=>{
        e.target.src = AvatarFact;
    }
    return (
        <MbjCardBack>
            <div className={`information_card ${props.className}`}>
                <h2>{props.Alliance.name}</h2>
                <div className={`info_creation`}>
                    <div className={"on_line_info"}>
                        <div className={"pre_infos"}>Créé par</div>
                        <div className={`card_member`}>
                            <img
                                src={`${process.env["REACT_APP_API_URL_ROOT"]}${props.Alliance.Owner?.logoUrl}`}
                                alt={"avatar_logo"}
                                onError={handleError}
                            />
                            <span>{props.Alliance.Owner?.name}</span>
                            {!isMine &&
                                <MbjButton size={"sm"} leftIcon={<AiOutlineSend/>}>envoyer un message</MbjButton>
                            }
                        </div>
                    </div>
                    <div className={"on_line_info"}>
                        <div className={"pre_infos"}>Créé le</div>
                        <div className={`dateCreation`}>{props.Alliance.creationAt ? new Date(props.Alliance.creationAt).toLocaleDateString() : 'nc'}</div>
                    </div>
                </div>
            </div>
        </MbjCardBack>
    )
}

export const CardInformationOneAlliance = styled(CardInformationOneAllianceCtrl)`
  width: 100%;
  .info_creation{
    margin-top: 12px;
  }
  .pre_infos{
    font-weight: bold;
    color:${props=>props.theme.primary};
  }
    .card_member{
      flex-grow: 1;
      display: flex;
      gap:8px;
      img{
        width: 45px;
        height: auto;

      }
    }
  .on_line_info{
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
`
