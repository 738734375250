import * as React from "react";
import styled from "styled-components";
import { MbjButton } from "@mbj-front-monorepo/ui";
import { BiTrash, BiWindowClose } from "react-icons/bi";
import { useTrashMemberEquipeManager } from "@mbj-front-monorepo/data-access";


interface MemberTeamCtrlProps {
  className?: string;
  item: any
}



function MemberTeamCtrl(props: MemberTeamCtrlProps) {
  const deleteMemberTeam = useTrashMemberEquipeManager()
  const handleDelete = (id: number) => {
      deleteMemberTeam.mutate(id)
  }

  return (
    <tr>
      <td className="isCenter">{props.item.Employee.prenom + ' ' + props.item.Employee.nom}</td>
      <td className="isCenter"><MbjButton themeColor="warning" onClick={() => handleDelete(props.item.id)}><BiTrash /></MbjButton></td>
    </tr>
  );
}

export const MemberTeam = styled(MemberTeamCtrl)((props) => ({
  fontSize: "calc(7px + 0.80vmin)",
}));
