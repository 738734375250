import styled from "styled-components";
import {useContext} from "react";
import {LinkComp} from "./LinkComp";
import * as React from "react";
import {CompetenceOnMetierContext} from "../OneMetierGestionCompetences";
import {MbjLoading} from "@mbj-front-monorepo/ui";
import {SearchActivites} from "../../../../components/GestionCompetences/components/SearchActivites";

interface ListDomaineCtrlProps{
  className?:string;
  loading:boolean;
}

const ListDomaineCtrl = (props:ListDomaineCtrlProps)=>{
  const context = useContext(CompetenceOnMetierContext);
  const NiveauTot = context.Domaines.reduce((acc:number, item)=>acc+(item.NiveauTotal||0), 0);
  return (
    <div className={`list_domaines ${props.className}`}>
      <SearchActivites/>
      <h1>Profil de compétence par Domaine</h1>
      {props.loading ? <MbjLoading/> :
        context.Domaines.sort((a,b)=>(a.NiveauTotal||0)/(NiveauTot) > (b.NiveauTotal||0)/(NiveauTot) ? -1 : 1).map(item=>(
          <LinkComp to={item.id + ""} libelle={item.libelle} key={`one_linkF${item.id}`} niveau={(item.NiveauTotal||0)/(NiveauTot) } isN={true}/>
        ))
      }
    </div>
  )
}

export const ListDomaine = styled(ListDomaineCtrl)`
  padding: 1% 2%;
  h1{
    font-size: 18px;
    margin-bottom: 20px;
  }
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .lib_nivo{
    width: ${`${60/5}%`};
  }
  .lib_item{
    width: 40%;
  }
`
