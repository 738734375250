import styled from "styled-components";
import {components, operations} from "@mbj-front-monorepo/data-access";
import {OneResumeSearch} from "./OneResumeSearch";

interface WrapperSearchsCtrlProps{
    className?:string;
    list:(components["schemas"]["Searchs-searchs.read_read.Member_read.UserMetier_read.Metier"])[]
}

const WrapperSearchsCtrl = ({className, list}:WrapperSearchsCtrlProps)=>{
    return (
        <div className={`wrapper_search ${className}`}>
            <div className={`content_wrapper`}>
                {list.map((item, idx)=>(
                    <div className={`wrapp_one`} key={`one_S${idx}`}>
                        <div className={`in_one`}>
                            <OneResumeSearch

                                item={{
                                    id:item.id||0,
                                    libelle:item.UserMetier ? item.UserMetier.namePerso||'' : item.Metier ? item.Metier.libelle||'' : '',
                                    updateAt:item.updateAt||undefined,
                                    nbResultReclassement:item.nbResultReclassement||0,
                                    nbResultParticulier:item.nbResultParticulier||0,
                                    nbResultEmployee:item.nbResultEmployee||0,
                                    nbResultInterne:item.nbResultInterne||0
                                }}
                                styles={{
                                    col1:"100%",
                                    col2:"100%",
                                    col3:"100%",
                                    col4:"100%",
                                }}
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export const WrapperSearchs =styled(WrapperSearchsCtrl)`
  flex-grow: 1;
  overflow-y: auto;
  .content_wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .wrapp_one{
    width: 50%;
    padding: 0.5rem;
    .in_one{
      height: 100%;
    }
  }
`
