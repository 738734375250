import styled from "styled-components";
import {MbjButton, MbjLoading, MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {
  useGetEntretienAnnuelCodeE, useGetEntretienAnnuelCodeR,
  useSendCodeEmployee,
  useSendCodeResponsable,
  useSendSignature, useSendSignatureResponsable
} from "@mbj-front-monorepo/data-access";
import {useEffect, useRef, useState} from "react";
import {FaCheck} from "react-icons/fa";

interface ModalSignatureResponsableCtrlProps extends MbjModalCtrlProps{
    className?:string;
    idEntretien:number;
}

const ModalSignatureResponsableCtrl = (props: ModalSignatureResponsableCtrlProps) => {
  const mutation =useSendCodeResponsable(props.idEntretien);
  const mutationSignature = useSendSignatureResponsable(props.idEntretien);
  const [code, setCode] = useState('');
  const EntretienByCodeQuery = useGetEntretienAnnuelCodeR(code);
  const [myCode, setMyCode] = useState('');
  const [askCode, setAskCode] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const refChiffre1 = useRef<HTMLInputElement>(null);
  const refChiffre2 = useRef<HTMLInputElement>(null);
  const refChiffre3 = useRef<HTMLInputElement>(null);
  const refChiffre4 = useRef<HTMLInputElement>(null);
  const refChiffre5 = useRef<HTMLInputElement>(null);
  const refChiffre6 = useRef<HTMLInputElement>(null);
  const [myEntretienCode, setMyEntretienCode] = useState<any>(null);
  useEffect(() => {
    if(EntretienByCodeQuery.data && EntretienByCodeQuery.data.length>0){
      setMyEntretienCode(EntretienByCodeQuery.data[0])
    }
  }, [EntretienByCodeQuery.data]);
  const SendSignature = ()=>{
    mutationSignature.mutate({id:props.idEntretien}, {
      onSuccess:()=>{
          setIsSuccess(true);
      }
    })
  }
  useEffect(() => {
    if(props.isShowing && !askCode) {
      mutation.mutate({id: props.idEntretien}, {
        onSuccess: () => {
          setAskCode(true);
        }, onError: () => {
          console.log('oups');
        }
      })
    }
  }, [props.isShowing]);
  const handleChangeChiffre = (idChiffre:number)=>{
    if(idChiffre === 1){
      if(refChiffre1.current){
        const val = (refChiffre1.current.value);
        setMyCode(val)
        refChiffre2.current?.focus();
      }
    } else if(idChiffre === 2){
      if(refChiffre2.current){
        const val = (refChiffre2.current.value);
        setMyCode(myc=> {
          return myc+""+val
        })
        refChiffre3.current?.focus();
      }
    } else if(idChiffre === 3){
      if(refChiffre3.current){
        const val = (refChiffre3.current.value);
        setMyCode(myc=> {
          return myc+""+val
        })
        refChiffre4.current?.focus();
      }
    } else if(idChiffre === 4){
      if(refChiffre4.current){
        const val = (refChiffre4.current.value);
        setMyCode(myc=> {
          return myc+""+val
        })
        refChiffre5.current?.focus();
      }
    } else if(idChiffre === 5){
      if(refChiffre5.current){
        const val = (refChiffre5.current.value);
        setMyCode(myc=> {
          return myc+""+val
        })
        refChiffre6.current?.focus();
      }
    } else {
      if(refChiffre6.current){
        const val = (refChiffre6.current.value);
        setMyCode(myc=> {
          return myc+""+val
        })
      }
    }
  }
  useEffect(() => {
    console.log(myCode.toString())
    if(myCode.toString().length === 6){
      setCode(myCode+"");
    }
  }, [myCode]);
  return (
    <MbjModal {...props} maxWidth={"70%"} title={"Signature entretien"}>
      <div className={`modal_signature ${props.className}`}>
        {isSuccess  ?
          <div className="wrap_success">
              <div className="wrap_sign">
                <FaCheck />
              </div>
              <p className="success">L'entretien est désormais signé !<br/> vous pouvez fermer cette page</p>
          </div>:
          myEntretienCode ?
          <div className={"for_sin"}>
            <p style={{fontSize:"18px"}}>Votre code est accepté vous pouvez désormais signer l'entretien</p>
            <div className={`wrap_send_signature`} style={{padding:"1rem", display:'flex', justifyContent:'center'}}>
              <MbjButton onClick={SendSignature} themeColor={"primary"} size={"lg"}>Signer mon entretien</MbjButton>
            </div>
          </div> :
          <div className="forCode">
            {mutation.isLoading ?
              <MbjLoading/> :
              askCode ?
                <div className={`wrap_code`}>
                <p className={"intro2"}>Vous avez reçu un code par email, merci de le renseigner pour signer l'entretien</p>
                  <div className={`wrapper_code`}>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre1} type={"text"} onChange={() => handleChangeChiffre(1)}/>
                    </div>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre2} type={"text"} onChange={() => handleChangeChiffre(2)}/>
                    </div>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre3} type={"text"} onChange={() => handleChangeChiffre(3)}/>
                    </div>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre4} type={"text"} onChange={() => handleChangeChiffre(4)}/>
                    </div>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre5} type={"text"} onChange={() => handleChangeChiffre(5)}/>

                    </div>
                    <div className="wrap_onechiffre">
                      <input ref={refChiffre6} type={"text"} onChange={() => handleChangeChiffre(6)}/>
                    </div>
                  </div>
                </div> :''
            }
          </div>
        }
      </div>
    </MbjModal>
  )
}

export const ModalSignatureResponsable = styled(ModalSignatureResponsableCtrl)`
  .intro2{
    font-size: 17px;
  }
  .wrap_success{
    p{
      font-size: 17px;
      text-align: center;
      padding: 1rem;
    }
  }
  .wrap_sign{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 15px auto;
    background: ${props => props.theme.thirdLighten};
    display: flex;
    justify-content: center;
    flex-direction: column;
    color:white;
    svg{
      font-size: 24px;
      margin: auto;
      fill:white;
    }

  }
  .wrapper_code{
    display: flex;
    justify-content: center;
    gap: 6px;
    margin: 15px auto;
    .wrap_onechiffre{
      width: 60px;
      height: 90px;
      border: solid #ccc 2px;
      border-radius: 6px;
      overflow: hidden;
      input{
        height: 100%;
        width: 100%;
        border:none;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
      }
    }
  }
`
