import styled from "styled-components";
import {MbjCardBack, MbjListItem, MbjNoResult} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetEmployee, useGetEntretienAnnuels, useGetMobilitesInternes} from "@mbj-front-monorepo/data-access";
import {useMemo} from "react";
import * as React from "react";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";

interface MobiliteInterneEntretienCtrlProps{
    className?:string;
    setForPTrigger:(idA:number, idB:number)=>void;
}

interface LineForEntretien{
    name:string;
    score:number;
    id:number;
    userMetier:{id:number, libelle:string}
}
const MobiliteInterneEntretienCtrl = (props:MobiliteInterneEntretienCtrlProps)=>{
    const { id } = useParams();
    const EntretiensAnnuelsQuery = useGetEntretienAnnuels(id ? parseInt(id) : undefined)
    const MobilitesQuery = useGetMobilitesInternes(id ? parseInt(id) : undefined);
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const listVsEntretien=useMemo(()=>{
        const list:LineForEntretien[] = [];
        if(EntretiensAnnuelsQuery.data && MobilitesQuery.data){
            const idsAn:number[] = [];
            EntretiensAnnuelsQuery.data.map(ea=>{
                const AnThis = ea.EvoAnInterne;
                const Trithis = ea.EvoTriInterne;
                if(AnThis){
                    const Tab = AnThis.split('/');
                    const Last = Tab[3];
                    if(idsAn.indexOf(parseInt(Last))===-1) idsAn.push(parseInt(Last));
                }
                if(Trithis){
                    const Tabtri = Trithis.split('/');
                    const Lasttri = Tabtri[3];
                    if(idsAn.indexOf(parseInt(Lasttri))===-1) idsAn.push(parseInt(Lasttri));
                }
                return ea;
            })
            idsAn.map(id=>{
                const MyMobilite = MobilitesQuery.data.find(m=>m.userMetier?.id === id)
                if(MyMobilite){
                    list.push({userMetier:{id:MyMobilite.userMetier?.id||0, libelle:MyMobilite.userMetier?.namePerso||'nc'}, id:MyMobilite.id||0, name:MyMobilite.userMetier?.namePerso||'nc', score:MyMobilite.score||0})
                }
                return id;
            })
        }
        return list;
    }, [EntretiensAnnuelsQuery.data, MobilitesQuery.data]);
    return (
      <MbjCardBack title={"Souhait lors des entretiens"}>
        <div className={`mob_entre ${props.className}`}>
          {listVsEntretien.map((item, idx) => (
            <MbjListItem key={`one_mobilite_interneEA${idx}`}
                         onClick={() => props.setForPTrigger((item.id || 0), (item.userMetier?.id || 0))}>
              <MbjMobiliteListItem libelle={item.name} score={item.score} />
            </MbjListItem>
          ))}
          {listVsEntretien.length === 0 &&
            <MbjNoResult text={"Aucun souhait de mobilité interne pour cette personne"} />
          }
        </div>
      </MbjCardBack>
    )
}
export const MobiliteInterneEntretien = styled(MobiliteInterneEntretienCtrl)`
    .line_trigg{
      &:hover{
        cursor: pointer;
      }
    }
`
