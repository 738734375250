import styled from "styled-components";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { GetElementType } from "@mbj-front-monorepo/utils";
import {
  createSelectColumn,
  MbjLoading,
  MbjScrollArea,
  MbjTableV2,
} from "@mbj-front-monorepo/ui";
import React from "react";
import {
  useGetEntretienRecapNoSignEmployees,
} from "@mbj-front-monorepo/data-access";
import { EntretienRespRecap } from "./EntretienRespRecap";

interface EntretienEmpRecapCtrlProps {
  className?:string;
}


const EntretienEmpRecapCtrl = (props: EntretienEmpRecapCtrlProps) => {
  const noSignAllEmp = useGetEntretienRecapNoSignEmployees()
  const columnHelper = createColumnHelper<GetElementType<typeof noSignAllEmp.data>>();

  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.accessor((row: any) => row.nom, {
      header: "Nom",
    }),
    columnHelper.accessor((row: any) => row.prenom, {
      header: "Prénom",
    }),
    columnHelper.accessor((row: any) => row.service ?? ' ', {
      header: "Service",
    }),
    columnHelper.accessor((row: any) => row.metier ?? '', {
      header: "Métier",
    }),
    columnHelper.display({
      header: 'Actions',
      id: 'actions',
    }),
  ];
  const table = useReactTable({
    data: noSignAllEmp?.data ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableRowSelection: true,
  })
  return (
    <div className={`Priorisation_listing pageStd ${props.className}`}>
      {noSignAllEmp.isLoading ? (
        <MbjLoading />
      ) : noSignAllEmp.isError ? (
        <p>Erreur</p>
      ) : (
        <>
          <div className={"space-between"}>
            <h3 className="titleI">Collaborateurs</h3>

            <div
              className={"ResultNumber"}>{table.getRowModel().rows.length > 1 ? `${table.getRowModel().rows.length} résultats` : `${table.getRowModel().rows.length} résultat`}</div>
          </div>


          <MbjScrollArea overflowX={"auto"}>
          <MbjTableV2 table={table} Multiple={true}/>
            <EntretienRespRecap />
          </MbjScrollArea>
        </>
      )}

    </div>
  )
}

export const EntretienEmpRecap = styled(EntretienEmpRecapCtrl)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0 50px;

  .titleI {
    color: #5d8a91;
    text-decoration: underline;
    font-size: 17px;
    text-align: center;
    margin: 1em 0;
  }
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ResultNumber {
    font-size: 20px;
  }

  .btn-actions {
    display: flex;
    justify-content: space-between;
  }

  .btn-actions button:first-child {
    margin-right: 10px;
  }

  .blk-plan {
    margin: 10px 0;
  }

  .plprg {
    margin-bottom: 0.5rem;
  }

  .plprg_select {
    display: block;
    margin: 20px 10px 20px 0;
  }
`
