import styled from "styled-components";
import {BiError} from "react-icons/bi";

interface MbjErrorDataCtrlProps{
    className?:string;
    libelle?:string;
}

const MbjErrorDataCtrl = (props:MbjErrorDataCtrlProps)=>{
    return (
        <div className={`error_data`}>
            <div className={`icon_place`}><BiError/></div>
            <div className={`legend_place`}>{props.libelle ? props.libelle : "Erreur de données"}</div>
        </div>
    )
}

export const MbjErrorData = styled(MbjErrorDataCtrl)`
  width: 100%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .icon_place{
    padding: 1rem;
    font-size: 42px;
    svg{
      margin: auto;
      path{
        stroke: red;
      }
    }
  }
  .legend_place{
    padding: 0.2rem;
    text-align: center;
  }
`
