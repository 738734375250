import styled from "styled-components";
import {useGetEmployee, useGetMetier, useMutateMobiliteSaved} from "@mbj-front-monorepo/data-access";
import React, {useEffect} from "react";
import {MbjFlex, MbjLoading, MbjNavPath} from "@mbj-front-monorepo/ui";
import {MbjCompareReferentiel} from "@mbj-front-monorepo/data-ui";
import {MetrixMetier} from "./MetrixMetier";
import {FormationsToWidget} from "./FormationsToWidget/FormationsToWidget";

interface ComparateurEmployeeMetierCtrlProps{
    className?:string;
    idEmployee:number;
    idMetier:number;
}

const ComparateurEmployeeMetierCtrl = (props:ComparateurEmployeeMetierCtrlProps)=>{

    const employeeQuery = useGetEmployee(props.idEmployee);
    const metierQuery = useGetMetier(props.idMetier!==0 ? props.idMetier : undefined);
    const savedMobiliteMutation = useMutateMobiliteSaved(props.idEmployee);

    useEffect(() => {
        if (!savedMobiliteMutation.isSuccess && !savedMobiliteMutation.isLoading) {
            console.log("mutateMobiliteSaved");
            savedMobiliteMutation.mutate(props.idMetier);
        }
    }, []);
    if(props.idMetier === 0){
        return null;
    }
    return (
        <div className={"Mobilites " + props.className}>
            {employeeQuery.isLoading || metierQuery.isLoading ? (
                <MbjLoading />
            ) : employeeQuery.isError || metierQuery.isError ? (
                <p>Erreur ...</p>
            ) : (
                <>
                    <MbjFlex direction={"column"} basis={"100%"}>
                        <h1>Mobilité vers : {metierQuery.data?.libelle}</h1>
                        <MbjCompareReferentiel
                            idToCompare1={employeeQuery.data.id || -1}
                            idToCompare2={metierQuery.data.id || -1}
                            typeCompared={"EM"}
                        />
                        <MetrixMetier Metier={metierQuery.data} />
                        <FormationsToWidget employeeId={employeeQuery.data.id} metierId={metierQuery.data.id} />
                    </MbjFlex>
                </>
            )}
        </div>
    );
}

export const ComparateurEmployeeMetier = styled(ComparateurEmployeeMetierCtrl)``
