import styled from "styled-components";
import {useOptinBEmp} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";

interface OptinBEmpCtrlProps {
  className?:string;
  idEmp:number;
}

const OptinBEmpCtrl = ({className, idEmp}: OptinBEmpCtrlProps) => {
  const mutation = useOptinBEmp();
  const [ended, setEnded] = useState(false);
  const [errorSub, setErrorSub] = useState<string>("")
  const ClickOnVal = ()=>{
    mutation.mutate(idEmp, {
      onSuccess:()=>{
        setEnded(true);
      }, onError:()=>{
        setErrorSub("Une erreur est survenue, merci de retenter plus tard")
      }
    })
  }

  const closeTab = () => {
    window.opener = null;
    window.open("about:blank", "_self");
    window.close();
    // window.location.reload();
  };
  return (
    <div className={`optin_b ${className}`}>
      {mutation.isLoading ?
        <div className={"in_course"}>
          Acceptation en cours d'enregistrement...
        </div>:
        ended ?
          <div className={`ended`}>
            <p>Votre acceptation à été enregistrée</p>
            <div className="wrap_sender">
              <MbjButton themeColor={"third"} onClick={closeTab} size={"lg"}>Fermer la fenêtre</MbjButton>
            </div>
          </div> :
          <div className={`block_submit2`}>
            <p>Merci de votre acceptation, afin de confirmer cette dernière merci de cliquer sur le bouton ci-dessous</p>
            {errorSub!=='' &&
              <div className={"ErrorSub"}>{errorSub}</div>
            }
            <div className="wrap_sender">
              <MbjButton themeColor={"third"} onClick={ClickOnVal} size={"lg"}>Je valider mon acceptation</MbjButton>
            </div>
          </div>
      }
    </div>
  )
}

export const OptinBEmp = styled(OptinBEmpCtrl)`
  padding: 1.5rem;
  .in_course{
    text-align: center;
  }
  .block_submit2, .ended{
    p{
      text-align: center;
      font-size: 17px;
    }
  }
  .ErrorSub{
    width: 100%;
    background: red;
    padding: 1rem;
    text-align: center;
    margin: 12px 0;
    color:white;
  }
  .wrap_sender{
    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }
`
