import styled from "styled-components";
import {
  Operation,
  useGetEmployeesCompetences,
  useGetMetrixForEmployee,
  useGetUserMetierStatistiques
} from "@mbj-front-monorepo/data-access";
import {useEffect, useMemo, useState} from "react";
import {OnColumnMetier} from "./components/OnColumnMetier";

interface MatrixCompCtrlProps {
  className?:string;
  employee?: Operation<"getEmployeeItem">;
}
export interface OneActi{
  id:number,
  libelle:string;
  score:number;
}
export interface OneUserMetier{
  id:number,
  libelle:string;
}

export interface LineComp{
  libelleActi:string;
  diffNivo:number;
}

const MatrixCompCtrl = (props: MatrixCompCtrlProps) => {
  const queryEmployeeCompetences = useGetEmployeesCompetences(props.employee?.id);
  const userMetierSatistiquesQuery = useGetUserMetierStatistiques();
  const MetrixQuery = useGetMetrixForEmployee(props.employee?.id||0)
  const [TabActis, setTabActis] = useState<OneActi[]>([]);
  useEffect(() => {
    if(queryEmployeeCompetences.data){
      const Actis:OneActi[] = [];
      queryEmployeeCompetences.data.forEach(q=>{
        const ThisT = q.EmployeeActivites;
        ThisT?.forEach(a=>{
          Actis.push({
            id:a.Activite?.id||0,
            libelle:a.Activite?.libelle||'',
            score:a.niveau||0
          })
        })
      })
      setTabActis(Actis);
    }
  }, [queryEmployeeCompetences.data]);
  const listUserMetier  = useMemo(()=>{
    const myList:OneUserMetier[] = [];
    if(userMetierSatistiquesQuery.data){
      userMetierSatistiquesQuery.data.forEach(item=>{
        myList.push({
          id:item.UserMetier?.id||0,
          libelle:item.UserMetier?.namePerso||''
        })
      })
    }
    return myList;
  }, [userMetierSatistiquesQuery.data])


  if(!props.employee){
    return null;
  }
  return (
    <div className={`matrix_comp ${props.className}`}>
      <div className="wrap_tabx">
        <table>
          <thead>
              <tr>
                  <th></th>
                {listUserMetier.map(item=>(
                  <th key={`H${item.id}`}>{item.libelle}</th>
                ))}
              </tr>
          </thead>
          <tbody>
          {MetrixQuery.data?.map(item=>(
            <tr key={`LMetrix${item.id}`}>
              <td>{item.activite?.libelle||''}</td>
              {item.userMetierScorings?.map(useMetierScoring=>(
                <td key={`Cell${item.id}${useMetierScoring.id}`}>
                  {useMetierScoring.niveauTo === -99 ?
                    <div className="noConcerned"/>:
                    <div className={`cell_compare ${(useMetierScoring.niveauFrom||0) - (useMetierScoring.niveauTo||0)> 0 ? 'good' : (useMetierScoring.niveauFrom||0) === (useMetierScoring.niveauTo||0) ? 'same' : 'bad'} `}>{(useMetierScoring.niveauFrom||0) - (useMetierScoring.niveauTo||0)}</div>
                  }
                </td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export const MatrixComp = styled(MatrixCompCtrl)`
  width:100%;
  padding-top: 20px;
  .wrap_tabx{
    margin: auto;
    width:78vw;
    max-height: 600px;
    background: white;
    overflow: auto;
    display: flex;
    justify-content: flex-start;
  }
  table{
    border-spacing : 0;
    border-collapse : collapse;
    thead{
      tr{

        th{
          background: white;
          position: sticky;
          top:0;
          text-align: center;
          font-size: 13px;
          min-width: 110px;
          padding: 0.25rem 0.5rem;
          border-bottom: solid ${props=>props.theme.primary} 1px;
          &:first-child{
            min-width: 280px;
          }
        }
      }
    }
    tbody{
      tr{
        td{
          &:first-child{
            font-weight: bold;
          }
            padding: 0.25rem;
        }
      }
    }
  }
  .cell_compare{
    margin: auto;
    padding: 0.25rem 1.5rem;
    background: #ccc;
    width: 100%;
    text-align: center;
    border-radius: 4px;
    &.good{
      background: ${props=>props.theme.thirdLighten};
    }
    &.same{
      background: ${props=>props.theme.primaryPastel};
    }
    &.bad{
      background: ${props=>props.theme.warningPastel};
    }
  }
`
