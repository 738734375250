import {
  MbjCardClassic,
  MbjFlex,
  MbjLabel,
  MbjLoading,
  MbjLoadingMessage,
  MbjTable,
  MbjWidgetClassic,
} from "@mbj-front-monorepo/ui";

import {
  useFindMatchingAllianceMembers,
  useGetFindSearchReclassment,
  useGetMemberMetierReclassement,
  useGetMetrixMetier,
  useGetTopRecrutementByUserMetier,
} from "@mbj-front-monorepo/data-access";
import styled from "styled-components";
import { ListeEntrepriseAlliance } from "./ListeEntrepriseAlliance";
import { MatchingOtherEntreprise } from "./MatchingOtherEntreprise";
import { TopRecrutementUserMetier } from "./TopRecrutementUserMetier";

interface ReclassementStatistiquesCtrlProps {
  className?: string;
  idRecl: number;
  idMetier: number;
}

const TopMetierTitles = [
  { libelle: "Raison sociale", Align: "isCenter" },
  { libelle: "Distance (en km)", Align: "isCenter" },
];

const TopMatchingAllianceTitles = [
  { libelle: "Entreprise", Align: "isCenter" },
  { libelle: "Metier", Align: "isCenter" },
  { libelle: "Salaire", Align: "isCenter" },
  { libelle: "Score (en %)", Align: "isCenter" },
];

const ListEntrepriseAllianceTitles = [
  { libelle: "Entreprise", Align: "isCenter" },
  { libelle: "Metier", Align: "isCenter" },
  { libelle: "Score (en %)", Align: "isCenter" },
];

const Titles = [
  { libelle: "Collaborateur", Align: "isLeft" },
  { libelle: "Poste", Align: "isCenter" },
  //{ libelle: "Service", Align: "isCenter" },
  { libelle: "Date d'entrée", Align: "isCenter" },
  { libelle: "Mobilité exprimée", Align: "isCenter" },
  { libelle: "Retiré", Align: "isCenter" },
  { libelle: "Proposé", Align: "isCenter" },
  { libelle: "Accepté", Align: "isCenter" },
];

function ReclassementStatistiquesCtrl(props: ReclassementStatistiquesCtrlProps) {
  const YearToday = new Date().getFullYear();

  const memberMetierReclassementDetailQuery = useGetMemberMetierReclassement(props.idRecl, true);
  const metrixMetierQuery = useGetMetrixMetier(props.idMetier);
  const searchStatResult = useGetFindSearchReclassment(memberMetierReclassementDetailQuery.data?.UserMetier?.id);
  const matchingAlliances = useFindMatchingAllianceMembers(memberMetierReclassementDetailQuery.data?.UserMetier?.id);
  const TopsUserMetiers = useGetTopRecrutementByUserMetier(memberMetierReclassementDetailQuery.data?.UserMetier?.id);

  return (
    <div className={"NouveauReclassement" + props.className}>
      <MbjFlex direction={"column"}>
        <MbjWidgetClassic title={"Statistiques du reclassement"}>
          <MbjCardClassic title={"Informations"} basis={"10%"}>
            {memberMetierReclassementDetailQuery.isLoading ? (
              <>
                <MbjLoadingMessage>
                  <p>L'affichage peut prendre une dizaine de secondes.</p>
                </MbjLoadingMessage>
                <MbjLoading />
              </>
            ) : memberMetierReclassementDetailQuery.isError ? (
              <p>Erreur...</p>
            ) : (
              <div className={"InformationsCardContent"}>
                <div className="Nom">
                  <MbjLabel>Reclassement</MbjLabel>
                  <div className="data">{memberMetierReclassementDetailQuery.data?.UserMetier?.namePerso}</div>
                </div>
                <div className="Nombre d'apparition">
                  <MbjLabel>Apparition</MbjLabel>
                  <div className="data">
                    Ce reclassement est apparu dans {memberMetierReclassementDetailQuery.data.Matching} recherches !
                  </div>
                </div>
              </div>
            )}
          </MbjCardClassic>
          <MbjCardClassic title="Les recrutements dans l'alliance proche de ce profil" basis={"100%"}>
            <MbjLabel>Nombre de recherches</MbjLabel>
            <div className="data" style={{ marginBottom: "10px" }}>
              {searchStatResult.isSuccess && searchStatResult?.data?.length}
            </div>
            {searchStatResult.isSuccess && searchStatResult?.data?.length > 0 ? (
              <MbjTable titles={ListEntrepriseAllianceTitles} themeColor={"primary"} noBorderRadius>
                {searchStatResult?.data?.map((item: any) => (
                  <ListeEntrepriseAlliance item={item} />
                ))}
              </MbjTable>
            ) : (
              <p>Aucun résultat</p>
            )}
          </MbjCardClassic>
          <MbjCardClassic title="Top recrutements" basis={"100%"}>
            {TopsUserMetiers.isLoading ? (
              <>
                <MbjLoadingMessage>
                  <p>L'affichage peut prendre une dizaine de secondes.</p>
                </MbjLoadingMessage>
                <MbjLoading />
              </>
            ) : TopsUserMetiers.isSuccess && TopsUserMetiers.data?.length > 0 ? (
              <>
                <MbjTable titles={TopMetierTitles} themeColor={"primary"} noBorderRadius>
                  {TopsUserMetiers.data?.map((item: any) => (
                    <TopRecrutementUserMetier key={item.id} item={item} />
                  ))}
                </MbjTable>
              </>
            ) : (
              <p>Aucun résultat</p>
            )}
          </MbjCardClassic>
          <MbjCardClassic title="Proximité vers les métiers d'autres entreprises de l'alliance" basis={"100%"}>
            {matchingAlliances.isLoading ? (
              <>
                <MbjLoadingMessage>
                  <p>L'affichage peut prendre une dizaine de secondes.</p>
                </MbjLoadingMessage>
                <MbjLoading />
              </>
            ) : matchingAlliances?.data?.length > 0 ? (
              <MbjTable titles={TopMatchingAllianceTitles} themeColor={"primary"} noBorderRadius>
                {matchingAlliances?.data?.map((item: any) => (
                  <MatchingOtherEntreprise item={item} />
                ))}
              </MbjTable>
            ) : (
              <p>Aucun résultat</p>
            )}
          </MbjCardClassic>
        </MbjWidgetClassic>
      </MbjFlex>
    </div>
  );
}
export const ReclassementStatistiques = styled(ReclassementStatistiquesCtrl)((props) => ({
  padding: "1% 2%",

  ".Infos": {
    padding: "0.5em 1em",
  },
  to: {
    display: "block",
  },
  ".InformationsCardContent": {
    padding: "4px 10px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
  },
  ".metrixContent": {
    fontSize: "1.4rem",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));
