import styled from "styled-components";
import {useEffect, useMemo, useState} from "react";
import ReactDOM from "react-dom";
import {GrClose} from "react-icons/gr";
import {
  Operation,
  useGetAllNotifications,
  useMe,
  useReadNotification,
  useReadNotification2
} from "@mbj-front-monorepo/data-access";
import {MbjLoading} from "../../../atoms/MbjLoading/MbjLoading";
import * as React from "react";
import {NotificationCard} from "./NotificationCard";

interface MbjNotifRubbonCtrlProps{
  className?:string,
  closeMe:()=>void;
  open:boolean;
  Wd?:string;
}

const VISIBLE =1;
const HIDDEN =2;
const ENTERING =3;
const LEAVING =4;

const MbjNotifRubbonCtrl = (props:MbjNotifRubbonCtrlProps)=>{
  const mutation = useReadNotification2()
  const notificationsQuery = useGetAllNotifications();
  const [state, setState] = useState(props.open ? VISIBLE : HIDDEN);
  const [all, setAll] = useState(false);
  const listNotifications = useMemo(()=>{
    if(notificationsQuery.data){
      if(all){
        return notificationsQuery.data;
      } else {
        return notificationsQuery.data.filter(n=>!n.readAt);
      }
    } else {
      return  [];
    }
  }, [all, notificationsQuery.data])
  const AllIsRead = ()=>{
    const now = new Date(new Date().getTime() - new Date().getTimezoneOffset()*60000)
    const data: Operation<"putNotificationsItem", "requestBody"> = {
      readAt: now.toISOString()
    }
    const myNot = notificationsQuery.data?.filter(n=>!n.readAt);
    if(myNot) {
      myNot.forEach(n => {
        mutation.mutate({idNotif:n.id||-1, data:data})
      })
    }
  }
  useEffect(()=>{
    if(!props.open){
      setState(LEAVING)
    } else{
      setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
    }
  }, [props.open])
  useEffect(()=>{
    if(state === LEAVING){
      const timer = setTimeout(()=>{
        setState(HIDDEN)
      }, 300)
      return ()=>{
        clearTimeout(timer);
      }
    } else if (state === ENTERING){
      const xx = document.body.offsetHeight
      setState(VISIBLE);
      return;
    } else {
      return;
    }
  }, [state])
  const style = {transitionDuration: `300ms`, transitionProperty:"width", width:"300px"}
  const styleShadow = {transitionDuration: `300ms`, transitionProperty:"opacity", opacity:1}
  if(state!==VISIBLE){
    style.width = "0";
    styleShadow.opacity=0
  }
  if(state === HIDDEN){
    return null;
  } else {
    return ReactDOM.createPortal(
      <div className={`rubbon_notif ${props.className}`}>
        <div className="shadow" style={styleShadow}/>
        <div className={"in_rubbon"} style={style}>
          <div className={"content-rubbon"}>
            <div className={"header"}>
              <div className="space"/>
              <div className={"titre"}>Notifications</div>
              <div className="space">
                <div className={"wrap_close"} onClick={()=>props.closeMe()}><GrClose/></div>
              </div>
            </div>
            <div className="body">
              <div className={`marker`}>
                <div className={`btn_marker`} onClick={AllIsRead}>Tout marquer comme lu</div>
                <div className={`btn_marker`} onClick={()=>setAll(a=>!a)}>{all ? "afficher les non lus" : "afficher tout"}</div>
              </div>
              <div className={`wrapper_notification`}>
                {notificationsQuery.isLoading ? (
                  <MbjLoading />
                ) : notificationsQuery.isError ? (
                  <p>Erreur ...</p>
                ) : (
                  <>
                    {listNotifications.map((item, idx:number)=>(
                      <NotificationCard Notification={item} key={`notification${idx}`}/>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>, document.body
    )
  }
}

export const MbjNotifRubbon = styled(MbjNotifRubbonCtrl)`
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  .content-rubbon{
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .wrapper_notification{
    height: 100%;
    overflow-y: auto;
    padding: 0.5rem;
  }
  .body{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    .marker{
      display: flex;
      justify-content: space-between;
      padding-bottom: 0.25rem;
      border-bottom: solid 1px black;
      .btn_marker{
        padding: 0 0.5rem;
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
  .header{
    width: 300px;
    padding: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .titre{
      flex-grow: 1;
      font-size: 16px;
      text-align: center;
    }
    .space{
      width: 45px;
      display: flex;
      justify-content: flex-end;
      .wrap_close{
        width: 22px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        &:hover{
          cursor: pointer;
        }
      }
    }
  }
  .shadow{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    background: rgba(0,0,0,.7);
  }
  .in_rubbon{
    background: white;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 2;
  }
`
