import styled from "styled-components";
import { Navigate, Route, Routes, To } from "react-router-dom";
import {MatrixCompMetier} from "./MatrixCompMetier/MatrixCompMetier";
import { InternSubDesktopLayout } from "@mbj-front-monorepo/ui";
import * as React from "react";
import { EntretienEmpRecap } from "./Entretien/EntretienEmpRecap";

interface PilotageRHRooterCtrlProps{
    className?:string;
}

const TabSubMenus:{libelle:string, to:To}[] = [
  {libelle: 'Matrice de compétence', to:"by_matrix"},
  {libelle: 'Entretiens en attente de signature', to:"by_entretien_collaborateurs"},
]
const PilotageRHRooterCtrl = ({className}:PilotageRHRooterCtrlProps)=>{
    return (
        <div className={`pilotage_rh pageStd ${className}`}>
            <Routes>
              <Route
                element={
                  <InternSubDesktopLayout
                    Links={TabSubMenus}
                  />
                }
              >
              <Route path="" element={<Navigate to="by_matrix"/>}/>
              <Route path={"/by_matrix"} element={<MatrixCompMetier/>}/>
              <Route path={"/by_entretien_collaborateurs"} element={<EntretienEmpRecap />}/>
              </Route>
            </Routes>
        </div>
    )
}

export const PilotageRHRooter = styled(PilotageRHRooterCtrl)`

`
