import styled from "styled-components";
import {MbjModal, MbjModalCtrlProps} from "@mbj-front-monorepo/ui";
import {FormNewMembre} from "./FormNewMembre";

interface ModalNewMembreCtrlProps extends MbjModalCtrlProps{}

const ModalNewMembreCtrl = (props:ModalNewMembreCtrlProps)=>{
    return (
        <MbjModal {...props} title="Nouveau membre" fitContent>
            <FormNewMembre closeMe={props.hide}/>
        </MbjModal>
    )
}

export const ModalNewMembre = styled(ModalNewMembreCtrl)``
