import {fetchApi, Operation} from "@mbj-front-monorepo/data-access";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";


export const useGetNiveauVerifEmployee = (idEmployee:number)=>{
  const promise = () => fetchApi("/api/niveau_verifs");
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["niveau_verif", idEmployee], promise, {
    enabled:idEmployee!==0
  });
}

export const useAddNiveauVerif = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: Operation<"postNiveauVerifCollection", "requestBody">) => {
      return fetchApi("/api/niveau_verifs", {
        method: "post",
        json: { ...data },
      });
    }, {
      onSuccess:()=>{
        queryClient.invalidateQueries(["niveau_verif"])
      }
    }
  );
};


