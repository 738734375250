import styled from "styled-components";
import {MbjModal2, MbjWidgetClassic, useModal} from "@mbj-front-monorepo/ui";
import {RecommandationMbj} from "./RecommandationMbj";
import {AllMobilites} from "./AllMobilites";
import {LastView} from "./LastView";
import {useParams} from "react-router-dom";
import {useState} from "react";
import {ComparateurEmployeeMetier} from "../../../../../components/Comparateur/ComparateurEmployeeMetier";


interface OneCollaborateurMobiliteExterneCtrlProps{
    className?:string;
}

const OneCollaborateurMobiliteExterneCtrl = (props:OneCollaborateurMobiliteExterneCtrlProps)=>{
    const {id} = useParams();
    const {isShowing, toggle} = useModal()
    const [idMetierCurrent, setidMetierCurrent] = useState<number>(0)
    const setMyId = (id:number)=>{
        setidMetierCurrent(id);
        toggle();
    }
    return (
        <div className={`mob_ext ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"row"} gap={"10px"}>
                <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                    <RecommandationMbj setIdMetier={setMyId}/>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                    <AllMobilites setIdMetier={setMyId}/>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                    <LastView setIdMetier={setMyId}/>
                </MbjWidgetClassic>
            </MbjWidgetClassic>
            <MbjModal2 Wd={"80%"} closeMe={toggle} open={isShowing} titre={"Mobilité"}>
                <div style={{padding:"1rem", height:"60vh", overflowY:"auto"}}>
                    <ComparateurEmployeeMetier idEmployee={id ? parseInt(id) : 0} idMetier={idMetierCurrent}/>
                </div>
            </MbjModal2>
        </div>
    )
}

export const OneCollaborateurMobiliteExterne = styled(OneCollaborateurMobiliteExterneCtrl)``
