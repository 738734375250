import styled from "styled-components";
import {MbjCardBack, MbjErrorData, MbjListItem, MbjLoading} from "@mbj-front-monorepo/ui";
import {useParams} from "react-router-dom";
import {useGetMobilitesInternes} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {MbjMobiliteListItem} from "@mbj-front-monorepo/data-ui";

interface AllMobilitesInternesCtrlProps{
    className?:string;
    setForPTrigger:(idA:number, idB:number)=>void;
}
const AllMobilitesInternesCtrl = (props:AllMobilitesInternesCtrlProps)=>{
    const { id } = useParams();
    const MobilitesQuery = useGetMobilitesInternes(id ? parseInt(id) : undefined);
    return (
      <MbjCardBack title={"Mobilités vers un métier interne"}>
       <div className={`mobi_int_all ${props.className}`}>
          {MobilitesQuery.isLoading ? <MbjLoading /> : MobilitesQuery.isError ? <MbjErrorData /> :
            MobilitesQuery.data?.map((item, idx) => (
              <MbjListItem key={`one_mobilite_interne${idx}`}
                           onClick={() => props.setForPTrigger((item.id || 0), (item.userMetier?.id || 0))}>
                <MbjMobiliteListItem libelle={item.userMetier?.namePerso} score={item.score} />
              </MbjListItem>
            ))
          }
        </div>
      </MbjCardBack>
    )
}
export const AllMobilitesInternes = styled(AllMobilitesInternesCtrl)`
  max-height: 450px;
  overflow-y: auto;
  padding: 0.25rem;
`
