import {
  CustomSelect,
  MbjButton,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useAddNewEquipeManager,
  useGetAllListEquipeManager,
  useGetListPotentialsManager,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as React from "react";

interface ModalAddEquipeCtrlProps extends MbjModalCtrlProps {}

const ModalAddEquipeCtrl = (props: ModalAddEquipeCtrlProps) => {
  const userEquipesQuery = useGetAllListEquipeManager();
  const mutation = useAddNewEquipeManager();
  const listManagers=  useGetListPotentialsManager()

  const HandleSend = (data: any) => {
    mutation.mutate(
      { nom: data.libelle, User: `/api/users/${data.manager.userId}` },
      {
        onSettled: () => {
          props.hide();
        },
      }
    );
  };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  return (
    <MbjModal {...props} title="Ajout d'une équipe" maxWidth={"600px"}>
      {userEquipesQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjSimpleInputContainer errors={errors} label={"Libellé de l'équipe à ajouter *"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
                minLength: {
                  value: 3,
                  message: "Libellé trop court",
                },
                validate: {
                  isExist: (value) => {
                    const TabExist = userEquipesQuery.data?.filter((team: any) => team.nom === value);
                    console.log(!!TabExist && TabExist.length > 0);
                    return !(!!TabExist && TabExist.length > 0) || "Cette équipe existe déjà";
                  },
                },
              }}
            />
          </MbjSimpleInputContainer>
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner un manager"} htmlFor={"manager"}>
            <Controller
              name={"manager"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"userId"}
                  isLoading={listManagers.isLoading}
                  options={listManagers.data}
                  getOptionLabel={(manager: any) => manager.prenom + ' ' + manager.nom}
                  getOptionValue={(manager: any) => manager.userId}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Ajouter
          </MbjButton>
        </form>
      )}
    </MbjModal>
  );
};

export const ModalAddEquipe = styled(ModalAddEquipeCtrl)((props) => ({
  ".serviceAddForm": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "end",
    },
  },
}));
