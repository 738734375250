import styled from "styled-components";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import * as React from "react";
import {MbjSideBar} from "./MbjSideBar";
import {Outlet} from "react-router-dom";
import {MbjHeader} from "./MbjHeader";


interface DesktopLayoutCtrlProps{
  className?:string;
  Blocks:MbjBlockMenu[];
  DisconnectCallBack:()=>void;
  userLogo?: React.ReactElement;
  numVersion:string;
  type?:string;
}

const DesktopLayoutCtrl = (props:DesktopLayoutCtrlProps)=>{
  return (
    <div className={`dektop_layout ${props.className}`}>
        <MbjSideBar Blocks={props.Blocks} numVersion={props.numVersion} type={props.type}/>
        <div className={`section_main`}>
          <MbjHeader userLogo={props.userLogo} disconnectCallBack={props.DisconnectCallBack} Blocks={props.Blocks}/>
          <div className={`mbj_content`}>
            <Outlet/>
          </div>
        </div>
    </div>
  )
}

export const DesktopLayout = styled(DesktopLayoutCtrl)`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  background: ${props=>props.theme.background1};
  .section_main{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .mbj_content{
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .pageStd{
      padding: 0.5% 2%;
      height: 100%;
      overflow-y: auto;
    }
  }
`
