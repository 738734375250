import styled from "styled-components";
import {MobiliteCollabSwitch} from "./MobiliteCollabSwitch";
import {ItemMenuChoixOnPage, MbjMenuChoixOnPage, MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {useGetEmployee, useGetRight} from "@mbj-front-monorepo/data-access";
import {useState} from "react";
import {useParams} from "react-router-dom";
import * as React from "react";
import {OneCollaborateurMobiliteExterne} from "./MobilitesExterne/OneCollaborateurMobiliteExterne";
import {OneCollaborateurMobiliteInterne} from "./MobilitesInterne/OneCollaborateurMobiliteInterne";
import { OneCollaborateurCarriereInterne } from "./CarriereInterne/OneCollaborateurCarriereInterne";

interface MobilitesRootCtrlRoot{
    className?:string;
}
const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Mobilités Internes'},
    {id:1, libelle:'Carrières internes'},
    {id:2, libelle:'Mobilités Externes'},
]

const MobilitesRootCtrl = (props:MobilitesRootCtrlRoot)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs", "OneCollab", "mobilité")
    const { id } = useParams();
    const employeeQuery = useGetEmployee(parseInt(id || "-1"));
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>({id:0, libelle:'Mobilités Externe'})
    return(
        <div className={`mobilite_root ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"column"} gap={"20px"}>
                <MbjWidgetClassic basis={"100%"}>
                    <MobiliteCollabSwitch readonly={!isAuthWrite}/>
                </MbjWidgetClassic>
            </MbjWidgetClassic>
            <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={Menus}/>
          {MenuCurrent.id === 0 && <OneCollaborateurMobiliteInterne/> }
          {MenuCurrent.id === 1 && <OneCollaborateurCarriereInterne/>}
          {MenuCurrent.id === 2 && <OneCollaborateurMobiliteExterne/>}
        </div>
    )
}

export const MobilitesRoot = styled(MobilitesRootCtrl)`
    padding:2% 2%;
`
