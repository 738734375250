import styled from "styled-components";
import {
    ItemMenuChoixOnPage,
    MbjButton,
    MbjFlex,
    MbjMenuChoixOnPage,
    MbjNavPath,
    useModal
} from "@mbj-front-monorepo/ui";
import {useLocation} from "react-router-dom";
import {GoPlus} from "react-icons/go";
import * as React from "react";
import {MaCvTheque} from "./MaCvTheque/MaCvTheque";
import {AllianceCvTheque} from "./AllianceCvTheque/AllianceCvTheque";
import {AjoutParticulierByCVModal} from "./AjoutParticulierByCVModal";
import {useState} from "react";
import {useGetRight} from "@mbj-front-monorepo/data-access";

interface CvThequeListingCtrlProps{
    className?:string;
}

const Menus:ItemMenuChoixOnPage[] = [
    {id:0, libelle:'Ma CvThèque'},
    {id:1, libelle:"CvThèque de l'alliance"},
]

const CvThequeListingCtrl = (props:CvThequeListingCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "cvTeque", "")
    const [MenuCurrent, setMenuCurrent] = useState<ItemMenuChoixOnPage>(Menus[0])
    const { isShowing: isShowingNewCVThequeCV, toggle: toggleCVThequeCV } = useModal();

    return (
        <div className={`root_cv_theque ${props.className}`}>
            <MbjFlex justify={"flex-end"}>
                {isAuthWrite &&
                    <MbjButton onClick={toggleCVThequeCV} leftIcon={<GoPlus />} alignSelf={"flex-end"}>
                        Ajouter un Cv
                    </MbjButton>
                }
            </MbjFlex>
            <MbjMenuChoixOnPage current={MenuCurrent} setChoix={setMenuCurrent} Items={Menus}/>
            {MenuCurrent.id === 0 ?
                <MaCvTheque/>:
                <AllianceCvTheque/>
            }
            <AjoutParticulierByCVModal isShowing={isShowingNewCVThequeCV} hide={()=>toggleCVThequeCV()}/>
        </div>
    )
}

export const CvThequeListing = styled(CvThequeListingCtrl)`
  padding: 2%;
  height: 88%;
`
