import styled from "styled-components";
import {useGetBadgesEmployee} from "@mbj-front-monorepo/data-access";
import {AvatarEmp, MbjLoading, MbjRank} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ExpertCardCtrlProps{
  className?:string;
  ExpertOne: ExpertOne;
  niveauActuel:number;
}

const ExpertCardCtrl = (props:ExpertCardCtrlProps)=>{
  const employeeBadgesQuery = useGetBadgesEmployee(props.ExpertOne.employee.id);
  return (
    <div className={`Expert_card ${props.className}`}>
        <div className={`in_card`}>
          <div className={`user_face`}><img src={AvatarEmp} alt={"avatar_part"}/></div>
          <div className={`name_user`}>
            <span>{props.ExpertOne.employee.prenom} {props.ExpertOne.employee.nom}</span>
            <MbjRank maxRank={5} rank={props.ExpertOne.niveau} themeColor={"complementary"} isStar />
          </div>
          <div className={`up_niveau`}>

          </div>
          <div className={`badges`}>
            <ul>
            {employeeBadgesQuery.data?.map((BE, idx) => (
                <li className={`badges`} key={`b_${BE.id}`}>{BE.Badge?.libelle}</li>
            ))}
            </ul>
          </div>

        </div>
    </div>
  )
}

export const ExpertCard = styled(ExpertCardCtrl)`
  padding: 0.5rem;
  width: 50%;
  .badges{
    flex-grow: 1;
    padding: 0.5rem 0;
    li{
      list-style: none;
      padding: 0;
      margin: 0;
    }
  }
  .slider_niveau{
    width: 100%;
    height: 12px;
    border-radius: 7px;
    position: relative;
    background: white;
    overflow: hidden;
    .slidy_niveau{
      position: absolute;
      left: 0;
      top:0;
      height: 100%;
      transition:width 300ms;
      background: ${props => props.theme.thirdLighten};
    }
  }
  .name_user{
    padding: 0.25rem;
    text-align: center;
    font-weight: bold;

  }
  .in_card{
    height: 100%;
    background: #f5f1f1;
    border-radius: 6px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    .user_face{
      width: 52px;
      text-align: center;
      img{
        width: 100%;
      }
    }
  }
`

