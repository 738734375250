import styled from "styled-components";
import {useAddConversation} from "@mbj-front-monorepo/data-access";
import {useForm} from "react-hook-form";
import {MbjFlex, MbjIconButton, MbjSimpleInput} from "@mbj-front-monorepo/ui";
import {IoSend} from "react-icons/io5";

interface FormsSendMessageToUserCtrlProps {
  className?:string;
  idRoom:number;
}

const FormsSendMessageToUserCtrl = (props: FormsSendMessageToUserCtrlProps) => {
  const addConversationMutation = useAddConversation(props.idRoom);
  const {
    handleSubmit,
    register,
    formState: { errors }, reset
  } = useForm({
    mode: "onChange",
  });
  return (
    <div className={`form_send ${props.className}`}>
      <form
        className={props.className}
        onSubmit={handleSubmit((data) => {
          addConversationMutation.mutate(data, {
            onSuccess:()=>{
              reset();
            }
          });
        })}
      >
        <MbjFlex align={"center"} width={"100%"}>
          <div style={{flexGrow:1}}>
            <MbjSimpleInput
              name={"content"}
              register={register}
              className={"content_input"}
              registerOptions={{ required: true }}
              width={"100%"}
            />
          </div>
          <MbjIconButton
            type={"submit"}
            icon={<IoSend />}
            ariaLabel={"Envoyer"}
            isRound
            themeColor={"grey"}
            isPending={addConversationMutation.isLoading}
          />
        </MbjFlex>
      </form>
    </div>
  )
}

export const FormsSendMessageToUser = styled(FormsSendMessageToUserCtrl)``
