import styled, { useTheme } from "styled-components";
import {
  CustomSelect,
  logoMBJ,
  MbjAvatar, MbjButton,
  MbjEmailSimpleInput, MbjLoading,
  MbjScrollArea,
  MbjSimpleInput,
  MbjSimpleInputContainer, MbjSimpleTextArea,
} from "@mbj-front-monorepo/ui";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAddAvisPlanFormation } from "@mbj-front-monorepo/data-access";

interface AvisPlanFormationCtrlProps {
  className?: string;
}

interface SelectItems {
  value: string;
  label: string;
}

interface StateValue {
  loading: boolean
  value: boolean | null
}

const getParams = () => {
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const searchParams = new URLSearchParams(url.search)
  const params: any = {};
  searchParams.forEach((value, key) => {
    params[key] = value;
  })

  return params
}

function AvisPlanFormationCtrl(props: AvisPlanFormationCtrlProps) {
  const theme = useTheme();
  const [state, setState] = useState<StateValue>({loading: true, value: null})
  const [alert, setAlert] = useState<boolean>(false)
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm({
    mode: "onTouched",
  });
  const mutation= useAddAvisPlanFormation()


  const onSubmit = (data: any) => {
    setAlert(false)
    const {fccofID} = getParams()
    data.niveauFormation = !data.niveauFormation ? null : data.niveauFormation.value
    data.priceType = data.priceType.value
    data.price = +data.price
    data.duration = +data.duration
    data.nbStagiaires = +data.nbStagiaires
    data.typeFormation = data.typeFormation.value
    data.fccof = `/api/f_c_c_o_fs/${fccofID}`
    mutation.mutate(data, {
      onSuccess: () => setState({ loading: false, value: null }),
      onError: () => setAlert(true)
    });
  };

  const typeCost = [
    { value: "horaire", label: "Horaire" },
    { value: "forfait", label: "Forfait" },
  ];

  const typeFormation = [
    { value: "distancielle", label: "Distancielle" },
    { value: "présentielle", label: "Présentielle" },
  ];

  const typeLevel = [
    { value: "sensiblise", label: "Sensiblisé" },
    { value: "autonomie", label: "Autonomie" },
    { value: "expertise", label: "Expertise" },
    { value: "control", label: "Exécution sous-contrôle" },
  ];

  useEffect(() => {
    const data = getParams()
    fetch(`${process.env["REACT_APP_API_URL"]}/api/check_avis_plan_formation`, {
      method: 'POST',
      headers: { "Accept": "application/json", 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
    })
      .then(res => (res.ok ? setState({ loading: false, value: true }) : setState({ loading: false, value: false })))
      .catch(err => setState({ loading: false, value: false }));
  }, []);

  return (
    <div className={"AvisPlanFormation " + props.className}>
      <MbjScrollArea>
        <div className="imageBack">
          <img src={theme.logo} alt="logo mbj" />
        </div>
        <div className="connexion-form-container">
          <MbjAvatar
            className="MbjBadge"
            src={logoMBJ}
            objectFit={"contain"}
            bg={"#ffffff"}
            withShadow
            padding={"6px"}
          />
          {state.loading && <MbjLoading />}
          {!state.loading && state.value && <>
            <h3>Formulaire de réponse</h3>
            <div>Entrez-vos informations ci-dessous</div>
            {alert && <p className="errorMs">Une erreur est survenue lors de la soumission du formulaire. Veuillez réessayer.</p>}
            <form onSubmit={handleSubmit(onSubmit)} className="Infos" id="form-avis">
              <div className="phr">Si cette formation est en intra, le lieu de formation sera fixé par le client.</div>
              <div>
                <MbjSimpleInputContainer
                  className={"blkContainer"}
                  errors={errors}
                  label={"Formation prévue en intra-entreprise ?"}
                >
                  <MbjSimpleInput name={"intra"} id={"intra"} type={"checkbox"} noPadding register={register} />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Nom de l'organisme de formation *"}>
                  <MbjSimpleInput
                    name={"ofName"}
                    id={"ofName"}
                    type={"text"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Organisme de formation requis",
                      },
                      minLength: {
                        value: 2,
                        message: "Organisme de formation trop court",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"SIRET *"}>
                  <MbjSimpleInput
                    name={"siret"}
                    id={"siret"}
                    type={"text"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "SIRET requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Libelle de la formation *"}>
                  <MbjSimpleInput
                    name={"libelleFormation"}
                    id={"libelleFormation"}
                    type={"text"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Libelle de formation requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Contact formation"}>
                  <MbjSimpleInput
                    name={"contactFormation"}
                    id={"contactFormation"}
                    type={"text"}
                    noPadding
                    register={register}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Email contact formation"}>
                  <MbjEmailSimpleInput
                    name={"emailContactFormation"}
                    id={"emailContactFormation"}
                    noPadding
                    register={register}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Coût d'un stagiaire (€/stagiaire) *"}>
                  <MbjSimpleInput
                    name={"price"}
                    id={"price"}
                    type={"number"}
                    min={0}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Coût d'un stagiaire requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Type de coût *"} htmlFor={"priceType"}>
                  <Controller
                    name={"priceType"}
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        inputId={"value"}
                        required={true}
                        //isLoading={listManagers.isLoading}
                        options={typeCost}
                        getOptionLabel={(type: SelectItems) => type.label}
                        getOptionValue={(type: SelectItems) => type.value}
                        isClearable={true}
                        defaultValue={field.value}
                        onChange={(selectedOption, triggeredAction) => {
                          if (selectedOption) {
                            field.onChange(selectedOption);
                          }
                          if (triggeredAction.action === "clear") {
                            field.onChange(null);
                          }
                        }}
                      />
                    )}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Adresse du lieu de formation"}>
                  <MbjSimpleInput
                    name={"adresseFormation"}
                    id={"adresseFormation"}
                    type={"text"}
                    noPadding
                    register={register}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Durée de la formation (en heure)"}>
                  <MbjSimpleInput name={"duration"} id={"duration"} type={"number"} noPadding register={register} />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer className={"blkContainer"} errors={errors} label={"Labellisation QUALIOPI"}>
                  <MbjSimpleInput name={"qualiopi"} id={"qualiopi"} type={"checkbox"} noPadding register={register} />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Date d'entrée en formation proposée *"}>
                  <MbjSimpleInput
                    type={"date"}
                    name={"formationAt"}
                    id={"formationAt"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Saisir la date d'entrée en formation",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Nombre de stagiaires *"}>
                  <MbjSimpleInput
                    name={"nbStagiaires"}
                    id={"nbStagiaires"}
                    type={"number"}
                    min={1}
                    noPadding
                    register={register} registerOptions={{
                    required: {
                      value: true,
                      message: "Nombre de stagiaire requis",
                    },
                  }}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Type de formation *"} htmlFor={"typeFormation"}>
                  <Controller
                    name={"typeFormation"}
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        required={true}
                        inputId={"value"}
                        //isLoading={listManagers.isLoading}
                        options={typeFormation}
                        getOptionLabel={(type: SelectItems) => type.label}
                        getOptionValue={(type: SelectItems) => type.value}
                        isClearable={true}
                        defaultValue={field.value}
                        onChange={(selectedOption, triggeredAction) => {
                          if (selectedOption) {
                            field.onChange(selectedOption);
                          }
                          if (triggeredAction.action === "clear") {
                            field.onChange(null);
                          }
                        }}
                      />
                    )}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer
                  errors={errors}
                  label={"Niveau visé en fin de formation"}
                  htmlFor={"niveauFormation"}
                >
                  <Controller
                    name={"niveauFormation"}
                    control={control}
                    render={({ field }) => (
                      <CustomSelect
                        inputId={"value"}
                        //isLoading={listManagers.isLoading}
                        options={typeLevel}
                        getOptionLabel={(type: SelectItems) => type.label}
                        getOptionValue={(type: SelectItems) => type.value}
                        isClearable={true}
                        defaultValue={field.value}
                        onChange={(selectedOption, triggeredAction) => {
                          if (selectedOption) {
                            field.onChange(selectedOption);
                          }
                          if (triggeredAction.action === "clear") {
                            field.onChange(null);
                          }
                        }}
                      />
                    )}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Niveau requis *"}>
                  <MbjSimpleInput
                    name={"niveauRequis"}
                    id={"niveauRequis"}
                    type={"text"}
                    noPadding
                    register={register}
                    registerOptions={{
                      required: {
                        value: true,
                        message: "Niveau requis",
                      },
                    }}
                  />
                </MbjSimpleInputContainer>
                <MbjSimpleInputContainer errors={errors} label={"Modalités d'évaluation"}>
                  <MbjSimpleInput name={"modaliteEvaluation"} id={"modaliteEvaluation"} type={"text"} noPadding register={register} />
                </MbjSimpleInputContainer>
              </div>
              <div className={"rowContainer"}>
                <MbjSimpleInputContainer errors={errors} label={"Commentaire"}>
                  <MbjSimpleTextArea
                    name={"comment"}
                    id={"comment"}
                    noPadding
                    register={register}
                  />
                </MbjSimpleInputContainer>
              </div>
              <div className={"text-info"}>Les champs notés * sont obligatoires</div>
              <div className={"rowContainer"}>
                <div>
                  <MbjButton type={"submit"} isPending={mutation.isLoading} alignSelf={"flex-start"}>
                    Soumettre
                  </MbjButton>
                </div>
              </div>
            </form>
          </>}
          {!state.loading && state.value === false && <>
            <h3>Page introuvable</h3>
            <div>La page que vous recherchez est introuvable !</div>
          </>}
          {!state.loading && state.value === null && <>
            <h3>Formulaire soumis avec succès.</h3>
            <div className="txtsuccess">Votre avis a été envoyé.</div>
          </>}
        </div>
      </MbjScrollArea>
    </div>
  );
}

export const AvisPlanFormation = styled(AvisPlanFormationCtrl)((props) => ({
  backgroundColor: props.theme.background1,
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  form: {
    gap: "8px",
    display: "flex",
    flexDirection: "column",
    ".rowContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "start",
      gap: "20px",
      marginBottom: "5px",
    },
    ".blkContainer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "start",
      gap: "20px",
    },
    ".phr": {
      fontSize: "12px",
      fontStyle: "italic",
      color: "#396c71",
      marginTop: "1em",
      textAlign: "left",
    },
    ".text-info" : {
      textAlign: "left"
    }
  },
  ".txtsuccess": {
    margin: "10px 0"
  },
  ".errorMs": {
    background: "#9f3d3d",
    color: "#fff",
    fontSize: "14px",
    margin: "1.5em 0 0",
    padding: ".3em 0",
    borderRadius: "4px"
  },
  ".alert": {
    textAlign: "center",
    color: "#fff",
    backgroundColor: "#396c71",
    fontSize: "15px",
    padding: "1em 0",
    lineHeight: "5px",
    borderRadius: "4px",
    margin: "1em 0"
  },

  "& .imageBack": {
    position: "fixed",
    width: "60vw",
    height: "100%",
    backgroundColor: props.theme.dark,
    top: 0,
    left: 0,
    boxShadow: "6px 0px 11px #00000040",
    img: {
      position: "absolute",
      left: "10px",
      top: "10px",
      maxWidth: "257px",
      width: "80%",
      zIndex: 1,
    },
  },

  "& .connexion-form-container": {
    margin: "80px auto",
    position: "relative",
    display: "flex",
    borderTop: "30px solid " + props.theme.primaryPastel,
    flexDirection: "column",
    textAlign: "center",
    width: "30%",
    padding: "30px",
    background: "rgba(255, 255, 255, 1)",
    borderRadius: "6px",
    //height: "450px",
    minWidth: "fit-content",
    zIndex: 2,
    boxShadow: "0px 3px 6px #00000029",

    ".MbjBadge": {
      position: "absolute",
      top: "-20px",
      right: "20px",
    },

    form: {
      paddingTop: "34px",
    },
  },
}));
