import styled from "styled-components";
import {
  useGetFiPart,
  useGetParticulier,
  useGetParticulierCompetences,
  useGetXpPart
} from "@mbj-front-monorepo/data-access";
import {
  AvatarEmp,
  AvatarPart,
  MbjAccordion,
  MbjAccordionItem, MbjButton,
  MbjFlex,
  MbjLoading,
  MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import {
  MbjEmployeeFiCard,
  MbjEmployeeXpCard,
  MbjParticulierLineCompetence
} from "@mbj-front-monorepo/data-ui";
import * as React from "react";

interface InformationsParticulierCtrlProps{
  className?:string;
  idPart:number;
}

const InformationsParticulierCtrl = (props:InformationsParticulierCtrlProps)=>{
  const ParticulierQuery = useGetParticulier(props.idPart)
  const queryCompetencePart = useGetParticulierCompetences(props.idPart)
  const XpQuery = useGetXpPart(props.idPart);
  const FisQuery = useGetFiPart(props.idPart);
  const DownloadCV = ()=>{
    window.open(`${process.env["REACT_APP_API_URL_ROOT"]}/documents/${ParticulierQuery.data?.CvDocument}`)
  }
  return (
    <div className={`infos_part ${props.className}`}>
      <div className={"wrap_infos"}>
        <div className={"wrap_infos_line"}>
          <div className={"Avatar_place"}><img src={AvatarPart} alt={"avatar_part"}/></div>
          <div className={"wrap_infos"}>
            {ParticulierQuery.isLoading ? (<MbjLoading/>) : ParticulierQuery.isError ? 'Erreur datas' :
              <>
                <div className={"name_place"}>{ParticulierQuery.data.prenom} {ParticulierQuery.data.nom}</div>
                <div className={"Entreprise info_2"}><span>Adresse : </span><span>{ParticulierQuery.data.adresse ? ParticulierQuery.data.adresse : 'nc'}</span></div>
                <div className={"Codepostal info_2"}><span>Code Postal : </span><span>{ParticulierQuery.data.codepostal ? ParticulierQuery.data.codepostal : 'nc'}</span></div>
                <div className={"Ville info_2"} style={{marginBottom:"6px"}}><span>Ville : </span><span>{ParticulierQuery.data.ville ? ParticulierQuery.data.ville : 'nc'}</span></div>
                {ParticulierQuery.data?.CvDocument &&
                  <MbjButton size={"sm"} themeColor={"secondary"} onClick={DownloadCV}>Télécharger le cv</MbjButton>
                }
              </>
            }
          </div>
        </div>
        <MbjFlex direction={"row"} gap={"24px"}>
          <MbjWidgetClassic title={`Parcours professionnel`} basis={"calc(50% - 12px)"} bodyGrow={1}>
            <MbjFlex direction={"column"} width={"100%"}>
              {XpQuery.isLoading ? (
                <MbjLoading />
              ) : XpQuery.isError ? (
                <p>Error...</p>
              ) : (
                XpQuery.data?.map((xp) => <MbjEmployeeXpCard EmployeeXp={xp} key={xp.id} isReadOnly/>)
              )}
            </MbjFlex>
          </MbjWidgetClassic>
          <MbjWidgetClassic title={`Formations`} basis={"calc(50% - 12px)"}>
            <MbjFlex direction={"column"} gap={"20px"}>
              {FisQuery.isLoading ? (
                <MbjLoading />
              ) : FisQuery.isError ? (
                <p>Error...</p>
              ) : (
                FisQuery.data?.map((fi) => <MbjEmployeeFiCard EmployeeFi={fi} key={fi.id} isReadOnly/>)
              )}
            </MbjFlex>
          </MbjWidgetClassic>
        </MbjFlex>
        <MbjWidgetClassic title={"Compétences"}>
          {queryCompetencePart.isLoading ? (
            <MbjLoading />
          ) : queryCompetencePart.isError ? (
            <p>Erreur ...</p>
          ) : (
            <MbjAccordion type="multiple" defaultValue={["item-1"]} className={"accordéon"}>
              {queryCompetencePart.data?.map((CE, idx) => (
                <MbjAccordionItem value={`item-${idx}`} key={CE?.Competence?.id}>
                  <MbjParticulierLineCompetence
                    CompetencesParticulier={CE}
                    idParticulier={props.idPart || -1}
                    isLoading={queryCompetencePart.isLoading}
                    isError={queryCompetencePart.isError}
                    ParticulierActivites={CE.ParticulierActivites}
                    isEditable={false}
                  />
                </MbjAccordionItem>
              ))}
            </MbjAccordion>
          )}
        </MbjWidgetClassic>
      </div>
    </div>
  )
}

export const InformationsParticulier = styled(InformationsParticulierCtrl)`
  padding: 0.5rem 1.5rem;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  .info_2{
    span{
      font-size: 16px;
      &:first-child{
        font-weight: bold;
      }
      &:last-child{
        color:${props => props.theme.primary};
      }
    }
  }
  .name_place{
    font-size: 18px;
    color:${props => props.theme.primary};
    font-weight: bold;
  }
  .wrap_infos_line{
    display: flex;
    justify-content: flex-start;
    .Avatar_place{
      width: 160px;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      img{
        width: 130px;

      }
    }
    .wrap_infos{
      flex-grow: 1;
    }
  }
`
