import styled from "styled-components";
import {
  createSelectColumn,
  MbjButton, MbjConfirmModal,
  MbjFlex,
  MbjLink,
  MbjLoading,
  MbjNavPath,
  MbjScrollArea, MbjTableV2, MbjWidgetClassic, useModal
} from "@mbj-front-monorepo/ui";
import {useLocation} from "react-router-dom";
import {
  useDeleteCV,
  useGetCvTheque,
  useGetCvThequeAlliance,
  useGetMemberAlliances,
  useGetMemberMe, useGetRight
} from "@mbj-front-monorepo/data-access";
import {
  createColumnHelper,
  getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable
} from "@tanstack/react-table";
import {getDateFR, GetElementType} from "@mbj-front-monorepo/utils";
import * as React from "react";
import {GoPlus} from "react-icons/go";
import {useMemo} from "react";

interface AllianceCvThequeCtrlProps{
  className?:string;
}

const AllianceCvThequeCtrl = (props:AllianceCvThequeCtrlProps)=>{
  const {isLoading:loadRightW, isAuth:isAuthWrite} = useGetRight("Dynamique de l'effectif", "write", "Recrutements", "cvTeque", "oneCvTheque")
  const CvQuery = useGetCvThequeAlliance();
  const alliancesQuery = useGetMemberAlliances();
  const memberQuery = useGetMemberMe();
  const mutationDelete = useDeleteCV();
  const { isShowing: isShowingDeleteCv, toggle: toggleDeleteCV } = useModal();
  const ListCv = useMemo(()=>{
    if(!CvQuery.data || !memberQuery.data || !alliancesQuery.data){
      return []
    } else {
      const mesAlliancesID = alliancesQuery.data.filter(a=>a.Alliance).map(a=>a.Alliance?.id||0)
      return CvQuery.data.filter(cv=>{
          const ThisAlliances = cv.alliances;
          if(ThisAlliances){
            let nb = 0;
            ThisAlliances.forEach(ida=>{
              if(mesAlliancesID.indexOf(parseInt(ida))!==-1){
                nb++;
              }
            })
            if(nb>0){
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
      })
    }
  }, [CvQuery.data, memberQuery.data, alliancesQuery.data])

  const columnHelper = createColumnHelper<GetElementType<typeof CvQuery.data>>();
  const columns = [
    createSelectColumn(columnHelper),
    columnHelper.group({
      header: "Identité",
      columns: [
        columnHelper.accessor((row) => row.nom, {
          header: "Nom",
          cell: (info) => <MbjLink to={info.row.original.id?.toString() || "0"}>{info.getValue()}</MbjLink>,
        }),
        columnHelper.accessor((row) => row.prenom, {
          header: "Prénom",
        }),
      ],
    }),
    columnHelper.group({
      header: "Localisation",
      columns: [
        // columnHelper.accessor((row) => row.adresse || "--", {
        //   header: "Adresse",
        // }),
        columnHelper.accessor((row) => row.codepostal || "--", {
          header: "Code Postal",
        }),
        columnHelper.accessor((row) => row.ville || "--", {
          header: "Ville",
        }),
      ],
    }),
  ];

  const table = useReactTable({
    data: ListCv,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    initialState: {
      sorting: [{ id: "Nom", desc: false }],
    },
  });
  const DeleteOne = ()=>{
    const ids = table.getSelectedRowModel().rows.map((row) => row.original.id || 0)
    //console.log(ids);
    ids.forEach(id=>{
      mutationDelete.mutate(id, {
        onSuccess:()=>{
          toggleDeleteCV();
        }
      })
    })

  }
  const VerifyIsMine = ()=>{
    const ids = table.getSelectedRowModel().rows.map((row) => row.original.id || 0)
    let nbNot = 0;
    ids.forEach(id=>{
      const myPart = CvQuery.data?.find(cv=>cv.id === id);
      if(!myPart){
        nbNot++;
      } else {
        if(myPart.MemberId !== memberQuery.data?.id){
          nbNot++;
        }
      }
    })
    return nbNot === 0;
  }
  return (
    <div className={`ma_cv_theque ${props.className}`}>
      <MbjWidgetClassic bodyGrow={1} grow={1} bodyDirection={"column"} bodyNoWrap>
        {CvQuery.isLoading ? (
          <MbjLoading />
        ) : CvQuery.isError ? (
          <p>Error !</p>
        ) : (
          <>
            <div className={"space-between"}>
              <div className={"ResultNumber"}>{table.getRowModel().rows.length} résultats</div>
            </div>
            {((table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()) && isAuthWrite && VerifyIsMine()) && (
              <MbjButton themeColor={"warning"} onClick={toggleDeleteCV} isPending={false}>
                Supprimer {table.getSelectedRowModel().rows.length} cv(s)
              </MbjButton>
            )}
            <MbjScrollArea overflowX={"auto"} grow={1}>
              <MbjTableV2 table={table} Multiple={true}/>
            </MbjScrollArea>
          </>
        )}
      </MbjWidgetClassic>
      <MbjConfirmModal
        isShowing={isShowingDeleteCv}
        hide={toggleDeleteCV}
        title="Confirmation de suppression de cv(s)"
        confirmText={`Etes-vous sûr de vouloir supprimer ${table.getSelectedRowModel().rows.length} cv(s) ?`}
        confirmFunction={DeleteOne}
        isLoading={mutationDelete.isLoading}
      />
    </div>
  )
}

export const AllianceCvTheque = styled(AllianceCvThequeCtrl)`
  padding: 1% 2%;
  display: flex!important;
  flex-direction: column;
  height: 100%;

  .ResultNumber{
    font-size:20px;
  }
  .space-between{
    display:flex;
    justify-content: space-between;
    align-items: center;
}
`

