import styled from "styled-components";
import {ReactNode, useEffect, useRef} from "react";

interface MbjMenuFloatingCtrlProps{
    className?:string;
    children:ReactNode;
    top:string;
    left:string;
    right:string;
    closeMe:()=>void;
}

const MbjMenuFloatingCtrl = (props:MbjMenuFloatingCtrlProps)=>{
    const refMenu = useRef<HTMLDivElement>(null);
    useEffect(() => {
        function handleClickOutside(event:any) {
            if (refMenu.current && !refMenu.current.contains(event.target)) {
                props.closeMe();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [refMenu, props.closeMe]);
    return (
        <div className={`mbj_menu_floating ${props.className}`} ref={refMenu}>
            {props.children}
        </div>
    )
}

export const MbjMenuFloating = styled(MbjMenuFloatingCtrl)`
  position: absolute;
  left:${props=>props.left};
  top:${props=>props.top};
  right:${props=>props.right};
  background: white;
  border-radius: 4px;
  box-shadow: 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12), 0 11px 15px -7px rgba(0,0,0,.2);
  z-index: 4;
  overflow: hidden;
`
