import { operations, paths } from "../openApi-schema";
import jwt_decode from "jwt-decode";

const refreshToken = async <T>(): Promise<string> => {
  const refresh_token = window.localStorage.getItem(localStorageKeyR);
  if (!refresh_token) {
    return "";
    removeToken2();
  }

  const body = { refresh_token: window.localStorage.getItem(localStorageKeyR) };

  const response = await fetch(`${process.env["REACT_APP_API_URL"]}/api/token/refresh`, {
    method: "POST",
    headers: { "content-type": "application/json" },
    body: JSON.stringify(body),
  });
  const data = await response.json();
  console.log(data.status);
  if (data.status !== 200) {
    removeToken2();
  }
  localStorage.setItem("__user_token__", JSON.stringify(data.token).replace(/['"]+/g, ""));
  localStorage.setItem("__user_refreshtoken__", JSON.stringify(data.refresh_token).replace(/['"]+/g, ""));
  return JSON.stringify(data.token).replace(/['"]+/g, "");
};
/**
 * Utility types
 */
// Filtre un objet en retirant les clefs qui ne satisfont pas la condition C
type Filter<T, C> = Pick<
  T,
  {
    [Key in keyof T]: T[Key] extends C ? Key : never;
  }[keyof T]
>;
// Trouve les valeurs qui sont dans tous les ensembles T
type KeysOfUnion<T> = T extends T ? keyof T : never;
// Trouve le type d'une valeur en profondeur dans un object Get<obj, ["player", "firstname"]>
type Get<T, K extends any[], D = never> = K extends []
  ? T
  : K extends [infer A, ...infer B]
  ? A extends keyof T
    ? Get<NonNullable<T[A]>, B>
    : D
  : D;
// Extrait la liste des clefs requises
type RequiredKeys<T> = {
  [K in keyof T]-?: T extends Record<K, T[K]> ? K : never;
}[keyof T];
// Vérifie si toutes les sous clef sont requise dans T, renvoie never si ce n'est pas le cas
type AllRequiredKey<T> = {
  [K in keyof T]: RequiredKeys<T[K]> extends never ? K : never;
}[keyof T];
// Rend certaines clefs optionnelles
type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;
// Rend les clefs, qui n'ont que des valeurs optionnelles, optionnelles
type OptionalDeep<T> = Optional<T, AllRequiredKey<T>>;
// Retire les valeur "never" des clefs d'un objet
type PickDefined<T> = Pick<T, { [K in keyof T]: T[K] extends never ? never : K }[keyof T]>;

/*
 * To get the type of operation response or requestBody:
 */
export type Operation<
  Operation extends keyof operations,
  Schema extends "responses" | "requestBody" = "responses",
  Type = "application/json"
> = Get<
  operations,
  [Operation, Schema, ...(Schema extends "responses" ? [HTTPSuccess, "content", Type] : ["content", Type])]
>;
/*
 * API Related types
 */
export type Paths = keyof paths;
type PathMethods<Path extends Paths> = keyof paths[Path];
type HTTPSuccess = 200 | 201 | 204;
export type Methods = KeysOfUnion<paths[keyof paths]>;
export type ApiResponse<Path, Method, Type = "application/json"> = Get<
  paths,
  [Path, Method, "responses", HTTPSuccess, "content", Type]
>;
type ApiParam<Path, Method, Parameter> = Get<paths, [Path, Method, "parameters", Parameter]>;
export type ApiRequestBody<Path, Method, Type = "application/json"> = Get<
  paths,
  [Path, Method, "requestBody", "content", Type]
>;
export type FetchOptions<Path, Method> = RequestInit & {
  method?: Method;
  headers?: Record<string, string>;
} & OptionalDeep<
    PickDefined<{
      query: ApiParam<Path, Method, "query">;
      params: ApiParam<Path, Method, "path">;
      json: ApiRequestBody<Path, Method, "application/json">;
    }>
  >;
const localStorageKey = "__user_token__";
const localStorageKeyR = "__user_refreshtoken__";

let refreshingFunc: any = undefined;

export function fetchApi<Path extends Paths, Method extends Methods = "get">(
  path: Path,
  options?: FetchOptions<Path, Method>,
  baseUrl?: string
): Promise<ApiResponse<Path, Method>> {
  const o = {
    headers: {},
    ...options,
  } as RequestInit & {
    json?: object;
    headers: Record<string, string>;
    query?: Record<string, any>;
    params?: Record<string, any>;
  };
  const query = o.query;
  const params = o.params;
  const baseUrlToUse = baseUrl || process.env["REACT_APP_API_URL"];
  let url = baseUrlToUse + path;
  o.headers["Accept"] = "application/json";
  // Si on a une clef json, alors la requête aura un body json
  if (o.json) {
    o.body = JSON.stringify(o.json);
    o.headers["Content-Type"] = "application/json";
  }
  //
  let token = window.localStorage.getItem("__user_token__");
  if (token) {
    const jwtDecode: any = jwt_decode(token);
    const exp = jwtDecode["exp"];
    const Today = new Date();
    const timeToday = Today.getTime();
    if (timeToday / 1000 > exp) {
      if (!refreshingFunc) refreshingFunc = refreshToken;
      token = refreshingFunc();
    }
    o.headers["Authorization"] = `Bearer ${token}`;
  }
  // On ajoute les query parameters à l'URL
  if (query) {
    const params = new URLSearchParams();
    Object.keys(query).forEach((k: string) => {
      if (query[k] !== undefined) {
        params.set(k, query[k]);
      }
    });
    url += `?${params.toString()}`;
  }
  // On remplace les paramètres dans l'url ("/path/{id}" par exemple)
  if (params) {
    Object.keys(params).forEach((k) => (url = url.replace(`{${k}}`, params[k])));
  }
  return fetch(url, o).then((r) => {
    if (r.status === 204) {
      return null;
    }
    if (r.status >= 200 && r.status < 300) {
      return r.json();
    }
    if (r.status === 401) {
      removeToken(r);
    }
    throw new Error(`${r.status}`);
  });
}

export function removeToken(response: Response) {
  window.localStorage.removeItem("__user_token__");

  response.json().then((data) => {
    if (data.message === "Expired JWT Token") {
      window.location.assign(window.location.href);
    }
  });
}
export function removeToken2() {
  const paramsF = window.location.search;
  let params = "";
  if (paramsF.length > 1) {
    params = paramsF;
  }
  localStorage.setItem("q", JSON.stringify(params));
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem(localStorageKeyR);
  window.location.assign(window.location.href);
}
