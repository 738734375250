import React from "react";
import styled from "styled-components";
import {Operation, useDeleteFiStagiaire} from "@mbj-front-monorepo/data-access";
import { BiEdit } from "react-icons/bi";
import { BsTrash } from "react-icons/bs";
import {
  MbjCard,
  MbjCardBody,
  MbjCardCtrlProps,
  MbjCardFooter,
  MbjCardHeader,
  MbjConfirmModal,
  MbjFlex,
  MbjHeading,
  MbjIconButton,
  MbjModal,
  MbjStaticInfo,
  useModal,
} from "@mbj-front-monorepo/ui";
import { getDateFR, GetElementType } from "@mbj-front-monorepo/utils";
import { MbjStagiaireFiEditForm } from "./MbjStagiaireFiEditForm";

interface MbjStagiaireFiCardCtrlProps extends MbjCardCtrlProps {
  StagiaireFi: GetElementType<Operation<"getStagiaireFiCollection">>;
  isReadOnly?:boolean;
}

const MbjStagiaireFiCardCtrl = (props: MbjStagiaireFiCardCtrlProps) => {
  const { isShowing, toggle } = useModal();
  const { isShowing: ShowConfirm, toggle: toggleConfirm } = useModal();
  const mutationDelete = useDeleteFiStagiaire();
  const HandleConfirmDelete = () => {
    mutationDelete.mutate(props.StagiaireFi.id || -1, {
      onSettled: () => {
        toggleConfirm();
      },
    });
  };
  return (
    <>
      <MbjCard {...props}>
        <MbjCardHeader>
          <MbjHeading>
            Obtenu le{" "}
            {props.StagiaireFi.obtentionAt ? getDateFR(new Date(props.StagiaireFi.obtentionAt)) : "inconnu"} :{" "}
            {props.StagiaireFi.nom}
          </MbjHeading>
        </MbjCardHeader>
        <MbjCardBody>
          <MbjFlex direction={"column"}>
            <MbjFlex gap={"15px"}>
              <MbjStaticInfo
                label="Niveau d'étude : "
                info={props.StagiaireFi.NiveauEtude ? props.StagiaireFi.NiveauEtude.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
              <MbjStaticInfo
                label="Discipline : "
                info={props.StagiaireFi.Discipline ? props.StagiaireFi.Discipline.libelle : "inconnu"}
                direction={"row"}
                gap={"5px"}
              />
            </MbjFlex>
            {props.StagiaireFi.description && (
              <MbjStaticInfo label="Description : " info={props.StagiaireFi.description} gap={"5px"} />
            )}
          </MbjFlex>
        </MbjCardBody>
        {!props.isReadOnly &&
        <MbjCardFooter justify={"flex-end"}>
          <MbjFlex direction={"row"} justify={"flex-end"} gap={"7px"}>
            <MbjIconButton
                size={"sm"}
              onClick={toggle}
              ariaLabel={"Editer la formation"}
              icon={<BiEdit />}
              themeColor={"secondary"}
              isRound={false}
            />
            <MbjIconButton
                size={"sm"}
              onClick={toggleConfirm}
              ariaLabel={"Supprimer la formation"}
              icon={<BsTrash />}
              themeColor={"warning"}
              isRound={false}
            />
          </MbjFlex>
        </MbjCardFooter>
        }
      </MbjCard>
      <MbjModal isShowing={isShowing} hide={toggle} title="Modification d'une formation initiale">
        <MbjStagiaireFiEditForm StagiaireFi={props.StagiaireFi} hideModal={toggle} />
      </MbjModal>
      <MbjConfirmModal
        isShowing={ShowConfirm}
        hide={toggleConfirm}
        title="Confirmation suppression formation"
        confirmText={"Voulez-vous vraiment supprimer cette formation ?"}
        confirmFunction={HandleConfirmDelete}
        isLoading={mutationDelete.isLoading}
      />
    </>
  );
};

export const MbjStagiaireFiCard = styled(MbjStagiaireFiCardCtrl)``
