import styled from "styled-components";
import {Operation} from "@mbj-front-monorepo/data-access";
import * as React from "react";
import {NavLink} from "react-router-dom";
import {MbjLink} from "@mbj-front-monorepo/ui";

interface NoUserMetierPageCtrlProps{
    className?:string;
    employee?: Operation<"getEmployeeItem">;
}

const NoUserMetierPageCtrl = (props:NoUserMetierPageCtrlProps)=>{
    return (
        <div className={`no_user_metier_suivi ${props.className}`}>
            <p>Le collaborateur n'a pas de métier précisé, le suivi sera disponible au moment de la sélection d'un métier</p>
            <p>Vous pouvez précisez le métier exercé ici</p>
            <div className={`wrap_link`}>
                <MbjLink themeColor={"secondary"} fontSize={"24px"} to={"../informations"}>Informations Collaborateur</MbjLink>
            </div>
        </div>
    )
}

export const NoUserMetierPage = styled(NoUserMetierPageCtrl)`
    padding: 3%;
  p{
    text-align: center;
    font-size: 18px;
    color:${props=>props.theme.primary};
  }
  .wrap_link{
    padding: 0.5rem;
    display: flex;
    justify-content: center;
  }
`
