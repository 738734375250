import styled from "styled-components";
import {useParams} from "react-router-dom";
import {Operation, useGetOFAutoFormation, useGetProximiteReferentiel} from "@mbj-front-monorepo/data-access";
import {
    MbjCardBack,
    MbjDivider,
    MbjErrorData, MbjFlex,
    MbjHeading,
    MbjLoading,
    MbjNumber,
    MbjWidgetClassic
} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface DashboardSuiviCtrlProps{
    className?:string;
    employee?: Operation<"getEmployeeItem">;
}

const DashboardSuiviCtrl = (props:DashboardSuiviCtrlProps)=>{
    if (!props.employee?.id) throw new TypeError("employee.id is undefined");
    const useGetMobBilanQuery = useGetProximiteReferentiel("EUM", props.employee?.id, props.employee?.UserMetier?.id);
    const OfQuery = useGetOFAutoFormation(props.employee?.id)
    return (
        <div className={`dash_suivi ${props.className}`}>
            {useGetMobBilanQuery.isLoading ? <MbjLoading/> : useGetMobBilanQuery.isError ? <MbjErrorData/> :
                props.employee.UserMetier ?
                <MbjWidgetClassic bodyDirection={"column"} gap={"20px"}>
                    <MbjWidgetClassic basis={"100%"}>
                        <MbjFlex>
                            <MbjHeading withLine={false}>
                                {"Suivi de " + props.employee?.prenom + " " + props.employee?.nom}
                            </MbjHeading>
                            <MbjDivider orientation={"vertical"} isFlexChild />
                            <MbjHeading withLine={false}>{"Poste actuel : " + (props.employee?.UserMetier?.namePerso||"Métier non précisé")}</MbjHeading>
                        </MbjFlex>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic bodyDirection={"row"} gap={"20px"}>
                        <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                            <MbjCardBack>
                                <MbjNumber number={useGetMobBilanQuery.data?.Shared?.length||0} legend={"Nombre de compétences acquises"} themeColor={"primary"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                            <MbjCardBack>
                                <MbjNumber number={useGetMobBilanQuery.data?.ToRised?.length||0} legend={"Nombre de compétences à améliorer"} themeColor={"primary"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={"calc(33.33% - 20px)"}>
                            <MbjCardBack>
                                <MbjNumber number={useGetMobBilanQuery.data?.Missed?.length||0} legend={"Nombre de compétences à acquérir"} themeColor={"primary"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic bodyDirection={"row"} gap={"20px"}>
                        <MbjWidgetClassic basis={"calc(50% - 20px)"}>
                            <MbjCardBack>
                                <MbjNumber number={useGetMobBilanQuery.data?.Experts?.length||0} legend={"Nombre Experts internes mobilisables"} themeColor={"primary"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                        <MbjWidgetClassic basis={"calc(50% - 20px)"}>
                            <MbjCardBack>
                                <MbjNumber number={OfQuery.data?.OFActivites?.length||0} legend={"Nombre Organismes de formation"} themeColor={"primary"}/>
                            </MbjCardBack>
                        </MbjWidgetClassic>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>:
                    <p style={{fontSize:"18px"}}>Le collaborateur n'a pas de métier précisé, le suivi sera disponible au moment de la sélection d'un métier</p>
            }
        </div>
    )
}

export const DashboardSuivi = styled(DashboardSuiviCtrl)`
  padding: 3% 2%;
`
