import {
  MbjButton,
  MbjCardClassic,
  MbjLoading,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useGetCustomQuestion,
  useGetCustomQuestions,
  useUpdateLibelleCustomQuestion,
} from "@mbj-front-monorepo/data-access";
import { useForm } from "react-hook-form";
import styled from "styled-components";

interface EditQuestionCtrlProps extends MbjModalCtrlProps {
  questionID: number;
  onToggle: () => void;
}

export const EditQuestionCtrl = (props: EditQuestionCtrlProps) => {
  const questionsQuery = useGetCustomQuestions();
  const myQuestion = useGetCustomQuestion(props.questionID);
  const mutation = useUpdateLibelleCustomQuestion();

  const HandleSend = (data: any) => {
    mutation.mutate({ id: props.questionID, libelle: data.libelle }, {
      onSuccess: () => props.onToggle()
    })
  }

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "onTouched",
  });

  if (myQuestion.isSuccess) {
    setValue("libelle", myQuestion.data.libelle);
  }

  return (
    <div style={{padding: "1em"}}>
      <MbjCardClassic title="Edition" basis={"100%"}>
        {questionsQuery.isLoading ? (
          <MbjLoading />
        ) : (
          <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
            <MbjSimpleInputContainer errors={errors} label={"Libellé de la question à modifier *"}>
              <MbjSimpleInput
                name={"libelle"}
                id={"libelle"}
                type={"text"}
                noPadding
                register={register}
                registerOptions={{
                  required: {
                    value: true,
                    message: "Libellé requis",
                  },
                  minLength: {
                    value: 3,
                    message: "Libellé trop court",
                  },
                  validate: {
                    isExist: (value) => {
                      const TabExist = questionsQuery.data?.filter((question: any) => question.libelle === value);
                      return !(!!TabExist && TabExist.length > 0) || "Cette question existe déjà";
                    },
                  },
                }}
              />
            </MbjSimpleInputContainer>
            <MbjButton  isPending={mutation.isLoading}>
              Editer
            </MbjButton>
          </form>
        )}
      </MbjCardClassic>
    </div>
  );
};

export const EditQuestion = styled(EditQuestionCtrl)`

  .serviceAddForm {
    display: flex;
    flex-direction: column;
    gap: 10px;

    button {
      align-self: end;
      margin-top: 10px;
    }
  }
`;
