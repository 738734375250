import styled from "styled-components";
import React, {ReactNode, useEffect, useState} from "react";
import {BsFillArrowLeftSquareFill} from "react-icons/bs";
import ReactDOM from "react-dom";


interface MbjModalSlidyCtrlProps{
    className?:string;
    children:ReactNode;
    open:boolean;
    closeMe:()=>void;
    title?:string;
}

const VISIBLE = 1;
const HIDDEN = 2;
const ENTERING = 3;
const LEAVING = 4;

const MbjModalSlidyCtrl = (props:MbjModalSlidyCtrlProps)=>{
    const [state, setState] = useState(props.open ? VISIBLE : HIDDEN);
    const cible = document.getElementById("SectionContentSub")||document.body
    useEffect(()=>{
        if(!props.open){
            setState(LEAVING)
        } else{
            setState((s)=>s===HIDDEN ? ENTERING : LEAVING)
        }
    }, [props.open])
    useEffect(()=>{
        if(state === LEAVING){
            const timer = setTimeout(()=>{
                setState(HIDDEN)
            }, 300)
            return ()=>{
                clearTimeout(timer);
            }
        } else if (state === ENTERING){
            const sf = document.body.offsetHeight
            setState(VISIBLE);
            return ()=>{
                console.log('xx')
            }
        }
        return ()=>{
            console.log('xx')
        }
    }, [state])


    const styleModal = {transitionDuration: `300ms`, transitionProperty:"width", width:"100%"}
    if(state!==VISIBLE){
        styleModal.width = "0";
    }

    if(state === HIDDEN){
        return null;
    } else {
        return ReactDOM.createPortal(
            <div className={`Modal_suivi_competence ${props.className}`} style={styleModal}>
                <div className={`header`}>
                    <div className={"Close"} onClick={()=>props.closeMe()}>
                        <div className={`wrap_close`}><BsFillArrowLeftSquareFill/></div>
                    </div>
                    <div className={"lib"}>{props.title ? props.title : ''}</div>
                </div>
                <div className={`body`}>
                    {props.children}
                </div>
            </div>, cible
        )
    }
}

export const MbjModalSlidy = styled(MbjModalSlidyCtrl)`
  position: absolute;
  top: 0;
  right: 0;
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 4;
  .header{
    width: 100%;
    display: flex;
    padding: 0.5rem;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    .Close, .wrap_close{
      font-size: 18px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .wrap_close{
      &:hover{
        cursor: pointer;
      }
    }
    .lib{
      flex-grow: 1;
      font-size: 22px;
      color:${props=>props.theme.primary};
    }
  }
  .body{
    flex-grow: 1;
    padding: 1rem;
    height: 0;
    overflow-y: auto;
  }
`
