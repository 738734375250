import styled from "styled-components";
import {Navigate, Route, Routes, useParams} from "react-router-dom";
import {useGetAlliance, useGetEmployee} from "@mbj-front-monorepo/data-access";
import {InternDesktopLayout, MbjHeading, MbjLink, MbjLoading, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import {FaUserCircle} from "react-icons/fa";
import * as React from "react";
import {AllianceInformations} from "./01_Informations/AllianceInformations";
import {NotFound} from "../../../../components/NotFound/NotFound";
import {OneAllianceMembres} from "./02_Membres/OneAllianceMembres";


interface OneAllianceRooterCtrlProps{
    className?:string;
}

const OneAllianceRooterCtrl = (props:OneAllianceRooterCtrlProps)=>{
    const { id } = useParams();
    const AllianceQuery = useGetAlliance(parseInt(id || "-1"));
    return (
        <Routes>
            <Route
                element={
                    <InternDesktopLayout
                        type="Entreprise"
                        mbjSectionMenu={
                            <MbjSectionMenu
                                className={"custom-sectionMenu " + props.className}
                                otherContent={
                                    <div className={"custom-content-menu"}>
                                        {AllianceQuery.data ? (
                                            <>
                                                <FaUserCircle className={"icon-profile-menu"} />
                                                <MbjHeading marginIn={"0"} themeColor={"primary"} level={"h2"}>
                                                    {AllianceQuery.data?.name}
                                                </MbjHeading>
                                            </>
                                        ) : (
                                            <MbjLoading noMargin={true} height={"25px"} />
                                        )}
                                    </div>
                                }
                            >
                                <MbjLink to="informations">Informations</MbjLink>
                                <MbjLink to="membres">Membres</MbjLink>
                                <></>
                            </MbjSectionMenu>
                        }
                    />
                }
            >
                <Route path="" element={<Navigate to="informations" />} />
                <Route path="/informations" element={<AllianceInformations />} />
                <Route path="/membres" element={<OneAllianceMembres />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    );
}

export const OneAllianceRooter = styled(OneAllianceRooterCtrl)`
  min-height: fit-content;
  justify-content: space-between;
  .custom-content-menu{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;

    .icon-profile-menu{
      fill: ${props=>props.theme.primaryDarken};
      height: 25px;
      width: 25px;
    }
  }
`
