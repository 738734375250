import styled from "styled-components";
import {OnActiChoice, OnEmplChoice} from "../NouvelleFormation";
import {FaPlus} from "react-icons/fa";
import {FaRegTrashCan} from "react-icons/fa6";

interface OneCardEmplToRemoveCtrlProps {
  className?:string;
  Employee:OnEmplChoice;
  RemoveEmpl:(e:OnEmplChoice)=>void;
}

const OneCardEmplToRemoveCtrl = (props: OneCardEmplToRemoveCtrlProps) => {
  return (
    <div className={"empl_to_remove "+props.className } onClick={()=>props.RemoveEmpl(props.Employee)}>
      <div className="libActi">{props.Employee.libelle}</div>
      <div className="wrap_trash">
        <FaRegTrashCan />
      </div>
    </div>
  )
}

export const OneCardEmplToRemove = styled(OneCardEmplToRemoveCtrl)`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  border-radius: 4px;
  .libActi{
    flex-grow: 1;
    padding: 0 0.25rem;
  }
  .wrap_trash{
    width: 0;
    position: relative;
    overflow: hidden;
    svg{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  &:hover{
    cursor: pointer;
    background: ${props => props.theme.complementaryPastel};
    .wrap_trash{
      transition: width 0.3s;
      width: 32px;
    }
  }
`
