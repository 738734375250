import styled from "styled-components";
import {BadgeExpert} from "./BadgeExpert";

interface BadgeMainCtrlProps{
    className?:string;
}

const BadgeMainCtrl = ({className}:BadgeMainCtrlProps)=>{
    return (
        <div className={`Bdges pageStd ${className}`}>
            <BadgeExpert/>
        </div>
    )
}

const BadgeMain = styled(BadgeMainCtrl)`

`


export default BadgeMain;
