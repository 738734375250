import styled from "styled-components";
import {useState} from "react";
import * as React from "react";
import {OneBlockCompetenceStd} from "./OneBlockCompetenceStd";
import {BiChevronUp} from "react-icons/bi";

export interface OneEmployeeMaster{
    Employee:{id:number, name:string},
    niveau:number;
    niveauTarget:number,
    idMaster:number,
    Activite:{id:number, libelle:string, idCompetence:number}
    Competence:{id:number, libelle:string},
}

export interface OneActiviteMaster{
    Activite:{id:number, libelle:string, idCompetence:number}
    EmployeesInActi:OneEmployeeMaster[]
}
export interface OneItemMaster{
    Competence:{id:number, libelle:string},
    Activites:OneActiviteMaster[]
}
export interface MasterBlock{
    Master:{id:number, libelle:string},
    listCompetence:OneItemMaster[],
    nb:number
}

interface OneBlockMasterCtrlProps{
    className?:string;
    Blocks:MasterBlock;
    ClickActi:(item:{id:number, libelle:string, niveau:number})=>void;
    listPlanification:{idEmployee:number, idActi:number}[];
    selectedItems?: any[];
    onCheckboxHandler?: any;
    onCheckboxCompetenceHandler?: any
    onCheckboxActivityHandler?: any
    selectedCompetenceItems: any[];
    selectedActivity: any[];
}

const OneBlockMasterCtrl = (props:OneBlockMasterCtrlProps)=>{
    const [open, setOpen] = useState(false);
    return (
        <div className={`one_block_service ${props.className}  ${open ? 'opened' : 'closed'}`}>
            <div className={`titre_block ${open ? 'opened' : 'closed'}`} onClick={()=>setOpen(o=>!o)}>
                <BiChevronUp className={"chevron_in"}/>
                <div className={`lib_in_titre`}>{props.Blocks.Master.libelle} ({props.Blocks.nb})</div>
            </div>
            <div className={`wrap_list_in`} aria-expanded={!open}>
                {props.Blocks.listCompetence.sort((a, b)=>(a.Competence.libelle||'') > (b.Competence.libelle||'') ? 1 : -1).map((item, idx)=>(
                    <OneBlockCompetenceStd
                        selectedCompetenceItems={props.selectedCompetenceItems}
                        selectedActivity={props.selectedActivity}
                        onCheckboxCompetenceHandler={props.onCheckboxCompetenceHandler}
                        onCheckboxActivityHandler={props.onCheckboxActivityHandler}
                        selectedItems={props.selectedItems}
                        onCheckboxHandler={props.onCheckboxHandler}
                        competenceID={item.Competence.id}
                        key={`one_block${idx}`}
                        titre={`${item.Competence.libelle} (${item.Activites.length})`}
                        ClickActi={props.ClickActi}
                        listPlanification={props.listPlanification}
                        list={item.Activites.map(a=>{
                            const listEmpl:{id:number, prenom?:string, nom?:string, name?:string, niveau:number, niveauCible:number}[] = [];
                            a.EmployeesInActi.forEach(e=>{
                                listEmpl.push({id:e.Employee.id||0, name:e.Employee.name, niveau:e.niveau, niveauCible:e.niveauTarget})
                            })
                            return {
                                activite:{id:a.Activite.id, libelle:a.Activite.libelle},
                                Employees:listEmpl
                            }
                        })}
                    />
                ))}
            </div>
        </div>
    )
}


export const OneBlockMaster = styled(OneBlockMasterCtrl)`
  margin-bottom: 15px;
  .titre_block{
    font-size: 20px;
    padding: 0.25rem;
    color:${props=>props.theme.primary};
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    &.closed{
      background: white;
      border-radius: 4px;
    }
    &.opened{
      background: transparent;
      .chevron_in{
        transform: rotate(180deg);
      }
    }
    &:hover{
      cursor: pointer;
    }
  }
  .wrap_list_in[aria-expanded="true"] {
    max-height: 0;
    padding: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
  }
  .wrap_list_in{
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 30000px;
    overflow-y: auto;
    transition: max-height 1s ease-in-out;
    position: relative;
    padding-bottom: 0.5rem;
  }
`
