import styled, {useTheme} from "styled-components";
import {useParams} from "react-router-dom";
import {useGetEntretienAnnuelSlugE, useGetEntretienAnnuelSlugR} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjLoading, useModal} from "@mbj-front-monorepo/ui";
import {useEffect, useMemo, useRef, useState} from "react";
import {ModalSignatureEmployee} from "./ModalSignatureEmployee";
import {LienInactif} from "./LienInactif";
import {ModalSignatureResponsable} from "./ModalSignatureResponsable";

interface SignatureEntretienResponsableCtrlProps {
  className?:string;
}

const SignatureEntretienResponsableCtrl = (props: SignatureEntretienResponsableCtrlProps) => {
  const {slug} = useParams();
  const refFrame = useRef<HTMLIFrameElement>(null);
  const Entretien = useGetEntretienAnnuelSlugR(slug ? slug : '')
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const {isShowing, toggle} = useModal()

  const myEntretien = useMemo(()=>{
    if(!Entretien.data){
      return null;
    } else {
      return Entretien.data[0];
    }
  }, [Entretien.data])
  const theme = useTheme();
  return (
    <div className={`signature_entretien ${props.className}`}>
      <div className="header">
        <div className="wrap_logo">
          <img src={theme.logo}/>
        </div>
      </div>
      <div className="content_sign">
        {Entretien.isLoading ? <MbjLoading/> :
          myEntretien ?
            <div className={`wrap_for_sign`}>
              <div className="intro">
                <h1>Signature de votre entretien individuel d'évaluation</h1>
                <p>Merci de prendre connaissance du document ci-dessous, et de valider sa signature</p>
              </div>
              <div className="wrap_fram">
                <iframe onScroll={()=>{
                  console.log('ici')
                }} id={"MyFrame"} ref={refFrame} width={1100} height={600} src={`${process.env["REACT_APP_API_URL_ROOT"]}/Entretiens/${myEntretien.document}`}/>
              </div>
              <div className="block_signature">
                <div className={`accept accept-lecture`}>
                  <label>
                    <input
                      type="checkbox"
                      checked={checked1}
                      onChange={() => setChecked1(o => !o)}
                    />
                    <span>Je reconnais avoir pris connaissance du document dans son ensemble</span>
                  </label>
                </div>
                <div className={`accept accept-content`}>
                  <label>
                    <input
                      type="checkbox"
                      checked={checked2}
                      onChange={() => setChecked2(o => !o)}
                    />
                    <span>Je reconnais que l'ensemble du document est conforme à l'entretien que j'ai passé avec le collaborateur</span>
                  </label>
                </div>
                <div className="wrap_sender">
                  <MbjButton onClick={toggle} size={"lg"} disabled={!checked1 || !checked2} themeColor={"primary"}>Signer l'entretien</MbjButton>
                </div>
              </div>
            </div> :
            <LienInactif/>
        }
      </div>
      {myEntretien &&
        <ModalSignatureResponsable hide={toggle} idEntretien={myEntretien.id||0} isShowing={isShowing} title={"signature"}/>
      }
    </div>
  )
}

export const SignatureEntretienResponsable = styled(SignatureEntretienResponsableCtrl)`
  background: ${props => props.theme.background1};
  height: 100vh;
  overflow-y: auto;
  .intro{
  }
  .wrap_fram{
    margin: 20px auto;
  }
  .content_sign {
    padding: 1% 2%;
  }
  .header{
    width: 100%;
    background: ${props=>props.theme.dark};
    padding: 1rem;
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .wrap_logo{
      height: 100%;
      position: relative;
    }
    img{
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 70%;
      width: auto;
    }
  }
  .accept{
    margin-bottom: 8px;
    label{
      font-size: 18px;
      display: flex;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;
      span{
        font-weight: bold;
      }
    }
  }
  .wrap_sender{
    padding: 1rem 0;
    display: flex;
    justify-content: center;
  }
`
