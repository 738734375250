import {
  CustomSelect,
  MbjButton, MbjLabel,
  MbjLoading,
  MbjModal,
  MbjModalCtrlProps,
  MbjSimpleInput,
  MbjSimpleInputContainer,
} from "@mbj-front-monorepo/ui";
import {
  useAddNewEquipeManager, useEditLeadManager,
  useGetAllListEquipeManager, useGetEmployeeByUserID,
  useGetListPotentialsManager,
} from "@mbj-front-monorepo/data-access";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as React from "react";
import { useEffect } from "react";

interface ModalEditLeadEquipeCtrlProps extends MbjModalCtrlProps {
  teamUserId: number;
  teamId: number;
  teamLibelle: string;
}

const ModalEditLeadEquipeCtrl = (props: ModalEditLeadEquipeCtrlProps) => {
  const userEquipesQuery = useGetAllListEquipeManager();
  const mutation = useEditLeadManager(props.teamId);
  const listManagers=  useGetListPotentialsManager()
  const teamHeadManager = useGetEmployeeByUserID(props.teamUserId)


  const HandleSend = (data: any) => {
    const result: {nom?: string, User?: string} = {}
    if (data.libelle) {
      result['nom'] = data.libelle
    }
    if (data.manager?.userId) {
      result['User'] = `/api/users/${data.manager.userId}`
    }
    console.log(result)
    mutation.mutate(result,
      {
        onSettled: () => {
          props.hide();
        },
      }
    );
  };
  const {
    setValue,
    control,
    handleSubmit,
    register,
   formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    setValue('libelle', props.teamLibelle)
  }, [props.teamLibelle, setValue])

  return (
    <MbjModal {...props} title="Modifier le manager" maxWidth={"700px"}>
      {userEquipesQuery.isLoading ? (
        <MbjLoading />
      ) : (
        <form onSubmit={handleSubmit(HandleSend)} className="serviceAddForm">
          <MbjSimpleInputContainer errors={errors} label={"Libellé de l'équipe à ajouter *"}>
            <MbjSimpleInput
              name={"libelle"}
              id={"libelle"}
              type={"text"}
              noPadding
              register={register}
              registerOptions={{
                required: {
                  value: true,
                  message: "Libellé requis",
                },
                minLength: {
                  value: 3,
                  message: "Libellé trop court",
                },
                validate: {
                  isExist: (value) => {
                    const TabExist = userEquipesQuery.data?.filter((team: any) => team.nom === value);
                    console.log(!!TabExist && TabExist.length > 0);
                    return !(!!TabExist && TabExist.length > 0) || "Cette équipe existe déjà";
                  },
                },
              }}
            />
          </MbjSimpleInputContainer>
          <div className="Nom">
            <MbjLabel>Manager actuel</MbjLabel>
            {teamHeadManager.isLoading ?
              <MbjLoading /> : teamHeadManager.isSuccess ? teamHeadManager.data.map((item: any, index: number) => <div
                className="data" key={index.toString()}>{item.prenom + " " + item.nom}</div>) : 'Aucun manager'}
          </div>
          <MbjSimpleInputContainer errors={errors} label={"Sélectionner un manager"} htmlFor={"manager"}>
            <Controller
              name={"manager"}
              control={control}
              render={({ field }) => (
                <CustomSelect
                  inputId={"userId"}
                  isLoading={listManagers.isLoading}
                  options={listManagers.data}
                  getOptionLabel={(manager: any) => manager.prenom + ' ' + manager.nom}
                  getOptionValue={(manager: any) => manager.userId}
                  isClearable={true}
                  defaultValue={field.value}
                  onChange={(selectedOption, triggeredAction) => {
                    if (selectedOption) {
                      field.onChange(selectedOption);
                    }
                    if (triggeredAction.action === "clear") {
                      field.onChange(null);
                    }
                  }}
                />
              )}
            />
          </MbjSimpleInputContainer>
          <MbjButton className={"sendButton"} isPending={mutation.isLoading}>
            Editer
          </MbjButton>
        </form>
      )}
    </MbjModal>
  );
};

export const ModalEditLeadEquipe = styled(ModalEditLeadEquipeCtrl)((props) => ({
  ".serviceAddForm": {
    display: "flex",
    flexDirection: "column",
    gap: "10px",

    button: {
      alignSelf: "end",
    },
  },
}));
