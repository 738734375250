import styled from "styled-components";
import {Route, Routes, useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import * as React from "react";
import {FamilleContext} from "./OneFamille";
import {CompetencesLayout} from "./CompetencesLayout";
import {ListActivites} from "./ListActivites";
import {MyCompetence} from "../OneMetierGestionCompetences";

interface OneCompetenceCtrlProps{
  className?:string;
}

const OneCompetenceCtrl = (props:OneCompetenceCtrlProps)=>{
  const {idCompetence} = useParams();
  const Context = useContext(FamilleContext)
  const [Competence, setCompetence] = useState<MyCompetence|null>(null)
  useEffect(() => {
    if(Context && idCompetence){
      const Competence = Context.Competences.find(c=>c.id+""===idCompetence);
      if(Competence){
        setCompetence(Competence);
      }
    }
  }, [idCompetence, Context]);
  return (
    <Routes>
      <Route element={
        <CompetencesLayout link={{link:'../', libelle:Competence ? Competence.libelle : '', niveau : Competence ? Competence.Niveau : 0, backColor:'#cdd9ea'}}/>}>
        <Route path="" element={<ListActivites Activites={Competence ? Competence.Activites : []}/>} />
        <Route path="*" element={<p>pas troue</p>} />
      </Route>
    </Routes>
  )
}

export const OneCompetence = styled(OneCompetenceCtrl)`
  padding: 1% 2%;
  .lien_do{
    display: block;
    text-decoration: none;
    background: white;
    padding:1rem;
    margin-bottom: 10px;
  }
  .title_link{
    text-decoration: none;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    margin: 15px 0;
  }
`
