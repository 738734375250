import styled from "styled-components";
import {ReactNode} from "react";

interface MbjCardBackCtrlProps{
    className?:string;
    children:ReactNode;
    title?:string;
    height?:string;
    isClickable?:boolean;
    onClick?:()=>void;
    Flight?:boolean;
    noShadow?:boolean;
}

const MbjCardBackCtrl = (props:MbjCardBackCtrlProps)=>{
    const ClickMe = ()=>{
        if(props.onClick){
            props.onClick();
        }
    }
    return (
        <div className={`card_back ${props.className} ${props.isClickable ? 'clickable' : ''}`} {...(props.onClick && {onClick:ClickMe})}>
            {props.title &&
                <div className={`header_card_back`}>{props.title}</div>
            }
            <div className="content">
              {props.children}
            </div>
        </div>
    )
}

export const MbjCardBack = styled(MbjCardBackCtrl)`
  background: white;
  border-radius: 8px;
  padding: 12px;
  width: 100%;
  box-shadow: ${props => props.noShadow ? "none" :  "1px 1px 3px rgba(0,0,0,.2)"};
  height: ${props=>props.height ? props.height : "auto"};
  .header_card_back{
    margin-bottom: 12px;
    font-weight: bold;
  }
  transition: transform 0.3s;
  display: flex;
  flex-direction: column;
  .content{
    flex-grow: 1;
    overflow-y: auto;
  }
  &.clickable{

    &:hover{
      transform: translateY(-10px);
      transition: transform 0.3s;
      cursor: pointer;
    }
  }
`
