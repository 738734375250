import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useGetUserMetier, useMutateUserMetier} from "@mbj-front-monorepo/data-access";
import {MbjButton, MbjCardBack, MbjSimpleInput, MbjSimpleInputContainer} from "@mbj-front-monorepo/ui";
import {useForm} from "react-hook-form";
import {useEffect} from "react";
import {getDateEN} from "@mbj-front-monorepo/utils";
import * as React from "react";

interface OneMetierFormModifCtrlProps{
    className?:string;
    readonly:boolean;
}

const OneMetierFormModifCtrl = (props:OneMetierFormModifCtrlProps)=>{
    const {id} = useParams();
    const userMetierQuery = useGetUserMetier(id ? parseInt(id) :-1)
    const UMMutation = useMutateUserMetier(parseInt(id || "-1"));
    const {
        handleSubmit,
        register,
        formState: { errors },
        reset,
    } = useForm({
        mode: "onChange",
    });
    useEffect(() => {
        const defaultValues = {
            namePerso: userMetierQuery.data?.namePerso,
            formatCible: userMetierQuery.data?.formatCible ? userMetierQuery.data?.formatCible : "",
            formatCibleAt: userMetierQuery.data?.formatCibleAt
                ? getDateEN(new Date(userMetierQuery.data?.formatCibleAt))
                : "",
        };
        reset(defaultValues);
    }, [reset, userMetierQuery.data]);

    const onSubmit = (data: any) => {
        const myData = {
            namePerso: data.namePerso,
            formatCible: data.formatCible ? parseInt(data.formatCible) : null,
            formatCibleAt: data.formatCibleAt ? getDateEN(new Date(data.formatCibleAt)) : null,
        };
        //console.log(myData);
        UMMutation.mutate(myData);
    };
    return (
        <MbjCardBack>
            <div className={`form_change_user_metier ${props.className}`}>
                <form onSubmit={handleSubmit(onSubmit)} className="namePersoForm">
                    <MbjSimpleInputContainer errors={errors} label={"Libellé"}>
                        <MbjSimpleInput
                            name={"namePerso"}
                            id={"namePerso"}
                            disabled={props.readonly}
                            noPadding
                            register={register}
                            registerOptions={{
                                required: {
                                    value: true,
                                    message: "Libellé requis",
                                },
                                minLength: {
                                    value: 4,
                                    message: "Nécessite au moins 4 caractères",
                                },
                            }}
                        />
                    </MbjSimpleInputContainer>
                    <MbjSimpleInputContainer errors={errors} label={"Effectif à atteindre..."}>
                        <MbjSimpleInput name={"formatCible"} type={"number"} id={"formatCible"} noPadding register={register} disabled={props.readonly} />
                    </MbjSimpleInputContainer>
                    <MbjSimpleInputContainer errors={errors} label={"...à échéance de"}>
                        <MbjSimpleInput
                            name={"formatCibleAt"}
                            type={"date"}
                            id={"formatCibleAt"}
                            noPadding
                            register={register}
                            disabled={props.readonly}
                        />
                    </MbjSimpleInputContainer>
                    {!props.readonly &&
                        <MbjButton isPending={UMMutation.isLoading}>Enregistrer</MbjButton>
                    }
                </form>
            </div>
        </MbjCardBack>
    )
}

export const OneMetierFormModif = styled(OneMetierFormModifCtrl)`
  form{
  display: flex;
  flex-direction: column;
  gap: 10px;
  }
`
