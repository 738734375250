import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useGetMemberMe } from "../member";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";

export const useGetMemberMetierReclassementsByMemberScopeAlliance = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () =>
    fetchApi("/api/member_metier_reclassements", { method: "get", query: { MemberScopeAlliance: memberId } });
  return useQuery(["member_metier_reclassements", { memberScopeAlliance: memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetMemberMetierReclassementsByAlliance = (allianceId?: number) => {
  const promise = () =>
    fetchApi("/api/member_metier_reclassements", { method: "get", query: { Alliance: allianceId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["member_metier_reclassements", { allianceId }],
    promise,
    {
      enabled: !!allianceId,
    }
  );
};

export const useGetMemberMetierReclassements = (options?: any) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/member_metier_reclassements", { method: "get", query: { Member: memberId } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["member_metier_reclassements", { memberId }], promise, {
    ...options,
    enabled: !!memberId,
  });
};

export const useGetMemberMetierReclassement = (memberMetierReclassementId?: number, withStats?: boolean) => {
  const promise = () =>
    fetchApi("/api/member_metier_reclassements/{id}", {
      method: "get",
      params: { id: memberMetierReclassementId + "" },
      query: { withStats },
    });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(
    ["member_metier_reclassements", { memberMetierReclassementId, withStats }],
    promise,
    {
      enabled: !!memberMetierReclassementId && memberMetierReclassementId!==-1,
    }
  );
};

export const useAddMemberMetierReclassement = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;

  return useMutation(
    (data: Operation<"postMemberMetierReclassementCollection", "requestBody">) => {
      return fetchApi("/api/member_metier_reclassements", { method: "post", json: data });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["member_metier_reclassements", { memberId }]);
        toast.success("Reclassement ajouté !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteMemberMetierReclassement = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (memberMetierReclassementId: number) => {
      return fetchApi("/api/member_metier_reclassements/{id}", {
        method: "delete",
        params: { id: memberMetierReclassementId + "" },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["member_metier_reclassements", { memberId: memberQuery.data?.id }]);
        toast.success("Reclassement cloturé !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useGetFindSearchReclassment = (userMetierID?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/custom_results_search_reclassement/{id}", {
    method: "get",
    params: { id: memberId + "" },
    query: { userMetierID: userMetierID }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["custom_results_search", { memberId }, userMetierID], promise, {
    enabled: !!memberId && !!userMetierID,
  });
};

export const useGetTopRecrutementByUserMetier = (userMetierID?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/top_recrutements/by_usermetier/{id}", {
    method: "get",
    params: { id: userMetierID },
    query: { memberID: memberId }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["top_recrutements_by_usermetier", { memberId }, userMetierID], promise, {
    enabled: !!memberId && !!userMetierID,
  });
};

export const useGetTopRecrutementByEmployee = (empID?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/top_recrutements/by_employee/{id}", {
    method: "get",
    params: { id: empID },
    query: { memberID: memberId }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["top_recrutements_by_employee", { memberId }, empID], promise, {
    enabled: !!memberId && !!empID,
  });
};


export const useFindMatchingAllianceMembers = (userMetierID?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/matching_results_search_reclassement/{id}", {
    method: "get",
    params: { id: memberId + "" },
    query: { userMetierID: userMetierID }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["matching_results_search", { memberId }, userMetierID], promise, {
    enabled: !!memberId,
  });
};
export const useFindMatchingAllianceMembersForEmployee = (employeeID?: number) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/matching_results_search_reclassement_employee/{id}", {
    method: "get",
    params: { id: memberId + "" },
    query: { employeeID: employeeID }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["matching_results_search_employee", { memberId }, employeeID], promise, {
    enabled: !!memberId,
  });
};
