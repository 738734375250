import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchApi, Operation } from "../../services/fetchApi";
import { useGetMemberMe } from "../member";

export const useGetMemberServices = () => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/services", { method: "get", query: { Member: memberId + "" } });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["services", { memberId }], promise, {
    enabled: !!memberId,
  });
};

export const useGetMemberServicesByUserID = (userID: number|undefined|null) => {
  const memberQuery = useGetMemberMe();
  const memberId = memberQuery.data?.id;
  const promise = () => fetchApi("/api/services/by_user/{id}", {
    method: "get",
    params: { id: userID},
    query: { memberID: memberId + "" }
  });
  return useQuery<Awaited<ReturnType<typeof promise>>, Error>(["services_by_user", userID], promise, {
    enabled: !!userID,
  });
};
export const useAddService = () => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (data: any) => {
      return fetchApi("/api/services", {
        method: "post",
        json: { ...data, Member: "/api/members/" + memberQuery.data?.id },
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["services", { memberId: memberQuery.data?.id }]);
        queryClient.invalidateQueries(["services_by_user"]);
        toast.success("Service ajouté !", { autoClose: 2000 });
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useRemoveUserFromService = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: number) => {
      return fetchApi(`/api/services/update_user_droits/${data}`, {
        method: "put",
        json: { action: null },
      })
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["services_by_user"]);
        queryClient.invalidateQueries(["services"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useMutateService = (serviceId: number, memberId?: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (data: Operation<"putServicesItem", "requestBody">) => {
      return fetchApi("/api/services/{id}", {
        method: "put",
        params: { id: serviceId.toString() },
        json: data,
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["services", { memberId: memberId }]);
        toast.success("Service modifié !");
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};

export const useDeleteService = (memberId: number) => {
  const queryClient = useQueryClient();

  return useMutation(
    (serviceId: number) => {
      return fetchApi("/api/services/{id}", { method: "delete", params: { id: serviceId + "" } });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["services", { memberId }]);
        toast.success("Service supprimé", {});
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};


export const useEditLeadService = (id: number) => {
  const queryClient = useQueryClient();
  const memberQuery = useGetMemberMe();

  return useMutation(
    (data: any) => {
      return fetchApi("/api/services/{id}", {
        method: "put",
        params: { id: id + ""},
        json: data,
      });
    },
    {
      onSuccess: (newData) => {
        queryClient.invalidateQueries(["services", { memberId: memberQuery.data?.id }]);
        queryClient.invalidateQueries(["services_by_user"]);
        queryClient.invalidateQueries(["employees_userID"]);
      },
      onError: (error: any) => {
        toast.error("Erreur : " + error.message, {});
      },
    }
  );
};
