import styled from "styled-components";
import {ReactElement} from "react";
import {IconBaseProps} from "react-icons/lib/cjs/iconBase";

interface MbjSubMenuActionCtrlProps{
    className?:string;
    icon: ReactElement<IconBaseProps>;
    libelle:string;
    callback:()=>void;
}

const MbjSubMenuActionCtrl = (props:MbjSubMenuActionCtrlProps)=>{
    return (
        <div className={`menu_sub ${props.className}`} onClick={props.callback}>
            <div className={`icon_place`}>{props.icon}</div>
            <div className={`lib_place`}>{props.libelle}</div>
        </div>
    )
}

export const MbjSubMenuAction = styled(MbjSubMenuActionCtrl)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  padding: 0.25rem;

  &:hover{
    cursor: pointer;
    background: ${props => props.theme.primaryPastel};
  }
`
