import {useMutation} from "@tanstack/react-query";
import { fetchApi } from "../../services/fetchApi";
import {components} from "../../openApi-schema";
import {toast} from "react-toastify";


export const usePostDemandeContact = ()=>{
    // const queryClient = useQueryClient();
    return useMutation((datas:components["schemas"]["DemandeContactOF-write.contact"])=>{
        return fetchApi("/api/demande_contact_o_fs", {
            method: "post",
            json: datas,
        });
    }, {
        onSuccess:()=>{
            toast.success("Demande envoyée", {});
        },
        onError:(error: any) =>{
            toast.error("Erreur : " + error.message, {});
        }
    })
}
