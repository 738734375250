import styled from "styled-components";

interface LienInactifCtrlProps {
  className?:string;
}

const LienInactifCtrl = (props: LienInactifCtrlProps) => {
  return (
    <div className={props.className}>
      <p>Nous sommes désolé, mais il semble que ce lien ne soit plus actif</p>
    </div>
  )
}

export const LienInactif = styled(LienInactifCtrl)`
  margin: 20px;
  border:solid #ccc 1px;
  border-radius: 12px;
  padding: 1rem;
  font-size: 17px;
`
