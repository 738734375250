import styled from "styled-components";
import {MbjBlockMenu} from "../../../molecules/MbjMenu/MbjMenuLinks";
import {NavLink, To, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";

interface MbjItemMenuMobile2CtrlProps {
  className?:string;
  Block:MbjBlockMenu;
}

const MbjItemMenuMobile2Ctrl = (props: MbjItemMenuMobile2CtrlProps) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    const tabLoc = location.pathname.split("/")
    setIsActive(tabLoc[2] === props.Block.toDirect)
  }, [location]);
  if(!props.Block.toDirect){
    return null;
  }
  return (
    <NavLink to={props.Block.toDirect as To} className={`mbj_tab_link ${props.className} ${isActive ? 'is-active' : ''}`}>
      <div className="in_nav">
        <div className="content_nav">
          <div className={`icon_place`}>{props.Block.icon}</div>
          <div className={`lib_place`}>{props.Block.libelle}</div>
        </div>
      </div>
    </NavLink>
  )
}

export const MbjItemMenuMobile2 = styled(MbjItemMenuMobile2Ctrl)`
    width: 100%;
  text-decoration: none;
  z-index: 9;
  color: white;
    height: 100%;
    border-radius: 50%;
    display: block;

    background: ${props=>props.theme.dark};
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;
  border:solid transparent 3px;
  .in_nav{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
    &.is-active{
      background: white;
      top:-30%;
      border:solid ${props=>props.theme.background1} 3px;

      .in_nav{
        background: ${props=>props.theme.dark};;
      }
    }
  .icon_place{
    text-align: center;
    svg{
      margin: auto;
    }
  }
  .lib_place{
    text-align: center;
    font-size: 11px;
  }
`
