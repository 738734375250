import styled from "styled-components";
import {components} from "@mbj-front-monorepo/data-access";
import {MbjCardBack} from "@mbj-front-monorepo/ui";
import {BiUser} from "react-icons/bi"
import {BsFillCalendarFill, BsPhoneFill} from "react-icons/bs"
import {MdOutlineAlternateEmail} from "react-icons/md";

interface OneInvitationCurrentCtrlProps{
    className?:string;
    Invitation:components["schemas"]["DemandeInvitationAlliance"]
}

const OneInvitationCurrentCtrl = ({className, Invitation}:OneInvitationCurrentCtrlProps)=>{
    return (
        <div className={`one_invitation_current ${className}`}>
            <MbjCardBack>
                <div className={`name`}>{Invitation.entreprise}</div>
                <div className={`group_infos`}>
                    <div className={`line_infos`}>
                        <div className={'icon_place'}><BiUser/></div>
                        <div className={`lib_infos`}>{Invitation.prenom} {Invitation.nom}</div>
                    </div>
                    <div className={`line_infos`}>
                        <div className={'icon_place'}><BsPhoneFill/></div>
                        <div className={`lib_infos`}>{Invitation.telephone}</div>
                    </div>
                    <div className={`line_infos`}>
                        <div className={'icon_place'}><MdOutlineAlternateEmail/></div>
                        <div className={`lib_infos`}>{Invitation.email}</div>
                    </div>
                </div>
                <div className={`group_infos`}>
                    <div className={`line_infos`}>
                        <div className={'icon_place'}><BsFillCalendarFill/></div>
                        <div className={`lib_infos`}>{Invitation.creationAt ? new Date(Invitation.creationAt).toLocaleDateString() : '--'}</div>
                    </div>
                </div>
            </MbjCardBack>
        </div>
    )
}
export const OneInvitationCurrent = styled(OneInvitationCurrentCtrl)`
  padding: 1rem;
  width: 50%;
  .name{
    font-size: 18px;
    color:${props=>props.theme.primary};
    font-weight: bold;
    margin-bottom: 8px;
  }
  .line_infos{
    display: flex;
    gap:8px;
    align-items: center;
    .icon_place{
      color:${props=>props.theme.third};
    }
  }
  .group_infos{
    margin: 0 0 10px 8px;
  }
`
