import styled from "styled-components";
import {ReactNode} from "react";
import {MbjCardBack} from "@mbj-front-monorepo/ui";

interface ObjectifsSectionCtrlProps{
    className?:string;
    children:ReactNode;
    titre:string;
}

const ObjectifsSectionCtrl = ({className, children, titre}:ObjectifsSectionCtrlProps)=>{
    return (

        <MbjCardBack title={titre}>
            <div className={`objectifs ${className}`}>
            {children}
            </div>
        </MbjCardBack>

    )
}

export const ObjectifsSection = styled(ObjectifsSectionCtrl)`

`
