import styled from "styled-components";
import {EquipeManager} from "./EquipeManagerMain";
import {BsTrash} from "react-icons/bs";
import {MbjIconButton, MbjModal2, useModal} from "@mbj-front-monorepo/ui";
import React from "react";
import {ModalAddMember} from "./components/ModalAddMember";
import {FaPlus} from "react-icons/fa";

interface GestionOneEquipeManagerCtrlProps {
  className?:string;
  Equipe:EquipeManager;
}

const GestionOneEquipeManagerCtrl = ({className, Equipe}: GestionOneEquipeManagerCtrlProps) => {
  const {isShowing, toggle} = useModal();
  const TrashMember = ()=>{
    console.log('trash')
  }
  const ClickAdd = ()=>{
    toggle();
  }
  return (
    <div className={`gestion_one_equipe ${className}`}>
      <div className="titreEquipe">{Equipe.nom}</div>
      <div className="wrap_member_team">
      {Equipe.Employees.map(item=>(
        <div className={`one_member_equipe_manager`} key={`Em${item.idEq}${item.id}`}>
            <div className="nameMember">{item.prenom} {item.nom}</div>
            <div className="trash_member">
              <MbjIconButton
                size={"sm"}
                onClick={(e: any) => {
                  e.stopPropagation();
                  TrashMember();
                }}
                ariaLabel={"Supprimer le member"}
                icon={<BsTrash />}
                themeColor={"warning"}
                isRound={false}
              />
            </div>
        </div>
      ))}
      </div>
      <div className="addMember" onClick={toggle}>
        <FaPlus />
        <span>Ajouter des membres</span>
      </div>
      <MbjModal2 open={isShowing} closeMe={toggle} titre={"Ajouter des membres"} Wd={"65%"}>
        <ModalAddMember idEquipe={Equipe.id} closeMe={toggle}/>
      </MbjModal2>
    </div>
  )
}

export const GestionOneEquipeManager = styled(GestionOneEquipeManagerCtrl)`
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  margin-bottom: 15px;
  .titreEquipe{
    font-size: 22px;
    font-weight: bold;
    color:${props => props.theme.secondaryDarken};

  }
  .one_member_equipe_manager{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    .nameMember{
      font-weight: bold;
    }
    &:hover{
      background:${props=>props.theme.greyPastel};
    }
  }
  .wrap_member_team{
    margin: 15px 0;
    max-height: 300px;
    overflow-y: auto;
    padding: 0.25rem;
    display: flex;
    flex-direction: column;
  }
  .addMember{
    margin-top: 10px;
    width: max-content;
    padding: 0.25rem 0.5rem;
    background: ${props=>props.theme.greyPastel};
    border-radius: 4px;
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    &:hover{
      cursor: pointer;
    }
  }
`
