import styled from "styled-components";
import {NavLink, To} from "react-router-dom";
import {ReactElement} from "react";
import {IconBaseProps} from "react-icons/lib/cjs/iconBase";

interface MbjSideDirectLinkCtrlProps{
    className?:string;
    to:To;
    icon: ReactElement<IconBaseProps>;
    libelle:string
}

const MbjSideDirectLinkCtrl = (props:MbjSideDirectLinkCtrlProps)=>{
    return (
        <NavLink to={props.to} className={`mbj_side_link ${props.className}`}>
            <div className={`icon_place`}>{props.icon}</div>
            <div className={`lib_place`}>{props.libelle}</div>
        </NavLink>
    )
}

export const MbjSideDirectLink = styled(MbjSideDirectLinkCtrl)`
  font-size: 17px;
  display: flex;
  justify-content: flex-start;
  color: white;
  gap: 8px;
  padding: 0.5rem;
  text-decoration: none;
  &:visited{
    color: white;
  }
  &:hover{
    background: ${props => props.theme.background2};
    cursor: pointer;
    color: ${props=>props.theme.dark};
    font-weight: bold;
  }
`
