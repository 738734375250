import styled from "styled-components";
import {useConvertEmployeeToUser2, useGetEmployees} from "@mbj-front-monorepo/data-access";
import {useMemo, useState} from "react";
import {MbjButton} from "@mbj-front-monorepo/ui";
import * as React from "react";

interface ModalDistribAccesCtrlProps {
  className?:string;
  closeMe:()=>void;
}

const ModalDistribAccesCtrl = ({className, closeMe}: ModalDistribAccesCtrlProps) => {
  const employeesQuery = useGetEmployees();
  const [employeeToConvert, setEmployeeToConvert] = useState<number[]>([]);
  const [error, setError] = useState<string>('')
  const mutation = useConvertEmployeeToUser2();
  const listWithout = useMemo(()=>{
    if(!employeesQuery.data){
      return [];
    } else {
      return employeesQuery.data.filter(e=>!e.userId);
    }
  }, [employeesQuery.data])
  const submit = ()=>{
    employeeToConvert.forEach(id=>{
      mutation.mutate(id, {
        onSuccess:()=>{
          closeMe();
        }
      })
    })
  }
  const SwitchIn = (id:number)=>{
    const Actuals = [...employeeToConvert];
    if(Actuals.indexOf(id)===-1){
      setEmployeeToConvert(eps=>[...eps, id])
    } else {
      setEmployeeToConvert(eps=>[...eps.filter(i=>i!==id)])
    }
  }
  return (
    <div className={`distrib_acces ${className}`}>
      <div className="wrap_list">
        {listWithout.map((item, idx)=>(
          <div className={`one_empToConvert ${employeeToConvert.indexOf(item.id||0) !==-1 ? 'selected' : ''} `} key={`Ec${idx}${item.id||0}`} onClick={()=>SwitchIn(item.id||0)}>
              <span>{item.prenom||''} {item.nom||''}</span>
          </div>
        ))}
      </div>
      <div className={"wrap_sender"}>
        <MbjButton size={"sm"} themeColor={"secondary"} onClick={submit}>Envoyer les accès</MbjButton>
      </div>
    </div>
  )
}

export const ModalDistribAcces = styled(ModalDistribAccesCtrl)`
  padding: 1rem;
  width: 20vw;
  .wrap_list{
    display: flex;
    flex-direction: column;
    max-height: 60vh;
    overflow-y: auto;
  }
  .one_empToConvert{
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    &:hover{
      cursor: pointer;
      span{
        font-weight: bold;
      }
    }
    &.selected{
      background: ${props => props.theme.thirdPastel};
    }
  }
  .wrap_sender{
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
  }
`
