import styled from "styled-components";
import {MbjFlex, MbjIconButton, MbjSimpleInput} from "@mbj-front-monorepo/ui";
import {IoSend} from "react-icons/io5";
import {useForm} from "react-hook-form";
import {useAddConversation} from "@mbj-front-monorepo/data-access";

interface FormSendMessageCtrlProps{
    className?:string;
    idRoom:string;
}

const FormSendMessageCtrl = (props:FormSendMessageCtrlProps)=>{
    const addConversationMutation = useAddConversation(props.idRoom);
    const {
        handleSubmit,
        register,
        formState: { errors }, reset
    } = useForm({
        mode: "onChange",
    });
    return (
        <div className={`form_send ${props.className}`}>
            <form
                className={props.className}
                onSubmit={handleSubmit((data) => {
                    addConversationMutation.mutate(data, {
                        onSuccess:()=>{
                            reset();
                        }
                    });
                })}
            >
                <MbjFlex align={"center"} width={"100%"}>
                    <div style={{flexGrow:1}}>
                        <MbjSimpleInput
                            name={"content"}
                            register={register}
                            className={"content_input"}
                            registerOptions={{ required: true }}
                            width={"100%"}
                        />
                    </div>
                    <MbjIconButton
                        type={"submit"}
                        icon={<IoSend />}
                        ariaLabel={"Envoyer"}
                        isRound
                        themeColor={"grey"}
                        isPending={addConversationMutation.isLoading}
                    />
                </MbjFlex>
            </form>
        </div>
    )
}

export const FormSendMessage = styled(FormSendMessageCtrl)`
  width: 100%;
`
