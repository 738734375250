import * as React from 'react';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

interface InternDesktopLayoutCtrlProps {
  /**
   * Classe HTML de intern desktop layout :
   */
  className?: string;
  /**
   * Représente le site vers lequel l'utilisateur peut aller (POUR DEBUG)
   */
  type?: string;
  /**
   * Menu de la section :
   */
  mbjSectionMenu?: ReactElement<HTMLElement>;
}

function InternDesktopLayoutCtrl(props: InternDesktopLayoutCtrlProps) {
  const sectionMenu: any = props.mbjSectionMenu;

  return (
    <div className={props.className}>
      {props.mbjSectionMenu ? (
        React.cloneElement(sectionMenu, { className: props.mbjSectionMenu.props.className + ' sectionMenu' })
      ) : (
        <React.Fragment />
      )}
      <div className="section-content">
        <Outlet />
      </div>
    </div>
  );
}
export const InternDesktopLayout = styled(InternDesktopLayoutCtrl)((props) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  minHeight: 0,

  '& .sectionMenu': {
    zIndex: 1,
  },

  '& > .section-content': {
    flexGrow: 1,
    maxWidth: '100%',
    overflowX: 'hidden',

    '& > *': {
      //display: 'inline-block',
      width: '100%',
    },
  },
}));
