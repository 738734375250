import styled from "styled-components";
import {Navigate, Route, Routes} from "react-router-dom";
import {InternDesktopLayout, MbjLink, MbjSectionMenu} from "@mbj-front-monorepo/ui";
import * as React from "react";
import {DashboardFormationCollaborateur} from "./Dashboard/DashboardFormationCollaborateur";
import {BesoinsRooter} from "./Besoins/BesoinsRooter";
import {PlannificationAlliance} from "./PlannificationAlliance/PlannificationAlliance";
import {NotFound} from "../../../components/NotFound/NotFound";
import {useGetRight} from "@mbj-front-monorepo/data-access";
import { AnalysePriorisationRooter } from "./AnalysePriorisation/AnalysePriorisationRooter";
import { PlanificationRooter } from "./Plannification/PlanificationRooter";

interface FormationRooterCtrlProps{
    className?:string;
}

const FormationRooterCtrl = (props:FormationRooterCtrlProps)=>{
    const {isLoading:loadRightR_dash, isAuth:isAuthR_dash} = useGetRight("Collaborateurs", "read", "Besoins formation", "Tableau de bord", "")
    const {isLoading:loadRightR_bs, isAuth:isAuthR_bs} = useGetRight("Collaborateurs", "read", "Besoins formation", "Besoins", "")
    const {isLoading:loadRightR_ap, isAuth:isAuthR_ap} = useGetRight("Collaborateurs", "read", "Besoins formation", "Analyse et priorisation", "")
    const {isLoading:loadRightR_pl, isAuth:isAuthR_pl} = useGetRight("Collaborateurs", "read", "Besoins formation", "planification", "")
    const {isLoading:loadRightR_pla, isAuth:isAuthR_pla} = useGetRight("Collaborateurs", "read", "Besoins formation", "planification alliances", "")
    return (
        <div className={"Formation_rooter" + props.className}>
            <Routes>
                <Route
                    element={
                        <InternDesktopLayout
                            type="Entreprise"
                            mbjSectionMenu={
                                <MbjSectionMenu>
                                    {isAuthR_dash &&
                                        <MbjLink to="dashboard">Tableau de bord</MbjLink>
                                    }
                                    {isAuthR_bs &&
                                        <MbjLink to="besoin_formation">Besoins en formations</MbjLink>
                                    }
                                    {isAuthR_ap &&
                                      <MbjLink to="analyse_priorisation">Analyse et priorisation</MbjLink>
                                    }
                                    {isAuthR_pl &&
                                        <MbjLink to="plannification_formation">Planification formation</MbjLink>
                                    }
                                    {isAuthR_pla &&
                                        <MbjLink to="plannification_alliance">Planification formation Alliances</MbjLink>
                                    }
                                </MbjSectionMenu>
                            }
                        />
                    }
                >
                    <Route path="" element={<Navigate to="dashboard" />} />
                    <Route path="dashboard" element={<DashboardFormationCollaborateur />} />
                    <Route path="/besoin_formation/*" element={<BesoinsRooter />} />
                    <Route path="/analyse_priorisation/*" element={<AnalysePriorisationRooter />} />
                    <Route path="/plannification_formation/*" element={<PlanificationRooter />} />
                    <Route path="/plannification_alliance" element={<PlannificationAlliance />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </div>
    );
}

export const FormationRooter = styled(FormationRooterCtrl)`
    height: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`

