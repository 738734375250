import * as React from "react";
import styled from "styled-components";

interface MbjWidgetBodyCtrlProps {
  /**
   * Classe HTML de l'élément?
   */
  className?: string;
  /**
   * Corps de la card ?
   */
  children?: React.ReactNode;
  padding?:string;
}

function MbjWidgetBodyCtrl(props: MbjWidgetBodyCtrlProps) {
  return <div className={`MbjWidgetBody ${props.className}`}>{props.children}</div>;
}

export const MbjWidgetBody = styled(MbjWidgetBodyCtrl)((props) => ({
  padding: props.padding ? props.padding :  "8px 0 0 0",
  flexGrow:1,
}));
MbjWidgetBody.defaultProps = {};
