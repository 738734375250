import styled from "styled-components";
import React from "react";

interface MbjLoadingMessageCtrlProps {
  className?: string;
  children: React.ReactNode;
}

const MbjLoadingMessageCtrl = (props: MbjLoadingMessageCtrlProps) => {
  return <div className={props.className}>{props.children}</div>;
};

export const MbjLoadingMessage = styled(MbjLoadingMessageCtrl)`
  margin: 1rem 0;
  font-size: 16px;
  text-align: center;
  color: #396c71;
  font-style: italic;
`;

