import styled from "styled-components";
import {MbjWidgetClassic} from "@mbj-front-monorepo/ui";
import {OneCollaborateurPrez} from "./OneCollaborateurPrez";
import {OneCollaborateurBadges} from "./OneCollaborateurBadges";
import {OneCollaborateurResumeCompetence} from "./OneCollaborateurResumeCompetence";
import {OneCollaborateurXpResume} from "./OneCollaborateurXpResume";
import {OneCollaborateurFiResume} from "./OneCollaborateurFiResume";
import {useGetRight} from "@mbj-front-monorepo/data-access";

interface OneCollaborateurInformationsCtrlProps{
    className?:string;
}

const OneCollaborateurInformationsCtrl = (props:OneCollaborateurInformationsCtrlProps)=>{
    const {isLoading:loadRight, isAuth:isAuthWrite} = useGetRight("Collaborateurs", "write", "Mes collaborateurs", "OneCollab", "infos")
    return (
        <div className={`one_collab_infos ${props.className}`}>
            <MbjWidgetClassic bodyDirection={"column"}>
                <MbjWidgetClassic bodyDirection={"row"}>
                    <MbjWidgetClassic basis={"60%"}>
                        <OneCollaborateurPrez readonly={!isAuthWrite}/>
                    </MbjWidgetClassic>
                    <MbjWidgetClassic grow={1}>
                        <OneCollaborateurBadges readonly={!isAuthWrite}/>
                    </MbjWidgetClassic>
                </MbjWidgetClassic>
                <MbjWidgetClassic basis={"100%"}>
                    <OneCollaborateurResumeCompetence/>
                </MbjWidgetClassic>
                <MbjWidgetClassic bodyDirection={"row"}>
                    <MbjWidgetClassic basis={"calc(50% - 12px)"} title={`Parcours professionnel`}>
                        <OneCollaborateurXpResume/>
                    </MbjWidgetClassic> {/*Parcours pro*/}
                    <MbjWidgetClassic basis={"calc(50% - 12px)"} title={`Formations`}>
                        <OneCollaborateurFiResume/>
                    </MbjWidgetClassic> {/*formation*/}
                </MbjWidgetClassic>
            </MbjWidgetClassic>
        </div>
    )
}

export const OneCollaborateurInformations = styled(OneCollaborateurInformationsCtrl)`
  padding: 2% 2%;
`
