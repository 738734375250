import * as React from "react";
import styled, { useTheme } from "styled-components";
import { BiMenu } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import { MbjLink } from "../../../atoms/MbjLink/MbjLink";
import { useNavigate } from "react-router-dom";
import {useModal} from "../../../organisms/MbjModal/useModal.hooks";
import {GiHamburgerMenu} from "react-icons/gi";
import {MbjModalFullMobile} from "../../../organisms/MbjModal/MbjModalFullMobile";
import {ItemMenuBurger} from "./ItemMenuBurger";
import {FiUnlock} from "react-icons/fi";

interface MbjHeadBarMobile2CtrlProps {
  /**
   * Classe HTML de la HeadBar ?
   */
  className?: string;
  /**
   * Hauteur de la headbar
   */
  height?: string;
  /**
   * Largeur de la headbar
   */
  width?: string;
  Disconnect:()=>void;

}

function MbjHeadBarMobile2Ctrl(props: MbjHeadBarMobile2CtrlProps) {
  const theme = useTheme();
  const {isShowing, toggle} = useModal();
  const navigate = useNavigate();
  const DisconnectMe = ()=>{
    props.Disconnect();
    navigate("/")
  }
  return (
    <div className={props.className}>
      <div className="space_burger"/>
      <div className={"bar"}>
        <img src={theme.logo} alt="logo mbj" className={"logo "} />
      </div>
      <div className="space_burger">
        <div className={"wrap_burger"} onClick={toggle}>
          <GiHamburgerMenu />
        </div>
      </div>
      <MbjModalFullMobile open={isShowing} closeMe={toggle} titre={"Menu"}>
        <div style={{padding:"1rem"}}>
          <ItemMenuBurger Action={DisconnectMe} libelle={"Déconnexion"} icon={<FiUnlock />}/>
        </div>
      </MbjModalFullMobile>
    </div>
  );
}

export const MbjHeadBarMobile2 = styled(MbjHeadBarMobile2Ctrl)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .space_burger{
    width: 40px;
    display: flex;
    justify-content: flex-end;
    .wrap_burger{
      color: white;
      font-size: 24px;
      svg{

      }
    }
  }
  background: ${props => props.theme.dark};
    height: 9vh;
    padding: 0 0.5rem;
  .bar{
    flex-grow: 1;
    text-align: center;
    img{
      height: 5vh;
      margin: auto;
    }
  }
`

MbjHeadBarMobile2.defaultProps = {
  width: "100vw",
  height: "8vh",
};
